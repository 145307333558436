import React, { useEffect, useState } from 'react';
import { useFormikContext, Field, FieldArray } from 'formik';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import { Card } from '@producepay/pp-ui';

import FormikTextField from 'src/components/formik/FormikTextField';
import FormikToggle from 'src/components/formik/FormikToggle';
import { ISFRValues } from 'src/routes/Deals/ISFR/types';
import Fee from '../../components/Fee';
import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';
import { getEmptyFeeEntity } from '../../helpers';

/**
 * The structure of these components is:
 *
 * ApplicationFee
 *  -> Fee
 *     -> PaymentMethod
 *     -> PaymentMethod
 *  -> Fee
 *     -> PaymentMethod
 *     -> PaymentMethod
 *
 */

const ApplicationFee = () => {
  const [companyItems, setCompanyItems] = useState([]);

  const {
    values: { applicationFee, companies },
    status: { readOnly },
    setFieldValue,
  } = useFormikContext<ISFRValues>();

  useEffect(() => {
    if (applicationFee.fees.length === 0) {
      setFieldValue('applicationFee.fees', [getEmptyFeeEntity()]);
    }

    const allCompanies = filter(flatMap(companies), 'identifier');

    if (allCompanies.length) {
      setCompanyItems(
        allCompanies.map(c => ({
          label: c.name,
          value: c.identifier,
        })),
      );
    }
  }, [applicationFee.fees.length, companies, setFieldValue]);

  return (
    <Card className="pb-4">
      <SectionHeader title="Application Fee" subtitle="Financial settings for application fees" />
      <SectionItem
        title="Refund Application Fees"
        subtitle="As a cash rebate when the Minimum Annual Produce Volume is reached"
        controls={
          <Field
            as="checkbox"
            name="applicationFee.isRefundable"
            component={FormikToggle}
            data-testid="application-fee-is-refundable"
          />
        }
      />
      <SectionItem
        title="Minimum Annual Produce Volume"
        border={false}
        controls={
          <FormikTextField
            name="applicationFee.minimumAnnualProduceVolumeForRefund"
            iconPrefix={<span>$</span>}
            withSimpleErrorStyling
          />
        }
      />
      <div className="pt-2 px-4 mx-4">
        <FieldArray
          name="applicationFee.fees"
          render={({ push: pushApplicationFee, remove: removeApplicationFee }) => (
            <>
              {applicationFee.fees.map((fee, feeIndex) => (
                <React.Fragment key={fee.key}>
                  <Fee
                    fee={fee}
                    applicationFee={applicationFee}
                    feeIndex={feeIndex}
                    removeApplicationFee={removeApplicationFee}
                    setFieldValue={setFieldValue}
                    companyItems={companyItems}
                    readOnly={readOnly}
                  />
                </React.Fragment>
              ))}

              <div className="pt-5">
                <button
                  className="text-primary body2 font-semibold"
                  type="button"
                  onClick={() => pushApplicationFee(getEmptyFeeEntity())}
                  data-testid="add-application-fee"
                  disabled={readOnly}
                >
                  + Add Application Fee
                </button>
              </div>
            </>
          )}
        />
      </div>
    </Card>
  );
};

export default ApplicationFee;
