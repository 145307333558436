type Route = (...args: string[]) => string;
type NestedRoutes = { [key: string]: Route | NestedRoutes };

const makeNestedRoutes = <T extends NestedRoutes>(input: T) => input;

/**
 * Authenticated routes
 */
export const dashboard: Route = () => '/';
export const receivePayments: Route = () => '/payments/receive';
export const companiesIndex: Route = () => '/companies';

export const companyFundingAccounts: Route = (companyIdentifier, filter = 'active') =>
  `/companies/${companyIdentifier}/profile/fundingAccounts/${filter}`;
export const companyFundingAccountNew: Route = companyIdentifier =>
  `/companies/${companyIdentifier}/profile/fundingAccounts/new`;
export const companyFundingAccountEdit: Route = (companyIdentifier, fundingAccountIdentifier) =>
  `/companies/${companyIdentifier}/profile/fundingAccounts/${fundingAccountIdentifier}/edit`;

export const unreconciled: Route = () => '/payments/unreconciled';
export const fundActivityIndex: Route = () => '/fund_activities';

export const company = makeNestedRoutes({
  dashboard: companyIdentifier => `/companies/${companyIdentifier}/dashboard`,
  orders: {
    index: companyIdentifier => `/companies/${companyIdentifier}/orders`,
    overview: (companyIdentifier, orderId) => `/companies/${companyIdentifier}/orders/${orderId}`,
  },
  availableFinancing: companyIdentifier => `/companies/${companyIdentifier}/available-financing`,
  availableBalance: companyIdentifier => `/companies/${companyIdentifier}/available-balance`,
  profile: companyIdentifier => `/companies/${companyIdentifier}/profile`,
});

/**
 *  Deals Routes
 */

export const dealsIndex: Route = () => '/deals';
export const dealsEdit: Route = dealIdentifier => `/deals/${dealIdentifier}/edit`;
export const dealsISPPCreate: Route = () => '/deals/ispp/create';
export const dealsISFRCreate: Route = () => '/deals/isfr/create';
export const dealsPNMCreate: Route = () => '/deals/pnm/create';
export const dealsPNTRCreate: Route = () => '/deals/pntr/create';
export const dealsPNMSCreate: Route = () => '/deals/pnms/create';
export const dealsPSCreate: Route = () => '/deals/ps/create';

/**
 *  Send Payments Routes
 */
export const sendPayments: Route = () => '/payments/send';
export const sendPaymentsUnprepared: Route = companyIdentifier => `/payments/send/unprepared/${companyIdentifier}`;
export const sendPaymentsPrepared: Route = companyIdentifier => `/payments/send/prepared/${companyIdentifier}`;
export const sendPaymentsPreparedDetail: Route = (
  recipientCompanyIdentifier,
  sourceAccount,
  destinationAccountIdentifier,
) => `/payments/send/prepared/${recipientCompanyIdentifier}/detail/${sourceAccount}/${destinationAccountIdentifier}`;
export const sendPaymentsApproved: Route = sourceAccount => `/payments/send/approved/${sourceAccount}`;
export const sendPaymentsApprovedDetail: Route = (sourceAccountIdentifier, destinationAccountIdentifier) =>
  `/payments/send/approved/${sourceAccountIdentifier}/detail/${destinationAccountIdentifier}`;

/**
 *  Send Payments Routes
 */
export const reconcilePayments: Route = () => '/reconcile-payments';
export const reconcilePaymentsImports: Route = () => '/reconcile-payments/imports';
export const reconcilePaymentsPayments: Route = () => '/reconcile-payments/payments';
export const reconcilePaymentPaymentsEdit: Route = paymentIdentifier =>
  `/reconcile-payments/payments/${paymentIdentifier}`;
export const reconcilePaymentsEscrow: Route = () => '/reconcile-payments/escrow';
