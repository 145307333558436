import React, { FC, useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Card } from '@producepay/pp-ui';
import gql from 'graphql-tag';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import {
  FormikTable,
  TableToolbar,
  IndexCell,
  HeaderCell,
  DateCell,
  columnSum,
  currencyCellFormatter,
  SelectCell,
  SelectItem,
} from 'src/components/elements/Table';
import { GET_COMPANIES_AUTOCOMPLETE } from 'src/graphql/queries/getCompanies';
import { INSEASON_FUND_IDENTIFIER } from 'src/routes/Deals/constants';

import PSFooter from '../../components/PSDealNavigation/PSFooter';
import { PSPrepaymentValue, PSValues } from '../../types';

export const GET_SOURCE_ACCOUNTS = gql`
  query SourceAccounts {
    sourceAccounts(purpose: PAYMENTS) {
      value: identifier
      label: name
    }
  }
`;

export const GET_FUNDING_ACCOUNTS = gql`
  query PrepaymentFundingAccounts($companyIdentifiers: [String]) {
    fundingAccounts(companyIdentifiers: $companyIdentifiers, status: "active") {
      value: identifier
      subtitle: name
      companyIdentifier
    }
  }
`;

const columns = [
  {
    Header: props => <HeaderCell {...props} name="#" />,
    Cell: IndexCell,
    accessor: 'indexer',
    width: 50,
    canResize: false,
    align: 'center',
    verticalAlign: 'center',
    selectable: false,
    minHeight: 50,
  },
  {
    Header: props => <HeaderCell {...props} name="Effective Date" />,
    Cell: DateCell,
    accessor: 'effectiveDate',
  },
  {
    Header: props => <HeaderCell {...props} name="Repayment Due Date" />,
    Cell: DateCell,
    accessor: 'dueDate',
  },
  {
    Header: props => <HeaderCell {...props} name="Prepayment" formula={columnSum} />,
    accessor: 'amount',
    formatter: currencyCellFormatter,
  },
  {
    Header: props => <HeaderCell {...props} name="Distribution Fee" formula={columnSum} />,
    accessor: 'distributionFee',
    formatter: currencyCellFormatter,
  },
  {
    Header: props => <HeaderCell {...props} name="Daily Late Fee" />,
    accessor: 'dailyLateFee',
    formatter: currencyCellFormatter,
  },
];

const getDefaultPrepayment = (fundingAccounts): Partial<PSPrepaymentValue> => ({
  prepaymentUuid: uuidv4(),
  sourceBankAccountUuid: INSEASON_FUND_IDENTIFIER,
  recipientBankAccountUuid: fundingAccounts?.length ? fundingAccounts[0]?.value : null,
});

export const PrepaymentsView: FC = () => {
  const {
    values: {
      general: {
        companies: { producers, distributors },
      },
      prepayments,
    },
    setFieldValue,
  } = useFormikContext<PSValues>();

  // eslint-disable-next-line no-spaced-func
  const { data: { fundingAccounts } = {} } = useQuery<{
    fundingAccounts: (SelectItem & { companyIdentifier: string })[];
  }>(GET_FUNDING_ACCOUNTS, {
    variables: {
      companyIdentifiers: producers.concat(distributors).map(item => item.identifier),
    },
  });
  const { data: { sourceAccounts } = {} } = useQuery<{ sourceAccounts: SelectItem[] }>(GET_SOURCE_ACCOUNTS);
  const { data: { companies } = { companies: [] } } = useQuery<{ companies: SelectItem[] }>(GET_COMPANIES_AUTOCOMPLETE);

  const SourceBankAccount = useMemo(() => {
    return props => <SelectCell {...props} items={sourceAccounts ?? []} />;
  }, [sourceAccounts]);

  const RecipientBankAccount = useMemo(() => {
    const map = new Map(companies.map(item => [item.value, item]));
    return props => (
      <SelectCell
        {...props}
        items={(fundingAccounts ?? []).map(item => ({ ...item, label: map.get(item.companyIdentifier).label }))}
      />
    );
  }, [fundingAccounts, companies]);

  const allColumns = useMemo(
    () => [
      ...columns,
      {
        Header: props => <HeaderCell {...props} name="Source Bank Account" />,
        Cell: SourceBankAccount,
        accessor: 'sourceBankAccountUuid',
        pastable: false,
      },
      {
        Header: props => <HeaderCell {...props} name="Recipient Bank Account" />,
        Cell: RecipientBankAccount,
        accessor: 'recipientBankAccountUuid',
        pastable: false,
      },
    ],
    [SourceBankAccount, RecipientBankAccount],
  );

  useEffect(() => {
    if (isEmpty(prepayments) && fundingAccounts !== undefined) {
      setFieldValue('prepayments', [getDefaultPrepayment(fundingAccounts)]);
    }
  }, [fundingAccounts, prepayments, setFieldValue]);

  return (
    <div className="p-6">
      <Card>
        <TableToolbar title="Prepayments" />
        <FormikTable
          createNewRow={(_prev, _next, _current) => {
            return getDefaultPrepayment(fundingAccounts);
          }}
          fieldName="prepayments"
          columns={allColumns}
          outerBorders={false}
        />
      </Card>
      <PSFooter className="py-6" />
    </div>
  );
};
