import React from 'react';

import { FormikDealStatus, OnDealSave } from 'src/routes/Deals/types';
import { ISPPValuesPayload } from 'src/routes/Deals/ISPP/types';
import { DealQueryResponse } from 'src/routes/Deals/Edit/types';
import FormikISPPForm from '../Form';
import { initialValues } from '../Create';
import { transformToValues } from '../helpers';
import { FormAction, FormStatus } from '../../constants';

type ISPPEditProps = {
  dealResponse: DealQueryResponse;
  handleDealSave: OnDealSave;
};

export default ({ dealResponse, handleDealSave }: ISPPEditProps) => {
  const { dealConfig, dealUuid, status } = dealResponse;
  const deal = dealConfig ? transformToValues(dealConfig as ISPPValuesPayload, dealUuid, status) : initialValues;
  const readOnly = status === FormStatus.Active;

  const initialStatus: FormikDealStatus = {
    readOnly,
  };

  return (
    <FormikISPPForm
      initialValues={deal}
      initialStatus={initialStatus}
      action={FormAction.Edit}
      onDealSave={handleDealSave}
    />
  );
};
