import { useTable, useFlexLayout, UseTableOptions, TableInstance, TableOptions, Column, PluginHook } from 'react-table';
import { DisplayCell } from './DisplayCell';
import { ReactTableProps } from './ReactTable';

export interface UseReactTableOptions<T extends Record<string, unknown>>
  extends ReactTableProps<T>,
    Pick<UseTableOptions<T>, 'data'> {
  options?: Partial<TableOptions<T>>;
  customOptions?: Record<string, unknown>;
  pluginHooks?: PluginHook<T>[];
  // Have yo use {} here bc TS is unable to assign Column<Data>[]' to 'Column<Record<string, unknown>>[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  columns: Column<{}>[];
  initialState?: Record<string, unknown>;
}

export function useReactTable<T extends Record<string, unknown>>({
  data,
  columns,
  options = {},
  customOptions = {},
  pluginHooks = [],
  initialState,
}: UseReactTableOptions<T>): TableInstance<Record<string, unknown>> {
  return useTable<T>(
    {
      columns,
      data,
      defaultColumn: {
        Cell: DisplayCell,
      },
      initialState,
      ...options,
      ...customOptions,
    } as TableOptions<T>,
    useFlexLayout,
    ...pluginHooks,
  ) as TableInstance<Record<string, unknown>>;
}
