import React from 'react';

type DistributorDescriptionProps = {
  distributorUuid: string;
  distributorName?: string;
};

const DistributorDescription = ({ distributorUuid, distributorName }: DistributorDescriptionProps) => {
  if (distributorUuid === null) return <span>All Distributors</span>;
  if (!distributorName) return <span className="text-red-600">Distributor Not Found</span>;

  return <span>{distributorName}</span>;
};

export default DistributorDescription;
