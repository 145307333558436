import { Cell, Column, HeaderGroup, useTable } from 'react-table';
import React from 'react';

import { formatPrice } from 'src/helpers/format';
import { InventoryItem } from 'src/routes/Companies/OrdersIndex/types';
import { BodyCell } from './BodyCell';
import { calculateAllRowsTotal, calculateRowTotal, generateDetailsLine } from './helpers';
import Card from './Card';
import { HeaderCell } from './HeaderCell';

type OrderDetailsCardProps = {
  inventoryItems: InventoryItem[];
};

const OrderDetailsCard = ({ inventoryItems }: OrderDetailsCardProps) => {
  const columns = React.useMemo<Column<InventoryItem>[]>(
    () => [
      {
        Header: 'DESCRIPTION',
        accessor: inventoryItem => generateDetailsLine(inventoryItem),
      },
      {
        Header: 'QTY',
        accessor: 'quantity',
        className: 'text-center',
      },
      {
        Header: 'UNIT PRICE',
        accessor: ({ pricePerUnit }) => formatPrice(pricePerUnit, false),
        className: 'text-right',
      },
      {
        Header: 'TOTAL',
        accessor: ({ pricePerUnit, quantity }) => calculateRowTotal(quantity, pricePerUnit),
        className: 'text-right',
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: inventoryItems,
  });
  return (
    <Card>
      <h2 className="mb-7 font-semibold text-2xl text-turnip-900">Order Details</h2>
      <table className="border-1 rounded-lg" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className="bg-gray-100 text-left" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup & { className?: string }) => (
                <HeaderCell {...column.getHeaderProps([{ className: column.className }])}>
                  {column.render('Header')}
                </HeaderCell>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: Cell & { column: { className?: string } }) => {
                  return (
                    <BodyCell {...cell.getCellProps({ className: cell.column.className })}>
                      {cell.render('Cell')}
                    </BodyCell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="flex justify-end mt-4 pr-4 text-avocado-600 font-semibold" data-testid="all-rows-total">
        <span className="mr-8">Total </span>
        <span>{calculateAllRowsTotal(inventoryItems)}</span>
      </div>
    </Card>
  );
};

export default OrderDetailsCard;
