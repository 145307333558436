import React from 'react';
import { Card } from '@producepay/pp-ui';

import FormikTextField from 'src/components/formik/FormikTextField';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';

const LateFee = () => {
  return (
    <Card>
      <SectionHeader
        title="Late Fees &amp; Penalties"
        subtitle="Default financial settings for late fees and penalties paid by the Producer"
      />
      <SectionItem
        title="Days to Make Good Delivery"
        subtitle="Days since payment before late fees begin to incur"
        controls={
          <FormikTextField
            name="lateFee.makeGoodDeliveryDays"
            inputProps={{ className: 'text-right' }}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title="Daily Late Fee Calculation"
        subtitle="Percentage of the estimated value of the manifest or PO will be charged as daily late fee from the Days to Make Good Delivery deadline until the Delivered On date"
        controls={
          <FormikTextField
            name="lateFee.dailyLateFeeRate"
            inputProps={{ className: 'text-right' }}
            iconSuffix={<span>%</span>}
            withSimpleErrorStyling
            disabled
          />
        }
      />
    </Card>
  );
};

export default LateFee;
