import axios, { AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
});

// Axios middleware to convert all api responses to camelCase
api.interceptors.response.use((response: AxiosResponse) => {
  if (response.data && response.headers['content-type'].startsWith('application/json')) {
    response.data = camelizeKeys(response.data);
  }
  return response;
});

export default api;
