import { times } from 'lodash';
import React, { FC, HTMLAttributes, useCallback, useMemo } from 'react';
import { UsePaginationInstanceProps, UsePaginationState } from 'react-table';
import cx from 'classnames';
import { Select } from '@producepay/pp-ui';

import { useReactTableInstance } from './ReactTable';

const Square: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...rest }) => {
  return (
    <div className={cx(className, 'h-8 w-8 rounded-sm flex justify-center items-center body2')} {...rest}>
      <span className="inline-block">{children}</span>
    </div>
  );
};

type AnyRecord = Record<string, unknown>;

const PageNumber: FC<{ index: number }> = ({ index }) => {
  const {
    state: { pageIndex },
    gotoPage,
  } = useReactTableInstance<
    AnyRecord,
    UsePaginationInstanceProps<AnyRecord> & { state: UsePaginationState<AnyRecord> }
  >();
  const active = pageIndex === index;

  return (
    <Square
      className={cx({ 'bg-white': !active, 'bg-gray-300 font-bold': active }, 'cursor-pointer')}
      onClick={() => {
        gotoPage(index);
      }}
    >
      {index + 1}
    </Square>
  );
};

const pageSizeOptions = [10, 30, 50].map(item => ({
  label: String(item),
  value: item,
}));

const ellipsis = '...';

export const Pagination: FC = () => {
  const {
    rows,
    pageCount,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    setPageSize,
  } = useReactTableInstance<
    AnyRecord,
    UsePaginationInstanceProps<AnyRecord> & { state: UsePaginationState<AnyRecord> }
  >();
  const pageIndexes = useMemo(() => {
    const numbers: (number | typeof ellipsis)[] = [];
    if (pageCount === 1) {
      return [0];
    }
    if (pageCount <= 5) {
      return times(pageCount, idx => idx);
    }
    for (let i = 0; i < 2 && i < pageCount; i += 1) {
      numbers.push(i);
    }
    if (pageIndex !== pageCount - 1 && pageIndex > 2) {
      numbers.push(pageIndex);
    } else {
      numbers.push(2);
    }

    numbers.push(ellipsis);
    numbers.push(pageCount - 1);

    return numbers;
  }, [pageCount, pageIndex]);

  const selectedItem = useMemo(() => pageSizeOptions.find(item => item.value === pageSize), [pageSize]);
  const onChange = useCallback(
    (item: typeof pageSizeOptions[0]) => {
      setPageSize(item.value);
    },
    [setPageSize],
  );

  const resultsFrom = pageIndex * pageSize + 1;
  const resultsTo = Math.min((pageIndex + 1) * pageSize, rows.length);

  return (
    <div className="flex justify-between">
      <div className="flex justify-center items-center body2">
        <span className="inline-block">
          {resultsFrom} - {resultsTo} of {rows.length} results
        </span>
      </div>
      <div className="flex gap-6 items-center">
        <Select items={pageSizeOptions} selectedItem={selectedItem} onChange={onChange} size="small" />
        <div className="w-px bg-gray-300 h-full" />
        <div className="flex gap-2">
          <Square className="bg-white cursor-pointer" onClick={previousPage}>
            {'<'}
          </Square>
          {pageIndexes.map(idx => {
            return idx === ellipsis ? (
              <Square key={idx} className="bg-white">
                ...
              </Square>
            ) : (
              <PageNumber key={idx} index={idx} />
            );
          })}
          <Square className="bg-white cursor-pointer" onClick={nextPage}>
            {'>'}
          </Square>
        </div>
      </div>
    </div>
  );
};
