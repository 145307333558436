import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Grid } from '@producepay/pp-ui';

import FormikDatePicker from 'src/components/formik/FormikDatePicker';
import FormikTextField from 'src/components/formik/FormikTextField';
import FormikToggle from 'src/components/formik/FormikToggle';
import { SharedDealFormValues } from 'src/routes/Deals/types';

interface DealReferenceProps {
  autoRenew: boolean;
  renewable: boolean;
  formLocation?: string;
}

const DealReference = ({ autoRenew, renewable, formLocation = '' }: DealReferenceProps) => {
  const { setFieldValue } = useFormikContext<SharedDealFormValues>();

  useEffect(() => {
    if (autoRenew) {
      setFieldValue(`${formLocation}endsOn`, null);
    }
  }, [setFieldValue, autoRenew, formLocation]);

  return (
    <div className="bg-white border-b-1 justify-between flex py-2 px-5 sm:px-6">
      <Grid sm="1/3" className="flex flex-row items-center space-x-3">
        <label htmlFor="dealReference" className="flex-shrink-0 body2 font-semibold ">
          Deal ID{' '}
        </label>
        <FormikTextField
          className="flex-shrink-1"
          placeholder="Deal ID"
          size="small"
          style={{ height: 32 }}
          name={`${formLocation}dealReference`}
          withSimpleErrorStyling
        />
      </Grid>
      <Grid sm="1/2" className="flex flex-row items-center space-x-3 justify-end">
        <label htmlFor="effectiveOn" className="flex-shrink-0 body2 font-semibold">
          Effective On{' '}
        </label>
        <div className="flex flex-row items-center space-x-3 w-1/4">
          <Field
            name={`${formLocation}effectiveOn`}
            component={FormikDatePicker}
            inputProps={{
              name: `${formLocation}effectiveOn`,
            }}
            withSimpleErrorStyling
          />
        </div>
        {!autoRenew && renewable && (
          <>
            <label htmlFor="endsOn" className="body2 font-semibold">
              to
            </label>
            <div className="flex flex-row items-center space-x-3 w-1/4">
              <Field
                name={`${formLocation}endsOn`}
                component={FormikDatePicker}
                inputProps={{
                  name: `${formLocation}endsOn`,
                }}
                withSimpleErrorStyling
              />
            </div>
          </>
        )}
        {renewable && (
          <div className="flex flex-row items-center space-x-3">
            <Field
              as="checkbox"
              name={`${formLocation}ui.autoRenew`}
              component={FormikToggle}
              data-testid="ui-auto-renew"
            />
            <label htmlFor="recurring" className="body2">
              Auto-renew
            </label>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default DealReference;
