import React from 'react';
import { useHistory } from 'react-router-dom';

import { dealsEdit } from 'src/routes';
import { Document, DocumentCategory, FARMERS_WIRE_IDENTIFIER, FormAction } from 'src/routes/Deals/constants';
import { PNTRDocumentAcceptance, PNTRValues } from 'src/routes/Deals/PNTR/types';
import { FormikDealStatus } from 'src/routes/Deals/types';
import FormikPNTRForm from 'src/routes/Deals/PNTR/Form';

export const initialValues: PNTRValues = {
  companies: {
    buyers: [],
    sellers: [],
  },
  dealReference: null,
  defaultBankAccounts: {
    sourceAccounts: [
      {
        identifier: FARMERS_WIRE_IDENTIFIER,
        purpose: 'balance_return',
      },
    ],
  },
  effectiveOn: null,
  endsOn: null,
  dealUuid: null,
  productLineId: 5,
  status: 'draft',
  documentAcceptance: PNTRDocumentAcceptance.DistributorOrProducePay,
  tradingFees: {
    sellerFeeRate: '1.00',
    buyerFeeRate: '0.25',
  },
  repayment: {
    repaymentDays: '30',
    consignmentCollectionDays: '20',
    fixedPriceCollectionDays: '25',
    tradeAssurance: false,
    requiredItems: [{ name: Document.PACA_INVOICE, type: 'required', category: DocumentCategory.PROOF_OF_LOAD_VALUE }],
  },
  ui: {
    autoRenew: true,
  },
};

const initialStatus: FormikDealStatus = {
  readOnly: false,
};

export default () => {
  const history = useHistory();
  const handleDealSave = dealUuid => history.push(dealsEdit(dealUuid));

  return (
    <FormikPNTRForm
      initialValues={initialValues}
      initialStatus={initialStatus}
      action={FormAction.Create}
      onDealSave={handleDealSave}
    />
  );
};
