import * as Yup from 'yup';

import { ISPPValues } from 'src/routes/Deals/ISPP/types';
import { decimalString } from '../helpers';

const validateCompaniesFundingAccounts = (value, context) => {
  const {
    path,
    parent: {
      payment: { receiverRole },
    },
    createError,
  } = context;
  const companies = value[receiverRole] ?? [];
  const errors: Array<Yup.ValidationError> = [];

  companies.forEach((company, companyIndex) => {
    const { fundingAccounts = [] } = company;

    fundingAccounts.forEach((fundingAccount, index) => {
      if (!fundingAccount.accountId) {
        const error = createError({
          path: `${path}.${receiverRole}.${companyIndex}.fundingAccounts.${index}.accountId`,
          message: 'Bank Account is required',
        });

        errors.push(error);
      }
    });
  });

  if (errors.length) {
    throw new Yup.ValidationError(errors);
  }

  return true;
};

const companySchema = Yup.object().shape({
  identifier: Yup.string()
    .when('role', {
      is: role => role === 'distributor',
      then: Yup.string().nullable().required('Distributor Company is required'),
      otherwise: Yup.string().nullable().required('Producer Company is required'),
    })
    .nullable()
    .required('Company Required'),
  role: Yup.mixed().oneOf(['producer', 'distributor']),
  contactName: Yup.string().nullable(),
  name: Yup.string().nullable(),
  key: Yup.string().nullable(),
  fundingAccounts: Yup.array().of(
    Yup.object().shape({
      accountId: Yup.string().nullable(),
      value: decimalString('Amount'),
      key: Yup.string().nullable(),
      type: Yup.string().nullable(),
    }),
  ),
});

export const saveDraftSchema: ISPPValidationSchema = Yup.object().shape({
  productLineId: Yup.number().required(),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  dealUuid: Yup.string().nullable(),
  status: Yup.string(),
  effectiveOn: Yup.string().nullable(),
  endsOn: Yup.string().nullable(),
  distributorContract: Yup.string().nullable(),
  producerContract: Yup.string().nullable(),
  applicationFee: Yup.object().shape({
    minimumAnnualProduceVolumeForRefund: decimalString('Minimum Annual Produce Volume'),
    isRefundable: Yup.boolean(),
    fees: Yup.array().of(
      Yup.object().shape({
        amountDue: decimalString('Application Fee'),
        amountPaid: decimalString('Amount Paid'),
        datePaid: Yup.string().nullable(),
        paymentMethods: Yup.array().of(
          Yup.object().shape({
            amount: decimalString('Amount'),
            method: Yup.string().nullable(),
            key: Yup.string().nullable(),
          }),
        ),
        isWaived: Yup.boolean(),
        companyIdentifier: Yup.string().nullable(),
        key: Yup.string().nullable(),
      }),
    ),
  }),
  companies: Yup.object().shape({
    distributors: Yup.array().of(companySchema).min(1, 'Distributor Company is required'),
    producers: Yup.array()
      .of(companySchema)
      .when('$dealType', {
        is: dealType => dealType === 'producerDistributor',
        then: Yup.array().of(companySchema).min(1, 'Producer Company is required'),
        otherwise: Yup.array().min(0),
      }),
  }),
  payment: Yup.object().shape({
    initialRate: decimalString('Initial Payment Ratio'),
    receiverRole: Yup.string().nullable(),
    requiredItems: Yup.array(),
    secondaryRate: decimalString('Second Payment Ratio'),
    shipmentAcceptance: Yup.string().nullable(),
    unitRate: decimalString(''),
  }),
  distributionFee: Yup.object().shape({
    feePayerRole: Yup.string().nullable(),
    feeRatio: decimalString('Distribution Fee Ratio'),
    repaymentTerms: decimalString('Repayment Terms'),
    unitRate: decimalString('Fee Per Unit'),
  }),
  lateFee: Yup.object().shape({
    repaymentDays: decimalString('Repayment Terms'),
    collectionDays: decimalString('Collection Terms'),
    feePayerRole: Yup.string().nullable(),
  }),
  defaultBankAccounts: Yup.object().shape({
    sourceAccounts: Yup.array(),
  }),
  rebates: Yup.array().of(
    Yup.object().shape({
      amount: decimalString('Amount'),
      key: Yup.string().nullable(),
      transactionVolumeThreshold: decimalString('Transaction Volume Threshold'),
    }),
  ),
  ui: Yup.object().shape({
    autoRenew: Yup.boolean(),
    dealType: Yup.string(),
    payment: Yup.object().shape({
      type: Yup.string(),
      secondPaymentEnabled: Yup.boolean(),
    }),
    distributionFee: Yup.object().shape({
      type: Yup.string(),
    }),
    disabled: Yup.boolean(),
    receiverExceptionsEnabled: Yup.boolean(),
  }),
});

const activationCompanySchema = Yup.object().shape({
  identifier: Yup.string()
    .when('role', {
      is: role => role === 'distributor',
      then: Yup.string().nullable().required('Distributor Company is required'),
      otherwise: Yup.string().nullable().required('Producer Company is required'),
    })
    .nullable()
    .required('Company is required'),
  role: Yup.mixed().oneOf(['producer', 'distributor']),
  contactName: Yup.string().when('role', {
    is: role => role === 'distributor',
    then: Yup.string().nullable().required('Distributor Contact is required'),
    otherwise: Yup.string().nullable().required('Producer Contact is required'),
  }),
  name: Yup.string().nullable(),
  key: Yup.string().nullable(),
  fundingAccounts: Yup.array().of(
    Yup.object().shape({
      accountId: Yup.string().nullable(),
      value: decimalString('Amount'),
      key: Yup.string().nullable(),
      type: Yup.string().nullable(),
    }),
  ),
});

const activationApplicationFeeSchema = Yup.object().shape({
  amountDue: decimalString('Application Fee').required('Application Fee is required'),
  amountPaid: decimalString('Amount Paid'),
  datePaid: Yup.string().nullable(),
  paymentMethods: Yup.array().of(
    Yup.object().shape({
      amount: decimalString('Amount').required('Amount is required'),
      method: Yup.string().nullable().required('Payment Method is required'),
      key: Yup.string().nullable(),
    }),
  ),
  isWaived: Yup.boolean(),
  companyIdentifier: Yup.string().nullable().required('Company is required'),
  key: Yup.string().nullable(),
});

export const activationSchema: ISPPValidationSchema = Yup.object().shape({
  productLineId: Yup.number().required(),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  dealUuid: Yup.string().nullable(),
  status: Yup.string(),
  effectiveOn: Yup.string().nullable().required('Effective Date is required'),
  endsOn: Yup.string().when('$autoRenew', {
    is: autoRenew => !autoRenew,
    then: Yup.string().nullable().required('End Date is required'),
    otherwise: Yup.string().nullable(),
  }),
  distributorContract: Yup.string().nullable().required('Distributor Contract is required'),
  producerContract: Yup.string().when('$dealType', {
    is: dealType => dealType === 'producerDistributor',
    then: Yup.string().nullable().required('Producer Contract is required'),
    otherwise: Yup.string().nullable(),
  }),
  applicationFee: Yup.object().shape({
    minimumAnnualProduceVolumeForRefund: decimalString('Minimum Annual Produce Volume').required(
      'Minimum Annual Produce Volume is required',
    ),
    isRefundable: Yup.boolean(),
    fees: Yup.array().of(activationApplicationFeeSchema).min(1),
  }),
  companies: Yup.object()
    .shape({
      distributors: Yup.array().of(activationCompanySchema).min(1, 'Distributor Company is required'),
      producers: Yup.array()
        .of(activationCompanySchema)
        .when('$dealType', {
          is: dealType => dealType === 'producerDistributor',
          then: Yup.array().of(activationCompanySchema).min(1, 'Producer Company is required'),
          otherwise: Yup.array().of(activationCompanySchema).min(0),
        }),
    })
    .test(validateCompaniesFundingAccounts),
  payment: Yup.object().shape({
    initialRate: Yup.string().when('$payment.type', {
      is: type => type === 'standard',
      then: decimalString('Initial Payment Ratio').required('Initial Payment Ratio is required'),
      otherwise: Yup.string().nullable(),
    }),
    receiverRole: Yup.string().nullable().required('Payment Recipient is required'),
    requiredItems: Yup.array(),
    secondaryRate: Yup.string().when('$payment.secondPaymentEnabled', {
      is: active => active === true,
      then: decimalString('Secondary Payment Ratio').required('Secondary Payment Ratio is required'),
      otherwise: decimalString('Secondary Payment Ratio'),
    }),
    shipmentAcceptance: Yup.string().nullable().required('Shipment Acceptance is required'),
    unitRate: Yup.string().when('$payment.type', {
      is: type => type === 'perUnit',
      then: decimalString('Payment Per Unit').required('Payment Per Unit is required'),
      otherwise: Yup.string().nullable(),
    }),
  }),
  distributionFee: Yup.object().shape({
    feePayerRole: Yup.string().nullable().required('Distribution Fee Payer is required'),
    feeRatio: Yup.string().when('$distributionFee.type', {
      is: feeType => feeType === 'standard' || feeType === 'daily',
      then: decimalString('Distribution Fee Ratio').required('Distribution Fee Ratio is required'),
      otherwise: Yup.string().nullable(),
    }),
    repaymentTerms: Yup.string().when('$distributionFee.type', {
      is: feeType => feeType === '$distributionFee.type',
      then: decimalString('Repayment Terms').required('Repayment Terms are required'),
      otherwise: Yup.string().nullable(),
    }),
    unitRate: Yup.string().when('$distributionFee.type', {
      is: feeType => feeType === 'per_unit',
      then: decimalString('Fee Per Unit').required('Fee Per Unit is required'),
      otherwise: Yup.string().nullable(),
    }),
  }),
  lateFee: Yup.object().shape({
    repaymentDays: Yup.string().when('$distributionFee.type', {
      is: feeType => feeType === 'standard' || feeType === 'per_unit',
      then: decimalString('Repayment Terms').required('Repayment Terms are required'),
      otherwise: Yup.string().nullable(),
    }),
    collectionDays: Yup.string().when('$distributionFee.type', {
      is: feeType => feeType === 'standard' || feeType === 'per_unit',
      then: decimalString('Collection Terms').required('Collection Terms are required'),
      otherwise: Yup.string().nullable(),
    }),
    feePayerRole: Yup.string().when('$distributionFee.type', {
      is: feeType => feeType === 'standard' || feeType === 'per_unit',
      then: Yup.string().nullable().required('Late Fee Payer is required'),
      otherwise: Yup.string().nullable(),
    }),
  }),
  defaultBankAccounts: Yup.object().shape({
    sourceAccounts: Yup.array().of(
      Yup.object().shape({
        identifier: Yup.string().when('purpose', {
          is: purpose => purpose === 'payment',
          then: Yup.string().nullable().required('Payment Source Account is required'),
          otherwise: Yup.string().nullable().required('Balance Return Source Account is required'),
        }),
        purpose: Yup.string().nullable().required(),
      }),
    ),
  }),
  rebates: Yup.array().of(
    Yup.object().shape({
      amount: decimalString('Rebate Amount'),
      key: Yup.string().nullable(),
      transactionVolumeThreshold: decimalString('Transaction Volume Threshold'),
    }),
  ),
  ui: Yup.object().shape({
    autoRenew: Yup.boolean(),
    dealType: Yup.string(),
    payment: Yup.object().shape({
      type: Yup.string(),
      secondPaymentEnabled: Yup.boolean(),
    }),
    distributionFee: Yup.object().shape({
      type: Yup.string(),
    }),
    disabled: Yup.boolean(),
    receiverExceptionsEnabled: Yup.boolean(),
  }),
});

export type ISPPValidationSchema = Yup.SchemaOf<ISPPValues>;
