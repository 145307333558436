import React from 'react';

type CardProps = {
  children: React.ReactNode;
};

const Card = ({ children }: CardProps) => {
  return <div className="flex flex-col mb-4 rounded-lg py-8 px-4 bg-white">{children}</div>;
};

export default Card;
