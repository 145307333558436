import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { Field, useFormikContext } from 'formik';
import { Select } from '@producepay/pp-ui';
import kebabCase from 'lodash/kebabCase';

import SimpleErrorMessageWrapper from '../SimpleErrorMessageWrapper';
import Wrapper from '../FormikInputWrapper';

import './select.css';

export interface SelectItem<T = unknown> {
  label: string;
  subtitle?: string;
  value: T;
}

export interface FormikSelectProps<T = unknown> {
  className?: string;
  label?: string;
  name: string;
  items: SelectItem<T>[];
  inputProps?: HTMLAttributes<HTMLInputElement>;
  withSimpleErrorStyling?: boolean;
  placeholder?: string;
  shouldShowDefault?: boolean;
  [key: string]: unknown;
}

/** FormikSelect
 * Wrapper for wiring up a Select field to the Formik Context.
 * Defers to `FormikInputWrapper` which adds labels and validation
 * error printing.
 */

function FormikSelect({
  className = '',
  label = null,
  name,
  items,
  inputProps = { className: '' },
  withSimpleErrorStyling = false,
  placeholder = null,
  shouldShowDefault = true,
  ...props
}: FormikSelectProps): JSX.Element {
  const { setFieldValue, setFieldTouched, status } = useFormikContext();
  const readOnly = status?.readOnly ?? false;
  const DEFAULT_ITEM = { label: placeholder || '', value: null };
  const shouldUseSimpleErrorMessage = label ? false : withSimpleErrorStyling;

  const resolvedItems = shouldShowDefault ? [DEFAULT_ITEM, ...items] : items;

  const handleFocus = (fieldName: string) => {
    setFieldTouched(fieldName, true);
  };

  return (
    <Field name={name} {...props}>
      {({ field, form, meta: { error, touched } }) => {
        const computedClassName = cx(inputProps?.className || '', {
          'cursor-not-allowed opacity-50 bg-gray-200': readOnly,
          'border-red-500': !touched && withSimpleErrorStyling && error,
        });

        const selectField = (
          <Select
            size="small"
            aria-labelledby={`form-label-${name}`}
            name={name}
            items={resolvedItems}
            {...field}
            className={computedClassName}
            selectedItem={items.find(i => i.value === field.value) || DEFAULT_ITEM}
            onChange={item => setFieldValue(name, item.value)}
            data-testid={kebabCase(name)}
            disabled={readOnly}
            onFocus={() => handleFocus(name)}
            placeholder={placeholder}
            {...props}
          />
        );

        if (shouldUseSimpleErrorMessage) {
          return (
            <SimpleErrorMessageWrapper className={className} error={error} cleared={touched}>
              {selectField}
            </SimpleErrorMessageWrapper>
          );
        }

        return (
          <Wrapper className={className} name={name} label={label} form={form}>
            {selectField}
          </Wrapper>
        );
      }}
    </Field>
  );
}

export default React.memo(FormikSelect);
