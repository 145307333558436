import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { Decimal } from 'decimal.js';
import { isValid } from 'date-fns';

interface FeeEntity {
  amountDue: string;
  amountPaid: string;
  datePaid: string;
  paymentMethods: {
    method: string;
    amount: string;
    key: string;
  }[];
  companyIdentifier: string;
  isWaived: boolean;
  key: string;
}

interface PaymentMethodEntity {
  method: string;
  amount: string;
  key: string;
}

export const getPaymentMethodEntity = (method = ''): PaymentMethodEntity => ({
  method,
  amount: '',
  key: uuidv4(),
});

export const getEmptyFeeEntity = (): FeeEntity => ({
  amountDue: '',
  amountPaid: '',
  datePaid: null,
  paymentMethods: [getPaymentMethodEntity('payment'), getPaymentMethodEntity('balance_return')],
  companyIdentifier: '',
  isWaived: false,
  key: uuidv4(),
});

export const decimalString = (name?: string): Yup.StringSchema<string, Record<string, unknown>, string> =>
  Yup.string()
    .nullable()
    .matches(/^\d*(\.\d+)?$/, {
      message: name ? `${name} must be a number` : 'Must be a number',
      excludeEmptyString: true,
    });

export const dateString = (name?: string): Yup.StringSchema<string, Record<string, unknown>, string> =>
  Yup.string()
    .nullable()
    .test('isValid', name ? `${name} must be a date` : 'Must be a date', value => {
      if (value === null || value === '' || value === undefined) {
        return true;
      }
      return isValid(new Date(value));
    });

export const safeDiv = (value: string, div: number): string => (value ? new Decimal(value).div(div).toString() : null);

export const safeMul = (value: string, div: number): string => (value ? new Decimal(value).mul(div).toString() : null);
