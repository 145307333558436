import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Card } from '@producepay/pp-ui';
import gql from 'graphql-tag';

import FormikSelect from 'src/components/formik/FormikSelect';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';
import { GetProducePaySourceAccounts } from 'src/routes/Deals/PNMS/Form/graphql-types/GetProducePaySourceAccounts';

export const GET_SOURCE_ACCOUNTS = gql`
  query GetProducePaySourceAccounts($purpose: SourceAccountPurpose!) {
    sourceAccounts(purpose: $purpose) {
      identifier
      companyIdentifier
      name
    }
  }
`;

export function SourceBankAccounts() {
  const { data: paymentsSourceAccountsData } = useQuery<GetProducePaySourceAccounts>(GET_SOURCE_ACCOUNTS, {
    variables: { purpose: 'PAYMENTS' },
  });
  const { data: balanceReturnsSourceAccountsData } = useQuery<GetProducePaySourceAccounts>(GET_SOURCE_ACCOUNTS, {
    variables: { purpose: 'BALANCE_RETURNS' },
  });

  const [paymentItems, setPaymentItems] = useState([]);
  const [balanceReturnItems, setBalanceReturnItems] = useState([]);

  useEffect(() => {
    if (paymentsSourceAccountsData?.sourceAccounts) {
      const { sourceAccounts } = paymentsSourceAccountsData;
      const mappedAccounts = sourceAccounts.map(({ identifier, name }) => ({
        value: identifier,
        label: name,
      }));
      setPaymentItems(mappedAccounts);
    }
  }, [paymentsSourceAccountsData, setPaymentItems]);

  useEffect(() => {
    if (balanceReturnsSourceAccountsData?.sourceAccounts) {
      const { sourceAccounts } = balanceReturnsSourceAccountsData;
      const mappedAccounts = sourceAccounts.map(({ identifier, name }) => ({
        value: identifier,
        label: name,
      }));
      setBalanceReturnItems(mappedAccounts);
    }
  }, [balanceReturnsSourceAccountsData, setBalanceReturnItems]);

  return (
    <Card>
      <SectionHeader title="Source Accounts" subtitle="Default bank accounts to send funds from" />
      <SectionItem
        title="Payments"
        subtitle="Account for sending funds to the payment recipient"
        controls={
          <FormikSelect
            name="defaultBankAccounts.sourceAccounts[0].identifier"
            items={paymentItems}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title="Balance Returns"
        subtitle="Account for sending customer balances"
        controls={
          <FormikSelect
            name="defaultBankAccounts.sourceAccounts[1].identifier"
            items={balanceReturnItems}
            withSimpleErrorStyling
          />
        }
        border={false}
      />
    </Card>
  );
}
