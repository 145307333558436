import React from 'react';
import { Badge, Card, Grid } from '@producepay/pp-ui';
import { Field } from 'formik';

import FormLabel from 'src/components/elements/FormLabel';
import FormikDatePicker from 'src/components/formik/FormikDatePicker';
import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';

import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';

const PAYMENT_METHODS = [
  { label: 'Check', value: 'check' },
  { label: 'Credit Card', value: 'credit_card' },
  { label: 'Wire', value: 'wire' },
];

const MembershipFee = () => {
  return (
    <Card>
      <SectionHeader title="Membership Fee" subtitle="Financial settings for the Preferred Network membership fee" />
      <SectionItem
        title="Membership Fee"
        subtitle="Yearly fee incurred upon the acceptance of the first Preferred Network trade a year after the last payment"
        controls={
          <FormikTextField
            inputProps={{ className: 'text-right' }}
            name="membershipFee.amountDue"
            iconPrefix={<span>$</span>}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title={
          <>
            Completed Payment
            <Badge className="py-1 ml-2 font-semibold bg-gray-200 text-smoke uppercase">Optional</Badge>
          </>
        }
        subtitle="Initial membership fee amount already paid by the member"
        border={false}
      />
      <Grid container className="px-6">
        <Grid sm="1/5">
          <FormLabel label="Paid on" />
        </Grid>
        <Grid sm="2/5">
          <FormLabel label="Payment Method" />
        </Grid>
        <Grid sm="2/5">
          <FormLabel label="Amount" />
        </Grid>
      </Grid>
      <Grid container className="justify-between px-6 pb-4">
        <Grid sm="1/5">
          <Field
            name="membershipFee.datePaid"
            component={FormikDatePicker}
            inputProps={{
              name: 'membershipFee.datePaid',
            }}
            withSimpleErrorStyling
          />
        </Grid>
        <Grid sm="2/5" className="flex flex-row">
          <FormikSelect
            name="membershipFee.methodPaid"
            placeholder="Select payment method"
            items={PAYMENT_METHODS}
            withSimpleErrorStyling
          />
        </Grid>
        <Grid sm="2/5">
          <FormikTextField name="membershipFee.amountPaid" iconPrefix={<span>$</span>} withSimpleErrorStyling />
        </Grid>
      </Grid>
    </Card>
  );
};

export default MembershipFee;
