import React, { useContext, useRef } from 'react';

import { PSDealNavigationContextValues } from './types';

export const PSDealNavigationContext = React.createContext<PSDealNavigationContextValues>({
  navigatePrev: () => undefined,
  navigateNext: () => undefined,
  setSaveCallback: () => undefined,
  saveCallback: { current: () => undefined },
});

export const usePSDealNavigation = (): PSDealNavigationContextValues => {
  return useContext(PSDealNavigationContext);
};

export const PSDealNavigationContextProvider = ({ navigatePrev, navigateNext, children }) => {
  const saveCallback = useRef<() => void>();

  const contextValues = {
    navigatePrev,
    navigateNext,
    saveCallback,
    setSaveCallback(callback) {
      saveCallback.current = callback;
    },
  };

  return <PSDealNavigationContext.Provider value={contextValues}>{children}</PSDealNavigationContext.Provider>;
};
