import React from 'react';
import { Formik, useFormikContext } from 'formik';

import { activationSchema, saveDraftSchema } from 'src/routes/Deals/PNMS/validationSchemas';
import { FormAction } from 'src/routes/Deals/constants';
import { FormikDealStatus, OnDealSave } from 'src/routes/Deals/types';
import { PNMSValues, PNMSValuesPayload } from 'src/routes/Deals/PNMS/types';
import { Companies } from 'src/routes/Deals/PNMS/Form/Companies';
import LateFee from 'src/routes/Deals/PNMS/Form/LateFee';
import { DocumentRequirements } from 'src/routes/Deals/PNMS/Form/DocumentRequirements';
import { removeDefaultEntities, transformToPayload } from 'src/routes/Deals/PNMS/helpers';
import { SectionedContent, Section } from 'src/components/elements/SectionedContent/SectionedContent';
import DealReference from 'src/routes/Deals/components/DealReference';
import FormHeader from 'src/routes/Deals/components/FormHeader';
import { SourceBankAccounts } from 'src/routes/Deals/PNMS/Form/SourceBankAccounts';
import { DistributionFee } from 'src/routes/Deals/PNMS/Form/DistributionFee';
import { Payments } from 'src/routes/Deals/PNMS/Form/Payments';

interface PNMSFormProps {
  action: FormAction;
  onDealSave: OnDealSave;
}

interface FormikPNMSFormProps extends PNMSFormProps {
  initialValues: PNMSValues;
  initialStatus: FormikDealStatus;
}

const sections: Section[] = [
  { name: 'Companies', id: 'companies', component: <Companies /> },
  { name: 'Source Bank Accounts', id: 'source-bank-accounts', component: <SourceBankAccounts /> },
  {
    name: 'Payments',
    id: 'payments',
    component: <Payments />,
  },
  { name: 'Distribution Fee', id: 'distribution-fee', component: <DistributionFee /> },
  { name: 'Late Fees & Penalties', id: 'late-fees', component: <LateFee /> },
  { name: 'Document Requirements', id: 'document-requirements', component: <DocumentRequirements /> },
];

const PNMSForm = ({ action, onDealSave }: PNMSFormProps) => {
  const {
    values: {
      status,
      dealUuid,
      ui: { autoRenew },
    },
  } = useFormikContext<PNMSValues>();

  return (
    <form onSubmit={e => e.preventDefault()}>
      <FormHeader<PNMSValues, PNMSValuesPayload>
        action={action}
        status={status}
        dealUuid={dealUuid}
        onDealSave={onDealSave}
        productLine="Mid-Season Financing"
        activationSchema={activationSchema}
        saveDraftSchema={saveDraftSchema}
        transformToPayload={transformToPayload}
        removeDefaultEntities={removeDefaultEntities}
      />
      <DealReference renewable={false} autoRenew={autoRenew} />
      <div className="px-6">
        <SectionedContent sections={sections} stickyOffset={50 /* offset height of the top bar */} />
      </div>
    </form>
  );
};

export default ({ action, initialValues, initialStatus, onDealSave }: FormikPNMSFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      initialStatus={initialStatus}
      onSubmit={null}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      <PNMSForm action={action} onDealSave={onDealSave} />
    </Formik>
  );
};
