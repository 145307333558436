import React, { FC, useCallback, useState } from 'react';
import { Button } from '@producepay/pp-ui';
import { CellProps } from 'react-table';
import { Align, AlignCellContent, CellWithAlign } from 'src/components/elements/Table';
import { FundingTransaction } from './types';

enum ActionType {
  Exclude,
  Keep,
}

export const ActionCell: FC<
  CellProps<FundingTransaction, unknown> & CellWithAlign & { onEdit(newData: FundingTransaction[]): void }
> = ({ column: { align = Align.Right }, row, onEdit, data }) => {
  const [actionTaken, setActionTaken] = useState<ActionType>();

  const setFlags = useCallback(
    (discarded: boolean, kept: boolean) => {
      const newData = [...data];
      newData[row.index] = { ...data[row.index], discarded, kept };
      onEdit(newData);
    },
    [onEdit, data, row],
  );
  const onExclude = useCallback(() => {
    setActionTaken(ActionType.Exclude);
    setFlags(true, false);
  }, [setFlags]);
  const onKeep = useCallback(() => {
    setActionTaken(ActionType.Keep);
    setFlags(false, true);
  }, [setFlags]);

  return (
    <AlignCellContent align={align}>
      <div className="flex items-center">
        {actionTaken !== undefined ? (
          <>
            <span className="uppercase text-primary text-sub font-semibold">
              {actionTaken === ActionType.Exclude ? 'Excluded' : 'Kept'}
            </span>
          </>
        ) : (
          <>
            {!data[row.index].discarded && (
              <Button label="Exclude" variant="outlined" color="default" onClick={onExclude} />
            )}
            {!data[row.index].kept && data[row.index].sourceCompanyUuid && (
              <Button label="Keep" variant="outlined" color="default" className="ml-4" onClick={onKeep} />
            )}
          </>
        )}
      </div>
    </AlignCellContent>
  );
};
