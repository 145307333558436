import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Card } from '@producepay/pp-ui';

import GET_SOURCE_ACCOUNTS from 'src/graphql/queries/getSourceAccounts';
import FormikSelect from 'src/components/formik/FormikSelect';
import { SourceAccountConfiguration } from 'src/graphql/queries/graphql-types/SourceAccountConfiguration';

import { FARMERS_WIRE_IDENTIFIER, INSEASON_FUND_IDENTIFIER } from '../../constants';
import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';

const SourceAccounts = () => {
  // get available Accounts from API
  const { data } = useQuery<SourceAccountConfiguration>(GET_SOURCE_ACCOUNTS);
  const [paymentItems, setPaymentItems] = useState([]);
  const [balanceReturnItems, setBalanceReturnItems] = useState([]);

  useEffect(() => {
    if (data?.sourceAccounts) {
      const mappedAccounts = data.sourceAccounts.map(a => ({
        value: a.identifier,
        label: a.name,
      }));
      setPaymentItems(mappedAccounts.filter(acc => acc.value !== FARMERS_WIRE_IDENTIFIER));
      setBalanceReturnItems(mappedAccounts.filter(acc => acc.value !== INSEASON_FUND_IDENTIFIER));
    }
  }, [data?.sourceAccounts, setPaymentItems, setBalanceReturnItems]);

  return (
    <Card>
      <SectionHeader title="Source Accounts" subtitle="Default bank accounts to send funds from" />
      <SectionItem
        title="Payments"
        subtitle="Account for sending funds to the payment recipient"
        controls={
          <FormikSelect
            name="defaultBankAccounts.sourceAccounts[0].identifier"
            items={paymentItems}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title="Balance Returns"
        subtitle="Account for sending customer balances"
        controls={
          <FormikSelect
            name="defaultBankAccounts.sourceAccounts[1].identifier"
            items={balanceReturnItems}
            withSimpleErrorStyling
          />
        }
        border={false}
      />
    </Card>
  );
};

export default SourceAccounts;
