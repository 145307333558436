/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DealStatus {
  ACTIVE = "ACTIVE",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
}

export enum DealsOrderByDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum DealsOrderByField {
  DEAL_REFERENCE = "DEAL_REFERENCE",
  EFFECTIVE_ON = "EFFECTIVE_ON",
}

export enum SourceAccountPurpose {
  BALANCE_RETURNS = "BALANCE_RETURNS",
  PAYMENTS = "PAYMENTS",
}

export interface DealsFilters {
  search?: string | null;
  status?: DealStatus | null;
}

export interface DealsOrderBy {
  direction?: DealsOrderByDirection | null;
  field?: DealsOrderByField | null;
}

export interface FinancialProductsFilters {
  dealUuids?: (string | null)[] | null;
  uuids?: (string | null)[] | null;
}

export interface ForecastCommoditiesFilters {
  dealUuids?: (string | null)[] | null;
}

export interface ForecastsFilters {
  dealUuids?: (string | null)[] | null;
}

export interface MilestonesFilters {
  dealUuids?: (string | null)[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
