import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Company } from '../Company';
import CompaniesList from './CompaniesList';

function Companies(): JSX.Element {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:companyIdentifier/:activeItem`}>
        <Company />
      </Route>
      <Route path={match.path}>
        <>
          <div className="text-4xl p-12">Select a Company</div>
          <div className="p-8">
            <CompaniesList />
          </div>
        </>
      </Route>
    </Switch>
  );
}

export default Companies;
