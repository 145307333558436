import React from 'react';
import { Formik, useFormikContext } from 'formik';

import { Section, SectionedContent } from 'src/components/elements/SectionedContent/SectionedContent';
import { ISPPValues, ISPPValuesPayload } from 'src/routes/Deals/ISPP/types';
import { FormikDealStatus, OnDealSave } from '../../types';
import { FormAction } from '../../constants';
import { removeDefaultEntities, transformToPayload } from '../helpers';
import { activationSchema, saveDraftSchema } from '../validationSchemas';
import ApplicationFee from './ApplicationFee';
import Companies from './Companies';
import DealReference from '../../components/DealReference';
import DistributionFee from './DistributionFee';
import DocumentRequirements from './DocumentRequirements';
import FormHeader from '../../components/FormHeader';
import LateFee from './LateFee';
import Payments from './Payments';
import RebateSchedule from './RebateSchedule';
import SourceAccounts from './SourceAccounts';

interface ISPPFormProps {
  action: FormAction;
  onDealSave: OnDealSave;
}

interface FormikISPPFormProps extends ISPPFormProps {
  initialValues: ISPPValues;
  initialStatus: FormikDealStatus;
}

const DEFAULT_INDEX_SECTIONS: Array<Section> = [
  { name: 'Companies', id: 'companies', component: <Companies /> },
  { name: 'Source Accounts', id: 'source-accounts', component: <SourceAccounts /> },
  { name: 'Payments', id: 'payments', component: <Payments /> },
  { name: 'Distribution Fee', id: 'distribution-fee', component: <DistributionFee /> },
  { name: 'Late Fee', id: 'late-fee', component: <LateFee /> },
  { name: 'Application Fee', id: 'application-fee', component: <ApplicationFee /> },
  {
    name: 'Document Requirements',
    id: 'document-requirements',
    component: <DocumentRequirements />,
  },
  { name: 'Volume Rebate Schedule', id: 'volume-rebate-schedule', component: <RebateSchedule /> },
];

const ISPPForm = ({ action, onDealSave }: ISPPFormProps) => {
  const {
    values: {
      status,
      dealUuid,
      ui: {
        autoRenew,
        distributionFee: { type: distributionFeeType },
      },
    },
  } = useFormikContext<ISPPValues>();

  const sections =
    distributionFeeType === 'daily'
      ? DEFAULT_INDEX_SECTIONS.filter(section => section.id !== 'late-fee')
      : DEFAULT_INDEX_SECTIONS;

  return (
    <form onSubmit={e => e.preventDefault()}>
      <FormHeader<ISPPValues, ISPPValuesPayload>
        action={action}
        status={status}
        dealUuid={dealUuid}
        onDealSave={onDealSave}
        productLine="In Season Pick &amp; Pack Financing"
        activationSchema={activationSchema}
        saveDraftSchema={saveDraftSchema}
        transformToPayload={transformToPayload}
        removeDefaultEntities={removeDefaultEntities}
      />
      <DealReference autoRenew={autoRenew} renewable />
      <div className="px-6">
        <SectionedContent sections={sections} stickyOffset={50 /* top bar height offset */} />
      </div>
    </form>
  );
};

export default ({ action, initialStatus, initialValues, onDealSave }: FormikISPPFormProps) => {
  return (
    <Formik
      initialStatus={initialStatus}
      initialValues={initialValues}
      onSubmit={null}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      <ISPPForm action={action} onDealSave={onDealSave} />
    </Formik>
  );
};
