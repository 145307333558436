import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Card } from '@producepay/pp-ui';

import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';

import { LateFee } from 'src/routes/Deals/types';
import { ISPPValues } from 'src/routes/Deals/ISPP/types';
import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';

const DISTRIBUTION_TYPE_ITEMS = [
  { label: 'Standard', value: 'standard' },
  { label: 'Per Unit', value: 'per_unit' },
  { label: 'Daily', value: 'daily' },
];

const DISTRIBUTION_FEE_PAYER_ITEMS = [
  { value: 'producer', label: 'Producer' },
  { value: 'distributor', label: 'Distributor' },
];

const DistributionFee = () => {
  const [selectRoleDisabled, setSelectRoleDisabled] = useState(false);
  const {
    values: {
      distributionFee,
      lateFee,
      ui: {
        distributionFee: { type },
        dealType,
      },
    },
    setFieldValue,
  } = useFormikContext<ISPPValues>();

  const getLateFeeChanges = (val: string): LateFee => {
    if (val === 'default') {
      return {
        repaymentDays: lateFee.repaymentDays || '30',
        collectionDays: lateFee.collectionDays || '40',
        feePayerRole: lateFee.feePayerRole || null,
      };
    }
    return {
      repaymentDays: null,
      collectionDays: null,
      feePayerRole: null,
    };
  };

  useEffect(() => {
    if (dealType === 'distributor') {
      setFieldValue('distributionFee.feePayerRole', 'distributor');
      setSelectRoleDisabled(true);
    } else {
      setSelectRoleDisabled(false);
    }
  }, [dealType, setFieldValue]);

  useEffect(() => {
    switch (type) {
      case 'per_unit':
        setFieldValue('distributionFee.feeRatio', null);
        setFieldValue('distributionFee.repaymentTerms', null);
        if (!distributionFee.unitRate) {
          setFieldValue('distributionFee.unitRate', '2.00');
        }
        setFieldValue('lateFee', getLateFeeChanges('default'));
        break;
      case 'standard':
        setFieldValue('distributionFee.unitRate', null);
        setFieldValue('distributionFee.repaymentTerms', null);
        if (!distributionFee.feeRatio) {
          setFieldValue('distributionFee.feeRatio', '2.00');
        }
        setFieldValue('lateFee', getLateFeeChanges('default'));
        break;
      case 'daily':
        setFieldValue('distributionFee.unitRate', null);
        if (!distributionFee.feeRatio) {
          setFieldValue('distributionFee.feeRatio', '2.00');
        }

        if (!distributionFee.repaymentTerms) {
          setFieldValue('distributionFee.repaymentTerms', '30');
        }

        setFieldValue('lateFee', getLateFeeChanges(null));
        break;
      default:
        break;
    }
    // getLateFeeChanges, causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, type]);

  return (
    <Card>
      <SectionHeader
        title="Distribution Fee"
        subtitle="Default financial settings for distribution fees"
        controls={<FormikSelect name="ui.distributionFee.type" items={DISTRIBUTION_TYPE_ITEMS} />}
      />
      {['standard', 'daily'].includes(type) && (
        <SectionItem
          title="Distribution Fee Ratio"
          subtitle="Percentage of initial shipment value"
          controls={
            <FormikTextField
              inputProps={{ className: 'text-right' }}
              name="distributionFee.feeRatio"
              iconSuffix={<span>%</span>}
              withSimpleErrorStyling
            />
          }
        />
      )}
      {type === 'per_unit' && (
        <SectionItem
          title="Fee Per Unit"
          subtitle="Fixed amount charged per accepted unit"
          controls={
            <FormikTextField name="distributionFee.unitRate" iconPrefix={<span>$</span>} withSimpleErrorStyling />
          }
        />
      )}
      {type === 'daily' && (
        <SectionItem
          title="Repayment Terms"
          subtitle="Days since payment. Used to calculate Daily Fee"
          controls={
            <FormikTextField
              name="distributionFee.repaymentTerms"
              inputProps={{ className: 'text-right' }}
              withSimpleErrorStyling
            />
          }
        />
      )}
      <SectionItem
        title="Distribution Fee Payer"
        border={false}
        controls={
          <FormikSelect
            name="distributionFee.feePayerRole"
            placeholder="Select fee payer"
            items={DISTRIBUTION_FEE_PAYER_ITEMS}
            disabled={selectRoleDisabled}
            withSimpleErrorStyling
          />
        }
      />
    </Card>
  );
};

export default DistributionFee;
