import Decimal from 'decimal.js';

export interface ExposureData {
  creditLimit: string;
  outstandingAmount: string;
  pendingAmount: string;
}

export enum ExposureLevel {
  OVER,
  WARNING,
  OK,
}

export const calculateExposure = (
  exposure: ExposureData,
  { includePending = true, selectedAmount = '0.00' } = {},
): {
  available: Decimal;
  used: Decimal;
  factor: Decimal;
} => {
  let used = new Decimal(exposure.outstandingAmount).add(selectedAmount);
  if (includePending) {
    used = used.add(exposure.pendingAmount);
  }
  const available = new Decimal(exposure.creditLimit).minus(used);
  const factor = new Decimal(used).div(exposure.creditLimit);
  return {
    available,
    used,
    factor,
  };
};

export const getExposureLevel = (exposureFactor: Decimal): ExposureLevel => {
  if (exposureFactor.greaterThan('1.00') || exposureFactor.isNaN()) {
    return ExposureLevel.OVER;
  }
  if (exposureFactor.greaterThan('0.75')) {
    return ExposureLevel.WARNING;
  }
  return ExposureLevel.OK;
};

export const getExposureColor = (
  exposureLevel: ExposureLevel,
  { warningColor = 'warning', okColor = 'primary' } = {},
): string => {
  switch (exposureLevel) {
    case ExposureLevel.OVER:
      return 'secondary';
    case ExposureLevel.WARNING:
      return warningColor;
    case ExposureLevel.OK:
      return okColor;
    default:
      return okColor;
  }
};

export const exposureValid = (exposureData: ExposureData): boolean => {
  return exposureData?.creditLimit !== '0.00';
};

export const getMostExposed = (
  exposures: { company: ExposureData; companyGroup: ExposureData },
  options = {},
): 'company' | 'companyGroup' => {
  // If company group credit limit is zero, we defer to company exposure.
  // If company exposure is zero, that is probably bad data and we can show
  // exposure as Infinity%.
  const companyExposureFactor = calculateExposure(exposures.company, options).factor;
  const groupExposureFactor = exposureValid(exposures.companyGroup)
    ? calculateExposure(exposures.companyGroup, options).factor
    : 0;
  return companyExposureFactor >= groupExposureFactor ? 'company' : 'companyGroup';
};

export const getIsOverExposed = (
  companyExposure: { company: ExposureData; companyGroup: ExposureData },
  totalSelected: string,
): boolean => {
  const maxExposure = calculateExposure(companyExposure[getMostExposed(companyExposure)], {
    selectedAmount: totalSelected,
  });
  return getExposureLevel(maxExposure.factor) === ExposureLevel.OVER;
};
