import cx from 'classnames';
import { Cell, Column, HeaderGroup, useTable } from 'react-table';
import React from 'react';

type OrderOverviewTableProps = {
  columns: Column<Record<string, unknown>>[];
  data: Record<string, unknown>[];
};

const OrderOverviewTable = ({ columns, data }: OrderOverviewTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <table className="border-1 rounded-lg" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr className="bg-gray-100 text-left" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: HeaderGroup & { className?: string }) => (
              <th className="px-4 py-2 text-xs text-turnip-600" {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr className="border-b-1" {...row.getRowProps()}>
              {row.cells.map((cell: Cell & { column: { className?: string } }) => (
                <td
                  {...cell.getCellProps({
                    className: cx('px-4 py-3', cell.column.className),
                  })}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OrderOverviewTable;
