import React, { useEffect } from 'react';
import { TextField } from '@producepay/pp-ui';
import { groupBy, startCase } from 'lodash';
import { decimalSum, formatCurrency } from 'src/helpers/currency';
import { parseISO } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { formatFriendly } from 'src/helpers/format';
import { formatProductType } from 'src/helpers/products';
import {
  ReconcileOutboundPayment,
  UnapproveOutboundPayment,
  CancelApprovedOutboundPayment,
} from 'src/helpers/command/types';
import { DescriptionList, Title, Datum } from 'src/components/elements/DescriptionList';
import CommandButton from 'src/components/molecules/CommandButton';
import DataTable, { LinkFormatter } from 'src/components/molecules/DataTable';
import * as routes from 'src/routes';
import PopoverMenu from 'src/components/elements/PopoverMenu';
import { Sidebar, SidebarHeader, SidebarFooter } from '../Sidebar';
import AmountAlert from '../AmountAlert';

interface PaymentDetailProps {
  batch: OutboundPaymentItemBatch;
  onNotesChanged?: (val: string) => void;
  wireFee: string;
  notes: string;
  refetch: () => void;
}

const columns = [
  {
    displayName: 'Slug',
    name: 'slug',
    itemClass: 'text-primary font-bold',
    formatter: (slug, { detailsUrl }) => (
      <LinkFormatter url={detailsUrl} newTab>
        {slug}
      </LinkFormatter>
    ),
  },
  { displayName: 'Inv/Ref #', name: 'referenceNumber' },
  {
    displayName: 'Amount',
    name: 'amount',
    formatter: (amount, { productType, purpose, suggestedAmount }) => (
      <AmountAlert
        amount={amount}
        context="approved"
        paymentPurpose={purpose}
        productType={productType}
        suggestedAmount={suggestedAmount}
      />
    ),
  },
];

const SidebarFooterActions = ({ batch, refetch, wireFee, notes }) => (
  <SidebarFooter>
    <PopoverMenu label="Edit" points={['bl', 'tl']}>
      <CommandButton<UnapproveOutboundPayment>
        color="default"
        variant="text"
        className="block p-3"
        command={{
          command: 'UnapproveOutboundPayment',
          payload: {
            sourceAccountIdentifier: batch.sourceAccountIdentifier,
            destinationAccountIdentifier: batch.destinationAccountIdentifier,
            outboundPaymentItemIdentifiers: batch.items.map(({ identifier }) => identifier),
          },
        }}
        successTemplate={() => ({
          body: `This payment to ${batch.recipientCompanyName} has been unapproved.`,
          containerClassName: 'last:mb-20',
          header: 'Payment Unapproved',
        })}
        onSuccess={() => {
          refetch();
        }}
      >
        Move to Prepared
      </CommandButton>
      <CommandButton<CancelApprovedOutboundPayment>
        color="default"
        variant="text"
        className="block p-3"
        command={{
          command: 'CancelApprovedOutboundPayment',
          payload: {
            sourceAccountIdentifier: batch.sourceAccountIdentifier,
            destinationAccountIdentifier: batch.destinationAccountIdentifier,
            outboundPaymentItemIdentifiers: batch.items.map(({ identifier }) => identifier),
          },
        }}
        successTemplate={() => ({
          body: `This payment to ${batch.recipientCompanyName} has been canceled.`,
          containerClassName: 'last:mb-20',
          header: 'Payment Canceled',
        })}
        onSuccess={() => {
          refetch();
        }}
      >
        Cancel Payment
      </CommandButton>
    </PopoverMenu>
    <CommandButton<ReconcileOutboundPayment>
      command={{
        command: 'ReconcileOutboundPayment',
        payload: {
          outboundPaymentItemIdentifiers: batch.items.map(({ identifier }) => identifier),
          sourceAccountIdentifier: batch.sourceAccountIdentifier,
          destinationAccountIdentifier: batch.destinationAccountIdentifier,
          wireFee,
          notes,
        },
      }}
      successTemplate={() => ({
        body: `This payment to ${batch.recipientCompanyName} has been marked as sent.`,
        containerClassName: 'last:mb-20',
        header: 'Payment Sent',
      })}
      onSuccess={() => {
        refetch();
      }}
    >
      Send
    </CommandButton>
  </SidebarFooter>
);

export default ({ batch, refetch, wireFee, notes, onNotesChanged }: PaymentDetailProps) => {
  const { recipientCompanyIdentifier, sourceAccountIdentifier } = useParams();
  const history = useHistory();
  useEffect(() => {
    // redirect to company index after deleting or approving OPIs
    if (!batch) {
      history.push(routes.sendPaymentsApproved(sourceAccountIdentifier));
    }
    // missing deps: [history, sourceAccountIdentifier]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientCompanyIdentifier, batch]);
  const { sourceAccountName, approvedBy, approvedAt, destinationAccountName } = batch;
  return (
    <Sidebar>
      <SidebarHeader
        destination={batch.recipientCompanyName}
        amount={formatCurrency(batch.totalAmount)}
        onCloseClicked={() => history.push(routes.sendPaymentsApproved(sourceAccountIdentifier))}
      />
      <DescriptionList className="mx-4 overflow-hidden">
        <Title>Source</Title>
        <Datum>{sourceAccountName}</Datum>
        <Title>Destination</Title>
        <Datum>{destinationAccountName}</Datum>
        <Title>Approved by</Title>
        <Datum>
          {approvedBy}, {approvedAt && formatFriendly(parseISO(approvedAt))}
        </Datum>
      </DescriptionList>
      <div className="px-2 py-4">
        {Object.entries(groupBy(batch.items, 'purpose')).map(([purpose, purposeItems]) =>
          Object.entries(groupBy(purposeItems, p => p.productType || '')).map(([productType, items]) => (
            <div className="overflow-x-scroll" key={`product-list-${purpose}-${productType}`}>
              <h5 className="m-2 py-2 uppercase text-xs border-b-1">
                <span className="font-bold">{startCase(purpose.replace(/_/, ' '))}</span>
                {productType?.length ? ` | ${formatProductType(productType)}` : ''}
                <span className="float-right font-bold">{formatCurrency(decimalSum('amount', items))}</span>
              </h5>
              <DataTable<OutboundPaymentItem>
                columns={columns}
                keyName="identifier"
                rows={items}
                trClass="even:bg-table-highlight"
              />
            </div>
          )),
        )}
      </div>
      <div className="p-4">
        <TextField
          aria-label="Notes"
          name="notes"
          placeholder="Notes"
          onChange={evt => onNotesChanged(evt.target.value)}
          rows={4}
          type="textarea"
          style={{ height: 'auto' }}
          value={notes}
        />
      </div>
      <SidebarFooterActions batch={batch} refetch={refetch} wireFee={wireFee} notes={notes} />
    </Sidebar>
  );
};
