import React, { useEffect, useState } from 'react';
import { Field, FieldArray } from 'formik';
import { flatMap, filter } from 'lodash';
import { Card } from '@producepay/pp-ui';

import FormikTextField from 'src/components/formik/FormikTextField';
import FormikToggle from 'src/components/formik/FormikToggle';

import Fee from 'src/routes/Deals/components/Fee';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';
import { getEmptyFeeEntity } from 'src/routes/Deals/helpers';
import { ApplicationFeeFormValues } from 'src/routes/Deals/types';
import { PSGroupedCompanies } from 'src/routes/Deals/PS/types';

interface ApplicationFeeProps {
  formData: {
    companies: PSGroupedCompanies;
    applicationFee: ApplicationFeeFormValues;
  };
  readOnly: boolean;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
}

const ApplicationFee = ({ readOnly, formData, setFieldValue }: ApplicationFeeProps) => {
  const [companyItems, setCompanyItems] = useState([]);
  const { applicationFee, companies } = formData;

  useEffect(() => {
    if (applicationFee.fees.length === 0) {
      setFieldValue('general.applicationFee.fees', [getEmptyFeeEntity()]);
    }

    const allCompanies = filter(flatMap(companies), 'identifier');

    if (allCompanies.length) {
      setCompanyItems(
        allCompanies.map(c => ({
          label: c.name,
          value: c.identifier,
        })),
      );
    }
  }, [applicationFee.fees.length, companies, setFieldValue]);

  return (
    <Card className="pb-4">
      <SectionHeader title="Application Fee" subtitle="Financial settings for application fees" />
      <SectionItem
        title="Refund Application Fees"
        subtitle="As a cash rebate when the Minimum Annual Produce Volume is reached"
        controls={
          <Field
            as="checkbox"
            name="general.applicationFee.isRefundable"
            component={FormikToggle}
            data-testid="application-fee-is-refundable"
          />
        }
      />
      <SectionItem
        title="Minimum Annual Produce Volume"
        border={false}
        controls={
          <FormikTextField
            name="general.applicationFee.minimumAnnualProduceVolumeForRefund"
            iconPrefix={<span>$</span>}
            withSimpleErrorStyling
          />
        }
      />
      <div className="pt-2 px-4 mx-4">
        <FieldArray
          name="general.applicationFee.fees"
          render={({ push: pushApplicationFee, remove: removeApplicationFee }) => (
            <>
              {applicationFee.fees.map((fee, feeIndex) => (
                <React.Fragment key={fee.key}>
                  <Fee
                    fee={fee}
                    applicationFee={applicationFee}
                    feeIndex={feeIndex}
                    removeApplicationFee={removeApplicationFee}
                    setFieldValue={setFieldValue}
                    companyItems={companyItems}
                    readOnly={readOnly}
                    formLocation="general."
                  />
                </React.Fragment>
              ))}

              <div className="pt-5">
                <button
                  className="text-primary body2 font-semibold"
                  type="button"
                  onClick={() => pushApplicationFee(getEmptyFeeEntity())}
                  data-testid="add-application-fee"
                  disabled={readOnly}
                >
                  + Add Application Fee
                </button>
              </div>
            </>
          )}
        />
      </div>
    </Card>
  );
};

export default ApplicationFee;
