import React from 'react';
import cx from 'classnames';
import { Field, useFormikContext } from 'formik';
import { Select } from '@producepay/pp-ui';
import kebabCase from 'lodash/kebabCase';

import SimpleErrorMessageWrapper from '../SimpleErrorMessageWrapper';
import Wrapper from '../FormikInputWrapper';

import './select.css';

/** FormikSelect
 * Wrapper for wiring up a Select field to the Formik Context.
 * Defers to `FormikInputWrapper` which adds labels and validation
 * error printing.
 */

function FormikMultiSelect({
  className = '',
  label = null,
  name,
  items,
  inputProps = { className: '' },
  withSimpleErrorStyling = false,
  ...props
}): JSX.Element {
  const { setFieldValue, status } = useFormikContext();
  const readOnly = status?.readOnly ?? false;
  const shouldUseSimpleErrorMessage = label ? false : withSimpleErrorStyling;

  const handleSelection = (selection: { label: string; value: unknown }[]) => {
    setFieldValue(
      name,
      selection.map(item => item.value),
    );
  };

  return (
    <Field name={`${name}`} {...props}>
      {({ field, form, meta: { error, touched } }) => {
        const computedClassName = cx(inputProps?.className || '', {
          'cursor-not-allowed opacity-50 bg-gray-200': readOnly,
          'border-red-500': !touched && withSimpleErrorStyling && error,
        });

        const selectField = (
          <Select
            size="small"
            aria-labelledby={`form-label-${name}`}
            name={name}
            items={items}
            isMultiple
            isSearchable={false}
            selectedItems={items.filter(item => field.value.includes(item.value))}
            {...field}
            className={computedClassName}
            onChange={handleSelection}
            data-testid={kebabCase(name)}
            disabled={readOnly}
            {...props}
          />
        );

        if (shouldUseSimpleErrorMessage) {
          return (
            <SimpleErrorMessageWrapper className={className} error={error} cleared={!error}>
              {selectField}
            </SimpleErrorMessageWrapper>
          );
        }

        return (
          <Wrapper className={className} name={name} label={label} form={form}>
            {selectField}
          </Wrapper>
        );
      }}
    </Field>
  );
}

export default React.memo(FormikMultiSelect);
