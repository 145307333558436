import React, { FC } from 'react';
import { CellProps } from 'react-table';
import cx from 'classnames';
import { Info } from 'react-feather';

import { Align, AlignCellContent, CellWithAlign, TextAlignClassName } from 'src/components/elements/Table';
import Tooltip from 'src/components/elements/Tooltip';

import { FundingTransaction } from './types';

export const PaymentTypeCell: FC<
  CellProps<FundingTransaction, FundingTransaction['sourceCompanyUuid']> & CellWithAlign
> = ({ value, column: { align = Align.Right }, row }) => {
  const textAlign = TextAlignClassName[align];

  return (
    <AlignCellContent align={align}>
      <div className="flex flex-col">
        <div className="flex items-center">
          <span className={cx(textAlign, 'body2 capitalize mr-1')}>{value}</span>
          <Tooltip tooltipText={row.original.transactionDescription}>
            <Info size={12} />
          </Tooltip>
        </div>
        <span className={cx(textAlign, 'text-xs text-gray-500')}>{row.original.transactionNumber ?? ''}</span>
      </div>
    </AlignCellContent>
  );
};
