import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Card } from '@producepay/pp-ui';

import FormikSelect from 'src/components/formik/FormikSelect';

import { ISPPValues } from 'src/routes/Deals/ISPP/types';
import RoleCompanies from '../../components/RoleCompanies';
import SectionHeader from '../../components/SectionHeader';

const ISPPFormCompanies = () => {
  const {
    values: {
      ui: { dealType },
    },
    touched,
    initialValues,
    setFieldValue,
  } = useFormikContext<ISPPValues>();

  const dealTypeDirty = touched?.ui?.dealType && initialValues?.ui?.dealType !== dealType;

  useEffect(() => {
    if (dealType === 'distributor' && dealTypeDirty) {
      setFieldValue('companies.producers', []);
      setFieldValue('producerContract', null);
      setFieldValue('distributorContract', null);
    }
    if (dealType === 'producerDistributor' && dealTypeDirty) {
      setFieldValue('producerContract', null);
      setFieldValue('distributorContract', null);
    }
  }, [dealType, dealTypeDirty, setFieldValue]);

  const producerContracts = [
    { label: '121', value: '121' },
    { label: '201', value: '201' },
    { label: '211', value: '211' },
  ];

  const distributorContracts =
    dealType === 'distributor'
      ? [
          { label: '301', value: '301' },
          { label: '302', value: '302' },
        ]
      : [
          { label: '122', value: '122' },
          { label: '202', value: '202' },
          { label: '212', value: '212' },
        ];

  return (
    <Card>
      <SectionHeader
        title="Companies"
        subtitle="Entities trading through the deal"
        controls={
          <FormikSelect
            name="ui.dealType"
            items={[
              {
                label: 'Producer & Distributor',
                value: 'producerDistributor',
              },
              {
                label: 'Distributor',
                value: 'distributor',
              },
            ]}
          />
        }
      />
      {dealType === 'producerDistributor' && (
        <RoleCompanies companyRole="Producer" formRole="producers" contractItems={producerContracts} />
      )}
      <RoleCompanies companyRole="Distributor" formRole="distributors" contractItems={distributorContracts} />
    </Card>
  );
};

export default ISPPFormCompanies;
