import React from 'react';
import { find } from 'lodash';
import { Trash2, Edit } from 'react-feather';
import { PSRepaymentRule } from 'src/routes/Deals/PS/types';
import { CommodityTypes, RepaymentRule } from 'src/routes/Deals/constants';

interface RuleActions {
  onEdit: (ruleIndex: number) => void;
  onDelete: (ruleIndex: number) => void;
}

const ActionCell = ({ onEdit, onDelete }): JSX.Element => {
  return (
    <td className="alignRight text-sm">
      <button className="bg-white focus:outline-none mr-2" onClick={onEdit} type="button">
        <Edit size={17} />
      </button>
      <button className="bg-white focus:outline-none" onClick={onDelete} type="button">
        <Trash2 size={17} />
      </button>
    </td>
  );
};

interface RepaymentRulesTableProps {
  rules: PSRepaymentRule[];
  actions: RuleActions;
}

const RepaymentRulesTable = ({ rules, actions }: RepaymentRulesTableProps): JSX.Element => (
  <table className="financeTable">
    <thead>
      <tr>
        <th>TYPE</th>
        <th colSpan={2}>REPAYMENT AMOUNT</th>
      </tr>
    </thead>
    <tbody>
      {rules.map((rule, index) => {
        if (rule.type === RepaymentRule.Fixed) {
          return (
            <tr key={rule.type}>
              <td colSpan={2}>Fixed</td>
              <ActionCell onDelete={() => actions.onDelete(index)} onEdit={() => actions.onEdit(index)} />
            </tr>
          );
        }
        if (rule.type === RepaymentRule.Percentage) {
          return (
            <tr key={rule.type}>
              <td>Percentage</td>
              <td>
                <b>{rule.amountRate}</b>% of actual shipment value before deductions
              </td>
              <ActionCell onDelete={() => actions.onDelete(index)} onEdit={() => actions.onEdit(index)} />
            </tr>
          );
        }
        if (rule.type === RepaymentRule.PerUnit) {
          return (
            <tr key={`${rule.type}-${rule.commodity.uuid}`}>
              <td>Per Unit</td>
              <td>
                <b>${rule.amount}</b> per{' '}
                <b className="bg-gray-100 px-3 py-1 rounded-full">
                  {`${rule.units.length > 1 ? `${rule.units.length} types of packages` : rule.units[0].name}`}
                </b>{' '}
                of {rule.commodity.name} • {rule.variety.name} •{' '}
                {find(CommodityTypes, { value: rule.classification }).label}
              </td>
              <ActionCell onDelete={() => actions.onDelete(index)} onEdit={() => actions.onEdit(index)} />
            </tr>
          );
        }
        return null;
      })}
    </tbody>
  </table>
);

export default RepaymentRulesTable;
