import { setIn } from 'formik';
import { last } from 'lodash';
import { useEffect } from 'react';
import { Hooks, Column, TableInstance } from 'react-table';
import { createNewRow as defaultCreateNewRow } from '../helpers/createNewRow';

interface ExtendedColumn {
  pastable?: boolean;
}

function normalize(data: string[][]): string[][] {
  const len = data[0].length;
  data.forEach(row => {
    if (row.length < len) {
      const rowLength = len - row.length;
      for (let i = 0; i < rowLength; i += 1) {
        row.push('');
      }
    }
  });
  return data;
}

function parsePastedData(data: string): string[][] {
  const separatorRegex = /\r\n|\n|\r/;
  if (separatorRegex.test(data) || data.includes('\t')) {
    return normalize(
      data
        .trim()
        .split(separatorRegex)
        .map(row => row.split('\t')),
    );
  }
  return [[data]];
}

type ColumnType<T extends Record<string, unknown>> = Column<T> & {
  pasteParser?: (value) => unknown;
};

function merge<T extends Record<string, unknown>>(
  startRowId: number,
  startColumnId: string,
  columns: ColumnType<T>[],
  data: readonly T[],
  matrix: string[][],
  createNewRow: (prevRow?: T, nextRow?: T, currentRow?: T) => T = defaultCreateNewRow,
): T[] {
  const startColumnIndex = columns.findIndex(column => column.id === startColumnId);
  const newData = [...data];
  for (let rowIndex = startRowId; rowIndex < matrix.length + startRowId; rowIndex += 1) {
    if (newData.length <= rowIndex) {
      newData.push(createNewRow(last(newData)));
    } else {
      newData[rowIndex] = { ...newData[rowIndex] };
    }
    for (
      let columnIndex = startColumnIndex;
      columnIndex < columns.length && columnIndex - startColumnIndex < matrix[rowIndex - startRowId].length;
      columnIndex += 1
    ) {
      const { pasteParser } = columns[columnIndex];
      const value = pasteParser
        ? pasteParser(matrix[rowIndex - startRowId][columnIndex - startColumnIndex])
        : matrix[rowIndex - startRowId][columnIndex - startColumnIndex];
      newData[rowIndex] = setIn(newData[rowIndex], columns[columnIndex].id, value);
    }
  }
  return newData;
}

function useInstance<T extends Record<string, unknown>>({
  visibleColumns,
  state,
  data,
  onEdit,
  createNewRow,
}: TableInstance<T> & {
  onEdit(newData: T[]): void;
  state: { selectedCell: { rowId: string; columnId: string } };
  createNewRow?(prevRow?: T, nextRow?: T): T;
}): void {
  // In Chrome and Safari onCopy/onPaste events don't fire on programmaticaly focused element
  // So we are forced to have global event handler
  // Note: this might be a Chromium specific bug as onCopy/onPaste work fine after programmatic focus in Firefox
  useEffect(() => {
    function onCopy(e: ClipboardEvent) {
      if (!state.selectedCell || document.getSelection().toString()) {
        return;
      }
      e.preventDefault();
      e.clipboardData.setData('text/plain', data[state.selectedCell.rowId][state.selectedCell.columnId]);
    }
    function onPaste(e: ClipboardEvent) {
      if (!state.selectedCell) {
        return;
      }
      const column = visibleColumns.find(item => item.id === state.selectedCell.columnId);
      if ((column as ExtendedColumn).pastable === false) {
        return;
      }
      const newData = merge(
        Number(state.selectedCell.rowId),
        state.selectedCell.columnId,
        visibleColumns.filter(item => (item as ExtendedColumn).pastable !== false),
        data,
        parsePastedData(e.clipboardData.getData('text')),
        createNewRow,
      );
      onEdit(newData);
    }
    document.addEventListener('copy', onCopy);
    document.addEventListener('paste', onPaste);
    return () => {
      document.removeEventListener('copy', onCopy);
      document.removeEventListener('paste', onPaste);
    };
  }, [visibleColumns, state, data, onEdit, createNewRow]);
}

export function useCopyPaste<T extends Record<string, unknown>>(hooks: Hooks<T>): void {
  hooks.useInstance.push(useInstance);
}

useCopyPaste.pluginName = 'useCopyPaste';
