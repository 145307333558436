import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal } from '@producepay/pp-ui';

import * as routes from 'src/routes';

type DealLink = { route: string; label: string };

const dealLinks: DealLink[] = [
  {
    route: routes.dealsISPPCreate(),
    label: 'In-Season Pick & Pack Financing',
  },
  { route: routes.dealsISFRCreate(), label: 'In-Season Factoring' },
  {
    route: routes.dealsPNMCreate(),
    label: 'Preferred Network Membership',
  },
  { route: routes.dealsPNTRCreate(), label: 'Preferred Network Trading' },
  {
    route: routes.dealsPNMSCreate(),
    label: 'Mid-Season Financing',
  },
  {
    route: routes.dealsPSCreate(),
    label: 'Pre-Season Financing',
  },
];

export default () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button onClick={handleOpen}>Create Deal</Button>

      {isOpen && (
        <Modal title="Select Product Line" onClose={handleClose}>
          {dealLinks.map(({ route, label }) => (
            <div className="border rounded text-center mb-6 last:mb-0" key={route}>
              <Link to={route} className="block p-6">
                {label}
              </Link>
            </div>
          ))}
        </Modal>
      )}
    </>
  );
};
