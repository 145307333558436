import { endOfDay, startOfDay } from 'date-fns';
import { Row } from 'react-table';

import { SelectOption } from 'src/routes/ReconcilePayments/types';
import { FundingTransaction } from './types';

export function oneOf(
  rows: Row<FundingTransaction>[],
  id: keyof FundingTransaction,
  filterValue: SelectOption[],
): Row<FundingTransaction>[] {
  if (filterValue.length === 0) {
    return rows;
  }
  const filterValueSet = new Set(filterValue.map(item => item.value));
  return rows.filter(item => filterValueSet.has(item.values[id]));
}

export function globalFilter(
  rows: Row<FundingTransaction>[],
  _columnIds: (keyof FundingTransaction)[],
  globalFilterValue: string,
): Row<FundingTransaction>[] {
  function includes(value: string | undefined): boolean {
    return (value ?? '').toLowerCase().includes(globalFilterValue.toLowerCase());
  }
  return rows.filter(
    item =>
      includes(item.original.transactionNumber) ||
      includes(item.original.importedOriginatorCompanyName) ||
      includes(item.values.amount),
  );
}

export function insideDateRange(
  rows: Row<FundingTransaction>[],
  id: string,
  filterValue: { startDate: Date; endDate: Date },
): Row<FundingTransaction>[] {
  return rows.filter(
    item => startOfDay(filterValue.startDate) <= item.values[id] && item.values[id] <= endOfDay(filterValue.endDate),
  );
}
