import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import ReconcileDetails from './ReconcileDetails';
import { receivedPayment, companyTransactions } from './__mocks__/mocks';

const ReconcilePaymentDetails = (): JSX.Element => {
  const history = useHistory();

  const initialValues = {
    companies: companyTransactions.map(transaction => ({
      companyUuid: transaction.companyUuid,
      companyName: transaction.companyName,
      orders: transaction.orders.map(order => ({ ...order, reconciliationAmount: 0 })),
      payee: transaction.payee,
      escrow: { note: '', amount: 0 },
    })),
  };

  return (
    <div>
      <div className="bg-white border-b-1">
        <div className="px-8 py-2 h-16 flex gap-4 items-center">
          <button type="button" onClick={() => history.goBack()}>
            <ArrowLeft className="text-gray-500" />
          </button>
          <div className="h2 font-semibold capitalize">Reconcile Payment</div>
        </div>
      </div>
      <ReconcileDetails receivedPayment={receivedPayment} initialValues={initialValues} />
    </div>
  );
};

export default ReconcilePaymentDetails;
