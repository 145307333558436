import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

export interface MenuBarItem {
  label: string | JSX.Element;
  active: boolean;
  url: string;
}

interface MenuBarProps {
  items: MenuBarItem[];
  className?: string;
}

function MenuBar({ items, className }: MenuBarProps): JSX.Element {
  const inactiveClassName = 'text-gray-400';
  const activeClassName = 'border-b-4 border-primary';

  return (
    <div className={cx('flex flex-row bg-white py-2', className)}>
      {items.map(item => (
        <div key={`menu-${item.url}`} className="mx-8">
          <Link
            to={item.url}
            className={cx('h-10 body2 py-2', {
              [activeClassName]: item.active,
              [inactiveClassName]: !item.active,
            })}
          >
            {item.label}
          </Link>
        </div>
      ))}
    </div>
  );
}

export default MenuBar;
