import React, {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  KeyboardEvent,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  memo,
} from 'react';
import { CellProps } from 'react-table';
import { useField } from 'formik';
import cx from 'classnames';

import { isArrow, isBackspace, isEnter, isEscape, isHotKey, isPrintable, isTab } from './helpers/keyboardHelpers';
import { Align, AlignCellContent, TextAlignClassName } from './AlignCellContent';

export interface EditorProps {
  inputProps: Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & { ref?: Ref<HTMLInputElement> };
  finishEdit(options?: { setNewValue?: boolean; newValue?: string }): void;
  value: string;
}

export const DefaultViewer = memo(
  ({
    value,
    column: { formatter, align = Align.Right },
  }: CellProps<Record<string, unknown>> & { column: { formatter?(value: unknown): string; align?: Align } }) => {
    const textAlign = TextAlignClassName[align];
    return (
      <div>
        <span className={cx('body2', textAlign)}>{formatter?.(value) ?? value}</span>
      </div>
    );
  },
  (prev, next) => prev.value === next.value,
);

export function DefaultEditor({ value, inputProps }: EditorProps) {
  return (
    <div>
      <input value={value} {...inputProps} />
    </div>
  );
}

export function EditCell({
  rootFieldName,
  Viewer = DefaultViewer,
  Editor = DefaultEditor,
  value,
  preserveDisplayCellInput = true,
  parser = val => val,
  ...props
}: CellProps<Record<string, unknown>> & {
  preserveDisplayCellInput?: boolean;
  rootFieldName: string;
  column: { align?: Align };
  Editor?: FC<EditorProps & CellProps<Record<string, unknown>>>;
  Viewer?: FC<CellProps<Record<string, unknown>>>;
  parser?(value: string): string;
}) {
  const fieldName = `${rootFieldName}.${props.cell.row.id}.${props.cell.column.id}`;
  const [field, { error, touched }, { setValue, setTouched }] = useField(fieldName);
  const [editMode, setEditMode] = useState(false);
  const [inEditValue, setInEditValue] = useState('');
  const wrapperRef = useRef<HTMLInputElement>();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!editMode) {
      return undefined;
    }
    // eslint-disable-next-line no-unused-expressions
    inputRef.current?.focus();
    const wrapper = wrapperRef.current;
    return () => {
      wrapper.focus();
    };
  }, [editMode]);

  const finishEdit: EditorProps['finishEdit'] = useCallback(
    ({ setNewValue = true, newValue } = {}) => {
      setEditMode(false);
      if (setNewValue) {
        setValue(newValue ?? parser(inEditValue));
      }
    },
    [setEditMode, setValue, inEditValue, parser],
  );

  const onBlur = useCallback(() => {
    finishEdit();
  }, [finishEdit]);

  const onKeyDownDisplayCell = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (isEnter(e)) {
        e.preventDefault();
        e.stopPropagation();
        setEditMode(true);
        setTouched(true);
        setInEditValue(field.value);
      } else if (isPrintable(e) && !isHotKey(e)) {
        e.preventDefault();
        setEditMode(true);
        setTouched(true);
        setInEditValue(preserveDisplayCellInput ? e.key : field.value);
      } else if (isBackspace(e)) {
        finishEdit({ newValue: '' });
      }
    },
    [setEditMode, setInEditValue, field.value, preserveDisplayCellInput, setTouched, finishEdit],
  );

  const onDoubleClick = useCallback(() => {
    setEditMode(true);
    setTouched(true);
    setInEditValue(field.value);
  }, [setEditMode, setInEditValue, field.value, setTouched]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (isArrow(e)) {
        e.stopPropagation();
      } else if (isEnter(e) || isTab(e)) {
        finishEdit();
      } else if (isEscape(e)) {
        finishEdit({ setNewValue: false });
      }
    },
    [finishEdit],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInEditValue(e.target.value);
    },
    [setInEditValue],
  );

  const onClick = useCallback((e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  }, []);

  const wrapperProps = editMode
    ? { onClick, onKeyDown }
    : {
        role: 'presentation',
        onKeyDown: onKeyDownDisplayCell,
        onDoubleClick,
      };

  const textAlign = TextAlignClassName[props.column.align ?? Align.Right];
  return (
    <AlignCellContent
      tabIndex={0}
      className="relative"
      ref={wrapperRef}
      align={props.column.align ?? Align.Right}
      {...wrapperProps}
    >
      {editMode ? (
        <Editor
          {...props}
          inputProps={{
            className: cx('w-full body2', textAlign),
            ref: inputRef,
            onChange,
            onBlur,
          }}
          finishEdit={finishEdit}
          value={inEditValue}
        />
      ) : (
        <>
          <Viewer value={value} {...props} />
          {!touched && error && (
            <div className="absolute left-3 bottom-px text-xs text-red-600 font-normal w-full">{error}</div>
          )}
        </>
      )}
    </AlignCellContent>
  );
}
