import React from 'react';

import FormikPNMSForm from 'src/routes/Deals/PNMS/Form';

import { DealQueryResponse } from 'src/routes/Deals/Edit/types';
import { FormikDealStatus, OnDealSave } from 'src/routes/Deals/types';
import { PNMSValuesPayload } from 'src/routes/Deals/PNMS/types';
import { FormAction, FormStatus } from 'src/routes/Deals/constants';

import { transformToValues } from 'src/routes/Deals/PNMS/helpers';

import { initialValues } from 'src/routes/Deals/PNMS/Create';

type PNMSEditProps = {
  dealResponse: DealQueryResponse;
  handleDealSave: OnDealSave;
};

export default ({ dealResponse, handleDealSave }: PNMSEditProps) => {
  const { dealConfig, dealUuid, status } = dealResponse;

  const deal = dealConfig ? transformToValues(dealConfig as PNMSValuesPayload, dealUuid, status) : initialValues;
  const readOnly = status === FormStatus.Active;

  const initialStatus: FormikDealStatus = {
    readOnly,
  };

  return (
    <FormikPNMSForm
      initialValues={deal}
      initialStatus={initialStatus}
      action={FormAction.Edit}
      onDealSave={handleDealSave}
    />
  );
};
