import { KeyboardEvent } from 'react';

export function isArrow({ key }: KeyboardEvent<HTMLElement>): boolean {
  switch (key) {
    case 'ArrowUp':
    case 'ArrowDown':
    case 'ArrowLeft':
    case 'ArrowRight':
      return true;
    default:
      return false;
  }
}

export function isPaste({ metaKey, ctrlKey, key }: KeyboardEvent<HTMLElement>): boolean {
  return (metaKey || ctrlKey) && key === 'v';
}

export function isHotKey({ metaKey, ctrlKey }: KeyboardEvent<HTMLElement>): boolean {
  return metaKey || ctrlKey;
}

export function isEnter({ key }: KeyboardEvent<HTMLElement>): boolean {
  return key === 'Enter';
}

export function isEscape({ key }: KeyboardEvent<HTMLElement>): boolean {
  return key === 'Escape';
}

export function isTab({ key }: KeyboardEvent<HTMLElement>): boolean {
  return key === 'Tab';
}

export function isPrintable({ key }: KeyboardEvent<HTMLElement>): boolean {
  return key.length === 1;
}

export function isBackspace({ key }: KeyboardEvent<HTMLElement>): boolean {
  return key === 'Backspace';
}
