import React, { useState } from 'react';

import { Edit, Trash2 } from 'react-feather';
import PSCommodityModal from './PSCommodityModal';
import DistributorDescription from './DistributorDescription';

import { getOrganicDescription } from './helpers';

import { PSForecastCommodity, PSForecastCommodityForm } from '../../types';

type PSCommodityTableRowProps = {
  commodity: PSForecastCommodity;
  distributorNameMap: { [key: string]: string };
  onUpdate: (newCommodity: PSForecastCommodityForm) => void;
  onDelete: () => void;
};

const PSCommodityTableRow = ({
  commodity: existingCommodity,
  distributorNameMap,
  onUpdate,
  onDelete,
}: PSCommodityTableRowProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { commodity, variety, size, packaging, isOrganic, distributorUuid } = existingCommodity;

  return (
    <>
      <tr data-testid={`commodity-row-${existingCommodity.forecastCommodityUuid}`}>
        <td className="font-semibold">{commodity?.name}</td>
        <td className="font-semibold">{variety?.name || 'All Varieties'}</td>
        <td>{size?.name || 'All Sizes'}</td>
        <td>{packaging?.name || 'All Packagings'}</td>
        <td>{getOrganicDescription(isOrganic)}</td>
        <td>
          <DistributorDescription
            distributorUuid={distributorUuid}
            distributorName={distributorNameMap[distributorUuid]}
          />
        </td>
        <td>
          <div className="flex justify-end">
            <Edit
              data-testid="commodity-edit"
              size={20}
              className="text-gray-400 cursor-pointer mr-2"
              onClick={() => setIsModalOpen(true)}
            />

            <Trash2
              data-testid="commodity-delete"
              size={20}
              className="text-gray-400 cursor-pointer"
              onClick={() => onDelete()}
            />
          </div>
        </td>
      </tr>

      {isModalOpen && (
        <PSCommodityModal
          title="Update Commodity"
          commodity={existingCommodity}
          onClose={() => setIsModalOpen(false)}
          onSave={onUpdate}
        />
      )}
    </>
  );
};

export default PSCommodityTableRow;
