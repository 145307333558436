import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Autocomplete } from '@producepay/pp-ui';
import { PlusCircle } from 'react-feather';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import BorderedButton from 'src/components/elements/BorderedButton';
import { GET_COMPANIES_AUTOCOMPLETE } from 'src/graphql/queries/getCompanies';
import { CompaniesAutocomplete } from 'src/graphql/queries/graphql-types/CompaniesAutocomplete';
import SimpleErrorMessageWrapper from 'src/components/formik/SimpleErrorMessageWrapper';
import { SelectOption } from 'src/routes/ReconcilePayments/types';

interface AddCompanyProps {
  onCompanyAdded: (company: SelectOption) => void;
}

interface CompanySearchbarProps extends AddCompanyProps {
  onCancel: () => void;
  companies: SelectOption[];
}

const CompanySearchbar = ({ onCompanyAdded, onCancel, companies }: CompanySearchbarProps) => {
  const [companyName, setCompanyName] = useState('');
  const [company, setCompany] = useState(null);
  const [error, setError] = useState(false);
  const searchRef = useRef(null);
  const history = useHistory();
  const addButtonDisabled = !company && !error;

  useEffect(() => {
    if (searchRef && searchRef.current) {
      if (!company && companyName.length > 0 && searchRef.current.getElementsByTagName('li').length === 0) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [searchRef, companyName, company]);

  const handleCompanyAdded = () => {
    if (error) {
      history.push('/companies/create');
    } else {
      onCompanyAdded(company);
    }
  };

  return (
    <div className="bg-white py-5 px-8 w-full rounded mb-6 flex items-center justify-between">
      <div ref={searchRef} className="w-5/12 flex flex-col">
        <SimpleErrorMessageWrapper error={error ? 'This company does not exist on our platform' : null} cleared={false}>
          <Autocomplete
            searchTerm={companyName}
            onSearchChange={value => setCompanyName(value)}
            items={companies || []}
            onItemSelected={setCompany}
            isLoading={!companies}
          />
        </SimpleErrorMessageWrapper>
      </div>
      <div>
        <button data-testid="cancel-company" type="button" className="ml-2 rounded-full py-2 px-4" onClick={onCancel}>
          Cancel
        </button>
        <button
          data-testid="add-company"
          type="button"
          disabled={addButtonDisabled}
          className={cx(
            'ml-2 rounded-full text-white py-2 px-4',
            !addButtonDisabled ? 'bg-primary' : 'bg-gray-400 cursor-not-allowed',
          )}
          onClick={handleCompanyAdded}
        >
          {error ? 'Create New Company' : 'Add'}
        </button>
      </div>
    </div>
  );
};

const AddCompany = ({ onCompanyAdded }: AddCompanyProps) => {
  const [addingCompany, setAddingCompany] = useState(false);
  const { data: { companies } = { companies: [] } } = useQuery<CompaniesAutocomplete>(GET_COMPANIES_AUTOCOMPLETE);

  return (
    <div className="w-full">
      {addingCompany ? (
        <CompanySearchbar
          companies={companies}
          onCompanyAdded={company => {
            onCompanyAdded(company);
            setAddingCompany(!addingCompany);
          }}
          onCancel={() => setAddingCompany(!addingCompany)}
        />
      ) : (
        <BorderedButton
          onClick={() => setAddingCompany(!addingCompany)}
          className="bg-white border-gray-200 mx-auto"
          icon={<PlusCircle />}
        >
          Company
        </BorderedButton>
      )}
    </div>
  );
};

export default AddCompany;
