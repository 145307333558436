import { AssetCompany } from '../types';

export const getCounterparty = (
  suppliers: AssetCompany[],
  receivers: AssetCompany[],
  companyIdentifier: string,
): AssetCompany => {
  const legacyCompanyId: string = companyIdentifier.replace(/\D/g, '');
  const supplier = suppliers?.[0] ?? null;
  const receiver = receivers?.[0] ?? null;

  return String(supplier?.legacyId) === legacyCompanyId ? receiver : supplier;
};
