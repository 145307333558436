import React from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { dealsEdit } from 'src/routes';
import { FormikDealStatus } from 'src/routes/Deals/types';
import { ISPPValues } from 'src/routes/Deals/ISPP/types';
import {
  FormAction,
  FARMERS_WIRE_IDENTIFIER,
  INSEASON_FUND_IDENTIFIER,
  Document,
  DocumentCategory,
} from '../../constants';
import FormikISPPForm from '../Form';

export const initialValues: ISPPValues = {
  applicationFee: {
    isRefundable: false,
    minimumAnnualProduceVolumeForRefund: '1000000.00',
    fees: [],
  },
  companies: {
    distributors: [],
    producers: [],
  },
  dealReference: null,
  defaultBankAccounts: {
    sourceAccounts: [
      { identifier: INSEASON_FUND_IDENTIFIER, purpose: 'payment' },
      { identifier: FARMERS_WIRE_IDENTIFIER, purpose: 'balance_return' },
    ],
  },
  distributionFee: {
    feePayerRole: null,
    feeRatio: null,
    repaymentTerms: null,
    unitRate: null,
  },
  distributorContract: null,
  effectiveOn: null,
  endsOn: null,
  dealUuid: null,
  lateFee: {
    repaymentDays: null,
    collectionDays: null,
    feePayerRole: null,
  },
  producerContract: null,
  productLineId: 1,
  payment: {
    initialRate: '50.00',
    secondaryRate: '80.00',
    receiverRole: null,
    unitRate: null,
    shipmentAcceptance: 'distributor',
    requiredItems: [
      { name: Document.MANIFEST, type: 'preferred', category: DocumentCategory.PROOF_OF_DELIVERY },
      { name: Document.SIGNED_BILL_OF_LADING, type: 'preferred', category: DocumentCategory.PROOF_OF_DELIVERY },
      { name: Document.OUTSIDE_INVOICE, type: 'preferred', category: DocumentCategory.PROOF_OF_LOAD_VALUE },
      { name: Document.PACA_INVOICE, type: 'preferred', category: DocumentCategory.PROOF_OF_LOAD_VALUE },
      { name: Document.PURCHASE_ORDER, type: 'preferred', category: DocumentCategory.PROOF_OF_LOAD_VALUE },
      { name: Document.CROSSING_DOCUMENTS, type: 'preferred', category: DocumentCategory.OTHER },
    ],
  },
  rebates: [
    { transactionVolumeThreshold: '', amount: '', key: uuidv4() },
    { transactionVolumeThreshold: '', amount: '', key: uuidv4() },
    { transactionVolumeThreshold: '', amount: '', key: uuidv4() },
  ],
  status: 'draft',
  ui: {
    dealType: 'producerDistributor',
    autoRenew: true,
    payment: {
      type: 'standard',
      secondPaymentEnabled: true,
    },
    distributionFee: {
      type: 'standard',
    },
  },
};

const initialStatus: FormikDealStatus = {
  readOnly: false,
};

export default () => {
  const history = useHistory();
  const handleDealSave = dealUuid => history.push(dealsEdit(dealUuid));

  return (
    <FormikISPPForm
      initialValues={initialValues}
      initialStatus={initialStatus}
      action={FormAction.Create}
      onDealSave={handleDealSave}
    />
  );
};
