import React from 'react';
import { Card } from '@producepay/pp-ui';

import FormikTextField from 'src/components/formik/FormikTextField';
import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';

const MaximumRebateAmount = () => {
  return (
    <Card className="pb-4">
      <SectionHeader title="Maximum Rebate Amount" subtitle="Default financial settings for maximum rebate amount" />
      <SectionItem
        title="Maximum Rebate Amount"
        subtitle="Amount Seller is owed of the verified receivable amount if the Receiver repays ProducePay within Asset Pool Aging"
        controls={
          <FormikTextField
            inputProps={{ className: 'text-right' }}
            name="maximumRebate.rate"
            iconSuffix={<span>%</span>}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title="Asset Pool Aging"
        subtitle="Days before rebate amount will start decreasing"
        controls={
          <FormikTextField
            name="maximumRebate.assetPoolAgingDays"
            inputProps={{ className: 'text-right' }}
            withSimpleErrorStyling
          />
        }
        border={false}
      />
    </Card>
  );
};

export default MaximumRebateAmount;
