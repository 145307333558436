import React from 'react';
import { DealQueryResponse } from 'src/routes/Deals/Edit/types';
import { PNTRValuesPayload } from 'src/routes/Deals/PNTR/types';
import FormikPNTRForm from 'src/routes/Deals/PNTR/Form';
import { initialValues } from 'src/routes/Deals/PNTR/Create';
import { transformToValues } from 'src/routes/Deals/PNTR/helpers';
import { FormAction, FormStatus } from 'src/routes/Deals/constants';
import { FormikDealStatus, OnDealSave } from 'src/routes/Deals/types';

type PNTREditProps = {
  dealResponse: DealQueryResponse;
  handleDealSave: OnDealSave;
};

export default ({ dealResponse, handleDealSave }: PNTREditProps) => {
  const { dealConfig, dealUuid, status } = dealResponse;

  const deal = dealConfig ? transformToValues(dealConfig as PNTRValuesPayload, dealUuid, status) : initialValues;
  const readOnly = status === FormStatus.Active;

  const initialStatus: FormikDealStatus = {
    readOnly,
  };

  return (
    <FormikPNTRForm
      initialValues={deal}
      initialStatus={initialStatus}
      action={FormAction.Edit}
      onDealSave={handleDealSave}
    />
  );
};
