import { useCallback, HTMLAttributes, useEffect } from 'react';
import {
  actions,
  ActionType,
  Cell,
  CellPropGetter,
  CellProps,
  Hooks,
  MetaBase,
  TableInstance,
  TableProps,
  TableState,
} from 'react-table';
import { isArrow, isEnter, isTab } from '../helpers/keyboardHelpers';

actions.selectCell = 'selectCell';

function getPrevId(arr: { id: string }[], index: string): string {
  const arrIndex = arr.findIndex(item => item.id === index);
  return arrIndex > 0 ? arr[arrIndex - 1].id : arr[arrIndex].id;
}

function getNextId(arr: { id: string }[], index: string): string {
  const arrIndex = arr.findIndex(item => item.id === index);
  return arrIndex < arr.length - 1 ? arr[arrIndex + 1].id : arr[arrIndex].id;
}

interface SelectedCell {
  rowId: string;
  columnId: string;
}

export interface SelectCellMeta {
  instance: SelectCellInstance;
}

export interface SelectCellInstance {
  state: ExtendedState;
  clearSelection(): void;
  selectCellBelow(): void;
}

interface ExtendedState {
  selectedCell: SelectedCell;
}

interface ExtendedColumn {
  selectable?: boolean;
}

interface ExtendedCell {
  column: ExtendedColumn;
}

function getTableProps<T extends Record<string, unknown>>(
  props: Partial<TableProps> & HTMLAttributes<HTMLDivElement>,
  { instance }: MetaBase<T> & SelectCellMeta,
): (TableProps & HTMLAttributes<HTMLDivElement>)[] {
  const {
    state: { selectedCell },
    dispatch,
    selectCellBelow,
  } = instance;

  return [
    props,
    {
      onKeyDown(e) {
        const selectableColumns = instance.visibleColumns.filter(
          column => (column as ExtendedColumn).selectable !== false,
        );
        // TODO: update eslint to support optional chaining
        // eslint-disable-next-line no-unused-expressions
        props.onKeyDown?.(e);
        if (isTab(e) || isArrow(e)) {
          // To prevent window scroll
          e.preventDefault();
          // To remove text selection on keyboard navigation
          document.getSelection().removeAllRanges();
          let newSelectedCell: SelectedCell | undefined;
          if (isTab(e)) {
            let columnId = e.shiftKey
              ? getPrevId(selectableColumns, selectedCell.columnId)
              : getNextId(selectableColumns, selectedCell.columnId);
            let { rowId } = selectedCell;
            if (columnId === selectedCell.columnId) {
              rowId = e.shiftKey
                ? getPrevId(instance.rows, selectedCell.rowId)
                : getNextId(instance.rows, selectedCell.rowId);
              if (rowId !== selectedCell.rowId) {
                columnId = e.shiftKey ? selectableColumns[selectableColumns.length - 1].id : selectableColumns[0].id;
              }
            }
            newSelectedCell = {
              rowId,
              columnId,
            };
          } else {
            newSelectedCell = {
              rowId:
                e.key === 'ArrowUp'
                  ? getPrevId(instance.rows, selectedCell.rowId)
                  : e.key === 'ArrowDown'
                  ? getNextId(instance.rows, selectedCell.rowId)
                  : selectedCell.rowId,
              columnId:
                e.key === 'ArrowLeft'
                  ? getPrevId(selectableColumns, selectedCell.columnId)
                  : e.key === 'ArrowRight'
                  ? getNextId(selectableColumns, selectedCell.columnId)
                  : selectedCell.columnId,
            };
          }
          if (selectedCell.rowId !== newSelectedCell.rowId || selectedCell.columnId !== newSelectedCell.columnId) {
            dispatch({ type: actions.selectCell, value: newSelectedCell, e });
          }
        } else if (isEnter(e)) {
          selectCellBelow();
        }
      },
      tabIndex: 0,
    },
  ];
}

function getCellProps<T extends Record<string, unknown>>(
  props: CellProps<T>,
  {
    instance,
    cell,
  }: MetaBase<T> & {
    cell: Cell<T> & ExtendedCell;
  } & SelectCellMeta,
): (CellProps<T> | HTMLAttributes<HTMLDivElement>)[] {
  const {
    state: { selectedCell },
    dispatch,
  } = instance;

  const isSelected = selectedCell?.rowId === cell.row.id && selectedCell?.columnId === cell.column.id;

  return [
    props,
    {
      onClick(e) {
        if (cell.column.selectable === false) {
          return;
        }
        dispatch({ type: actions.selectCell, value: { rowId: cell.row.id, columnId: cell.column.id }, e });
      },
      id: isSelected ? 'selectedCell' : undefined,
    },
  ];
}

function reducer<T extends Record<string, unknown>>(
  state: TableState<T> & ExtendedState,
  action: ActionType,
): TableState<T> & ExtendedState {
  if (action.type === actions.selectCell) {
    return {
      ...state,
      selectedCell: action.value,
    };
  }
  return state;
}

function useInstance<T extends Record<string, unknown>>(instance: TableInstance<T> & SelectCellInstance): void {
  const {
    dispatch,
    state: { selectedCell },
    rows,
  } = instance;

  const clearSelection = useCallback(() => {
    dispatch({ type: actions.selectCell });
  }, [dispatch]);

  const selectCellBelow = useCallback(() => {
    dispatch({
      type: actions.selectCell,
      value: { rowId: getNextId(rows, selectedCell.rowId), columnId: selectedCell.columnId },
    });
  }, [selectedCell, dispatch, rows]);

  useEffect(() => {
    if (selectedCell) {
      (document.getElementById('selectedCell').firstChild as HTMLElement).focus();
    }
  }, [selectedCell]);

  Object.assign(instance, { clearSelection, selectCellBelow });
}

export function useSelectCell<T extends Record<string, unknown>>(hooks: Hooks<T>): void {
  hooks.getTableProps.push(getTableProps);
  hooks.getCellProps.push(getCellProps as CellPropGetter<T>);
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
}

useSelectCell.pluginName = 'useSelectCell';
