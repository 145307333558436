import React from 'react';
import { FieldArray } from 'formik';
import FormikTextField from 'src/components/formik/FormikTextField';
import SimpleErrorMessageWrapper from 'src/components/formik/SimpleErrorMessageWrapper';
import { formatCurrency } from 'src/helpers/currency';
import { ReconcileOption } from '../types';

interface ReconcileCompanyTransactionsProps {
  option: ReconcileOption;
  optionIndex: number;
}

const ReconcileCompanyTransactions = ({ option, optionIndex }: ReconcileCompanyTransactionsProps) => (
  <FieldArray
    name="companies"
    render={() => (
      <div className="bg-white rounded">
        <div className="py-6 px-10 border-b-1">
          <p className="text-lg font-semibold">{option.companyName}</p>
        </div>
        <div className="p-6">
          {option.orders.length ? (
            <div className="mb-8">
              <p className="text-md font-semibold mx-4 mb-2">Orders</p>
              <table className="financeTable no-border compact">
                <thead>
                  <tr>
                    <th>SLUG</th>
                    <th>ORDER #</th>
                    <th>NET ORDER VALUE</th>
                    <th>NET ORDER BALANCE</th>
                    <th>RECONCILE</th>
                  </tr>
                </thead>
                <tbody>
                  {option.orders.map((order, orderIndex) => {
                    const netBalance = order.netBalance - order.reconciliationAmount;
                    return (
                      <tr key={order.slug}>
                        <td className="text-primary font-semibold">{order.slug}</td>
                        <td data-testid={`company-${optionIndex}-order-${orderIndex}-number`}>{order.number}</td>
                        <td data-testid={`company-${optionIndex}-order-${orderIndex}-netValue`}>
                          {formatCurrency(order.netValue)}
                        </td>
                        <td data-testid={`company-${optionIndex}-order-${orderIndex}-netBalance`}>
                          {formatCurrency(netBalance)}
                        </td>
                        <td
                          data-testid={`company-${optionIndex}-order-${orderIndex}-reconciliationAmount`}
                          className="w-48"
                        >
                          <SimpleErrorMessageWrapper
                            error={netBalance < 0 || order.reconciliationAmount < 0 ? 'Invalid amount' : null}
                            cleared={false}
                          >
                            <FormikTextField
                              type="number"
                              name={`companies[${optionIndex}].orders[${orderIndex}].reconciliationAmount`}
                              iconPrefix={<span>$</span>}
                            />
                          </SimpleErrorMessageWrapper>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
          <div className="flex mx-4 justify-between items-center">
            <p className="text-md font-semibold">Escrow</p>
            <div className="w-40">
              <FormikTextField
                type="number"
                name={`companies[${optionIndex}].escrow.amount`}
                iconPrefix={<span>$</span>}
              />
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

export default ReconcileCompanyTransactions;
