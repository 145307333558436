import React, { useState, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { startCase, has } from 'lodash';

import { PSValues } from 'src/routes/Deals/PS/types';

import MenuBar from '../../../components/MenuBar';
import FormHeader from '../../../components/FormHeader';
import DealReference from '../../../components/DealReference';
import { MenuItem, OnDealSave } from '../../../types';
import { FormAction } from '../../../constants';
import { MENU_ORDER } from '../../constants';
import { activationSchema } from '../../validationSchemas';
import { getConfigMap } from './helpers';
import { PSDealNavigationContextProvider } from './context';

export const PSDealNavigation = ({ action, onDealSave }: { action: FormAction; onDealSave: OnDealSave }) => {
  const formikContext = useFormikContext<PSValues>();
  const {
    values: {
      general: { status, dealUuid },
    },
    errors,
    touched,
  } = formikContext;

  const [selectedTab, setSelectedTab] = useState(MenuItem.General);
  const selectedTabIndex = MENU_ORDER.indexOf(selectedTab);

  const prevTab = MENU_ORDER[selectedTabIndex - 1];
  const nextTab = MENU_ORDER[selectedTabIndex + 1];

  const {
    SelectedView,
    validationSchema,
    transformPayload,
    removeDefaultEntities,
    saveDraftSuccessToastBody,
    saveDraftFailedToastHeader,
    saveDraftButtonText,
    formatErrors,
    getAllValues,
    getGeneralValues,
    transformToGeneralPayload,
    commandType,
    onDealSaveCallback,
  } = getConfigMap(formikContext, action)[selectedTab];

  const menuBarItems = MENU_ORDER.map(value => ({
    value,
    label: startCase(value),
    isActive: selectedTab === value,
    shouldDisplayError: has(errors, value) && !has(touched, value),
  }));

  const navigatePrev = useCallback(() => {
    // TODO: validation
    setSelectedTab(prevTab);
  }, [prevTab]);

  const navigateNext = useCallback(() => {
    // TODO: validation
    setSelectedTab(nextTab);
  }, [nextTab]);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    <PSDealNavigationContextProvider
      navigatePrev={prevTab ? navigatePrev : null}
      navigateNext={nextTab ? navigateNext : null}
    >
      <FormHeader<any, any>
        action={action}
        status={status}
        dealUuid={dealUuid}
        onDealSave={uuid => {
          onDealSaveCallback && onDealSaveCallback();
          onDealSave(uuid);
        }}
        productLine="Pre-Season Financing"
        activationSchema={activationSchema}
        saveDraftSchema={validationSchema}
        transformToPayload={transformPayload}
        removeDefaultEntities={removeDefaultEntities}
        selectedTab={selectedTab}
        inputProps={{
          onSaveDraftSuccessToastBody: saveDraftSuccessToastBody,
          onSaveDraftFailedToastHeader: saveDraftFailedToastHeader,
          saveDraftButtonText,
          formatErrors,
          getAllValues,
          getGeneralValues,
          transformToGeneralPayload,
        }}
        commandType={commandType}
      />

      <DealReference autoRenew={false} renewable={false} formLocation="general." />

      <MenuBar items={menuBarItems} onClick={setSelectedTab} />

      <SelectedView />
    </PSDealNavigationContextProvider>
  );
  /* eslint-enable @typescript-eslint/no-explicit-any */
};
