import React from 'react';
import { useFormikContext } from 'formik';
import cx from 'classnames';

import './toggle.css';

function FormikToggle({ field, form, meta, className, disabled = false, ...props }) {
  const { status } = useFormikContext();
  const readOnly = status?.readOnly ?? false;

  const computedClassName = cx(
    className,
    {
      'bg-primary': field.value,
      'react-toggle-off': !field.value,
      'opacity-75 cursor-not-allowed': disabled || readOnly,
    },
    'react-toggle-label',
  );

  return (
    <>
      <label className={computedClassName} htmlFor={field.name}>
        <input
          checked={field.value || false}
          className="react-toggle-checkbox"
          id={field.name}
          type="checkbox"
          disabled={disabled || readOnly}
          {...field}
          {...props}
        />
        <span className="react-toggle-button" />
      </label>
    </>
  );
}

export default React.memo(FormikToggle);
