import dateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { safeFormatISO } from 'src/helpers/dates';
import { PNMValues, PNMValuesPayload } from 'src/routes/Deals/PNM/types';
import { DATEPICKER_FORMAT } from '../constants';

export const removeDefaultEntities = (data: PNMValues): PNMValues => data;

export const transformToPayload = (data: PNMValues): PNMValuesPayload => {
  return {
    ...data,
    effectiveOn: data.effectiveOn ? safeFormatISO(new Date(data.effectiveOn)) : null,
    membershipFee: {
      ...data.membershipFee,
      datePaid: data?.membershipFee?.datePaid ? safeFormatISO(new Date(data.membershipFee.datePaid)) : null,
    },
  };
};

export const transformToValues = (dealConfig: PNMValuesPayload, dealUuid: string, status: string): PNMValues => {
  return {
    ...dealConfig,
    dealUuid,
    status: status.toLowerCase(),
    effectiveOn: dealConfig.effectiveOn
      ? `${dateFnsFormat(parseISO(dealConfig.effectiveOn).getTime(), DATEPICKER_FORMAT)}`
      : null,
    membershipFee: {
      ...dealConfig.membershipFee,
      datePaid: dealConfig?.membershipFee?.datePaid
        ? `${dateFnsFormat(parseISO(dealConfig.membershipFee.datePaid).getTime(), DATEPICKER_FORMAT)}`
        : null,
    },
  };
};
