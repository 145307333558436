import * as Yup from 'yup';
import { PNTRCompany, PNTRDocumentAcceptance, PNTRValues } from 'src/routes/Deals/PNTR/types';
import { decimalString } from '../helpers';

const validateCompaniesFundingAccounts = (value, context) => {
  const { path, createError } = context;
  const receiverRole = 'sellers';
  const companies = value[receiverRole] ?? [];
  const errors: Array<Yup.ValidationError> = [];

  companies.forEach((company, companyIndex) => {
    const { fundingAccounts = [] } = company;

    fundingAccounts.forEach((fundingAccount, index) => {
      if (!fundingAccount.accountId) {
        const error = createError({
          path: `${path}.${receiverRole}.${companyIndex}.fundingAccounts.${index}.accountId`,
          message: 'Bank Account is required',
        });

        errors.push(error);
      }
    });
  });

  if (errors.length) {
    throw new Yup.ValidationError(errors);
  }

  return true;
};

const companySchema: Yup.SchemaOf<PNTRCompany> = Yup.object().shape({
  identifier: Yup.string()
    .when('role', {
      is: role => role === 'seller',
      then: Yup.string().nullable().required('Seller Company is required'),
      otherwise: Yup.string().nullable().required('Buyer Company is required'),
    })
    .nullable()
    .required('Company Required'),
  role: Yup.mixed().oneOf(['buyer', 'seller']),
  contactName: Yup.string().nullable(),
  name: Yup.string().nullable(),
  key: Yup.string().nullable(),
  fundingAccounts: Yup.array().of(
    Yup.object().shape({
      accountId: Yup.string().nullable(),
      value: decimalString('Amount'),
      key: Yup.string().nullable(),
      type: Yup.string().nullable(),
    }),
  ),
});

export const saveDraftSchema: PNTRValidationSchema = Yup.object().shape({
  companies: Yup.object().shape({
    buyers: Yup.array().of(companySchema).min(1, 'Buyer Company is required'),
    sellers: Yup.array().of(companySchema).min(1, 'Seller Company is required'),
  }),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  defaultBankAccounts: Yup.object().shape({
    sourceAccounts: Yup.array(),
  }),
  documentAcceptance: Yup.mixed<PNTRDocumentAcceptance>(),
  effectiveOn: Yup.string().nullable(),
  endsOn: Yup.string().nullable(),
  dealUuid: Yup.string().nullable(),
  productLineId: Yup.number().required(),
  repayment: Yup.object().shape({
    repaymentDays: decimalString('Repayment Days'),
    consignmentCollectionDays: decimalString('Consignment Collection Days'),
    fixedPriceCollectionDays: decimalString('Fixed Price Collection Days'),
    tradeAssurance: Yup.boolean(),
    requiredItems: Yup.array(),
  }),
  tradingFees: Yup.object().shape({
    sellerFeeRate: decimalString('Seller Fee Rate'),
    buyerFeeRate: decimalString('Buyer Fee Rate'),
  }),
  status: Yup.string(),
  ui: Yup.object().shape({
    autoRenew: Yup.boolean(),
    disabled: Yup.boolean(),
    receiverExceptionsEnabled: Yup.boolean(),
  }),
});

const activationCompanySchema: Yup.SchemaOf<PNTRCompany> = Yup.object().shape({
  identifier: Yup.string()
    .when('role', {
      is: role => role === 'seller',
      then: Yup.string().nullable().required('Seller Company is required'),
      otherwise: Yup.string().nullable().required('Buyer Company is required'),
    })
    .nullable()
    .required('Company Required'),
  role: Yup.mixed().oneOf(['buyer', 'seller']),
  contactName: Yup.string().when('role', {
    is: role => role === 'seller',
    then: Yup.string().nullable().required('Seller Contact is required'),
    otherwise: Yup.string().nullable().required('Buyer Contact is required'),
  }),
  name: Yup.string().nullable(),
  key: Yup.string().nullable(),
  fundingAccounts: Yup.array().of(
    Yup.object().shape({
      accountId: Yup.string().nullable(),
      value: decimalString('Amount'),
      key: Yup.string().nullable(),
      type: Yup.string().nullable(),
    }),
  ),
});

export const activationSchema: PNTRValidationSchema = Yup.object().shape({
  companies: Yup.object()
    .shape({
      buyers: Yup.array().of(activationCompanySchema).min(1, 'Buyer Company is required'),
      sellers: Yup.array().of(activationCompanySchema).min(1, 'Seller Company is required'),
    })
    .test(validateCompaniesFundingAccounts),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  defaultBankAccounts: Yup.object().shape({
    sourceAccounts: Yup.array().of(
      Yup.object().shape({
        identifier: Yup.string().nullable().required('Balance Return Source Account is required'),
        purpose: Yup.string().nullable().required(),
      }),
    ),
  }),
  documentAcceptance: Yup.mixed<PNTRDocumentAcceptance>().nullable().required('Document Acceptance Party is required'),
  effectiveOn: Yup.string().nullable().required('Effective Date is required'),
  endsOn: Yup.string().when('$autoRenew', {
    is: autoRenew => autoRenew === false,
    then: Yup.string().nullable().required('Ends On Date is required'),
    otherwise: Yup.string().nullable(),
  }),
  dealUuid: Yup.string().nullable(),
  productLineId: Yup.number().required(),
  repayment: Yup.object().shape({
    repaymentDays: decimalString('Repayment Days').required('Repayment Terms is required'),
    consignmentCollectionDays: decimalString('Consignment Collection Days').required(
      'Consignment Collection Alert is required',
    ),
    fixedPriceCollectionDays: decimalString('Fixed Price Collection Days').required(
      'Fixed Price Collection Alert is required',
    ),
    tradeAssurance: Yup.boolean(),
    requiredItems: Yup.array(),
  }),
  tradingFees: Yup.object().shape({
    sellerFeeRate: decimalString('Seller Fee Rate').nullable().required('Seller Fee is required'),
    buyerFeeRate: decimalString('Buyer Fee Rate').nullable().required('Buyer Fee is required'),
  }),
  status: Yup.string(),
  ui: Yup.object().shape({
    autoRenew: Yup.boolean(),
    disabled: Yup.boolean(),
    receiverExceptionsEnabled: Yup.boolean(),
  }),
});

export type PNTRValidationSchema = Yup.SchemaOf<PNTRValues>;
