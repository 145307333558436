import React from 'react';
import cx from 'classnames';

import { Button } from '@producepay/pp-ui';
import { usePSDealNavigation } from '../context';

interface PSFooterProps {
  className?: string;
}

const PSFooter = ({ className }: PSFooterProps) => {
  const { navigatePrev, navigateNext, saveCallback } = usePSDealNavigation();

  const goNext = () => {
    navigateNext();
    saveCallback.current();
  };

  const goBack = () => {
    navigatePrev();
    saveCallback.current();
  };

  return (
    <div className={cx('flex justify-between mb-2', className)}>
      {navigatePrev ? (
        <Button variant="outlined" color="secondary" className="w-36 font-semibold body2" onClick={goBack}>
          Previous
        </Button>
      ) : (
        <div />
      )}

      {navigateNext ? (
        <Button className="w-36 font-semibold body2" onClick={goNext}>
          Continue
        </Button>
      ) : (
        <div />
      )}
    </div>
  );
};

export default PSFooter;
