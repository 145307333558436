import React from 'react';
import { Field, FieldArray } from 'formik';
import { Grid } from '@producepay/pp-ui';

import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';
import FormikToggle from 'src/components/formik/FormikToggle';
import Trash from 'src/components/icons/Trash';

import { ApplicationFee, ApplicationFeeFormValues } from 'src/routes/Deals/types';
import { getPaymentMethodEntity } from '../helpers';
import PaymentMethod from './PaymentMethod';

interface FeeProps {
  applicationFee: ApplicationFeeFormValues;
  companyItems: { label: string; value: string }[];
  fee: ApplicationFee;
  feeIndex: number;
  removeApplicationFee: (index: number) => void;
  setFieldValue: (field: string, value: unknown) => void;
  readOnly: boolean;
  formLocation?: string;
}

const Fee = ({
  fee,
  feeIndex,
  setFieldValue,
  applicationFee,
  removeApplicationFee,
  companyItems,
  readOnly,
  formLocation = '',
}: FeeProps) => {
  const handleIsWaivedChange = () => {
    const nextIsWaivedValue = !fee.isWaived;
    setFieldValue(
      `${formLocation}applicationFee.fees.${feeIndex}.paymentMethods`,
      nextIsWaivedValue ? [] : [getPaymentMethodEntity('payment'), getPaymentMethodEntity('balance_return')],
    );
    setFieldValue(`${formLocation}applicationFee.fees.${feeIndex}.isWaived`, nextIsWaivedValue);
  };
  return (
    <>
      {feeIndex > 0 ? <div className="h-10" /> : null}
      <Grid container className="border-1 rounded">
        <div className="bg-gray-100 p-4 font-bold w-full rounded rounded-b-none flex justify-between">
          <div className="sm:w-1/2 flex justify-between">
            <div className="sm:w-3/4 flex">
              <FormikSelect
                name={`${formLocation}applicationFee.fees[${feeIndex}].companyIdentifier`}
                placeholder="Select Company"
                items={companyItems}
                withSimpleErrorStyling
              />
            </div>
            <div className="font-bold flex pr-4 pt-2">Fee</div>
          </div>
          <div className="sm:w-1/2 flex justify-between">
            <div className="flex justify-between">
              <div className="w-5/6">
                <FormikTextField
                  name={`${formLocation}applicationFee.fees[${feeIndex}].amountDue`}
                  iconPrefix={<span>$</span>}
                  withSimpleErrorStyling
                />
              </div>
            </div>
            <div className="sm:w-1/2 flex justify-end pr-2 pt-2">
              <div className="sm:w-3/4 flex">
                <Field
                  as="checkbox"
                  name={`${formLocation}applicationFee.fees.${feeIndex}.isWaived`}
                  component={FormikToggle}
                  onChange={handleIsWaivedChange}
                />
                <div className="pl-2">Waive</div>
              </div>
              {applicationFee.fees.length > 1 ? (
                <button
                  className="focus:outline-none pb-2 pl-6 flex justify-start"
                  type="button"
                  data-testid={`remove-application-fee-${feeIndex}`}
                  onClick={() => removeApplicationFee(feeIndex)}
                  disabled={readOnly}
                >
                  <Trash disabled={readOnly} size={14} />
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {!applicationFee.fees[feeIndex].isWaived && (
          <>
            <div className="p-4 font-bold w-full flex justify-center border-b-1 mx-4">
              <div className="w-1/2 font-bold flex items-center pr-2 justify-end">Paid</div>
              <div className="sm:w-1/2 flex justify-start">
                <div className="flex justify-start">
                  <div className="w-5/6">
                    <FormikTextField
                      name={`${formLocation}applicationFee.fees[${feeIndex}].amountPaid`}
                      iconPrefix={<span>$</span>}
                      withSimpleErrorStyling
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 mx-2 pb-4 w-full">
              <Grid container className="justify-between pt-6">
                <div className="sm:w-2/3">
                  <div className="font-semibold">Outstanding Fee Payment</div>
                  <div className="body2 text-gray-500">Configure the fund management of the outstanding fee</div>
                </div>
              </Grid>
            </div>
            <Grid className="p-4">
              <FieldArray
                name={`${formLocation}applicationFee.fees.${feeIndex}.paymentMethods`}
                render={({ push: pushPaymentMethod, remove: removePaymentMethod }) => (
                  <>
                    {applicationFee.fees[feeIndex].paymentMethods.map((method, index) => (
                      <React.Fragment key={method.key}>
                        <PaymentMethod
                          fee={fee}
                          method={method}
                          index={index}
                          removePaymentMethod={removePaymentMethod}
                          setFieldValue={setFieldValue}
                          feeIndex={feeIndex}
                          readOnly={readOnly}
                          formLocation={formLocation}
                        />
                      </React.Fragment>
                    ))}
                    {!readOnly && (
                      <div className="pl-4 py-1">
                        <button
                          className="text-primary body2 font-semibold"
                          type="button"
                          onClick={() => pushPaymentMethod(getPaymentMethodEntity())}
                          disabled={readOnly}
                          data-testid="add-payment-method"
                        >
                          + Add Payment Method
                        </button>
                      </div>
                    )}
                  </>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Fee;
