import React from 'react';
import { Link } from 'react-router-dom';

import * as routes from '..';

function Dashboard() {
  return (
    <div>
      <Link to={routes.receivePayments()}>
        <div className="p-8">Receive Payments</div>
      </Link>

      <Link to={routes.sendPayments()}>
        <div className="p-8">Send Payments</div>
      </Link>

      <Link to={routes.companiesIndex()}>
        <div className="p-8">Companies</div>
      </Link>

      <Link to={routes.unreconciled()}>
        <div className="p-8">Unreconciled Index</div>
      </Link>

      <Link to={routes.fundActivityIndex()}>
        <div className="p-8">Fund Activities</div>
      </Link>

      <Link to={routes.dealsIndex()}>
        <div className="p-8">Deals Index</div>
      </Link>

      <Link to={routes.reconcilePayments()}>
        <div className="p-8">Reconcile Payments</div>
      </Link>
    </div>
  );
}

export default Dashboard;
