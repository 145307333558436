import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Card, CardHeader, LoadingSpinner } from '@producepay/pp-ui';
import { useDebounce, SEARCH_DEBOUNCE_MS } from 'src/helpers/common';
import GET_FUND_ACTIVITIES from 'src/graphql/queries/getFundActivities';
import ElasticTable from 'src/components/molecules/ElasticTable';
import FundActivityRow from './FundActivityRow';
import QueryBar, { FundActivitiesQuery } from './QueryBar';

function FundActivityIndex() {
  const [totalRecords, setTotalRecords] = useState(0);
  const [query, setQuery] = useState<FundActivitiesQuery>();
  const searchText = useDebounce(query?.searchText, SEARCH_DEBOUNCE_MS);

  // hilite results of filtering
  const hiLiteAfterFiltering = data => {
    setTotalRecords(data.fundActivities.total_count);
  };

  // find fund activities
  const {
    loading,
    data: activities,
    refetch,
  } = useQuery(GET_FUND_ACTIVITIES, {
    variables: { ...query, searchText },
    fetchPolicy: 'no-cache',
    onCompleted: hiLiteAfterFiltering,
  });

  const columns = [
    { name: 'created-on', displayName: 'Created On' },
    { name: 'effective-on', displayName: 'Effective On' },
    { name: 'company', displayName: 'Company', width: '23%' },
    { name: 'link', displayName: 'Link', width: '5em' },
    { name: 'entry-type', displayName: 'Entry Type' },
    { name: 'amount', displayName: 'Amount', width: '8em', align: 'right' },
    { name: 'balance', displayName: '', width: '11em' },
    { name: 'hold', displayName: '', width: '8em' },
  ];

  return (
    <>
      <CardHeader className="bg-white " titleClassName="font-extrabold text-2xl" title="Fund Activities" />
      <div className="FundActivityIndex px-5 sm:px-8 pb-6">
        <Card className="mb-6 mt-6 px-5 py-6">
          <QueryBar totalRecords={totalRecords} onChange={setQuery} />
        </Card>
        <Card>
          <ElasticTable columns={columns}>
            {loading ? (
              <div className="p-8">
                <LoadingSpinner centered />
              </div>
            ) : (
              activities &&
              activities.fundActivities.results.map((activity, index) => (
                <FundActivityRow
                  key={activity.identifier}
                  defaultOpen={index === 0}
                  createdOn={activity.createdOn}
                  effectiveOn={activity.effectiveOn}
                  identifier={activity.identifier}
                  legacyPaymentUrl={activity.legacyPaymentUrl}
                  companyName={activity.companyName}
                  amount={activity.amount}
                  entryType={activity.entryType}
                  checkNumber={activity.checkNumber}
                  details={activity.details}
                  reverted={activity.reverted}
                  refetch={refetch}
                />
              ))
            )}
          </ElasticTable>
        </Card>
      </div>
    </>
  );
}

export default FundActivityIndex;
