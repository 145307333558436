import React from 'react';

type HeaderCellProps = {
  className?: string;
  children: React.ReactNode;
};

export const HeaderCell = ({ children, className = '' }: HeaderCellProps) => (
  <th className={`px-4 py-2 text-xs text-turnip-600 ${className}`}>{children}</th>
);

export default HeaderCell;
