import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { LoadingSpinner } from '@producepay/pp-ui';

import { DealQueryResponse } from 'src/routes/Deals/Edit/types';
import { Deal, DealVariables } from 'src/routes/Deals/Edit/graphql-types/Deal';
import ISPPEdit from '../ISPP/Edit';
import ISFREdit from '../ISFR/Edit';
import PNMEdit from '../PNM/Edit';
import PNTREdit from '../PNTR/Edit';
import PNMSEdit from '../PNMS/Edit';
import PSEdit from '../PS/Edit';

export const GET_DEAL_QUERY = gql`
  query Deal($dealUuid: String!) {
    deal(dealUuid: $dealUuid) {
      dealUuid
      dealReference
      status
      dealConfig
      createdAt
      updatedAt
      endsOn
      productLine {
        name
        shortName
        slug
        id
      }
    }
  }
`;

export default () => {
  const { dealUuid } = useParams<string>();
  const [dealResponse, setDealResponse] = useState<DealQueryResponse>(null);
  const [slug, setSlug] = useState(null);
  const { data, loading, refetch } = useQuery<Deal, DealVariables>(GET_DEAL_QUERY, {
    variables: { dealUuid },
  });

  useEffect(() => {
    if (data?.deal) {
      const {
        productLine: { slug: existingSlug },
      } = data.deal;
      setSlug(existingSlug);
      setDealResponse(data.deal);
    }
  }, [data?.deal]);

  const handleDealSave = () => refetch();

  const EditComponent = () => {
    if (!slug) {
      return null;
    }

    switch (slug) {
      case 'ispp':
        return <ISPPEdit dealResponse={dealResponse} handleDealSave={handleDealSave} />;
      case 'isfr':
        return <ISFREdit dealResponse={dealResponse} handleDealSave={handleDealSave} />;
      case 'pnm':
        return <PNMEdit dealResponse={dealResponse} handleDealSave={handleDealSave} />;
      case 'pntr':
        return <PNTREdit dealResponse={dealResponse} handleDealSave={handleDealSave} />;
      case 'pnms':
        return <PNMSEdit dealResponse={dealResponse} handleDealSave={handleDealSave} />;
      case 'ps':
        return <PSEdit dealResponse={dealResponse} handleDealSave={handleDealSave} />;
      default:
        return <div>Error - Unknown Product Slug: {slug}</div>;
    }
  };

  return loading ? (
    <div className="mt-10">
      <LoadingSpinner centered />
    </div>
  ) : (
    <EditComponent />
  );
};
