import React from 'react';
import { useHistory } from 'react-router-dom';

import { dealsEdit } from 'src/routes';
import {
  FormAction,
  FARMERS_WIRE_IDENTIFIER,
  INSEASON_FUND_IDENTIFIER,
  Document,
  DocumentCategory,
} from 'src/routes/Deals/constants';
import { FormikDealStatus } from 'src/routes/Deals/types';
import { PNMSValues, PNMSDocumentAcceptanceRole, PNMSRole } from 'src/routes/Deals/PNMS/types';
import FormikPNMSForm from 'src/routes/Deals/PNMS/Form';

export const initialValues: PNMSValues = {
  companies: {
    distributor: [],
    producer: [],
  },
  dealReference: null,
  defaultBankAccounts: {
    sourceAccounts: [
      { identifier: INSEASON_FUND_IDENTIFIER, purpose: 'payment' },
      { identifier: FARMERS_WIRE_IDENTIFIER, purpose: 'balance_return' },
    ],
  },
  distributionFee: { rate: '1.00' },
  effectiveOn: null,
  endsOn: null,
  dealUuid: null,
  lateFee: {
    dailyLateFeeRate: '0.05',
    makeGoodDeliveryDays: null,
  },
  payment: {
    documentAcceptanceRole: PNMSDocumentAcceptanceRole.Distributor,
    paymentRate: '50',
    receiverRole: PNMSRole.Producer,
    requiredItems: [
      {
        name: Document.MANIFEST,
        type: 'required',
        category: DocumentCategory.PROOF_OF_INTENT_TO_SELL_OR_PURCHASE,
      },
      {
        name: Document.PURCHASE_ORDER,
        type: 'required',
        category: DocumentCategory.PROOF_OF_INTENT_TO_SELL_OR_PURCHASE,
      },
    ],
  },
  productLineId: 6,
  status: 'draft',
  ui: { autoRenew: true },
};

const initialStatus: FormikDealStatus = {
  readOnly: false,
};

export function PNMSCreate() {
  const history = useHistory();
  const handleDealSave = dealUuid => history.push(dealsEdit(dealUuid));

  return (
    <FormikPNMSForm
      initialValues={initialValues}
      initialStatus={initialStatus}
      action={FormAction.Create}
      onDealSave={handleDealSave}
    />
  );
}
