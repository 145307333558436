import React, { useEffect } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Card, Grid } from '@producepay/pp-ui';
import { v4 as uuidv4 } from 'uuid';

import Trash from 'src/components/icons/Trash';
import FormikTextField from 'src/components/formik/FormikTextField';
import FormLabel from 'src/components/elements/FormLabel';
import { ISFRValues } from 'src/routes/Deals/ISFR/types';
import SectionHeader from '../../components/SectionHeader';

const RebateSchedule = () => {
  const {
    values: { rebates },
    status: { readOnly },
    setFieldValue,
  } = useFormikContext<ISFRValues>();

  useEffect(() => {
    if (isEmpty(rebates)) {
      setFieldValue('rebates', [{ transactionVolumeThreshold: '', amount: '', key: uuidv4() }]);
    }
  }, [rebates, setFieldValue]);

  return (
    <Card className="pb-2">
      <SectionHeader
        title="Volume Rebate Schedule"
        subtitle="Cash rebates when transaction volume thresholds are met. Optional."
      />
      <Grid container className="px-4 pt-2">
        <Grid sm="2/3">
          <FormLabel label="Transaction Volume Threshold" />
        </Grid>
        <Grid sm="1/3">
          <FormLabel label="One-Time Cash Rebate" />
        </Grid>
      </Grid>
      <Grid>
        <FieldArray
          name="rebates"
          render={({ push, remove }) => (
            <>
              {rebates.map((rebate, index) => (
                <Grid container key={`rebate-${rebate.key}`} className="justify-between px-2 pt-1">
                  <Grid sm="2/3">
                    <FormikTextField
                      name={`rebates[${index}].transactionVolumeThreshold`}
                      inputProps={{
                        className: 'h-10',
                      }}
                      iconPrefix={<span>$</span>}
                      withSimpleErrorStyling
                    />
                  </Grid>
                  <Grid sm="1/3" className="flex flex-row">
                    <FormikTextField
                      name={`rebates[${index}].amount`}
                      inputProps={{
                        className: 'h-10',
                      }}
                      iconPrefix={<span>$</span>}
                      withSimpleErrorStyling
                    />
                    {!readOnly && (
                      <button
                        className="bg-white focus:outline-none py-2 pl-6 pr-2"
                        color="white"
                        type="button"
                        data-testid={`remove-rebate-${index}`}
                        onClick={() => remove(index)}
                        disabled={readOnly}
                      >
                        <Trash disabled={readOnly} size={14} />
                      </button>
                    )}
                  </Grid>
                </Grid>
              ))}
              {!readOnly && (
                <div className="pt-4 px-2">
                  <button
                    className="text-primary body2 font-semibold"
                    type="button"
                    onClick={() =>
                      push({
                        transactionVolumeThreshold: '',
                        amount: '',
                        key: uuidv4(),
                      })
                    }
                    data-testid="add-rebate"
                  >
                    + Add Threshold
                  </button>
                </div>
              )}
            </>
          )}
        />
      </Grid>
    </Card>
  );
};

export default RebateSchedule;
