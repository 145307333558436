import React, { createContext, ReactNode, useEffect, useState, useRef, useContext } from 'react';
import { Card } from '@producepay/pp-ui';
import ChevronUp from '@producepay/pp-ui/dist/components/icons/ChevronUp';

interface ElasticTableColumn {
  align?: string;
  name: string;
  displayName: string;
  width?: number | string;
}
interface ElasticTableProps {
  children: ReactNode;
  columns: ElasticTableColumn[];
  hPadding?: number | string;
}
interface ElasticTableDatumProps {
  children: ReactNode;
  className?: string;
  name: string | string[];
}
interface ElasticTableRowProps {
  children: ReactNode;
  defaultOpen: boolean;
  className?: string;
  detail: () => ReactNode;
}
interface ElasticTableHeaderProps {
  columns: ElasticTableColumn[];
  hPadding: number | string;
  setBboxes: (bbox: { name?: ClientRect }) => void;
}
interface ElasticTableColumnBoxProps {
  children: (ClientRect) => ReactNode;
  name: string;
}
export const ElasticTableContext = createContext({ columns: [], hPadding: null });

const ElasticTableHeader = ({ columns, hPadding, setBboxes }: ElasticTableHeaderProps) => {
  const refs = useRef({});
  const getBbox = name => {
    const el = refs.current[name];
    return el?.getBoundingClientRect();
  };
  useEffect(() => {
    const onResize = () => setBboxes(Object.keys(refs.current).reduce((m, k) => ({ ...m, [k]: getBbox(k) }), {}));
    setTimeout(() => {
      onResize();
    }, 0);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // missing deps: [setBboxes]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs.current]);
  return (
    <Card className="sticky top-0 py-6 bg-dark z-10 uppercase font-bold text-white text-left flex text-xs">
      <span className="inline-block flex-shrink-0" style={{ width: hPadding }} />
      {columns.map(({ align = 'left', name, displayName, width }) => (
        <span
          key={name}
          className={`inline-block flex-grow flex-shrink-0 px-2 text-${align}`}
          id={`elastic-header-${name}`}
          style={{ minWidth: width }}
          ref={r => {
            refs.current[name] = r;
          }}
        >
          {displayName}
        </span>
      ))}
      <span className="inline-block flex-shrink-0" style={{ width: `calc(${hPadding} / 2` }} />
    </Card>
  );
};

export const ElasticTableRow = ({ children, className = '', defaultOpen, detail }: ElasticTableRowProps) => {
  const [showDetail, setShowDetail] = useState(defaultOpen || false);
  const handleToggleDetail = () => {
    setShowDetail(!showDetail);
  };
  const { hPadding } = useContext(ElasticTableContext);

  const bgValue = showDetail === true ? 'bg-inset' : 'bg-white';

  return (
    <>
      <div
        className={`${bgValue} ${className} py-6 w-full focus:outline-none border-t border-gray-200 text-gray-700 text-left text-sm relative select-text flex flex-row items-center`}
        onClick={handleToggleDetail}
        role="presentation"
      >
        <span className="inline-block text-center" style={{ width: '3rem' }}>
          <ChevronUp
            style={{
              transform: `translate(10px, 0px) scale(0.7, 0.7) ${showDetail ? 'rotate(180deg)' : 'rotate(90deg)'}`,
              transformOrigin: 'center',
              transition: '0.3s ease-out',
            }}
          />
        </span>
        {children}
      </div>
      <div
        className="bg-inset"
        style={{
          height: showDetail ? 'auto' : 0,
          overflow: 'hidden',
          padding: showDetail ? `1rem calc(2 * ${hPadding}/3)` : 0,
        }}
      >
        <Card
          className="border-l-4 border-primary-light bg-white overflow-hidden relative"
          style={{ padding: `calc(${hPadding}/3)` }}
        >
          {detail()}
        </Card>
      </div>
    </>
  );
};
export const Row = ElasticTableRow;

export const ElasticTableColumnBox = ({ children, name }: ElasticTableColumnBoxProps) => (
  <ElasticTableContext.Consumer>
    {({ columns }) => children(columns.find(c => name === c.name)?.bbox)}
  </ElasticTableContext.Consumer>
);
export const Box = ElasticTableColumnBox;

export const ElasticTableDatum = ({ children, className, name }: ElasticTableDatumProps) => (
  <ElasticTableContext.Consumer>
    {({ columns }) => {
      const names = Array.isArray(name) ? name : [name];
      const bboxes = columns.filter(c => names.includes(c.name)).map(c => c.bbox);
      const totalWidth = bboxes.reduce((acc, b) => acc + b.width, 0);
      return (
        <span
          aria-labelledby={`elastic-header-${names[0]}`}
          className={`inline-block px-2 ${className || ''}`}
          style={{ width: totalWidth || 'auto' }}
        >
          {children}
        </span>
      );
    }}
  </ElasticTableContext.Consumer>
);
export const Datum = ElasticTableDatum;

const ElasticTable = ({ children, columns, hPadding = '3rem' }: ElasticTableProps) => {
  const [bboxes, setBboxes] = useState({});
  const columnsWithWidth = columns.map(col => ({ bbox: bboxes[col.name], ...col }));
  return (
    <ElasticTableContext.Provider value={{ columns: columnsWithWidth, hPadding }}>
      <ElasticTableHeader columns={columns} setBboxes={setBboxes} hPadding={hPadding} />
      {children}
    </ElasticTableContext.Provider>
  );
};

export default ElasticTable;
