import React from 'react';
import AlertCircle from '@producepay/pp-ui/dist/components/icons/AlertCircle';

import { MenuItem } from '../types';
import { usePSDealNavigation } from '../PS/components/PSDealNavigation/context';

interface MenuBarItem {
  value: MenuItem;
  label: string;
  isActive: boolean;
  shouldDisplayError: boolean;
}

interface MenuBarProps {
  items: MenuBarItem[];
  onClick: (label: MenuItem) => void;
}

function MenuBar({ items, onClick }: MenuBarProps): JSX.Element {
  const activeClassName = 'border-b-4 border-primary font-bold';

  // This really ties the PS Deals to this component. We should refactor this
  // as soon as we get the chance.
  const psContext = usePSDealNavigation();

  return (
    <div className="flex flex-row bg-white border-b-1">
      {items.map(item => (
        <div key={`menu-${item.value}`} className="mx-8">
          <div
            className={`h-10 body2 pt-2 cursor-pointer ${item.isActive && activeClassName}`}
            onClick={() => {
              psContext && psContext.saveCallback && psContext.saveCallback.current();
              onClick(item.value);
            }}
            onKeyDown={e => e.preventDefault()}
            role="presentation"
            data-testid={`${item.label.toLowerCase()}-menu-tab`}
          >
            {item.label}

            {item.shouldDisplayError && (
              <div style={{ float: 'right', marginLeft: '4px' }}>
                <AlertCircle size={14} color="#dc2626" className="inline" data-testid={`${item.value}-tab-alert`} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default MenuBar;
