import gql from 'graphql-tag';

import { PRODUCE_PAY_COMPANY_IDENTIFIER } from 'src/helpers/products';

export const GET_PRODUCE_PAY_FUNDING_ACCOUNTS = gql`
  query ProducePayFundingAccounts {
    fundingAccounts(status: "active", companyIdentifier: "${PRODUCE_PAY_COMPANY_IDENTIFIER}") {
      value: identifier
      label: name
    }
  }
`;
