import { AxiosResponse } from 'axios';
import humps from 'humps';

import api from 'src/helpers/axios';
import { useAuth } from 'src/contexts/auth/AuthProvider';
import { Command } from './types';

export const send = async <T extends Command, R = void>(
  payload: T,
  { token }: { token: string },
): Promise<AxiosResponse<R>> => {
  try {
    return await api.post('/command', payload, { headers: { Authorization: `Bearer ${token}` } });
  } catch (e) {
    if (e.errors?.details) {
      throw new Error(e.errors.details);
    } else if (e.error) {
      throw new Error(e.error);
    } else if (e.response?.data?.error) {
      throw new Error(e.response.data.error.message || e.response.data.error);
    } else if (e.message) {
      throw new Error(e.message);
    } else {
      throw e;
    }
  }
};

type CommandOptions = Record<string, unknown>;

export const useCommand = (): {
  send: <T extends Command, R = void>(payload: T, options?: CommandOptions) => Promise<AxiosResponse<R>>;
} => {
  const { getTokenSilently } = useAuth();
  return {
    send: async <T extends Command, R = void>(payload: T, options: CommandOptions = {}) => {
      const token = await getTokenSilently();
      return send<T, R>(humps.decamelizeKeys(payload) as T, { ...options, token });
    },
  };
};
