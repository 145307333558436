import React from 'react';
import cx from 'classnames';

const STICKY_INDEX_OFFSET = 30;

export type Section = { name: string; id: string; component: React.ReactNode };

type SectionWithRef = Section & {
  anchorRef: React.RefObject<HTMLSpanElement>;
  sectionRef: React.RefObject<HTMLDivElement>;
};
type StickyOffset = null | number;
type Props = {
  sections: Array<Section>;
  stickyOffset?: StickyOffset;
  className?: string;
};

function isSectionVisible(section: SectionWithRef) {
  const boundaries = section.sectionRef.current.getBoundingClientRect();
  return boundaries.top >= 0 && boundaries.left >= 0;
}

function getFirstVisibleSection(sections: Array<SectionWithRef>): null | SectionWithRef {
  const [section, ...rest] = sections;

  if (!section) {
    return null;
  }

  if (isSectionVisible(section)) {
    return section;
  }

  return getFirstVisibleSection(rest);
}

function Index({ sections, stickyOffset }: { sections: Array<SectionWithRef>; stickyOffset: StickyOffset }) {
  const [selectedSectionId, setSelectedSectionId] = React.useState(null);

  React.useEffect(() => {
    const selectSectionIdForVisibleSection = () => {
      const visibleSection = getFirstVisibleSection(sections);

      if (visibleSection) {
        setSelectedSectionId(visibleSection.id);
      }
    };

    selectSectionIdForVisibleSection();
    window.addEventListener('scroll', selectSectionIdForVisibleSection);
    return () => {
      window.removeEventListener('scroll', selectSectionIdForVisibleSection);
    };
  }, [sections, setSelectedSectionId]);

  const topOffset = STICKY_INDEX_OFFSET + (stickyOffset ?? 0);

  return (
    <div className="sticky top-0 mt-6 " style={{ top: `${topOffset}px` }}>
      {sections.map(({ id: sectionId, name, anchorRef }) => (
        <div key={sectionId}>
          <button
            type="button"
            className={cx('px-4 py-2 w-full text-left body2 focus:outline-none', {
              'font-bold': selectedSectionId === sectionId,
            })}
            onClick={event => {
              event.preventDefault();
              anchorRef.current.scrollIntoView();
              setTimeout(() => setSelectedSectionId(sectionId), 50);
            }}
            data-testid={`section-link-${sectionId}`}
          >
            {name}
          </button>
        </div>
      ))}
    </div>
  );
}

const Anchor = React.forwardRef(
  ({ sectionId, topOffset }: { sectionId: string; topOffset: number }, ref: React.RefObject<HTMLSpanElement>) => {
    return (
      <span
        ref={ref}
        className="relative"
        style={{ top: `${topOffset}px` }}
        data-testid={`section-anchor-${sectionId}`}
      />
    );
  },
);

const Section = React.forwardRef(
  ({ sectionId, children }: { sectionId: string; children: React.ReactNode }, ref: React.RefObject<HTMLDivElement>) => (
    <div ref={ref} className="mt-6" data-testid={`section-content-${sectionId}`}>
      {children}
    </div>
  ),
);

function Content({ sections, stickyOffset }: { sections: Array<SectionWithRef>; stickyOffset: StickyOffset }) {
  const topOffset = stickyOffset ? -1 * stickyOffset : 0;
  return (
    <>
      {sections.map(({ id, sectionRef, anchorRef, component }) => (
        <React.Fragment key={id}>
          <Anchor ref={anchorRef} topOffset={topOffset} sectionId={id} />
          <Section ref={sectionRef} sectionId={id}>
            {component}
          </Section>
        </React.Fragment>
      ))}
    </>
  );
}

export function SectionedContent({ className, sections, stickyOffset }: Props) {
  const sectionsWithRef = sections.map(section => ({
    ...section,
    anchorRef: React.createRef<HTMLSpanElement>(),
    sectionRef: React.createRef<HTMLDivElement>(),
  }));

  return (
    <div className={cx('flex justify-start', className, !className && 'pb-12')}>
      <div className="w-1/6 mr-2">
        <Index sections={sectionsWithRef} stickyOffset={stickyOffset} />
      </div>
      <div className="w-2/3">
        <Content sections={sectionsWithRef} stickyOffset={stickyOffset} />
      </div>
    </div>
  );
}
