import { useEffect, useMemo, useReducer, useState } from 'react';
import { useApolloClient, QueryOptions, ApolloQueryResult, useQuery } from '@apollo/client';
import { useToast } from '@producepay/pp-ui';

interface UseQueriesResults {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: ApolloQueryResult<any>;
}

export interface UseQueriesOutput {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key: string]: any };
  refetch: () => void;
  results: UseQueriesResults;
  loading: boolean;
  error: Error;
}

interface UseQueriesQueries {
  [key: string]: QueryOptions;
}

/** useQueryWithToast
 *  Thin wrapper to Apollo `useQuery` that displays any errors in a toast.
 */
type UseQueryType = typeof useQuery;
export const useQueryWithToast: UseQueryType = (query, options) => {
  const { addToastToQueue } = useToast();
  const { error, ...rest } = useQuery(query, options);
  useEffect(() => {
    if (error) {
      addToastToQueue({
        actions: [{ label: 'Dismiss' }],
        body: error.message,
        header: 'Error Fetching Data',
        type: 'error',
      });
    }
    // missing deps: [addToastToQueue]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  return { error, ...rest };
};

/** useQueries
 *  Convenience function for spawning multiple apollo queries from a component and merging their results.
 *  gets a reference to ApolloClient via hooks and causes the calling component to re-render by updating
 *  state.
 *
 *  Limitations: Will only fetch data on component initial mount or manual call to `refetch`.
 *  Does not handle subscriptions.
 *
 *  Arguments:
 *   - queries: { [key]: QueryOptions }
 *   - options: {
 *      showErrorToast: true  //spawn an error toast for failed requests, unless set to false
 *    }
 *
 *  Returns:
 *  {
 *   data: data only for each graphql query or undefined, keyed according the the queries object from arguments
 *   results: full results obtained from Apollo after query completes, allows individual requests to be checked for
 *     loading state or errors
 *   loading: true until all queries have completed
 *   error: if any queries have failed, returns the first Error object f
 *  }
 */
export const useQueries = (queries: UseQueriesQueries, options = { showErrorToast: true }): UseQueriesOutput => {
  const showErrorToast = options.showErrorToast || true;
  const client = useApolloClient();
  const { addToastToQueue } = useToast();
  const resultsReducer = useReducer(
    (state, result) => ({
      ...state,
      ...result,
    }),
    {},
  );
  const results: UseQueriesResults = resultsReducer[0];
  // Only change `data` object when results changes to avoid render loop
  // missing deps: [queries]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => Object.keys(queries).reduce((m, k) => ({ ...m, [k]: results[k]?.data }), {}), [results]);
  const dispatchResults = resultsReducer[1];
  const [loading, setLoading] = useState(true);

  /** doFetch
   * Called on initial fetch, or directly when a forced
   * refetch is desired. Accepts one argument with overrides
   * for the query, such as a different `fetchPolicy`
   */
  const doFetch = (queryOptions = {}) => {
    Promise.all(
      Object.entries(queries).map(async ([k, v]) => {
        try {
          const result = await client.query({ ...v, ...queryOptions });
          dispatchResults({ [k]: result });
        } catch (error) {
          dispatchResults({ [k]: { error } });
          if (showErrorToast) {
            addToastToQueue({
              actions: [{ label: 'Dismiss' }],
              body: error.message,
              header: 'Error Fetching Data',
              type: 'error',
            });
          }
        }
      }),
    ).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    doFetch();
    // missing deps: [doFetch]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    error: Object.values(results).find(r => r.error)?.error,
    refetch: (queryOptions = { fetchPolicy: 'network-only' }) => doFetch(queryOptions),
    results,
    loading,
  };
};
