import React, { FC, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { Column, PluginHook, TableInstance, useRowSelect } from 'react-table';

import { StandaloneReactTable } from './StandaloneReactTable';
import { UseReactTableOptions } from './useReactTable';
import { EditCell } from './EditCell';
import { useSelectCell } from './plugins/useSelectCell';
import { useCopyPaste } from './plugins/useCopyPaste';
import { useRowActions } from './plugins/useRowActions';
import { TableMenu } from './TableMenu';

export interface FormikTableProps<T extends Record<string, unknown>> extends Omit<UseReactTableOptions<T>, 'data'> {
  fieldName: string;
  createNewRow?(prevRow?: T, nextRow?: T, currentRow?: T): T;
  Menu?: FC<TableInstance<T>>;
}

export function FormikTable<T extends Record<string, unknown>>({
  fieldName,
  createNewRow,
  Menu = TableMenu,
  ...props
}: FormikTableProps<T>) {
  const [{ value }] = useField<T[]>(fieldName);
  const { setFieldValue } = useFormikContext();

  const onEdit = useCallback(
    (data: Pick<UseReactTableOptions<T>, 'data'>) => {
      setFieldValue(fieldName, data);
    },
    [fieldName, setFieldValue],
  );

  return (
    <StandaloneReactTable
      data={value}
      options={{ defaultColumn: { Cell: EditCell, width: 100 } as Column<T> }}
      customOptions={{ rootFieldName: fieldName, onEdit, createNewRow }}
      pluginHooks={[useSelectCell, useCopyPaste, useRowActions, useRowSelect] as PluginHook<T>[]}
      Menu={Menu}
      {...props}
    />
  );
}
