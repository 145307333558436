import React, { forwardRef, HTMLAttributes } from 'react';
import cx from 'classnames';

export enum Align {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface CellWithAlign {
  column: { align?: Align };
}

export const AlignItemsClassName = {
  [Align.Right]: 'justify-end',
  [Align.Center]: 'justify-center',
  [Align.Left]: 'justify-start',
};

export const TextAlignClassName = {
  [Align.Right]: 'text-right',
  [Align.Center]: 'text-center',
  [Align.Left]: 'text-left',
};

export const AlignCellContent = forwardRef<HTMLDivElement, { align: Align } & HTMLAttributes<HTMLDivElement>>(
  ({ align, className, children, ...rest }, ref) => {
    const alignItems = AlignItemsClassName[align];

    return (
      <div ref={ref} className={cx('w-full h-full flex items-center px-3', alignItems, className)} {...rest}>
        {children}
      </div>
    );
  },
);
