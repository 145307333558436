import React from 'react';
import { Card } from '@producepay/pp-ui';
import FormikTextField from 'src/components/formik/FormikTextField';
import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';

export function DistributionFee() {
  return (
    <Card>
      <SectionHeader
        title="Distribution Fee"
        subtitle="Default financial settings for distribution fees paid by the Producer"
      />
      <SectionItem
        title="Distribution Fee Ratio"
        subtitle="Percentage of estimated manifest value or purchase order"
        controls={
          <FormikTextField
            inputProps={{ className: 'text-right' }}
            name="distributionFee.rate"
            iconSuffix={<span>%</span>}
            withSimpleErrorStyling
            data-testid="distribution-fee-ratio"
          />
        }
      />
    </Card>
  );
}
