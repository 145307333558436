import { useQuery, NetworkStatus } from '@apollo/client';
import gql from 'graphql-tag';
import _ from 'lodash';

import { FULFILLMENT_API_CLIENT_NAME } from 'src/graphql/backends';

export const GET_COMMODITIES = gql`
  query GetCommodities {
    commodities {
      id
      name
    }
  }
`;

export const GET_VARIETIES = gql`
  query GetSkuVarieties($commodityId: String!, $packageId: String, $sizeId: String) {
    skuComponents(commodityId: $commodityId, packageId: $packageId, sizeId: $sizeId) {
      skuVarieties {
        id
        name
      }
    }
  }
`;

export const GET_PACKAGES = gql`
  query GetSkuPackages($commodityId: String!, $varietyId: String, $sizeId: String) {
    skuComponents(commodityId: $commodityId, varietyId: $varietyId, sizeId: $sizeId) {
      skuPackages {
        id
        name
      }
    }
  }
`;

export const GET_SIZES = gql`
  query GetSkuSizes($commodityId: String!, $varietyId: String, $packageId: String) {
    skuComponents(commodityId: $commodityId, varietyId: $varietyId, packageId: $packageId) {
      skuSizes {
        id
        name
      }
    }
  }
`;

type SkuInformation = {
  id: string;
  name: string;
};
type GetCommodities = {
  commodities: SkuInformation[];
};
type GetVarieties = {
  skuComponents: {
    skuVarieties: SkuInformation[];
  };
};
type GetPackages = {
  skuComponents: {
    skuPackages: SkuInformation[];
  };
};
type GetSizes = {
  skuComponents: {
    skuSizes: SkuInformation[];
  };
};

type UseSkuArguments = {
  commodityId?: string;
  varietyId?: string;
  packageId?: string;
  sizeId?: string;
};

type UseSkusReturnType = {
  commodities?: SkuInformation[];
  varieties?: SkuInformation[];
  packages?: SkuInformation[];
  sizes?: SkuInformation[];
  commoditiesNetworkStatus: NetworkStatus;
  skuVarietiesNetworkStatus: NetworkStatus;
  skuPackagesNetworkStatus: NetworkStatus;
  skuSizesNetworkStatus: NetworkStatus;
};

const useSkus = ({ commodityId, varietyId, packageId, sizeId }: UseSkuArguments): UseSkusReturnType => {
  const { data: commoditiesPayload, networkStatus: commoditiesNetworkStatus } = useQuery<GetCommodities>(
    GET_COMMODITIES,
    { context: { clientName: FULFILLMENT_API_CLIENT_NAME } },
  );

  const {
    data: skuVarietiesPayload,
    previousData: previousSkuVarietiesPayload,
    networkStatus: skuVarietiesNetworkStatus,
  } = useQuery<GetVarieties, UseSkuArguments>(GET_VARIETIES, {
    skip: !commodityId,
    context: { clientName: FULFILLMENT_API_CLIENT_NAME },
    variables: _.pickBy({ commodityId, packageId, sizeId }),
  });

  const {
    data: skuPackagesPayload,
    previousData: previousSkuPackagesPayload,
    networkStatus: skuPackagesNetworkStatus,
  } = useQuery<GetPackages, UseSkuArguments>(GET_PACKAGES, {
    skip: !commodityId,
    context: { clientName: FULFILLMENT_API_CLIENT_NAME },
    variables: _.pickBy({ commodityId, varietyId, sizeId }),
  });

  const {
    data: skuSizesPayload,
    previousData: previousSkuSizesPayload,
    networkStatus: skuSizesNetworkStatus,
  } = useQuery<GetSizes, UseSkuArguments>(GET_SIZES, {
    skip: !commodityId,
    context: { clientName: FULFILLMENT_API_CLIENT_NAME },
    variables: _.pickBy({ commodityId, varietyId, packageId }),
  });

  // Fallback to previous payload to maintain consistency while loading
  return {
    commodities: commoditiesPayload?.commodities,
    varieties: (skuVarietiesPayload || previousSkuVarietiesPayload)?.skuComponents?.skuVarieties || [],
    packages: (skuPackagesPayload || previousSkuPackagesPayload)?.skuComponents?.skuPackages || [],
    sizes: (skuSizesPayload || previousSkuSizesPayload)?.skuComponents?.skuSizes || [],
    commoditiesNetworkStatus,
    skuVarietiesNetworkStatus,
    skuPackagesNetworkStatus,
    skuSizesNetworkStatus,
  };
};

export default useSkus;
