import Decimal from 'decimal.js';

import { formatPrice } from 'src/helpers/format';
import { FinancialProduct, InventoryItem } from 'src/routes/Companies/OrdersIndex/types';

export const generateDetailsLine = (inventoryItem: InventoryItem): string => {
  const { commodity, grade, size, isOrganic, hasGrowerLabel, variety } = inventoryItem;
  const strings = [];

  variety ? strings.push(`${variety.name} ${commodity.name}`) : strings.push(commodity.name);
  if (size) strings.push(size.name);
  if (grade) strings.push(`Grade ${grade.name}`);
  hasGrowerLabel ? strings.push('Grower Label') : strings.push('Generic');
  isOrganic ? strings.push('Organic') : strings.push('Conventional');

  return strings.join(' • ');
};

export const calculateRowTotal = (quantity: number | null, pricePerUnit: string): string => {
  if (quantity === null) return '';

  const totalPrice = new Decimal(pricePerUnit || 0).times(quantity || 0).toFixed();
  const shouldTruncate = false;
  return formatPrice(totalPrice, shouldTruncate);
};

export const calculateAllRowsTotal = (inventoryItems: InventoryItem[]): string => {
  let totalPrice = new Decimal(0);

  inventoryItems.forEach(inventoryItem => {
    const pricePerUnit = inventoryItem.pricePerUnit || 0;
    const quantity = inventoryItem.quantity || 0;
    const currentRowTotal = new Decimal(pricePerUnit).times(quantity);
    totalPrice = totalPrice.plus(currentRowTotal);
  });

  const shouldTruncate = false;
  return formatPrice(totalPrice.toFixed(), shouldTruncate);
};

export const calculateFinancialProductsTotal = (financialProducts: FinancialProduct[]): string => {
  const totalPrice = financialProducts.reduce(
    (sum, financialProduct) =>
      financialProduct.status !== 'Rejected' ? sum.plus(financialProduct.requestedAmount ?? 0) : sum,
    new Decimal(0),
  );

  const shouldTruncate = false;
  return formatPrice(totalPrice.toFixed(), shouldTruncate);
};
