import React, { useCallback, useMemo } from 'react';
import { CellProps } from 'react-table';
import { Select } from '@producepay/pp-ui';
import cx from 'classnames';

import { EditCell, EditorProps } from './EditCell';
import { isEnter } from './helpers/keyboardHelpers';
import { SelectCellInstance } from './plugins/useSelectCell';
import { TextAlignClassName, Align, AlignItemsClassName } from './AlignCellContent';

export interface SelectItem<T = unknown> {
  label: string;
  subtitle?: string;
  value: T;
}

const ChevronDown = () => (
  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
);

export function SelectViewer({
  items,
  value,
  column: { align = Align.Right },
}: { items: SelectItem[] } & CellProps<Record<string, unknown>> & {
    column: { align?: Align };
  }) {
  const selectedItem = useMemo(() => {
    return items.find(item => item.value === value);
  }, [items, value]);

  const labelWrapperClassName = cx(AlignItemsClassName[align], 'flex-grow flex flex-col pr-2');
  const labelClassName = cx(TextAlignClassName[align], 'body2');

  const label = selectedItem?.label ?? 'Select an Option';

  return (
    <div className="flex items-center">
      <div className={labelWrapperClassName}>
        <span className={labelClassName}>{label}</span>
        {selectedItem?.subtitle && <span className="text-gray-500 text-xs">{selectedItem.subtitle}</span>}
      </div>
      <div>
        <ChevronDown />
      </div>
    </div>
  );
}

export function SelectEditor({
  items,
  value,
  column: { align = Align.Right },
  finishEdit,
  selectCellBelow,
}: EditorProps &
  CellProps<Record<string, unknown>> & {
    items: SelectItem[];
    column: { align?: Align };
  } & SelectCellInstance) {
  const selectedItem = useMemo(() => {
    return items.find(item => item.value === value);
  }, [items, value]);
  const onChange = useCallback(
    item => {
      finishEdit({ newValue: item.value });
      selectCellBelow();
    },
    [finishEdit, selectCellBelow],
  );
  const onStateChange = useCallback(
    change => {
      if (change.isOpen === false && !change.selectedItem) {
        finishEdit({ setNewValue: false });
      }
    },
    [finishEdit],
  );
  const onPortalClick = useCallback(e => {
    e.nativeEvent.stopImmediatePropagation();
  }, []);

  return (
    <div
      onKeyDown={e => {
        if (isEnter(e)) {
          e.stopPropagation();
        }
      }}
      role="presentation"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className="flex items-center flex-grow"
    >
      <Select
        selectedItem={selectedItem}
        items={items}
        caretStyle={{ paddingLeft: 8, padding: 0 }}
        color="default"
        className="border-b-0 border-t-0 border-l-0 border-r-0  p-0 body2"
        size={null}
        rounded={false}
        onChange={onChange}
        labelPosition={align}
        initialIsOpen
        onStateChange={onStateChange}
        subtitleClassName="text-xs"
        portalProps={{
          onClick: onPortalClick,
        }}
      />
    </div>
  );
}

export function SelectCell(props: CellProps<Record<string, unknown>> & { rootFieldName: string; items: SelectItem[] }) {
  return <EditCell preserveDisplayCellInput={false} {...props} Viewer={SelectViewer} Editor={SelectEditor} />;
}
