import React from 'react';
import GET_SEND_PAYMENTS_DATA from 'src/graphql/queries/getSendPaymentsData';
import GET_COMPANIES from 'src/graphql/queries/getCompanies';
import GET_FUNDING_ACCOUNTS from 'src/graphql/queries/getFundingAccounts';
import { useQuery } from '@apollo/client';
import { Route, Redirect, Switch } from 'react-router-dom';

import * as routes from 'src/routes';

import { Grid, LoadingSpinner } from '@producepay/pp-ui';
import CompaniesList from './CompaniesList';
import SendPaymentsNav from './SendPaymentsNav';
import PreparedList from './PreparedList';
import UnpreparedList from './UnpreparedList';
import ApprovedList from './ApprovedList';

import {
  useFilter,
  normalizePrepaymentItem,
  normalizeOPI,
  filter,
  NormalizedFilterProperties,
  normalizeBalanceReturn,
} from './filterReducer';
import FilterBar from './FilterBar';

interface SendPaymentsRouterProps {
  approvedItems: OutboundPaymentItem[];
  balanceReturns: BalanceReturn[];
  companies: Company[];
  currentFilter: (properties: NormalizedFilterProperties) => boolean;
  fundingAccounts: FundingAccount[];
  preparedItems: OutboundPaymentItem[];
  prepaymentProducts: ProductPrepaymentItem[];
  refetch: () => void;
}

const REFETCH_DELAY = 2000;

const SendPaymentsRouter = ({
  approvedItems,
  balanceReturns,
  companies,
  currentFilter,
  fundingAccounts,
  preparedItems,
  prepaymentProducts,
  refetch,
}: SendPaymentsRouterProps) => {
  const visibleApprovedItems = approvedItems.filter(item => currentFilter(normalizeOPI(item, companies)));
  const visiblePreparedItems = preparedItems.filter(item => currentFilter(normalizeOPI(item, companies)));
  const visibleBalanceReturns = balanceReturns.filter(item => currentFilter(normalizeBalanceReturn(item, companies)));
  const visiblePrepaymentProducts = prepaymentProducts.filter(item =>
    currentFilter(normalizePrepaymentItem(item, companies)),
  );
  return (
    <Grid container>
      {/* Render the Companies List with appropriate arguments for each view */}
      <Grid className="min-h-screen" sm="1/5">
        <div className="min-h-full bg-white">
          <Switch>
            <Route path={routes.sendPaymentsUnprepared(':companyIdentifier?')}>
              <CompaniesList
                mode="unprepared"
                balanceReturns={visibleBalanceReturns}
                preparedItems={visiblePreparedItems}
                unpreparedProducts={visiblePrepaymentProducts}
              />
            </Route>
            <Route path={routes.sendPaymentsPrepared(':companyIdentifier?')}>
              <CompaniesList
                mode="prepared"
                balanceReturns={visibleBalanceReturns}
                preparedItems={visiblePreparedItems}
                unpreparedProducts={visiblePrepaymentProducts}
              />
            </Route>
            <Route path={routes.sendPaymentsApproved(':sourceAccountIdentifier?')}>
              <CompaniesList mode="approved" approvedItems={visibleApprovedItems} />
            </Route>
          </Switch>
        </div>
      </Grid>
      <Grid sm="4/5">
        {/* Render the product/payments list */}
        <Switch>
          <Route path={routes.sendPaymentsUnprepared(':companyIdentifier')}>
            <UnpreparedList
              balanceReturns={visibleBalanceReturns}
              prepaymentProducts={visiblePrepaymentProducts}
              refetch={refetch}
            />
          </Route>
          <Route
            path={routes.sendPaymentsPreparedDetail(
              ':recipientCompanyIdentifier',
              ':sourceAccountIdentifier',
              ':destinationAccountIdentifier',
            )}
          >
            <PreparedList
              allPreparedItems={preparedItems}
              visiblePreparedItems={visiblePreparedItems}
              refetch={refetch}
            />
          </Route>
          <Route path={routes.sendPaymentsPrepared(':recipientCompanyIdentifier')}>
            <PreparedList
              allPreparedItems={preparedItems}
              visiblePreparedItems={visiblePreparedItems}
              refetch={refetch}
            />
          </Route>
          <Route path={routes.sendPaymentsApprovedDetail(':sourceAccountIdentifier', ':destinationAccountIdentifier')}>
            <ApprovedList
              allApprovedItems={approvedItems}
              fundingAccounts={fundingAccounts}
              visibleApprovedItems={visibleApprovedItems}
              refetch={refetch}
            />
          </Route>
          <Route path={routes.sendPaymentsApproved(':sourceAccountIdentifier')}>
            <ApprovedList
              allApprovedItems={approvedItems}
              fundingAccounts={fundingAccounts}
              visibleApprovedItems={visibleApprovedItems}
              refetch={refetch}
            />
          </Route>

          {/* When we navigate to the "prepared" section without a company selected. */}
          <Route path={routes.sendPaymentsPrepared('')}>
            {preparedItems?.length > 0 ? (
              <Redirect to={routes.sendPaymentsPrepared(preparedItems[0].recipientCompanyIdentifier)} />
            ) : (
              <p className="center p-4">There are no payments available to approve.</p>
            )}
          </Route>

          {/* When we navigate to the "approved" section without a company selected. */}
          <Route path={routes.sendPaymentsApproved('')}>
            {approvedItems?.length > 0 ? (
              <Redirect to={routes.sendPaymentsApproved(approvedItems[0].sourceAccountIdentifier)} />
            ) : (
              <p className="center p-4">There are no payments available to send.</p>
            )}
          </Route>

          {/* When we navigate to the "unprepared" section without a company selected, or any other weird case. */}
          {prepaymentProducts.length > 0 ? (
            <Redirect to={routes.sendPaymentsUnprepared(prepaymentProducts[0].recipientCompanyIdentifier)} />
          ) : balanceReturns.length > 0 ? (
            <Redirect to={routes.sendPaymentsUnprepared(balanceReturns[0].recipientCompanyIdentifier)} />
          ) : preparedItems.length > 0 ? (
            <Redirect to={routes.sendPaymentsPrepared(preparedItems[0].recipientCompanyIdentifier)} />
          ) : (
            <p className="center p-4">There are no products available to pay.</p>
          )}
          <Route exact path={routes.sendPayments()}>
            <Redirect to={routes.sendPaymentsUnprepared('')} />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
};

const SendPayments = () => {
  const [filterState, dispatchFilterAction] = useFilter();
  const currentFilter = filter(filterState);
  const {
    data,
    refetch,
    error: paymentsError,
  } = useQuery(GET_SEND_PAYMENTS_DATA, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 30000,
  });
  const { data: companiesData, error: companiesError } = useQuery(GET_COMPANIES);
  const { data: fundingAccountData, error: fundingAccountsError } = useQuery(GET_FUNDING_ACCOUNTS);

  const balanceReturns = process.env.REACT_APP_DISABLE_BALANCE_RETURNS === 'true' ? [] : data?.balanceReturns;
  const prepaymentProducts = data?.prepaymentProducts;
  const opis = data?.outboundPaymentItems;
  const preparedItems = opis?.filter(({ status }) => status === 'prepared');
  const approvedItems = opis?.filter(({ status }) => status === 'approved');

  const isReady = data && companiesData && fundingAccountData;
  const error = paymentsError || companiesError || fundingAccountsError;

  return (
    <div className="overflow-hidden">
      <SendPaymentsNav />
      <FilterBar filterState={filterState} dispatch={dispatchFilterAction} />
      {error ? (
        <p className="p-4">There was an error loading data: {error.message}.</p>
      ) : !isReady ? (
        <LoadingSpinner centered className="py-32" />
      ) : (
        <SendPaymentsRouter
          approvedItems={approvedItems}
          balanceReturns={balanceReturns}
          companies={companiesData?.companies}
          currentFilter={currentFilter}
          fundingAccounts={fundingAccountData?.fundingAccounts}
          prepaymentProducts={prepaymentProducts}
          preparedItems={preparedItems}
          refetch={() => {
            setTimeout(refetch, REFETCH_DELAY);
          }}
        />
      )}
    </div>
  );
};

export default SendPayments;
