import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Card } from '@producepay/pp-ui';

import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';

import { ISPPValues } from 'src/routes/Deals/ISPP/types';
import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';

const LATE_FEE_PAYER_ITEMS = [
  {
    value: 'producer',
    label: 'Producer',
  },
  {
    value: 'distributor',
    label: 'Distributor',
  },
];

const LateFee = () => {
  const [selectRoleDisabled, setSelectRoleDisabled] = useState(false);
  const {
    values: {
      ui: {
        distributionFee: { type },
        dealType,
      },
    },
    setFieldValue,
  } = useFormikContext<ISPPValues>();

  useEffect(() => {
    if (dealType === 'distributor') {
      setFieldValue('lateFee.feePayerRole', 'distributor');
      setSelectRoleDisabled(true);
    } else {
      setSelectRoleDisabled(false);
    }
  }, [dealType, setFieldValue]);

  return (
    type !== 'daily' && (
      <Card>
        <SectionHeader title="Late Fee" subtitle="Default financial settings for late fees" />
        <SectionItem
          title="Repayment Terms"
          subtitle="Days since payment before late fees begin to incur"
          controls={
            <FormikTextField
              name="lateFee.repaymentDays"
              inputProps={{ className: 'text-right' }}
              withSimpleErrorStyling
            />
          }
        />
        <SectionItem
          title="Collection Terms"
          subtitle="Days since payment before collections team reaches out"
          controls={
            <FormikTextField
              name="lateFee.collectionDays"
              inputProps={{ className: 'text-right' }}
              withSimpleErrorStyling
            />
          }
        />
        <SectionItem
          title="Late Fee Payer"
          controls={
            <FormikSelect
              name="lateFee.feePayerRole"
              placeholder="Select fee payer"
              items={LATE_FEE_PAYER_ITEMS}
              disabled={selectRoleDisabled}
              withSimpleErrorStyling
            />
          }
          border={false}
        />
      </Card>
    )
  );
};

export default LateFee;
