import React, { useMemo } from 'react';

import Card from './Card';
import OrderOverviewTable from './Table';

type OrderOverviewCardProps = {
  dueDate: string;
  isFixedPrice: boolean;
  pickupNumber: string;
  receiverName: string;
  referenceNumber: string;
  supplierName: string;
};

const OrderOverviewCard = ({
  dueDate,
  isFixedPrice,
  pickupNumber,
  receiverName,
  referenceNumber,
  supplierName,
}: OrderOverviewCardProps) => {
  const columns = useMemo(
    () => [
      {
        Header: 'SENDER',
        accessor: 'sender',
      },
      {
        Header: 'RECIPIENT',
        accessor: 'recipient',
      },
      {
        Header: 'TERMS OF SALE',
        accessor: 'termsOfSale',
      },
      {
        Header: 'DUE DATE',
        accessor: 'dueDate',
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        dueDate,
        recipient: receiverName,
        sender: supplierName,
        termsOfSale: isFixedPrice ? 'Fixed Price' : 'Consignment',
      },
    ],
    [dueDate, isFixedPrice, receiverName, supplierName],
  );

  return (
    <Card>
      <div className="flex justify-between mb-7 text-turnip-700">
        <h2 className="font-semibold text-2xl text-turnip-900">Order Overview</h2>
        <div className="font-semibold" data-testid="order-and-pickup-numbers">
          <span>ORDER # </span>
          <span className="text-avocado-600">{referenceNumber}</span> • <span>PICK UP # </span>
          <span className="text-avocado-600">{pickupNumber}</span>
        </div>
      </div>

      <OrderOverviewTable columns={columns} data={data} />
    </Card>
  );
};

export default OrderOverviewCard;
