import React from 'react';
import { SectionedContent, Section } from 'src/components/elements/SectionedContent/SectionedContent';
import { useFormikContext } from 'formik';
import { PSValues } from 'src/routes/Deals/PS/types';
import Repayment from './Repayment/Repayment';
import Companies from './Companies';
import PSForecastedFinancials from './ForecastedFinancials';
import ApplicationFee from './ApplicationFee';
import DocumentRequirements from './DocumentRequirements';
import PSFooter from '../../components/PSDealNavigation/PSFooter';

const GeneralView = ({ className = null }) => {
  const {
    values: {
      general: { applicationFee, companies, repayment },
    },
    status: { readOnly },
    errors,
    setFieldValue,
  } = useFormikContext<PSValues>();

  const sections: Section[] = [
    { name: 'Companies', id: 'companies', component: <Companies /> },
    {
      name: 'Repayment',
      id: 'repayment',
      component: <Repayment errors={errors} formData={{ repayment }} />,
    },
    { name: 'Forecasted Financials', id: 'forecasted-financials', component: <PSForecastedFinancials /> },
    {
      name: 'Application Fee',
      id: 'application-fee',
      component: (
        <ApplicationFee readOnly={readOnly} formData={{ applicationFee, companies }} setFieldValue={setFieldValue} />
      ),
    },
    { name: 'Document Requirements', id: 'document-requirements', component: <DocumentRequirements /> },
  ];

  return (
    <div className={className}>
      <SectionedContent className="pb-0" sections={sections} stickyOffset={50 /* offset height of the top bar */} />

      <div className="flex justify-center mt-6">
        <div className="w-2/3">
          <PSFooter />
        </div>
      </div>
    </div>
  );
};

export default GeneralView;
