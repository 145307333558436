import React from 'react';
import { Grid } from '@producepay/pp-ui';

import Trash from 'src/components/icons/Trash';
import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';
import { ApplicationFee, PaymentMethod as PaymentMethodType } from 'src/routes/Deals/types';

interface PaymentMethodProps {
  method: PaymentMethodType;
  setFieldValue: (field: string, value: unknown) => void;
  fee: ApplicationFee;
  removePaymentMethod: (index: number) => void;
  feeIndex: number;
  index: number;
  readOnly: boolean;
  formLocation: string;
}

const PaymentMethod = ({
  method,
  fee,
  removePaymentMethod,
  feeIndex,
  index,
  readOnly,
  formLocation = '',
}: PaymentMethodProps) => {
  return (
    <Grid container key={`${fee.key}-${method.key}`} className="justify-between pl-2 pr-4 pb-2">
      <Grid sm="1/2">
        <FormikSelect
          name={`${formLocation}applicationFee.fees[${feeIndex}].paymentMethods[${index}].method`}
          placeholder="Select a payment method"
          items={[
            {
              label: 'Balance Return',
              value: 'balance_return',
            },
            {
              label: 'Check',
              value: 'check',
            },
            {
              label: 'Credit Card',
              value: 'credit_card',
            },
            {
              label: 'Payment',
              value: 'payment',
            },
            {
              label: 'Wire',
              value: 'wire',
            },
          ]}
          withSimpleErrorStyling
        />
      </Grid>
      <div className="sm:w-1/2 flex justify-between  pl-1 py-2">
        <div className="flex justify-start">
          <div className="w-5/6">
            <FormikTextField
              name={`${formLocation}applicationFee.fees[${feeIndex}].paymentMethods[${index}].amount`}
              iconPrefix={<span className="font-bold">$</span>}
              withSimpleErrorStyling
            />
          </div>
        </div>
        {fee.paymentMethods.length > 1 && !readOnly && (
          <button
            className="bg-white focus:outline-none pl-6 pr-2 pt-2 flex justify-start"
            color="white"
            type="button"
            data-testid={`remove-payment-method-${index}`}
            onClick={() => removePaymentMethod(index)}
            disabled={readOnly}
          >
            <Trash disabled={readOnly} size={14} />
          </button>
        )}
      </div>
    </Grid>
  );
};

export default PaymentMethod;
