import React from 'react';

type BodyCellProps = {
  children: React.ReactNode;
  className?: string;
};

export const BodyCell = ({ children, className = '' }: BodyCellProps) => (
  <td className={`px-4 py-3 ${className}`}>{children}</td>
);

export default BodyCell;
