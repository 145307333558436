import React from 'react';
import { Card } from '@producepay/pp-ui';

import FormikSelect from 'src/components/formik/FormikSelect';
import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';

const MEMBERSHIP_DETAIL_ITEMS = [
  { label: 'Unverified', value: 'unverified' },
  { label: 'Verified', value: 'verified' },
  { label: 'Gold Verified', value: 'gold_verified' },
];

const MembershipDetails = () => {
  return (
    <Card>
      <SectionHeader
        title="Membership Details"
        subtitle="Membership settings that determine the member's level of Preferred Network benefits"
      />
      <SectionItem
        title="Preferred Network Rating Tier"
        subtitle="Preferred Network member's current rating tier"
        controls={
          <FormikSelect
            name="ratingTier"
            placeholder="Select tier"
            items={MEMBERSHIP_DETAIL_ITEMS}
            withSimpleErrorStyling
          />
        }
        border={false}
      />
    </Card>
  );
};

export default MembershipDetails;
