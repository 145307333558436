import dateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import reduce from 'lodash/reduce';

import { DATEPICKER_FORMAT } from 'src/routes/Deals/constants';
import { DefaultBankAccounts, DestinationAccount, ReceiverException, SourceAccount } from 'src/routes/Deals/types';
import { ISFRCompany, ISFRGroupedCompanies, ISFRValues, ISFRValuesPayload } from 'src/routes/Deals/ISFR/types';
import { safeDiv, safeMul } from 'src/routes/Deals/helpers';
import { safeFormatISO } from 'src/helpers/dates';

// TODO extract this
const transformCompanyData = (
  companies: ISFRGroupedCompanies,
  sourceAccounts: SourceAccount[],
  receiverRole: string,
): {
  companies: ISFRCompany[];
  defaultBankAccounts: DefaultBankAccounts;
} => {
  const receiverCompanies = companies[receiverRole];
  const destinationAccounts: DestinationAccount[] = flatten(
    receiverCompanies?.map(c => {
      let priority = 0;
      return c.fundingAccounts?.map(a => {
        priority += 1;
        return {
          companyIdentifier: c.identifier,
          fundingAccountIdentifier: a.accountId,
          type: a.type,
          value: a.value,
          priority,
        };
      });
    }),
  );

  return {
    companies: reduce(
      companies,
      (result, value) => {
        return [...result, ...value];
      },
      [],
    ),
    defaultBankAccounts: {
      sourceAccounts,
      destinationAccounts,
    },
  };
};

const transformReceiverExceptionsToPayload = (exceptions: ReceiverException[]): ReceiverException[] => {
  return exceptions.map(e => ({ ...e, purchasePriceRate: safeDiv(e.purchasePriceRate, 100) }));
};

const transformReceiverExceptionsToValues = (exceptions: ReceiverException[]): ReceiverException[] => {
  return exceptions.map(e => ({ ...e, purchasePriceRate: safeMul(e.purchasePriceRate, 100) }));
};

export const transformToPayload = (data: ISFRValues): ISFRValuesPayload => {
  return {
    ...data,
    effectiveOn: data.effectiveOn ? safeFormatISO(new Date(data.effectiveOn)) : null,
    endsOn: data.endsOn ? safeFormatISO(new Date(data.endsOn)) : null,
    ...transformCompanyData(data.companies, data.defaultBankAccounts.sourceAccounts, data.payment.receiverRole),
    payment: {
      ...data.payment,
      purchasePriceRate: safeDiv(data.payment.purchasePriceRate, 100),
      receiverRole: data.payment.receiverRole ? data.payment.receiverRole.slice(0, -1) : null,
      receiverExceptions: transformReceiverExceptionsToPayload(data.payment.receiverExceptions),
    },
    maximumRebate: {
      ...data.maximumRebate,
      rate: `${Number(data.maximumRebate.rate) / 100}`,
    },
  };
};

export const removeDefaultEntities = (data: ISFRValues): ISFRValues => data;

export const transformToValues = (dealConfig: ISFRValuesPayload, dealUuid: string, status: string): ISFRValues => {
  return {
    ...dealConfig,
    dealUuid,
    companies: groupBy(dealConfig.companies, c => `${c.role}s`) as unknown as ISFRGroupedCompanies,
    status: status.toLowerCase(),
    effectiveOn: dealConfig.effectiveOn
      ? `${dateFnsFormat(parseISO(dealConfig.effectiveOn).getTime(), DATEPICKER_FORMAT)}`
      : null,
    endsOn: dealConfig.endsOn ? `${dateFnsFormat(parseISO(dealConfig.endsOn).getTime(), DATEPICKER_FORMAT)}` : null,
    payment: {
      ...dealConfig.payment,
      purchasePriceRate: safeMul(dealConfig.payment.purchasePriceRate, 100),
      receiverRole: dealConfig?.payment?.receiverRole ? `${dealConfig?.payment?.receiverRole}s` : null,
      receiverExceptions: transformReceiverExceptionsToValues(dealConfig.payment.receiverExceptions),
    },
    maximumRebate: {
      ...dealConfig.maximumRebate,
      rate: safeMul(dealConfig.maximumRebate.rate, 100),
    },
  };
};
