import React, { FC } from 'react';
import { Card, LoadingSpinner } from '@producepay/pp-ui';

import { ReactTable, Pagination } from 'src/components/elements/Table';

export const ReconcilePaymentsTable: FC<{
  isLoading?: boolean;
}> = ({ isLoading = false }) => {
  return isLoading ? (
    <div className="mt-10">
      <LoadingSpinner centered />
    </div>
  ) : (
    <div className="p-6">
      <div className="py-2">
        <Pagination />
      </div>
      <Card>
        <ReactTable outerBorders={false} verticalBorders={false} headerBorders={false} />
      </Card>
    </div>
  );
};
