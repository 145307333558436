import React from 'react';

import { Card } from '@producepay/pp-ui';

import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import FormLabel from 'src/components/elements/FormLabel';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Trash2 } from 'react-feather';
import cx from 'classnames';
import FormikDatePicker from 'src/components/formik/FormikDatePicker';
import FormikTextField from 'src/components/formik/FormikTextField';
import { v4 as uuid } from 'uuid';
import { PSEntityAction } from 'src/routes/Deals/constants';
import { PSMilestoneValue } from 'src/routes/Deals/PS/types';

import PSFooter from '../../components/PSDealNavigation/PSFooter';

interface MilestoneItemProps {
  milestone: PSMilestoneValue;
  index: number;
  onDelete: (milestoneId: string) => void;
  canDelete?: boolean;
  onUpdate: (index: number, value: PSMilestoneValue) => void;
  contractedGmv: number;
}

const MilestoneItem = ({
  onDelete,
  index,
  milestone,
  canDelete = true,
  onUpdate,
  contractedGmv,
}: MilestoneItemProps): JSX.Element => {
  const setMilestoneDirty = () =>
    !milestone.action && onUpdate(index, { ...milestone, ...{ action: PSEntityAction.Update } });

  return (
    <div className="flex gap-5 items-center">
      <input readOnly name={`milestones[${index}].id`} value={milestone.milestoneUuid} hidden />
      <p className="w-full font-semibold">{`Milestone ${index + 1}`}</p>
      <Field
        name={`milestones[${index}].deadline`}
        component={FormikDatePicker}
        inputProps={{
          name: `milestones[${index}].deadline`,
          onFocus: setMilestoneDirty,
        }}
        placeholderDate="MM-DD-YYYY"
        withSimpleErrorStyling
      />
      <FormikTextField
        name={`milestones[${index}].gmvPercentage`}
        iconSuffix={<span>%</span>}
        readOnly
        value={(contractedGmv && contractedGmv > 0 ? milestone.gmvAmount / contractedGmv : 0).toFixed(2)}
        inputProps={{ className: 'text-right cursor-default pointer-events-none' }}
        withSimpleErrorStyling
        type="number"
      />
      <FormikTextField
        name={`milestones[${index}].gmvAmount`}
        iconPrefix={<span>$</span>}
        inputProps={{ className: 'text-right' }}
        withSimpleErrorStyling
        onBlur={setMilestoneDirty}
        type="number"
      />
      <div className="text-right w-96">
        <button
          onClick={() => canDelete && onDelete(milestone.milestoneUuid)}
          className={cx(
            'bg-white focus:outline-none',
            !canDelete ? 'cursor-not-allowed text-gray-300' : 'text-gray-500',
          )}
          type="button"
        >
          <Trash2 size={17} />
        </button>
      </div>
    </div>
  );
};

const generateNewMilestone = (): PSMilestoneValue => ({
  milestoneUuid: uuid(),
  deadline: null,
  gmvAmount: null,
  action: PSEntityAction.Create,
});

const Milestones = () => {
  const {
    values: {
      milestones,
      general: { forecastedFinancials },
    },
  } = useFormikContext();

  const [canDelete, setCanDelete] = React.useState(() => milestones.length > 1);

  React.useEffect(() => {
    setCanDelete(milestones.filter(milestone => milestone.action !== PSEntityAction.Delete).length > 1);
  }, [milestones]);

  const addMilestone = (addCallback: (index: PSMilestoneValue) => void) => {
    addCallback(generateNewMilestone());
  };

  const deleteMilestone = (
    index: number,
    removeCallback: (index: number) => void,
    updateCallback: (index: number, milestone: PSMilestoneValue) => void,
  ) => {
    const milestone = milestones[index];
    if (milestone.action === PSEntityAction.Create) {
      removeCallback(index);
    } else {
      updateCallback(index, { ...milestone, ...{ action: PSEntityAction.Delete, id: milestone.milestoneUuid } });
    }
  };

  return (
    <div className="w-2/3 mt-6 mx-auto px-6">
      <Card className="pb-6" data-testid="milestones-card">
        <SectionHeader showIcon={false} title="Milestones" />
        <div className="px-6 mt-6">
          <FieldArray
            name="milestones"
            render={({ remove, push, replace }) => {
              return (
                <>
                  <div className="flex gap-5">
                    <div className="w-full" />
                    <FormLabel className="w-full" label="DEADLINE" />
                    <FormLabel className="w-full" label="GMV PERCENTAGE" />
                    <FormLabel className="w-full" label="GMV AMOUNT" />
                    <div className="w-96" />
                  </div>
                  <div className="flex gap-5 flex-col">
                    {milestones.map(
                      (milestone, index) =>
                        milestone.action !== PSEntityAction.Delete && (
                          <MilestoneItem
                            canDelete={canDelete}
                            key={milestone.milestoneUuid}
                            milestone={milestone}
                            index={index}
                            onDelete={() => deleteMilestone(index, remove, replace)}
                            onUpdate={replace}
                            contractedGmv={parseInt(forecastedFinancials.contractedGmv, 10)}
                          />
                        ),
                    )}
                  </div>
                  <div className="mt-8">
                    <button
                      onClick={() => addMilestone(push)}
                      className="text-primary body2 font-semibold"
                      type="button"
                    >
                      + Add Milestone
                    </button>
                  </div>
                </>
              );
            }}
          />
        </div>
      </Card>

      <PSFooter className="mt-6" />
    </div>
  );
};

export default Milestones;
