import { createContext, useContext } from 'react';
import { Source } from 'src/routes/Company/CompanyBalances/types';

export enum DestinationType {
  FinancialProduct = 'financialProduct',
  Company = 'company',
  Deal = 'deal',
}

export enum DestinationSection {
  TestWire = 'testWire',
  InSeason = 'inSeason',
  PreSeason = 'preSeason',
  MidSeason = 'midSeason',
  Membership = 'membership',
  Application = 'application',
  Partnership = 'partnership',
  Trading = 'trading',
}

type CompanyBalancesContext = {
  selectedSources: Source[];
  setSelectedSources: (sources: Source[]) => void;
};

export const CompanyBalancesContext = createContext<CompanyBalancesContext>({
  selectedSources: [],
  setSelectedSources: () => null,
});

export const useCompanyBalances = (): CompanyBalancesContext => useContext(CompanyBalancesContext);
