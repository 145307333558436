import React, { RefObject, useRef } from 'react';
import RelativePortal from 'react-relative-portal';
import cx from 'classnames';
import { format, isValid, parse, startOfDay } from 'date-fns';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps } from 'react-day-picker';
import { FieldProps, useFormikContext } from 'formik';
import { TextField } from '@producepay/pp-ui';
import CalendarIcon from '@producepay/pp-ui/dist/components/icons/CalendarIcon';
import kebabCase from 'lodash/kebabCase';

import FormikTextField from '../FormikTextField';
import SimpleErrorMessageWrapper from '../SimpleErrorMessageWrapper';
import 'react-day-picker/lib/style.css';
import './datepicker.css';

interface DatePickerProps extends DayPickerInputProps {
  alignLeft?: boolean;
  id?: string;
  value: Date;
  placeholderDate?: Date | string;
  withSimpleErrorStyling?: boolean;
  inputProps: {
    className?: string;
    onFocus?(): void;
  };
  errorClearable?: boolean;
}

const DATEPICKER_FORMAT = 'MM/dd/yyyy';

function formatInputDate(date, formatStr, locale): string {
  return format(date, formatStr, { locale });
}

function parseInputDate(date: string, formatStr: string): Date | undefined {
  const value = parse(date, formatStr, new Date());
  return isValid(value) ? value : undefined;
}

const FormikDatePicker = React.forwardRef(
  (
    {
      alignLeft,
      field,
      id,
      placeholderDate,
      inputProps = {},
      dayPickerProps = {},
      withSimpleErrorStyling = false,
      errorClearable = true,
    }: DatePickerProps & FieldProps,
    ref: RefObject<DayPickerInput>,
  ) => {
    const valueRef = useRef(field.value ?? '');
    const keyRef = useRef(0);
    const currentDate = new Date(Date.now());

    const { getFieldMeta, setFieldValue, setFieldTouched, status } = useFormikContext();

    const readOnly = status?.readOnly ?? false;

    function handleFocus() {
      // eslint-disable-next-line no-unused-expressions
      inputProps?.onFocus?.();
      setFieldTouched(field.name, true);
    }

    const { error, touched } = getFieldMeta(field.name);

    const inputClasses = cx(inputProps?.className, {
      'border rounded border-red-500': withSimpleErrorStyling && error && !touched,
    });

    // pluck selectedDay from overlayProps to avoid react warning
    function RelativePortalOverlay({ classNames, children, selectedDay: _, ...overlayProps }): JSX.Element {
      return (
        <RelativePortal
          component="div"
          data-testid={`date-entry-${kebabCase(field.name)}-overlay`}
          left={alignLeft ? -85 : 0}
          top={0}
        >
          <div className={cx(classNames.overlayWrapper, 'FinanceDatePicker', 'z-50')} {...overlayProps}>
            <div className={classNames.overlay}>{children}</div>
          </div>
        </RelativePortal>
      );
    }

    const datePicker = (
      <div className="w-full">
        <DayPickerInput
          // DayPickerInput doesn't support controlled mode
          // using key hack to force delete internal input state after day picker gets hidden
          key={keyRef.current}
          ref={ref}
          overlayComponent={RelativePortalOverlay}
          keepFocus={false}
          dayPickerProps={{
            ...dayPickerProps,
            selectedDays: new Date(field.value),
            month: placeholderDate instanceof Date ? new Date(field.value ?? placeholderDate) : currentDate,
          }}
          placeholder={`${
            placeholderDate instanceof Date ? format(placeholderDate, DATEPICKER_FORMAT) : placeholderDate ?? ''
          }`}
          component={TextField}
          value={field.value ?? ''}
          inputProps={{
            ...inputProps,
            className: inputClasses,
            iconPrefix: <CalendarIcon size={16} />,
            id,
            size: 'small',
            onFocus: handleFocus,
            rounded: true,
            'data-testid': `date-entry-${kebabCase(field.name)}`,
          }}
          format={DATEPICKER_FORMAT}
          formatDate={formatInputDate}
          onDayChange={day => {
            valueRef.current = day;
          }}
          onDayPickerShow={() => {
            valueRef.current = field.value ?? '';
          }}
          onDayPickerHide={() => {
            keyRef.current += 1;
            // https://github.com/gpbl/react-day-picker/issues/809
            // using startOfDay to get correct start of day
            setFieldValue(field.name, valueRef.current ? startOfDay(valueRef.current) : null);
          }}
          parseDate={parseInputDate}
        />
      </div>
    );

    return readOnly ? (
      <FormikTextField className="flex-shrink-1" size="small" style={{ height: 32 }} name={field.name} />
    ) : withSimpleErrorStyling ? (
      <SimpleErrorMessageWrapper error={error} cleared={errorClearable && touched}>
        {datePicker}
      </SimpleErrorMessageWrapper>
    ) : (
      datePicker
    );
  },
);

export default FormikDatePicker;
