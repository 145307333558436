import gql from 'graphql-tag';

const GET_FINANCIAL_PRODUCTS = gql`
  query GetFinancialProducts($assetUuid: String!) {
    getFinancialProducts(assetUuid: $assetUuid) {
      financialProducts {
        financialProductUuid
        requestedDate
        productType
        requestedAmount
        approvedAmount
        status
      }
    }
  }
`;

export default GET_FINANCIAL_PRODUCTS;
