import React from 'react';
import { formatCurrency } from 'src/helpers/currency';

interface ReconcileBalanceProps {
  receivedAmount: number;
  reconciledAmount: number;
}

const ReconcileBalance = ({ receivedAmount, reconciledAmount }: ReconcileBalanceProps) => {
  return (
    <div className="bg-white rounded w-6/10 p-5 ml-auto flex justify-between items-center">
      <div className="flex items-center space-x-1">
        <p data-testid="balance-received-amount" className="font-semibold text-2xl">
          {formatCurrency(receivedAmount)}
        </p>
        <p className="text-xs text-gray-500">received</p>
      </div>

      <div className="flex items-center space-x-1 bg-gray-100 p-2 rounded">
        <p data-testid="balance-reconciled-amount" className="font-semibold text-xs">
          {formatCurrency(reconciledAmount)}
        </p>
        <p className="text-xs text-gray-500">reconciled</p>
      </div>

      <div className="flex items-center space-x-1 bg-tangerine bg-opacity-20 p-2 rounded">
        <p data-testid="balance-unreconciled-amount" className="font-semibold text-xs">
          {formatCurrency(receivedAmount - reconciledAmount)}
        </p>
        <p className="text-xs text-gray-500">remaining</p>
      </div>
    </div>
  );
};

export default React.memo(ReconcileBalance);
