import React, { useEffect, useState } from 'react';
import { Button, LoadingSpinner } from '@producepay/pp-ui';
import { AlertTriangle, Check, ChevronDown } from 'react-feather';
import {
  calculateExposure,
  getExposureLevel,
  getMostExposed,
  getExposureColor,
  ExposureLevel,
  exposureValid,
} from 'src/helpers/exposure';
import GET_COMPANY_EXPOSURES from 'src/graphql/queries/getCompanyExposures';
import { useQuery } from '@apollo/client';
import ExposureEntity from './ExposureEntity';

const getExposureIcon = (exposureLevel: ExposureLevel) => {
  switch (exposureLevel) {
    case ExposureLevel.OK:
    case ExposureLevel.WARNING:
      return (
        <span
          data-testid="alert-check"
          className={`inline-block rounded-full mx-1 align-middle p-0.5 bg-${getExposureColor(exposureLevel)}`}
        >
          <Check stroke="white" strokeWidth="4px" size={12} />
        </span>
      );
    case ExposureLevel.OVER:
      return (
        <span data-testid="alert-triangle" className="inline-block align-middle mb-1 mx-1">
          <AlertTriangle fill="#FF5A60" stroke="white" strokeWidth="2px" strokeLinecap="round" size={17} />
        </span>
      );
    default:
      return null;
  }
};

const ExposureHeader = ({ companyIdentifier, companyName, totalSelected }) => {
  const { data, loading, error, refetch } = useQuery(GET_COMPANY_EXPOSURES, {
    variables: {
      companyIdentifier,
    },
  });
  useEffect(() => {
    // We don't want to refetch on the initial mount. Both because it adds an
    // unnecessary http request and because it results in a MockedProvider error in tests.
    if (data) {
      refetch();
    }
    // missing deps: [data, refetch]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSelected]);
  const [isOpen, setIsOpen] = useState(false);
  const exposureData = data?.companyExposures;
  const exposureLevel =
    exposureData && exposureValid(exposureData.company)
      ? getExposureLevel(
          calculateExposure(exposureData[getMostExposed(exposureData)], { selectedAmount: totalSelected }).factor,
        )
      : ExposureLevel.OVER;
  return (
    <div className="border-b-1 p-4">
      <div className="flex justify-between">
        <h3 className="font-bold text-lg">{companyName}</h3>

        <Button
          color={loading ? 'default' : getExposureColor(exposureLevel, { okColor: 'default', warningColor: 'default' })}
          onClick={() => setIsOpen(!isOpen)}
          variant="text"
        >
          {loading ? (
            <span className="inline-block mx-1">
              <LoadingSpinner size={14} />
            </span>
          ) : (
            getExposureIcon(exposureLevel)
          )}
          Exposure
          <ChevronDown
            className="inline transition-transform duration-500 ml-1"
            size={16}
            style={{ transform: `rotate(${isOpen ? 360 : 180}deg` }}
          />
        </Button>
      </div>
      <div
        className={`${isOpen ? 'block opacity-100' : 'hidden opacity-0'} transition-opacity duration-300 pt-4`}
        hidden={!isOpen}
      >
        {error && <p className="text-secondary">There was an error fetching exposure data for this company.</p>}
        {exposureData && (
          <ExposureEntity exposure={exposureData.company} selectedAmount={totalSelected} type="Company" />
        )}
        {/* we only want to show the company group if it exists. */}
        {/* if it doesn't exist, the backend will return 0 for all the exposure data */}
        {exposureData && exposureValid(exposureData.companyGroup) && (
          <ExposureEntity exposure={exposureData.companyGroup} selectedAmount={totalSelected} type="Company Group" />
        )}
      </div>
    </div>
  );
};

export default ExposureHeader;
