import { Decimal } from 'decimal.js';

/* eslint-disable camelcase */
import { GetFinancialProductsWithBalance_financialProductsWithBalance_nodes } from 'src/routes/Company/CompanyBalances/graphql-types/GetFinancialProductsWithBalance';
import {
  GetUnpaidFees_unpaidFees_applicationFees,
  GetUnpaidFees_unpaidFees_membershipFees,
  GetUnpaidFees_unpaidFees_testWires,
  GetUnpaidFees_unpaidFees_tradingFees,
} from 'src/routes/Company/CompanyBalances/graphql-types/GetUnpaidFees';

// TODO: Figure out how to avoid underscores in child types.
// Until then, alias generated types without underscores
export type ApplicationFee = GetUnpaidFees_unpaidFees_applicationFees;
export type MembershipFee = GetUnpaidFees_unpaidFees_membershipFees;
export type TestWire = Omit<GetUnpaidFees_unpaidFees_testWires, '__typename'>;
export type TradingFee = GetUnpaidFees_unpaidFees_tradingFees;
export type FinancialProduct = GetFinancialProductsWithBalance_financialProductsWithBalance_nodes;
/* eslint-enable camelcase */

// TODO: implement partnerships
export type PartnershipFee = {
  toUuid: string;
  balance: string;
};

export enum FundAction {
  Allocate = 'allocate',
  Release = 'release',
}

export enum SourceType {
  Escrow = 'escrow',
  Order = 'order',
  PreSeasonCollections = 'pre-season-collections',
}

export type Source = SourceEscrow | SourceOrder | SourcePreSeasonCollection;

export type SourceEscrow = {
  type: SourceType.Escrow;
  uuid: string;
  balance: Decimal.Value;
};

export type SourceOrder = {
  type: SourceType.Order;
  uuid: string;
  balance: Decimal.Value;
  slug: string;
  reference: string;
};

export type SourcePreSeasonCollection = {
  type: SourceType.PreSeasonCollections;
  uuid: string;
  paid: Decimal.Value;
  balance: Decimal.Value;
  fees: Decimal.Value;
  reference: string;
  dueDate: Date;
  dealReference: string;
  dealUuid: string;
};

export enum SectionNames {
  ApplicationFees = 'applicationFees',
  InSeason = 'inSeason',
  MembershipFees = 'membershipFees',
  MidSeason = 'midSeason',
  Partnerships = 'partnerships',
  PreSeason = 'preSeason',
  TestWires = 'testWires',
  TradingFees = 'tradingFees',
}

export type AllocateBalanceFormValues = {
  [S in SectionNames]: { [key: string]: string };
};
