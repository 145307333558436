import React from 'react';
import { map, kebabCase, toString } from 'lodash';

import useSkus from 'src/hooks/use-skus';

import { RadioButton } from '@producepay/pp-ui';
import { Field, FormikProps } from 'formik';
import FormikSelect from 'src/components/formik/FormikSelect';
import { SelectItem } from 'src/components/formik/FormikAutocomplete';

import { PSCommodityFormikValues } from '../../../types';

const IS_ORGANIC_OPTION_VALUES_MAP = {
  'All Values': null,
  Conventional: false,
  Organic: true,
};

const mapSkuOptionsToItems = options => {
  return map(options, opt => ({ label: opt.name, value: opt.id }));
};

interface PSCommodityFormProps {
  formikProps: FormikProps<PSCommodityFormikValues>;
  distributorOptions: SelectItem[];
}

const PSCommodityForm = ({ formikProps, distributorOptions }: PSCommodityFormProps) => {
  const selectedCommodityId = formikProps.values.commodity?.uuid;
  const selectedVarietyId = formikProps.values.variety?.uuid;
  const selectedPackagingId = formikProps.values.packaging?.uuid;
  const selectedSizeId = formikProps.values.size?.uuid;

  const { commodities, varieties, packages, sizes } = useSkus({
    commodityId: selectedCommodityId,
    varietyId: selectedVarietyId,
    packageId: selectedPackagingId,
    sizeId: selectedSizeId,
  });

  const onSelectSkuOption = newValues => {
    formikProps.setValues({ ...formikProps.values, ...newValues });
  };

  return (
    <>
      <FormikSelect
        isSearchable
        data-testid="commodity-select"
        name="commodity.uuid"
        className="pb-4"
        label="Commodity"
        placeholder="Select a commodity"
        items={mapSkuOptionsToItems(commodities)}
        onChange={item => {
          onSelectSkuOption({
            commodity: { name: item.label, uuid: item.value },
            variety: { name: null, uuid: null },
            packaging: { name: null, uuid: null },
            size: { name: null, uuid: null },
          });
          formikProps.setFieldError('commodity', '');
        }}
        shouldShowDefault={false}
      />

      <FormikSelect
        isSearchable
        data-testid="variety-select"
        name="variety.uuid"
        className="pb-4"
        label="Variety (optional)"
        placeholder="All varieties"
        items={mapSkuOptionsToItems(varieties)}
        onChange={item => {
          let updates: Partial<PSCommodityFormikValues> = { variety: { name: item.label, uuid: item.value } };
          if (!item.value) {
            updates = {
              ...updates,
              packaging: { name: null, uuid: null },
              size: { name: null, uuid: null },
            };
          }
          onSelectSkuOption(updates);
        }}
        disabled={!selectedCommodityId}
      />

      <FormikSelect
        isSearchable
        data-testid="packaging-select"
        name="packaging.uuid"
        className="pb-4"
        label="Packaging (optional)"
        placeholder="All packagings"
        items={mapSkuOptionsToItems(packages)}
        onChange={item => {
          onSelectSkuOption({ packaging: { name: item.label, uuid: item.value } });
        }}
        disabled={!selectedCommodityId || !selectedVarietyId}
      />

      <FormikSelect
        isSearchable
        data-testid="size-select"
        name="size.uuid"
        className="pb-4"
        label="Size (optional)"
        placeholder="All sizes"
        items={mapSkuOptionsToItems(sizes)}
        onChange={item => {
          onSelectSkuOption({ size: { name: item.label, uuid: item.value } });
        }}
        disabled={!selectedCommodityId || !selectedVarietyId}
      />

      <div className="text-xs font-semibold mb-2">Type</div>
      <Field name="isOrganic">
        {({ field: { value }, form: { setFieldValue } }) => (
          <div className="flex pb-4">
            {map(IS_ORGANIC_OPTION_VALUES_MAP, (optionValue, optionLabel) => (
              <RadioButton
                key={optionLabel}
                data-testid={`${kebabCase(optionLabel)}-radio`}
                className="mr-4"
                label={optionLabel}
                value={toString(optionValue)}
                checked={optionValue === value}
                onClick={() => setFieldValue('isOrganic', optionValue)}
              />
            ))}
          </div>
        )}
      </Field>

      <FormikSelect
        data-testid="distributor-select"
        name="distributorUuid"
        label="Distributor (optional)"
        placeholder="All distributors"
        items={distributorOptions}
      />
    </>
  );
};

export default PSCommodityForm;
