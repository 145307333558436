import React, { FC, useEffect, useState, MouseEvent } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Options, VirtualElement } from '@popperjs/core';

export interface PopperProps extends Partial<Pick<Options, 'placement'>> {
  referenceElement: HTMLElement | VirtualElement;
  onClickOutside?(e: MouseEvent<HTMLDivElement>): void;
}

export const Popper: FC<PopperProps> = ({ placement = 'auto-end', referenceElement, children, onClickOutside }) => {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [5, 5],
        },
      },
    ],
    placement,
    strategy: 'fixed',
  });

  useEffect(() => {
    function onClick(e) {
      if (!popperElement.contains(e.target)) {
        // eslint-disable-next-line no-unused-expressions
        onClickOutside?.(e);
      }
    }
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [onClickOutside, popperElement]);

  return createPortal(
    <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      {children}
    </div>,
    document.body,
  );
};
