import React from 'react';
import { useFormikContext } from 'formik';
import { Decimal } from 'decimal.js';
import { Accordion, AccordionBody, AccordionHeader } from '@producepay/pp-ui';
import { groupBy, map } from 'lodash';

import { formatCurrency } from 'src/helpers/currency';
import DataTable, { DataTableColumn } from 'src/components/molecules/DataTable';
import { isDecimal } from 'src/routes/Company/CompanyBalances/helpers';
import { AllocateBalanceFormValues, SectionNames } from './types';

interface AllocationSectionProps<T> {
  allocationItems: T[];
  tableColumns: DataTableColumn<T>[];
  title: string;
  name: SectionNames;
  groupByKey?: string;
}

export function AllocationSection<T>({
  allocationItems,
  tableColumns,
  title,
  name,
  groupByKey,
}: AllocationSectionProps<T>) {
  const { values } = useFormikContext<AllocateBalanceFormValues>();

  const allocatedSum = Decimal.sum(0, ...Object.values(values?.[name] ?? []).filter(isDecimal));
  const stillDueItemSum = Decimal.abs(Decimal.sum(0, ...map(allocationItems, 'balance')));
  const groupedAllocationItems = groupBy(allocationItems, groupByKey);

  return (
    <>
      {allocationItems.length > 0 && (
        <div className="border rounded my-4">
          <Accordion>
            <AccordionHeader className="flex-row-reverse" clickAnywhere data-testid={`${name}-accordion`}>
              <div className="w-full flex pl-12">
                <div className="w-1/3">{title}</div>
                <div className="w-1/3 text-xs uppercase">
                  <div className="font-normal">Still Due</div>
                  <div data-testid={`${name}-still-due`}>
                    {formatCurrency(Decimal.sub(stillDueItemSum, allocatedSum))}
                  </div>
                </div>
                <div className="w-1/3 text-xs uppercase">
                  <div className="font-normal">Allocated</div>
                  <div data-testid={`${name}-allocated`}>{formatCurrency(allocatedSum)}</div>
                </div>
              </div>
            </AccordionHeader>
            <AccordionBody className="border-t">
              <div>
                {groupByKey ? (
                  Object.keys(groupedAllocationItems).map(k => (
                    <div key={k}>
                      <div className="bg-gray-100 p-2 font-bold">{k}</div>
                      <div className="px-4">
                        <DataTable<T>
                          data-testid={`${name}-table`}
                          className="table-fixed"
                          trClass="odd:bg-gray-50"
                          columns={tableColumns}
                          keyName="toUuid"
                          rows={groupedAllocationItems[k]}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <DataTable<T>
                    data-testid={`${name}-table`}
                    className="table-fixed"
                    trClass="odd:bg-gray-50"
                    columns={tableColumns}
                    keyName="toUuid"
                    rows={allocationItems}
                  />
                )}
              </div>
            </AccordionBody>
          </Accordion>
        </div>
      )}
    </>
  );
}
