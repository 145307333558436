import React, { useEffect, useMemo } from 'react';
import { Card } from '@producepay/pp-ui';
import { useField, useFormikContext } from 'formik';
import { nextFriday, previousFriday } from 'date-fns';
import { Column } from 'react-table';
import { v4 as uuidv4 } from 'uuid';

import {
  columnSum,
  currencyCellFormatter,
  FormikTable,
  HeaderCell,
  TableToolbar,
  DisplayCell,
} from 'src/components/elements/Table';
import { currencyToNumber } from 'src/helpers/currency';
import PSFooter from '../../components/PSDealNavigation/PSFooter';
import { PSForecastCommodityItem, PSForecastValue, PSForecastValues, PSValues } from '../../types';
import { IndexCell } from './IndexCell';
import { TableMenu } from './TableMenu';
import { getStartDate } from './helpers';
import { Actions } from './Actions';

export function formatNumber(value) {
  if (value === '' || value === undefined || value === null) {
    return '';
  }
  return new Intl.NumberFormat('en-US').format(value);
}

const columns = [
  {
    Header: props => <HeaderCell {...props} name="Week" />,
    Cell: IndexCell,
    accessor: 'week',
    width: 150,
    canResize: false,
    align: 'center',
    verticalAlign: 'center',
    selectable: false,
    minHeight: 50,
    className: 'bg-gray-100',
  },
  {
    Header: props => <HeaderCell {...props} name="Repayment" formula={columnSum} />,
    accessor: 'repayment',
    formatter: currencyCellFormatter,
    pasteParser: currencyToNumber,
  },
  {
    Header: props => <HeaderCell {...props} name="Total GMV" formula={columnSum} />,
    accessor: 'totalGmv',
    formatter: currencyCellFormatter,
    pasteParser: currencyToNumber,
  },
  {
    id: 'totalCases',
    Header: props => (
      <HeaderCell
        {...props}
        name="Total Cases"
        formula={(data: PSForecastValues) => {
          return data.reduce<number>((acc, item) => {
            const cellValue = Object.values(item.items ?? {}).reduce(
              (itemsAcc, commodity) => itemsAcc + Number(commodity.units ?? 0),
              0,
            );
            return acc + Number(cellValue ?? 0);
          }, 0);
        }}
      />
    ),
    Cell: DisplayCell,
    accessor: row => {
      const cellValue = Object.values<PSForecastCommodityItem>(row.items ?? {}).reduce<number>(
        (acc, item) => acc + Number(item.units ?? 0),
        0,
      );
      return cellValue === 0 ? '' : cellValue;
    },
    formatter: formatNumber,
    selectable: false,
    pastable: false,
    className: 'bg-gray-100',
  },
];

export const ForecastView = () => {
  const {
    values: {
      general: {
        effectiveOn,
        companies: { distributors },
      },
      commodities,
    },
  } = useFormikContext<PSValues>();
  const [{ value }, , { setValue }] = useField<PSForecastValues>('forecast');
  const allColumns = useMemo(() => {
    const accessor = index => row => {
      return row.items?.[index]?.units ?? '';
    };
    return [
      ...columns,
      ...commodities.map(item => ({
        id: `items.${item.forecastCommodityUuid}.units`,
        Header: props => {
          const subtitle = useMemo(
            () =>
              [
                item.packaging.name,
                item.size.name,
                item.isOrganic ? 'Organic' : item.isOrganic === false ? 'Conventional' : '',
                item.distributorUuid
                  ? (distributors ?? []).find(distributor => distributor.identifier === item.distributorUuid).name ?? ''
                  : '',
              ]
                .filter(v => v)
                .join(', '),
            [],
          );

          return (
            <HeaderCell
              {...props}
              name={[item.commodity.name, item.variety.name].filter(v => v).join(', ')}
              subtitle={subtitle}
            />
          );
        },
        accessor: accessor(item.forecastCommodityUuid),
        formatter: formatNumber,
      })),
    ];
  }, [distributors, commodities]);

  // TODO Kirill: think of a better way to replace formik values based on changes
  // in date range pickers or commodities
  // Ideally we don't want to render anything before formik has a valid state for table to consume
  useEffect(() => {
    const forecasts = [...(value ?? [])];
    if (value.length === 0) {
      forecasts.push({
        forecastUuid: uuidv4(),
        week: getStartDate(value, effectiveOn),
        items: Object.fromEntries(
          commodities.map(item => [item.forecastCommodityUuid, { uuid: item.forecastCommodityUuid, units: null }]),
        ),
      });
    } else {
      const commoditiesMap = new Map(commodities.map(item => [item.forecastCommodityUuid, item.commodity]));
      forecasts.forEach(item => {
        Object.keys(item.items).forEach(key => {
          if (!commoditiesMap.has(key)) {
            // eslint-disable-next-line no-param-reassign
            delete item.items[key];
          }
        });
        const itemsSet = new Set(Object.keys(item.items));
        [...commoditiesMap.keys()].forEach(key => {
          if (!itemsSet.has(key)) {
            // eslint-disable-next-line no-param-reassign
            item.items[key] = { uuid: key, units: null };
          }
        });
      });
    }
    setValue(forecasts);
  }, [commodities]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="p-6">
      <Card>
        <TableToolbar title="Forecast" Actions={Actions} />
        <FormikTable
          fieldName="forecast"
          columns={allColumns as Column[]}
          outerBorders={false}
          createNewRow={(prev: PSForecastValue, next: PSForecastValue, current: PSForecastValue) => {
            return {
              forecastUuid: uuidv4(),
              week: prev ? nextFriday(prev.week) : next ? previousFriday(next.week) : current.week,
              items: Object.fromEntries(
                commodities.map(item => [
                  item.forecastCommodityUuid,
                  { uuid: item.forecastCommodityUuid, units: null },
                ]),
              ),
            };
          }}
          Menu={TableMenu}
        />
      </Card>
      <PSFooter className="py-6" />
    </div>
  );
};
