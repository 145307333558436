import React from 'react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { map } from 'lodash';

import { Button, Modal } from '@producepay/pp-ui';
import { Formik, useFormikContext } from 'formik';

import PSCommodityForm from './PSCommodityForm';

import { PSForecastCommodity, PSForecastCommodityForm, PSValues } from '../../../types';

interface PSCommodityModalProps {
  title: string;
  onClose: () => void;
  onSave: (newCommodityValues: PSForecastCommodityForm) => void;
  commodity?: PSForecastCommodity;
}

const PSCommodityModal = ({ title, onClose, onSave, commodity: existingCommodity }: PSCommodityModalProps) => {
  const { values: preseasonFormikValues } = useFormikContext<PSValues>();

  const distributorOptions = map(preseasonFormikValues.general.companies?.distributors, distributor => ({
    label: distributor.name,
    value: distributor.identifier,
  }));

  return (
    <Formik
      initialValues={{
        commodity: {
          name: existingCommodity?.commodity?.name || '',
          uuid: existingCommodity?.commodity?.uuid || '',
        },
        variety: {
          name: existingCommodity?.variety?.name || null,
          uuid: existingCommodity?.variety?.uuid || null,
        },
        packaging: {
          name: existingCommodity?.packaging?.name || null,
          uuid: existingCommodity?.packaging?.uuid || null,
        },
        size: {
          name: existingCommodity?.size?.name || null,
          uuid: existingCommodity?.size?.uuid || null,
        },
        distributorUuid: existingCommodity?.distributorUuid || null,
        isOrganic: existingCommodity?.isOrganic ?? null,
      }}
      validationSchema={Yup.object().shape({
        commodity: Yup.object()
          .shape({
            name: Yup.string().nullable().required(' is required'),
            uuid: Yup.string().nullable().required(' is required'),
          })
          .required(' is required'),
        variety: Yup.object().shape({
          name: Yup.string().nullable(),
          uuid: Yup.string().nullable(),
        }),
        packaging: Yup.object().shape({
          name: Yup.string().nullable(),
          uuid: Yup.string().nullable(),
        }),
        size: Yup.object().shape({
          name: Yup.string().nullable(),
          uuid: Yup.string().nullable(),
        }),
        distributorUuid: Yup.string().nullable(),
        isOrganic: Yup.boolean().nullable(),
      })}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={values => {
        onSave({
          forecastCommodityUuid: existingCommodity?.forecastCommodityUuid || uuidv4(),
          ...values,
        });
        onClose();
      }}
    >
      {formikProps => (
        <Modal
          data-testid="ps-commodity-modal"
          className="rounded"
          title={title}
          onClose={onClose}
          footerContent={
            <div className="flex justify-between items-center w-full">
              <a
                href="https://producepay.tryretool.com/"
                className="text-primary text-sm"
                target="_blank"
                rel="noopener noreferrer"
              >
                Add SKU Trait
              </a>

              <div>
                <Button className="mr-8" label="Cancel" color="default" variant="text" onClick={() => onClose()} />

                <Button data-testid="commodity-modal-submit" label="Save" onClick={() => formikProps.submitForm()} />
              </div>
            </div>
          }
        >
          <PSCommodityForm formikProps={formikProps} distributorOptions={distributorOptions} />
        </Modal>
      )}
    </Formik>
  );
};

export default PSCommodityModal;
