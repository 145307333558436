import dateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';

import { DefaultBankAccounts, DestinationAccount, SourceAccount } from 'src/routes/Deals/types';
import { PNMSCompany, PNMSGroupedCompanies, PNMSValues, PNMSValuesPayload } from 'src/routes/Deals/PNMS/types';
import { DATEPICKER_FORMAT } from 'src/routes/Deals/constants';
import { safeFormatISO } from 'src/helpers/dates';
import { safeDiv, safeMul } from 'src/routes/Deals/helpers';

export const removeDefaultEntities = (data: PNMSValues): PNMSValues => data;

const transformCompanyData = (
  companies: PNMSGroupedCompanies,
  sourceAccounts: SourceAccount[],
  receiverRole: string,
): { companies: PNMSCompany[]; defaultBankAccounts: DefaultBankAccounts } => {
  const receiverCompanies = companies[receiverRole];
  const destinationAccounts: DestinationAccount[] = flatten(
    receiverCompanies?.map(c => {
      let priority = 0;
      return c.fundingAccounts?.map(a => {
        priority += 1;
        return {
          companyIdentifier: c.identifier,
          fundingAccountIdentifier: a.accountId,
          type: a.type,
          value: a.value,
          priority,
        };
      });
    }),
  );

  return {
    companies: reduce(
      companies,
      (result, value) => {
        return [...result, ...value];
      },
      [],
    ),
    defaultBankAccounts: {
      sourceAccounts,
      destinationAccounts,
    },
  };
};

export const transformToPayload = (data: PNMSValues): PNMSValuesPayload => {
  return {
    ...data,
    ...transformCompanyData(data.companies, data.defaultBankAccounts.sourceAccounts, data.payment.receiverRole),
    effectiveOn: data.effectiveOn ? safeFormatISO(new Date(data.effectiveOn)) : null,
    endsOn: data.endsOn ? safeFormatISO(new Date(data.endsOn)) : null,
    lateFee: {
      ...data.lateFee,
      dailyLateFeeRate: safeDiv(data.lateFee.dailyLateFeeRate, 100),
    },
    payment: {
      ...data.payment,
      paymentRate: safeDiv(data.payment.paymentRate, 100),
    },
    distributionFee: {
      rate: safeDiv(data.distributionFee.rate, 100),
    },
  };
};

export const transformToValues = (dealConfig: PNMSValuesPayload, dealUuid: string, status: string): PNMSValues => {
  return {
    ...dealConfig,
    dealUuid,
    status: status.toLowerCase(),
    companies: groupBy(dealConfig.companies, ({ role }) => role) as unknown as PNMSGroupedCompanies,
    effectiveOn: dealConfig.effectiveOn
      ? `${dateFnsFormat(parseISO(dealConfig.effectiveOn).getTime(), DATEPICKER_FORMAT)}`
      : null,
    endsOn: dealConfig.endsOn ? `${dateFnsFormat(parseISO(dealConfig.endsOn).getTime(), DATEPICKER_FORMAT)}` : null,
    lateFee: {
      ...dealConfig.lateFee,
      dailyLateFeeRate: safeMul(dealConfig.lateFee.dailyLateFeeRate, 100),
    },
    payment: {
      ...dealConfig.payment,
      paymentRate: safeMul(dealConfig.payment.paymentRate, 100),
    },
    distributionFee: {
      rate: safeMul(dealConfig.distributionFee.rate, 100),
    },
  };
};
