import React, { FC, useCallback } from 'react';
import { Autocomplete } from '@producepay/pp-ui';
import { CellProps } from 'react-table';
import cx from 'classnames';

import { Align, AlignCellContent, CellWithAlign, TextAlignClassName } from 'src/components/elements/Table';
import {
  CompaniesAutocomplete,
  CompaniesAutocomplete_companies as Companies,
} from 'src/graphql/queries/graphql-types/CompaniesAutocomplete';

import { FundingTransaction } from './types';

export const PayeeCompanyCell: FC<
  CellProps<FundingTransaction, FundingTransaction['sourceCompanyUuid']> &
    CellWithAlign &
    CompaniesAutocomplete & { onEdit(newData: FundingTransaction[]): void }
> = ({ value, column: { align = Align.Right }, row, companies, onEdit, data }) => {
  const textAlign = TextAlignClassName[align];

  const selectedCompany = value ? companies.find(item => item.value === value) : null;

  const onItemSelected = useCallback(
    (item: Companies) => {
      const newData = [...data];
      newData[row.index] = { ...data[row.index], sourceCompanyUuid: item.value };
      onEdit(newData);
    },
    [onEdit, row, data],
  );

  return (
    <AlignCellContent align={align}>
      <div className="flex flex-col py-2">
        <Autocomplete
          items={companies}
          onItemSelected={onItemSelected}
          initialSearchTerm={selectedCompany?.label ?? ''}
          placeholder="Select company"
          size="small"
        />
        <span className={cx(textAlign, 'text-xs text-gray-500 mt-2')}>
          {row.original.importedOriginatorCompanyName ?? ''}
        </span>
      </div>
    </AlignCellContent>
  );
};
