import { Decimal } from 'decimal.js';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const formatCurrency = (amount: number | string | Decimal): string => formatter.format(amount as number);

export const toDecimal = (number: string | number | Decimal): string => new Decimal(number).toFixed(2);

export const decimalSum = <T>(key: string, values: Array<T>): Decimal =>
  values.reduce((acc, row) => new Decimal(row[key] || '0.00').plus(acc), new Decimal('0.00'));

export const isNumeric = (value: string): boolean => Boolean(value.match(/^[0-9,.$ ]*$/));

export const cleanNumericString = (value: string): string =>
  value.length && isNumeric(value) ? value.replace(/[^.0-9]/g, '') : value;

export const currencyToNumber = (amount: string | number): number => {
  if (typeof amount === 'number') {
    return amount;
  }
  if (typeof amount === 'string' && amount.trim().match(/\$\s*(\d{1,3},)*\d{1,3}(\.\d+)*$|\$*\d+$|/g)) {
    return parseInt(amount.replace(/\.\d*/, '').match(/\d+/g).join(''), 10);
  }
  return NaN;
};
