export enum ColumnAccessors {
  ReceivedDate = 'effectiveDate',
  RecipientAccount = 'recipientAccount.name',
  RecipientIdentifier = 'recipientAccount.identifier',
  SourceAccount = 'sourceAccount.name',
  SourceIdentifier = 'sourceAccount.identifier',
  TransactionType = 'transactionType',
  Amount = 'amount',
  TransactionIdentifier = 'fundingTransactionUuid',
}
