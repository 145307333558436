import {
  BaseFormCompany,
  DefaultBankAccounts,
  FormFundingAccount,
  FormUI,
  RequiredItem,
  SharedDealFormValues,
  SourceAccount,
} from 'src/routes/Deals/types';

type PNTRRoles = 'sellers' | 'buyers';

export interface PNTRCompany extends BaseFormCompany {
  role: 'seller' | 'buyer';
  fundingAccounts: FormFundingAccount[];
}

export type PNTRGroupedCompanies = {
  [Property in PNTRRoles]: PNTRCompany[];
};

export interface PNTRValues extends SharedDealFormValues {
  companies: PNTRGroupedCompanies;
  productLineId: number;
  defaultBankAccounts: {
    sourceAccounts: SourceAccount[];
  };
  documentAcceptance: string;
  repayment: {
    repaymentDays: string;
    consignmentCollectionDays: string;
    fixedPriceCollectionDays: string;
    tradeAssurance: boolean;
    requiredItems: RequiredItem[];
  };
  tradingFees: {
    sellerFeeRate: string;
    buyerFeeRate: string;
  };
  ui: FormUI;
}

export interface PNTRValuesPayload extends SharedDealFormValues {
  companies: PNTRCompany[];
  defaultBankAccounts: DefaultBankAccounts;
  productLineId: number;
  documentAcceptance: string;
  repayment: {
    repaymentDays: string;
    consignmentCollectionDays: string;
    fixedPriceCollectionDays: string;
    tradeAssurance: boolean;
    requiredItems: RequiredItem[];
  };
  tradingFees: {
    sellerFeeRate: string;
    buyerFeeRate: string;
  };
  ui: FormUI;
}

export enum PNTRDocumentAcceptance {
  DistributorOrProducePay = 'distributorOrProducePay',
  Distributor = 'distributor',
  NotRequired = 'none',
}
