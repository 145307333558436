import React from 'react';
import { Autocomplete } from '@producepay/pp-ui';
import { Field, useFormikContext } from 'formik';
import cx from 'classnames';
import { kebabCase } from 'lodash';

export interface SelectItem {
  label: string;
  value: string;
}

interface FormikAutocompleteProps {
  items: SelectItem[];
  name: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onItemSelected?: (i: SelectItem) => void;
  className?: string;
  initialSearchTerm?: string;
  size?: 'small' | 'medium';
  isDisabled?: boolean;
}

const FormikAutocomplete = ({
  items,
  name,
  className,
  initialSearchTerm,
  onItemSelected,
  onChange,
  ...props
}: FormikAutocompleteProps) => {
  const { status, setFieldTouched, setFieldValue } = useFormikContext();
  const readOnly = status?.readOnly ?? false;

  const handleFocus = () => {
    setFieldTouched(name, true);
  };

  const defaultOnItemSelected = value => {
    setFieldValue(name, value);
  };

  /* TODO
     - Generalize onChange
     - Handle mapping of field.value to item labels
     - Handle clearing value
     - Handle maintaining focus when field is cleared
  */

  return (
    <Field name={name}>
      {({ meta: { touched, error } }) => {
        const computedClassName = cx(className, 'relative', {
          'border rounded border-red-500': !touched && error,
        });

        return (
          <>
            <Autocomplete
              items={items}
              onChange={onChange}
              onItemSelected={onItemSelected ?? defaultOnItemSelected}
              initialSearchTerm={initialSearchTerm ?? ''}
              onFocus={handleFocus}
              className={computedClassName}
              data-testid={kebabCase(name)}
              isDisabled={readOnly}
              {...props}
            />
            {!touched && error && <div className="absolute pt-1 text-xs text-red-600 w-full">{error}</div>}
          </>
        );
      }}
    </Field>
  );
};

export default React.memo(FormikAutocomplete);
