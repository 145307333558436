import { format, parseISO, formatISO } from 'date-fns';
import _ from 'lodash';

const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export function getUTCDate(dateString = Date.now()) {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export function gqlF(date) {
  return format(date, 'yyyy-MM-dd');
}

export function monthNumToName(monthNum) {
  return MONTHS[monthNum] || '';
}
export function monthNameToNum(name) {
  const month = MONTHS.indexOf(name);
  return month || 0;
}

// Convert a date string in the format YYYY-MM-DD 00:00:00 UTC to YYYY-MM-DD 07:00:00
// Useful for Nivo
export function utcDateStrToTimeZoneOffset(dateStr) {
  const timezoneOffset = new Date().getTimezoneOffset();
  const hoursOffset = _.padStart(Math.floor(timezoneOffset / 60), 2, '0');
  const minsOffset = _.padStart(timezoneOffset % 60, 2, '0');

  return dateStr.replace('00:00:00 UTC', `${hoursOffset}:${minsOffset}:00`);
}

export function dateTimeStringToDate(datetimeStr) {
  return formatISO(parseISO(datetimeStr), { representation: 'date' });
}

export function safeFormatISO(value, representation = 'date') {
  return value ? formatISO(value, { representation }) : null;
}
