import React from 'react';
import cx from 'classnames';
import { startCase } from 'lodash';

import { Field } from 'formik';
import styles from './styles.module.css';

export interface RadioOption {
  label: string;
  value: string | null;
  selected?: boolean;
}

interface FormikRadioGroupProps {
  options: RadioOption[];
  name: string;
  className?: string;
  id?: string;
  label?: string;
}

const RadioButton = props => <input type="radio" {...props} />;

const FormikRadioGroup = ({ options, className, name, label, id = name }: FormikRadioGroupProps): JSX.Element => {
  React.useEffect(() => {
    const selectedOption = options.find(option => option.selected);
    selectedOption && document.getElementById(selectedOption.value).setAttribute('checked', 'true');
  }, [options]);

  return (
    <div className={cx(className)}>
      <label className="form-label text-xs font-bold tracking-wide bg-white text-grey-darker" htmlFor={id}>
        {label || startCase(name)}
      </label>
      <div id={id} className={styles.radioGroup}>
        {options.map(option => (
          <div key={option.value} className="flex gap-2 items-center">
            <Field name={name} as={RadioButton} id={option.value} value={option.value} />
            <label className="text-sm" htmlFor={option.value}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormikRadioGroup;
