import Decimal from 'decimal.js';
import { map } from 'lodash';

import { Source } from 'src/routes/Company/CompanyBalances/types';

export function isDecimal(value: string): boolean {
  const decimalFilter = /^\d+(\.?\d{1,2})?$/;
  return decimalFilter.test(value);
}

export function getSourcesSum(sources: Source[]): Decimal.Value {
  return Decimal.sum(0, ...map(sources, 'balance'));
}
