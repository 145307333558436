import React, { createRef } from 'react';

import { useAuth } from 'src/contexts/auth/AuthProvider';

type AuthenticatedLinkProps = {
  children: React.ReactNode;
  fileName: string;
  url: string;
};

// https://blog.mellisdesigns.com/react-authenticated-file-downloads/
export const AuthenticatedLink = ({ url, fileName, children }: AuthenticatedLinkProps) => {
  const link = createRef<HTMLAnchorElement>();
  const { getTokenSilently } = useAuth();

  const handleAction = async () => {
    if (link.current.href) {
      return;
    }

    const token = await getTokenSilently();

    const result = await fetch(url, {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    });

    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);

    link.current.download = fileName;
    link.current.href = href;

    link.current.click();
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="font-semibold text-avocado-600"
      ref={link}
      role="button"
      onClick={handleAction}
      onKeyDown={handleAction}
      tabIndex={0}
    >
      {children}
    </a>
  );
};

export default AuthenticatedLink;
