import gql from 'graphql-tag';
import { PRODUCE_PAY_COMPANY_IDENTIFIER } from 'src/helpers/products';
import FUNDING_ACCOUNT from '../fragments/fundingAccount';

export default gql`
  ${FUNDING_ACCOUNT}
  query SourceAccountConfiguration {
    sourceAccounts: fundingAccounts(companyIdentifier: "${PRODUCE_PAY_COMPANY_IDENTIFIER}", status: "active") {
      ...fundingAccountFields
    }
  }
`;
