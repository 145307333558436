import React from 'react';
import cx from 'classnames';

interface Tab {
  name: string;
  id: string | number;
  disabled?: boolean;
  component: JSX.Element;
}

interface TabGroupProps {
  tabs: Tab[];
  id?: string | number;
  className?: string;
  defaultTabId?: string;
}

const TabGroup = ({ tabs, id, className, defaultTabId, ...props }: TabGroupProps): JSX.Element => {
  const [activeTab, setActiveTab] = React.useState(() =>
    defaultTabId ? tabs.find(tab => tab.id === defaultTabId) : tabs[0],
  );

  const handleTabClick = (tabId: string | number): void => {
    setActiveTab(tabs.find(tab => tab.id === tabId));
  };

  return (
    <div id={String(id)} className={cx('flex flex-col gap-7', className)}>
      <div className="flex w-full">
        {tabs.map(tab => (
          <button
            key={tab.id}
            id={String(tab.id)}
            className={cx(
              'h-10 font-normal first:rounded-l last:rounded-r border-gray-200 flex-grow focus:outline-none',
              activeTab.id === tab.id
                ? 'border-cerulean bg-cerulean bg-opacity-10 text-cerulean border-2'
                : ' border-l border-t border-b last:border-r',
              tab.disabled && 'cursor-not-allowed bg-gray-50',
            )}
            onClick={() => handleTabClick(tab.id)}
            type="button"
            disabled={tab.disabled}
            {...props}
          >
            {tab.name}
          </button>
        ))}
      </div>
      {activeTab.component}
    </div>
  );
};

export default TabGroup;
