import React from 'react';
import { Card } from '@producepay/pp-ui';

import { DocList } from 'src/routes/Deals/components/DocList';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';

export const DocumentRequirements = () => {
  return (
    <Card className="pb-6">
      <SectionHeader title="Document Requirements" subtitle="Default documents needed to enable payment" />
      <SectionItem
        title="Proof of Intent to Sell or Purchase"
        subtitle="User must provide one of the documents in the category, if required:"
        border={false}
      />
      <DocList
        items={[
          {
            name: 'Manifest',
            formLocation: 'payment.requiredItems.0.type',
          },
          {
            name: 'Purchase Order',
            formLocation: 'payment.requiredItems.1.type',
          },
        ]}
      />
    </Card>
  );
};
