import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, TextField } from '@producepay/pp-ui';
import SearchIcon from '@producepay/pp-ui/dist/components/icons/Search';

import Paginator from 'src/components/molecules/Paginator';
import DataTable from 'src/components/molecules/DataTable';
import { SEARCH_DEBOUNCE_MS, useDebounce } from 'src/helpers/common';
import * as routes from 'src/routes';
import { GetDeals, GetDealsVariables } from 'src/routes/DealsList/graphql-types/GetDeals';
import { DealsOrderByDirection, DealsOrderByField } from 'src/globalTypes';
import CreateDeal from './CreateDeal';

export const GET_DEALS = gql`
  query GetDeals($filters: DealsFilters, $limit: Int!, $offset: Int!, $orderBy: DealsOrderBy!) {
    deals(filters: $filters, limit: $limit, offset: $offset, orderBy: $orderBy) {
      nodes {
        dealUuid
        dealReference
        productLine {
          name
        }
        effectiveOn
      }
      totalCount
    }
  }
`;

const columns = [
  {
    name: 'dealReference',
    displayName: 'Deal ID',
  },
  {
    name: 'productLine',
    displayName: 'Product Line',
    formatter: col => col.name,
  },
  {
    name: 'effectiveOn',
    displayName: 'Effective On',
  },
];

const DEFAULT_PAGE_SIZE = 30;

export function DealsList() {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const searchDebounced = useDebounce(search, SEARCH_DEBOUNCE_MS);

  const [deals, setDeals] = useState([]);
  const { data, loading, refetch } = useQuery<GetDeals, GetDealsVariables>(GET_DEALS, {
    variables: {
      filters: { search: searchDebounced },
      limit: perPage,
      offset: perPage * (page - 1),
      orderBy: { direction: DealsOrderByDirection.DESC, field: DealsOrderByField.DEAL_REFERENCE },
    },
  });

  useEffect(() => {
    if (!loading) {
      setDeals(data?.deals?.nodes ?? []);
      setTotalCount(data?.deals?.totalCount ?? 0);
    }
  }, [loading, setTotalCount, data]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSearch = (searchInput: string) => {
    setSearch(searchInput);
    setPage(1);
  };

  const handleRowClick = row => {
    history.push(routes.dealsEdit(row.dealUuid));
  };

  return (
    <>
      <CardHeader
        className="bg-white justify-between"
        titleClassName="h1"
        title="Deals"
        headerComponents={<CreateDeal />}
      />
      <Card>
        <div className="w-1/3 px-8 py-4">
          <TextField
            placeholder="Search for deal ID, company"
            iconSuffix={<SearchIcon size="16" color="#ffffff" />}
            color="primary"
            value={search}
            onChange={e => {
              handleSearch(e.target.value);
            }}
          />
        </div>
      </Card>

      <div className="flex flex-row-reverse pt-4 px-8">
        <Paginator setPage={setPage} page={page} totalCount={totalCount} perPage={perPage} setPerPage={setPerPage} />
      </div>

      <div className="pt-4 px-8">
        <Card>
          <DataTable
            columns={columns}
            keyName="dealUuid"
            rows={deals}
            tdClass="p-4 border-b-1 text-base"
            thClass="bg-white px-4 pt-5"
            trClass="cursor-pointer"
            onClick={handleRowClick}
          />
        </Card>
      </div>

      <div className="flex flex-row-reverse py-4 px-8">
        <Paginator setPage={setPage} page={page} totalCount={totalCount} perPage={perPage} setPerPage={setPerPage} />
      </div>
    </>
  );
}
