import React from 'react';
import { Formik } from 'formik';

import { PSValues } from 'src/routes/Deals/PS/types';
import { PSDealNavigation } from '../components/PSDealNavigation';
import { FormAction } from '../../constants';
import { FormikDealStatus, OnDealSave } from '../../types';

interface PSFormProps {
  action: FormAction;
  onDealSave: OnDealSave;
}

interface FormikPSFormProps extends PSFormProps {
  initialValues: PSValues;
  initialStatus: FormikDealStatus;
}

const PSForm = ({ action, onDealSave }: PSFormProps) => {
  return (
    <form onSubmit={e => e.preventDefault()}>
      <PSDealNavigation action={action} onDealSave={onDealSave} />
    </form>
  );
};

export default ({ action, initialStatus, initialValues, onDealSave }: FormikPSFormProps) => {
  return (
    <Formik
      initialStatus={initialStatus}
      initialValues={initialValues}
      onSubmit={null}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      <PSForm action={action} onDealSave={onDealSave} />
    </Formik>
  );
};
