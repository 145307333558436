import React from 'react';
import EscrowFilterBar, { EscrowFilters } from '../FilterBar/EscrowFilterBar';
import Header from '../Header';

const Escrow = () => {
  const [filters, setFilters] = React.useState<EscrowFilters>({});

  return (
    <div>
      <Header />
      <EscrowFilterBar options={filters} onFilter={setFilters} />
      <div>Escrow</div>
    </div>
  );
};

export default Escrow;
