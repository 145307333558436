import gql from 'graphql-tag';

const GET_ORDERS = gql`
  query GetCompanyOrders(
    $companyIds: [String!]
    $legacyCompanyIds: [String!]
    $page: Int
    $perPage: Int
    $search: String
  ) {
    getOrders(
      companyIds: $companyIds
      legacyCompanyIds: $legacyCompanyIds
      page: $page
      perPage: $perPage
      search: $search
    ) {
      pageInfo {
        totalResults
        lastPage
        page
        perPage
      }
      orders {
        id
        slug
        referenceNumber
        orderStatus
        bookedDate
        updatedAt
        isFixedPrice
        caseCount
        suppliers {
          legacyId
          name
          role
        }
        receivers {
          legacyId
          name
          role
        }
      }
    }
  }
`;

export default GET_ORDERS;
