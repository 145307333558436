const companyTransactions = [
  {
    companyName: 'Sunny Fruit Farms',
    companyUuid: 'fake-uuid-1',
    orders: [
      { slug: 'order-slug-18', number: '#OrderNumberHere', netValue: 12000, netBalance: 5000 },
      { slug: 'order-slug-17', number: '#3168769', netValue: 7000, netBalance: 5000 },
    ],
  },
  {
    companyName: 'Very Berry Blue Farms',
    companyUuid: 'fake-uuid-2',
    orders: [{ slug: 'order-slug-19', number: '#OrderNumberHere', netValue: 12000, netBalance: 5000 }],
  },
  {
    companyName: 'Dandy Distribution Co.',
    companyUuid: 'fake-uuid-3',
    orders: [],
    payee: true,
  },
];

const receivedPayment = {
  amount: 8000,
  unreconciledReceivedAmount: 4500,
  recipientAccount: {
    institutionName: 'Wells Fargo',
    name: 'WF Client 1',
  },
  sourceAccount: {
    institutionName: 'Bank of America',
    name: 'Dandy Distribution Co.',
  },
  receivedDate: new Date('12/10/2021'),
  transactionDescription: 'Check #123',
};

export { companyTransactions, receivedPayment };
