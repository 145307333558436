import dateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

import { DATEPICKER_FORMAT } from 'src/routes/Deals/constants';
import { DefaultBankAccounts, DestinationAccount, SourceAccount } from 'src/routes/Deals/types';
import { ISPPCompany, ISPPGroupedCompanies, ISPPValues, ISPPValuesPayload } from 'src/routes/Deals/ISPP/types';
import { safeDiv, safeMul } from 'src/routes/Deals/helpers';
import { safeFormatISO } from 'src/helpers/dates';

/**
 * Since our form adds defaulted values to make it easier for the user,
 * we should remove those defaults before validation
 */
export const removeDefaultEntities = (values: ISPPValues): ISPPValues => {
  // filter any extra empty distributor companies, allow 1 empty company to pass & be caught by validation
  const distributors = (values.companies?.distributors ?? []).filter(
    (c, index) => !isEmpty(c.identifier) || !isEmpty(c.contactName) || index === 0,
  );

  // same for producers, but only if dealType is producerDistributor. else clear all empty producer companies
  const producers = (values.companies?.producers ?? []).filter(
    (c, index) =>
      !isEmpty(c.identifier) ||
      !isEmpty(c.contactName) ||
      (values.ui.dealType === 'producerDistributor' && index === 0),
  );

  const rebates = values.rebates.filter(r => !isEmpty(r.amount) || !isEmpty(r.transactionVolumeThreshold));

  return {
    ...values,
    companies: {
      ...values.companies,
      distributors,
      producers,
    },
    rebates,
  };
};

/**
 * The form's `companies` key has a different schema for the form vs. api
 * to make it easier for each
 *
 * Form:
 * {
 *    companies: {
 *      distributors: [],
 *      producers: [],
 *    }
 * }
 *
 * API:
 * {
 *    companies: []
 * }
 */

const transformCompanyData = (
  companies: ISPPGroupedCompanies,
  sourceAccounts: SourceAccount[],
  receiverRole: string,
): {
  companies: ISPPCompany[];
  defaultBankAccounts: DefaultBankAccounts;
} => {
  const receiverCompanies = companies[receiverRole];
  const destinationAccounts: DestinationAccount[] = flatten(
    receiverCompanies?.map(c => {
      let priority = 0;
      return c.fundingAccounts?.map(a => {
        priority += 1;
        return {
          companyIdentifier: c.identifier,
          fundingAccountIdentifier: a.accountId,
          type: a.type,
          value: a.value,
          priority,
        };
      });
    }),
  );

  return {
    companies: reduce(
      companies,
      (result, value) => {
        return [...result, ...value];
      },
      [],
    ),
    defaultBankAccounts: {
      sourceAccounts,
      destinationAccounts,
    },
  };
};

export const transformToPayload = (data: ISPPValues): ISPPValuesPayload => {
  return {
    ...data,
    effectiveOn: data.effectiveOn ? safeFormatISO(new Date(data.effectiveOn)) : null,
    endsOn: data.endsOn ? safeFormatISO(new Date(data.endsOn)) : null,
    ...transformCompanyData(data.companies, data.defaultBankAccounts.sourceAccounts, data.payment.receiverRole),
    payment: {
      ...data.payment,
      initialRate: safeDiv(data.payment.initialRate, 100),
      secondaryRate: safeDiv(data.payment.secondaryRate, 100),
      receiverRole: data.payment.receiverRole ? data.payment.receiverRole.slice(0, -1) : null,
    },
    distributionFee: {
      ...data.distributionFee,
      feeRatio: safeDiv(data.distributionFee.feeRatio, 100),
      unitRate: safeDiv(data.distributionFee.unitRate, 100),
    },
  };
};

export const transformToValues = (dealConfig: ISPPValuesPayload, dealUuid: string, status: string): ISPPValues => {
  return {
    ...dealConfig,
    dealUuid,
    status: status.toLowerCase(),
    companies: groupBy(dealConfig.companies, c => `${c.role}s`) as unknown as ISPPGroupedCompanies,
    payment: {
      ...dealConfig.payment,
      initialRate: safeMul(dealConfig.payment.initialRate, 100),
      secondaryRate: safeMul(dealConfig.payment.secondaryRate, 100),
      receiverRole: dealConfig?.payment?.receiverRole ? `${dealConfig?.payment?.receiverRole}s` : null,
    },
    distributionFee: {
      ...dealConfig.distributionFee,
      feeRatio: safeMul(dealConfig.distributionFee.feeRatio, 100),
      unitRate: safeMul(dealConfig.distributionFee.unitRate, 100),
    },
    effectiveOn: dealConfig.effectiveOn
      ? `${dateFnsFormat(parseISO(dealConfig.effectiveOn).getTime(), DATEPICKER_FORMAT)}`
      : null,
    endsOn: dealConfig.endsOn ? `${dateFnsFormat(parseISO(dealConfig.endsOn).getTime(), DATEPICKER_FORMAT)}` : null,
  };
};
