import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { TextField } from '@producepay/pp-ui';
import kebabCase from 'lodash/kebabCase';
import noop from 'lodash/noop';

import SimpleErrorMessageWrapper from '../SimpleErrorMessageWrapper';
import Wrapper from '../FormikInputWrapper';

/** FormikTextField
 * Wrapper for wiring up a TextField field to the Formik Context.
 * Defers to `FormikInputWrapper` which adds labels and validation
 * error printing.
 */

const FormikTextField = ({
  className = '',
  name,
  disabled = false,
  label = null,
  type = 'text',
  inputProps = { className: '' } as { [key: string]: unknown },
  iconPrefix = null,
  iconSuffix = null,
  withSimpleErrorStyling = false,
  errorClearable = true,
  validate = null,
  ...rest
}) => {
  const { setFieldTouched, status } = useFormikContext();

  const readOnly = status?.readOnly ?? false;
  const shouldUseSimpleErrorMessage = label ? false : withSimpleErrorStyling;

  const handleFocus = () => {
    setFieldTouched(name, true);
  };

  const validateFn = validate ?? noop;
  return (
    <Field name={name} validate={validateFn}>
      {({ field, form, meta: { error, touched } }) => {
        let textFieldClassNames = inputProps?.className || '';

        if (withSimpleErrorStyling && error && !touched) {
          textFieldClassNames = `${textFieldClassNames} border-red-500`;
        }

        const textField = (
          <TextField
            aria-labelledby={`form-label-${name}`}
            size="small"
            type={type}
            name={name}
            disabled={readOnly || disabled}
            {...field}
            onFocus={handleFocus}
            iconPrefix={readOnly && iconPrefix ? <span className="opacity-50">{iconPrefix}</span> : iconPrefix}
            iconSuffix={readOnly && iconSuffix ? <span className="opacity-50">{iconSuffix}</span> : iconSuffix}
            {...inputProps}
            className={textFieldClassNames}
            value={field.value || ''} // must come after field spread to overwrite
            data-testid={kebabCase(name)}
            {...rest}
          />
        );

        if (shouldUseSimpleErrorMessage) {
          return (
            <SimpleErrorMessageWrapper className={className} error={error} cleared={errorClearable && touched}>
              {textField}
            </SimpleErrorMessageWrapper>
          );
        }

        return (
          <Wrapper className={`${className} pb-4`} name={name} form={form} label={label}>
            {textField}
          </Wrapper>
        );
      }}
    </Field>
  );
};

FormikTextField.propTypes = {
  ...TextField.propTypes,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
  withSimpleErrorStyling: PropTypes.bool,
};

FormikTextField.defaultProps = {
  label: null,
  inputProps: { className: '' },
  withSimpleErrorStyling: false,
};

export default React.memo(FormikTextField);
