import React from 'react';
import { useFormikContext } from 'formik';
import { Card, Grid } from '@producepay/pp-ui';

import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';
import PaymentsRecipient from 'src/routes/Deals/components/PaymentsRecipient';
import { PNMSDocumentAcceptanceRole, PNMSRole, PNMSValues } from 'src/routes/Deals/PNMS/types';

const DOCUMENT_ACCEPTANCE_ITEMS = [
  { label: 'Distributor', value: PNMSDocumentAcceptanceRole.Distributor },
  { label: 'Distributor or ProducePay', value: PNMSDocumentAcceptanceRole.DistributorOrProducePay },
];

const RECEIVER_ROLE_ITEMS = [
  { label: 'Producer', value: PNMSRole.Producer },
  { label: 'Distributor', value: PNMSRole.Distributor },
];

function PaymentRecipients() {
  const {
    values: { companies, payment },
  } = useFormikContext<PNMSValues>();

  if (!payment.receiverRole) {
    return <div>Select a Recipient Role</div>;
  }

  const paymentReceivingCompanies = companies[payment.receiverRole];

  return (
    <>
      {paymentReceivingCompanies.map((c, i) => (
        <PaymentsRecipient key={c.key} company={c} receiverRole={payment.receiverRole} index={i} />
      ))}
    </>
  );
}

export function Payments() {
  return (
    <Card className="pb-4">
      <SectionHeader title="Payments" subtitle="Default financial settings for payments" />
      <SectionItem
        title="Payment Ratio"
        subtitle="Percentage of estimated manifest or purchase order value"
        controls={
          <FormikTextField
            name="payment.paymentRate"
            iconSuffix={<span>%</span>}
            inputProps={{ className: 'text-right' }}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title="Document Acceptance"
        subtitle="Party responsible for accepting the provided document(s) in order to access payment"
        controls={
          <FormikSelect
            name="payment.documentAcceptanceRole"
            items={DOCUMENT_ACCEPTANCE_ITEMS}
            withSimpleErrorStyling
          />
        }
        border={false}
      />
      <div className="px-4 mx-4 mt-2 pb-4">
        <Grid container className="border-1 rounded mt-6">
          <div className="bg-gray-100 p-4 font-bold w-full rounded rounded-b-none flex flex-row justify-between">
            <div>Payment Recipient</div>
            <div className="sm:w-1/4">
              <FormikSelect
                name="payment.receiverRole"
                placeholder="Select Recipient Role"
                shouldShowDefault={false}
                items={RECEIVER_ROLE_ITEMS}
                withSimpleErrorStyling
              />
            </div>
          </div>
          <Grid className="p-4">
            <PaymentRecipients />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
