import React from 'react';
import { Card } from '@producepay/pp-ui';
import { DocList } from 'src/routes/Deals/components/DocList';
import SectionItem from 'src/routes/Deals/components/SectionItem';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';

export const DocumentRequirements = () => {
  return (
    <Card className="pb-6">
      <SectionHeader title="Document Requirements" subtitle="Default documents needed to enable payment" />
      <SectionItem
        title="Proof of Delivery"
        subtitle="User must provide any document that is required:"
        border={false}
      />
      <DocList
        items={[
          {
            name: 'Signed Bill of Lading',
            formLocation: 'payment.requiredItems.0.type',
          },
        ]}
      />
      <SectionItem
        title="Proof of Load Value"
        subtitle="User must provide any document that is required:"
        border={false}
      />
      <DocList
        items={[
          {
            name: 'PACA Invoice',
            formLocation: 'payment.requiredItems.1.type',
          },
        ]}
      />
      <SectionItem title="Other" subtitle="User must provide any document that is required:" border={false} />
      <DocList
        items={[
          {
            name: 'Notice of Intent',
            formLocation: 'payment.requiredItems.2.type',
          },
          {
            name: 'Bill of Sale',
            formLocation: 'payment.requiredItems.3.type',
          },
        ]}
      />
    </Card>
  );
};
