import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { uniqBy, sortBy } from 'lodash';
import { Button, Grid, Select, TextField } from '@producepay/pp-ui';

import GET_SEND_PAYMENTS_DATA from 'src/graphql/queries/getSendPaymentsData';
import { FilterAction, NormalizedFilterProperties, ReducerTypes } from './filterReducer';

interface FilterBarProps {
  filterState: NormalizedFilterProperties;
  dispatch: (action: FilterAction) => void;
}

const PURPOSE_OPTIONS = [
  { label: 'All payment types', value: null },
  { label: 'Prepayment', value: 'prepayment' },
  { label: 'Balance Return', value: 'balance_return' },
];

const SHOW_SUSPENDED_OPTIONS = [
  { label: 'Active companies', value: false },
  { label: 'All companies', value: true },
];

const selectStyle = { caretStyle: { padding: 7 } };

// Look at everything that might show up on Send Payments and generate a companies list
// to be used by the filter.
const getPayableCompanies = data => {
  const companies = [];
  if (data) {
    [...data.balanceReturns, ...data.prepaymentProducts, ...data.outboundPaymentItems].forEach(item => {
      companies.push({ label: item.recipientCompanyName, value: item.recipientCompanyIdentifier });
      if ('liableCompanyIdentifier' in item) {
        companies.push({ label: item.liableCompanyName, value: item.liableCompanyIdentifier });
      }
    });
  }
  return sortBy(uniqBy(companies, 'value'), 'label').filter(({ value }) => value);
};

const FilterBar = ({ filterState, dispatch }: FilterBarProps) => {
  const { data } = useQuery(GET_SEND_PAYMENTS_DATA, { fetchPolicy: 'cache-only' });
  const [payableCompanies, setPayableCompanies] = useState([]);
  useEffect(() => {
    setPayableCompanies(getPayableCompanies(data));
  }, [data, setPayableCompanies]);

  return (
    <Grid className="bg-white py-1 px-2 border-b-1 border-t-1 text-sm" container>
      <Grid sm="1/6">
        <Select
          items={PURPOSE_OPTIONS}
          selectedItem={PURPOSE_OPTIONS.find(({ value }) => value === filterState.purpose)}
          onChange={({ value }) => dispatch({ type: ReducerTypes.SET_PURPOSE, purpose: value })}
          {...selectStyle}
        />
      </Grid>
      <Grid sm="1/6">
        <Select
          items={SHOW_SUSPENDED_OPTIONS}
          selectedItem={SHOW_SUSPENDED_OPTIONS.find(({ value }) => value === filterState.suspended)}
          onChange={({ value }) => dispatch({ type: ReducerTypes.SET_SHOW_SUSPENDED, suspended: value })}
          {...selectStyle}
        />
      </Grid>
      <Grid sm="1/3">
        <Select
          className="text-left whitespace-nowrap overflow-hidden"
          isMultiple
          isSearchable
          items={payableCompanies}
          selectedItems={payableCompanies.filter(({ label }) => filterState.companyNames.includes(label))}
          onChange={items =>
            dispatch({ type: ReducerTypes.SET_COMPANIES, companyNames: items.map(({ label }) => label) })
          }
          multipleItemLabel="companies"
          placeholder="Company"
          {...selectStyle}
        />
      </Grid>
      <Grid sm="1/3" className="flex flex-row">
        <TextField
          className="flex-shrink-1"
          placeholder="Search by slug, ID, inv / ref #"
          onChange={evt => dispatch({ type: ReducerTypes.SET_SEARCH, search: evt.target.value })}
          size="small"
          value={filterState.search || ''}
          style={{ height: 32 }}
        />
        <Button
          className="font-bold py-2 pl-4 pr-2 flex-shrink-0"
          color="primary"
          onClick={() => dispatch({ type: ReducerTypes.RESET_FILTERS })}
          variant="text"
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterBar;
