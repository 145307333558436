import React, { FC } from 'react';

interface TableToolbarProps {
  title: string;
  Actions?: FC;
  ActionsProps?: unknown;
}

export const TableToolbar: FC<TableToolbarProps> = ({ title, Actions, ActionsProps = {} }) => (
  <div className="p-4 flex justify-between">
    <div>
      <div className="h3">{title}</div>
    </div>
    {Actions && (
      <div>
        <Actions {...ActionsProps} />
      </div>
    )}
  </div>
);
