import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LoadingSpinner, TextField, Select } from '@producepay/pp-ui';
import { Search } from 'react-feather';

import GET_COMPANIES from 'src/graphql/queries/getCompanies';
import * as routes from 'src/routes';

import DataTable, { DataTableColumn, DataTableStyleWide } from 'src/components/molecules/DataTable';
import Paginator from 'src/components/molecules/Paginator';
import DotIndicator from 'src/components/elements/DotIndicator';
import Card from 'src/components/elements/Card';

const COLUMNS: DataTableColumn<Company>[] = [
  {
    displayName: 'Company',
    name: 'name',
    formatter: (name, company) => (
      <>
        <span className={`${company.isSuspended ? 'text-cherry' : 'text-basil'} inline-block`}>
          <DotIndicator />
        </span>
        <Link className="text-base" to={routes.company.dashboard(company.identifier)}>
          {name}
        </Link>
      </>
    ),
  },
];

const COMPANY_STATUSES = [
  { label: 'All Companies', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Suspended', value: 'suspended' },
];

function CompaniesList() {
  const { loading, data } = useQuery(GET_COMPANIES);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [comparator, setComparator] = useState({ current: () => 1 });
  const [search, setSearch] = useState('');
  const [currentStatus, setCurrentStatus] = useState(COMPANY_STATUSES[0]);

  useEffect(() => setPage(1), [search]);

  const handleSetComparator = useCallback(
    comp => {
      comp && setComparator({ current: comp });
    },
    [setComparator],
  );

  if (loading) {
    return <LoadingSpinner centered className="py-32" />;
  }

  const companies: Company[] = [...data.companies];
  const searchResults = companies
    .filter(company => {
      if (currentStatus.value === 'all') {
        return true;
      }
      if (currentStatus.value === 'suspended') {
        return company.isSuspended;
      }
      return !company.isSuspended;
    })
    .filter(company => company.name.toLowerCase().includes(search.toLowerCase()));

  searchResults.sort(comparator.current);
  const slicedRows = searchResults.slice((page - 1) * perPage, page * perPage);

  const Pagination = () => (
    <Paginator
      page={page}
      perPage={perPage}
      setPage={setPage}
      setPerPage={setPerPage}
      totalCount={searchResults.length}
    />
  );

  return (
    <>
      <div className="flex flex-row justify-between py-2 overflow-hidden">
        <TextField
          className="md:w-1/3"
          value={search}
          onChange={e => setSearch(e.target.value)}
          size="small"
          iconPrefix={<Search size={16} />}
          placeholder="Search for company"
        />
        <div className="md:w-1/6 px-2">
          <Select items={COMPANY_STATUSES} selectedItem={currentStatus} onChange={e => setCurrentStatus(e)} />
        </div>
        <Pagination />
      </div>
      <Card className="overflow-hidden">
        <DataTable
          columns={COLUMNS}
          keyName="identifier"
          onSortChanged={handleSetComparator}
          rows={slicedRows}
          sortable
          {...DataTableStyleWide}
        />
      </Card>
      <div className="flex flex-row-reverse py-2 overflow-hidden">
        <Pagination />
      </div>
    </>
  );
}

export default CompaniesList;
