import * as Yup from 'yup';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import { PNMCompany, PNMValues } from 'src/routes/Deals/PNM/types';
import { decimalString } from '../helpers';

const companySchema: Yup.SchemaOf<PNMCompany> = Yup.object().shape({
  identifier: Yup.string().nullable().required('Company is required'),
  role: Yup.mixed().oneOf(['member']),
  contactName: Yup.string().nullable(),
  name: Yup.string().nullable(),
  key: Yup.string().nullable(),
});

const companyActivationSchema: Yup.SchemaOf<PNMCompany> = companySchema.shape({
  contactName: Yup.string().nullable().required('Contact Name is required'),
});

const validateMembershipFee = ({ datePaid, amountPaid, methodPaid }, { path, createError }) => {
  // return true if all values are true or all are false
  if (every([datePaid, amountPaid, methodPaid], (item, _, arr) => !!item === !!arr[0])) {
    return true;
  }

  const errors = [];

  if (isEmpty(datePaid)) {
    errors.push(
      createError({
        path: `${path}.datePaid`,
        message: 'Paid On is required',
      }),
    );
  }
  if (isEmpty(amountPaid)) {
    errors.push(
      createError({
        path: `${path}.amountPaid`,
        message: 'Amount is required',
      }),
    );
  }
  if (isEmpty(methodPaid)) {
    errors.push(
      createError({
        path: `${path}.methodPaid`,
        message: 'Payment Method is required',
      }),
    );
  }

  if (errors.length) {
    throw new Yup.ValidationError(errors);
  }

  return true;
};

export const saveDraftSchema: PNMValidationSchema = Yup.object().shape({
  companies: Yup.array().of(companySchema),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  effectiveOn: Yup.string().nullable(),
  endsOn: Yup.string().nullable(),
  dealUuid: Yup.string().nullable(),
  membershipFee: Yup.object().shape({
    amountDue: decimalString('Amount Due'),
    amountPaid: decimalString('Amount Paid'),
    datePaid: Yup.string().nullable(),
    methodPaid: Yup.string().nullable(),
  }),
  productLineId: Yup.number().required(),
  ratingTier: Yup.string().nullable(),
  status: Yup.string(),
});

export const activationSchema: PNMValidationSchema = saveDraftSchema.shape({
  companies: Yup.array().of(companyActivationSchema),
  effectiveOn: Yup.string().nullable().required('Effective Date is required'),
  membershipFee: Yup.object()
    .shape({
      amountDue: decimalString('Amount Due').required('Membership Fee is required'),
      amountPaid: decimalString('Amount Paid'),
      datePaid: Yup.string().nullable(),
      methodPaid: Yup.string().nullable(),
    })
    .test(validateMembershipFee),
  ratingTier: Yup.string().nullable().oneOf(['unverified', 'verified', 'gold_verified'], 'Rating Tier is required'),
});

export type PNMValidationSchema = Yup.SchemaOf<PNMValues>;
