import differenceInDays from 'date-fns/differenceInDays';
import { Info as InfoIcon } from 'react-feather';
import React, { useMemo } from 'react';
import { useFlexLayout, useTable } from 'react-table';
import { useHistory, useParams } from 'react-router-dom';

import * as routes from 'src/routes';
import Tooltip from 'src/components/elements/Tooltip';
import { getCounterparty } from './helpers';
import { AssetCompany, Order } from '../types';
import StatusCell from '../StatusCell';

import './index.css';

type OrdersTableProps = {
  data: Order[];
};

const OrdersTable = ({ data }: OrdersTableProps) => {
  const history = useHistory();
  const { companyIdentifier } = useParams();

  const columns = useMemo(() => {
    return [
      {
        Header: 'SLUG',
        Cell: ({ value }) => <span className="font-semibold text-avocado-600">{value}</span>,
        accessor: 'slug',
        minWidth: 250,
      },
      {
        Header: 'ORDER #',
        accessor: 'referenceNumber',
      },
      {
        Header: 'STATUS',
        Cell: ({ cell: { value } }) => <StatusCell status={value} />,
        accessor: 'orderStatus',
      },
      {
        Header: 'NAME',
        accessor: ({ receivers, suppliers }) => {
          const counterparty: AssetCompany = getCounterparty(suppliers, receivers, companyIdentifier);
          return counterparty ? counterparty.name : 'N/A';
        },
        minWidth: 225,
      },
      {
        Header: 'ROLE',
        accessor: ({ receivers, suppliers }) => {
          const counterparty: AssetCompany = getCounterparty(suppliers, receivers, companyIdentifier);
          return counterparty ? counterparty.role : 'N/A';
        },
      },
      {
        Header: () => (
          <div className="flex items-center">
            <span className="mr-1">CASE COUNT</span>
            <Tooltip tooltipText="Most updated shipped or accepted case count for the order">
              <InfoIcon size={12} />
            </Tooltip>
          </div>
        ),
        accessor: 'caseCount',
        minWidth: 155,
      },
      {
        Header: 'TERMS OF SALE',
        accessor: ({ isFixedPrice }) => (isFixedPrice ? 'Fixed Price' : 'Consignment'),
      },
      {
        Header: 'BOOKED DATE',
        Cell: ({ value }) => {
          if (!value) return '--';

          const daysAgo = differenceInDays(Date.now(), new Date(value));
          return (
            <>
              <span className="mr-2">{value}</span>
              <span className="rounded-lg px-2 py-1 bg-blueberry-200">{daysAgo}d ago</span>
            </>
          );
        },
        accessor: 'bookedDate',
        minWidth: 200,
      },
    ];
  }, [companyIdentifier]);

  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useFlexLayout,
  );

  const TableHead = () => (
    <thead>
      {headerGroups.map(headerGroup => {
        return (
          <tr className="bg-gray-light" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className="flex align-center py-3 px-4 text-left text-xs text-slate" {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        );
      })}
    </thead>
  );

  const TableBody = () => (
    <tbody {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const orderId = (row as any).original.id;
        return (
          <tr
            className="table-body--row cursor-pointer"
            onClick={() => history.push(routes.company.orders.overview(companyIdentifier, orderId))}
            {...row.getRowProps()}
          >
            {row.cells.map(cell => (
              <td className="py-2 px-4 text-left text-sm" {...cell.getCellProps()}>
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );

  const Table = () => (
    <table className="w-full" {...getTableProps()}>
      <TableHead />
      <TableBody />
    </table>
  );

  return (
    <div className="w-full overflow-x-scroll overflow-y-hidden">
      <Table />
    </div>
  );
};

export default OrdersTable;
