import * as Yup from 'yup';

import {
  PSFormSectionValues,
  PSForecastCommodity,
  PSForecastValue,
  PSPrepaymentValue,
  PSGeneralValues,
  PSValues,
} from 'src/routes/Deals/PS/types';
import { decimalString } from '../helpers';
import { PSEntityAction, RepaymentRule } from '../constants';

const skuComponentSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  uuid: Yup.string().nullable(),
});

const requiredSkuComponentSchema = Yup.object()
  .shape({
    name: Yup.string().nullable().required(' is required'),
    uuid: Yup.string().nullable().required(' is required'),
  })
  .required(' is required');

const getCompanySchema = (action: string, contactRole: string) =>
  Yup.object().shape({
    identifier: Yup.string()
      .when('role', {
        is: role => role === 'distributor',
        then: Yup.string().nullable().required('Distributor Company is required'),
        otherwise: Yup.string().nullable().required('Producer Company is required'),
      })
      .nullable()
      .required('Company Required'),
    role: Yup.mixed().oneOf(['producer', 'distributor']),
    contactName:
      action === 'activate'
        ? Yup.string().nullable().required(`${contactRole} Contact is required`)
        : Yup.string().nullable(),
    name: Yup.string().nullable(),
    key: Yup.string().nullable(),
    fundingAccounts: Yup.array().of(
      Yup.object().shape({
        accountId: Yup.string().nullable(),
        value: decimalString('Amount'),
        key: Yup.string().nullable(),
        type: Yup.string().nullable(),
      }),
    ),
  });

const getFeeSchema = (action: 'draft' | 'activate') => {
  const isDraft = action === 'draft';
  return Yup.object().shape({
    amountDue: isDraft
      ? decimalString('Application Fee')
      : decimalString('Application Fee').required('Application Fee is required'),
    amountPaid: decimalString('Amount Paid'),
    datePaid: Yup.string().nullable(),
    paymentMethods: Yup.array().of(
      Yup.object().shape({
        amount: isDraft ? decimalString('Amount') : decimalString('Amount').required('Amount is required'),
        method: isDraft ? Yup.string().nullable() : Yup.string().required('Payment Method is required'),
        key: Yup.string().nullable(),
      }),
    ),
    isWaived: Yup.boolean(),
    companyIdentifier: isDraft ? Yup.string().nullable() : Yup.string().nullable().required('Company is required'),
    key: Yup.string().nullable(),
  });
};

const getApplicationFeeSchema = (action: 'draft' | 'activate') => {
  return Yup.object().shape({
    minimumAnnualProduceVolumeForRefund:
      action === 'activate'
        ? decimalString('Minimum Annual Produce Volume').required('Minimum Annual Produce Volume is required')
        : decimalString('Minimum Annual Produce Volume'),
    isRefundable: Yup.boolean(),
    fees: Yup.array()
      .of(getFeeSchema(action))
      .min(action === 'activate' ? 1 : 0),
  });
};

export const repaymentRuleSchema = Yup.object().shape({
  type: Yup.mixed()
    .oneOf([RepaymentRule.Fixed, RepaymentRule.Percentage, RepaymentRule.PerUnit])
    .required('Type is required'),
  amountRate: Yup.number().when('type', {
    is: RepaymentRule.Percentage,
    then: Yup.number().nullable().required('is required'),
    otherwise: Yup.number().strip(),
  }),
  amount: Yup.number().when('type', {
    is: RepaymentRule.PerUnit,
    then: Yup.number().required('is required'),
    otherwise: Yup.number().strip(),
  }),
  units: Yup.array()
    .of(skuComponentSchema)
    .when('type', {
      is: RepaymentRule.PerUnit,
      then: Yup.array()
        .of(skuComponentSchema)
        .min(1, 'At least one unit is required')
        .required('At least one unit is required'),
      otherwise: Yup.array().of(skuComponentSchema).strip(),
    }),
  commodity: skuComponentSchema.when('type', {
    is: RepaymentRule.PerUnit,
    then: requiredSkuComponentSchema,
    otherwise: Yup.object(),
  }),
  variety: skuComponentSchema.when('type', {
    is: RepaymentRule.PerUnit,
    then: skuComponentSchema,
    otherwise: Yup.object(),
  }),
  size: skuComponentSchema.when('type', {
    is: RepaymentRule.PerUnit,
    then: skuComponentSchema,
    otherwise: Yup.object(),
  }),
  classification: Yup.string().when('type', {
    is: RepaymentRule.PerUnit,
    then: Yup.string(),
    otherwise: Yup.string().strip(),
  }),
});

const milestoneSchema = Yup.object({
  milestoneUuid: Yup.string(),
  action: Yup.mixed().oneOf([PSEntityAction.Create, PSEntityAction.Update, PSEntityAction.Delete]).nullable(),
  deadline: Yup.date().when('action', {
    is: PSEntityAction.Delete,
    then: Yup.date().nullable(),
    otherwise: Yup.date().nullable().required('Deadline is required'),
  }),
  gmvAmount: Yup.number().when('action', {
    is: PSEntityAction.Delete,
    then: Yup.number().nullable(),
    otherwise: Yup.number().nullable().required('Amount is required'),
  }),
});

export const forecastCommoditySchema: Yup.SchemaOf<PSForecastCommodity> = Yup.object().shape({
  forecastCommodityUuid: Yup.string().required('Forecast Commodity UUID is required'),
  commodity: requiredSkuComponentSchema,
  variety: skuComponentSchema,
  packaging: skuComponentSchema,
  size: skuComponentSchema,
  distributorUuid: Yup.string().nullable(),
  isOrganic: Yup.boolean().nullable(),
  action: Yup.mixed().oneOf([PSEntityAction.Create, PSEntityAction.Update, PSEntityAction.Delete]).nullable(),
});

const prepaymentSchema: Yup.SchemaOf<PSPrepaymentValue> = Yup.object({
  prepaymentUuid: Yup.string().nullable(),
  amount: decimalString().required('Required'),
  distributionFee: decimalString().required('Required'),
  dailyLateFee: decimalString().required('Required'),
  effectiveDate: Yup.date().typeError('Must be a date').required('Required'),
  dueDate: Yup.date().typeError('Must be a date').required('Required'),
  sourceBankAccountUuid: Yup.string().nullable().required('Required'),
  recipientBankAccountUuid: Yup.string().nullable().required('Required'),
}).nullable();

const forecastSchema: Yup.SchemaOf<PSForecastValue> = Yup.object({
  forecastUuid: Yup.string().nullable(),
  week: Yup.date().required('Required'),
  repayment: decimalString().required('Required'),
  totalGmv: decimalString().required('Required'),
  totalCases: Yup.string().nullable(),
  // Yup doesn't have built-in Dictionary-like validation, hence have to use lazy here
  // See https://github.com/jquense/yup/issues/524 for some better/faster solutions
  // (though they don't seem to work with latest yup)
  items: Yup.lazy(items => {
    return Yup.object(
      Object.fromEntries(
        Object.keys(items).map(key => [
          key,
          Yup.object({
            units: Yup.number().nullable().typeError('Must be a number').required('Required'),
          }),
        ]),
      ),
    );
  }) as unknown as Yup.SchemaOf<PSForecastValue['items']>,
}).nullable();

export const generalValidationSchema: Yup.SchemaOf<PSGeneralValues> = Yup.object().shape({
  productLineId: Yup.number().required(),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  dealUuid: Yup.string().nullable(),
  endsOn: Yup.date().nullable(),
  status: Yup.string(),
  effectiveOn: Yup.date().nullable(),
  companies: Yup.object().shape({
    distributors: Yup.array()
      .of(getCompanySchema('saveDraft', 'Distributor'))
      .min(1, 'Distributor Company is required'),
    producers: Yup.array().of(getCompanySchema('saveDraft', 'Producer')).min(1, 'Producer Company is required'),
  }),
  repayment: Yup.object().shape({
    deadline: Yup.date().nullable(),
    rules: Yup.array().of(repaymentRuleSchema).min(0),
    requiredItems: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().nullable(),
        name: Yup.string(),
        category: Yup.string(),
      }),
    ),
  }),
  forecastedFinancials: Yup.object().shape({
    contractedGmv: decimalString('Contracted GMV'),
    ltvRate: decimalString('LTV'),
    aprRate: decimalString('APR'),
    earRate: decimalString('EAR'),
  }),
  applicationFee: getApplicationFeeSchema('draft'),
  ui: Yup.object().shape({
    disabled: Yup.boolean(),
  }),
});

export const prepaymentsValidationSchema = Yup.array().of(prepaymentSchema).min(1, 'Prepayment is required');
export const commoditiesValidationSchema = Yup.array().of(forecastCommoditySchema).min(1, 'Commodity is required');
export const forecastValidationSchema = Yup.array().of(forecastSchema).min(1, 'Forecast is required');
export const milestonesValidationSchema = Yup.array().of(milestoneSchema).min(1, 'Milestone is required');

export const activationSchema: Yup.SchemaOf<PSValues> = Yup.object().shape({
  general: Yup.object().shape({
    productLineId: Yup.number().required(),
    endsOn: Yup.date().nullable(),
    dealReference: Yup.string().nullable().required('Deal ID is required'),
    dealUuid: Yup.string().nullable().required('Deal ID is required'),
    status: Yup.string(),
    effectiveOn: Yup.date().nullable().required('Effective Date is required'),
    companies: Yup.object().shape({
      distributors: Yup.array()
        .of(getCompanySchema('activate', 'Distributor'))
        .min(1, 'Distributor Company is required'),
      producers: Yup.array().of(getCompanySchema('activate', 'Producer')).min(1, 'Producer Company is required'),
    }),
    forecastedFinancials: Yup.object().shape({
      contractedGmv: decimalString('Contracted GMV').required('Contracted GMV is required'),
      ltvRate: decimalString('LTV').required('LTV is required'),
      aprRate: decimalString('APR').required('APR is required'),
      earRate: decimalString('EAR').required('EAR is required'),
    }),
    applicationFee: getApplicationFeeSchema('activate'),
    repayment: Yup.object().shape({
      deadline: Yup.date().nullable().required('Repayment Deadline is required'),
      rules: Yup.array().of(repaymentRuleSchema).min(1, 'A Repayment Rule is required'),
      requiredItems: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().nullable().required('Notice of Intent is required'),
          name: Yup.string(),
          category: Yup.string(),
        }),
      ),
    }),
    ui: Yup.object().shape({
      disabled: Yup.boolean(),
    }),
  }),
  prepayments: prepaymentsValidationSchema,
  commodities: commoditiesValidationSchema,
  forecast: forecastValidationSchema,
  milestones: milestonesValidationSchema,
});

export type PSActivateValidationSchema = Yup.SchemaOf<PSValues>;
export type PSSaveValidationSchema = Yup.SchemaOf<PSFormSectionValues>;
