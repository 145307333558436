import _ from 'lodash';
import { useState, useEffect } from 'react';

export const SEARCH_DEBOUNCE_MS = 275;

// Obtained from https://usehooks.com/useDebounce/
// Hook
export function useDebounce(value, delay = SEARCH_DEBOUNCE_MS) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export const textSearchCompare = (search, text) => !search || text.toLowerCase().includes(search.toLowerCase());

export const textSearchCompareByWord = (search, text) =>
  !search || _.every(search.split(' '), word => text.toLowerCase().includes(word.toLowerCase()));

export const tokenize = text => text.toLowerCase().replace(/[^A-Z]/gi, '-');
