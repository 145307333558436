import React from 'react';
import { Formik, useFormikContext } from 'formik';

import { SectionedContent, Section } from 'src/components/elements/SectionedContent/SectionedContent';
import { Companies } from 'src/routes/Deals/PNM/Form/Companies';
import { FormikDealStatus, OnDealSave } from 'src/routes/Deals/types';
import { PNMValues, PNMValuesPayload } from 'src/routes/Deals/PNM/types';
import { FormAction } from '../../constants';
import { removeDefaultEntities, transformToPayload } from '../helpers';
import { activationSchema, saveDraftSchema } from '../validationSchemas';
import FormHeader from '../../components/FormHeader';
import DealReference from '../../components/DealReference';
import MembershipDetails from './MembershipDetails';
import MembershipFee from './MembershipFee';

interface PNMFormProps {
  action: FormAction;
  onDealSave: OnDealSave;
}

interface FormikPNMFormProps extends PNMFormProps {
  initialValues: PNMValues;
  initialStatus: FormikDealStatus;
}

const sections: Section[] = [
  { name: 'Companies', id: 'companies', component: <Companies /> },
  { name: 'Membership Details', id: 'membership-details', component: <MembershipDetails /> },
  { name: 'Membership Fee', id: 'membership-fee', component: <MembershipFee /> },
];

const PNMForm = ({ action, onDealSave }: PNMFormProps) => {
  const {
    values: { status, dealUuid },
  } = useFormikContext<PNMValues>();

  return (
    <form onSubmit={e => e.preventDefault()}>
      <FormHeader<PNMValues, PNMValuesPayload>
        action={action}
        status={status}
        dealUuid={dealUuid}
        onDealSave={onDealSave}
        productLine="Preferred Network Membership"
        activationSchema={activationSchema}
        saveDraftSchema={saveDraftSchema}
        transformToPayload={transformToPayload}
        removeDefaultEntities={removeDefaultEntities}
      />
      <DealReference renewable={false} autoRenew={false} />
      <div className="px-6">
        <SectionedContent sections={sections} stickyOffset={50 /* offset height of the top bar */} />
      </div>
    </form>
  );
};

export default ({ action, initialValues, initialStatus, onDealSave }: FormikPNMFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      initialStatus={initialStatus}
      onSubmit={null}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      <PNMForm action={action} onDealSave={onDealSave} />
    </Formik>
  );
};
