import React from 'react';
import { format, getWeek } from 'date-fns';

export const IndexCell = ({ value }) => {
  // Week numbering is complicated and subject to different standards
  // firstWeekContainsDate is set to 4 to follow ISO spec
  // Change it to 0 for traditional USA week numbering
  // It's weird and doesn't seem to be widespread (even in USA)
  // https://en.wikipedia.org/wiki/Week#Week_numbering
  const weekNumber = getWeek(value, { firstWeekContainsDate: 4 });
  return (
    <div className="flex items-center h-full justify-between px-3">
      <div className="w-5 h-5 bg-white rounded flex justify-center items-center text-xs">{weekNumber}</div>
      {format(value, 'MM-dd-yyyy')}
    </div>
  );
};
