export type AssetCompany = {
  legacyId: number;
  role: 'RECEIVER' | 'SUPPLIER';
  name: string;
};

export enum OrderStatus {
  ACCEPTED = 'ACCEPTED',
  BOOKED = 'BOOKED',
  CREATED = 'CREATED',
  DISPUTED = 'DISPUTED',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  VOID = 'VOID',
}

export type Order = {
  bookedDate: string | null;
  caseCount: number;
  id: string;
  isFixedPrice: boolean;
  orderStatus: OrderStatus;
  receivers: AssetCompany[];
  referenceNumber: string;
  slug: string;
  suppliers: AssetCompany[];
  updatedAt: string;
};

export type InventoryItem = {
  commodity: {
    name: string;
  };
  grade: {
    name: string;
  };
  hasGrowerLabel: boolean;
  isOrganic: boolean;
  pricePerUnit: string | null;
  quantity: number | null;
  size: {
    name: string;
  };
  variety: {
    name: string;
  };
};

export type ProductType = 'PS' | 'Pick & Pack' | 'Factoring' | 'Membership' | 'Trading' | 'Mid-Season';

export type FinancingStatus = 'None' | 'Requested' | 'Approved' | 'Rejected' | 'Wired';

export type FinancialProduct = {
  financialProductUuid: string;
  requestedDate: string | null;
  productType: ProductType;
  requestedAmount: number;
  approvedAmount: number;
  status: FinancingStatus;
};

export type GetFinancialProductsResponse = {
  getFinancialProducts: {
    financialProducts: FinancialProduct[];
  };
};

export type DocumentType =
  | 'BILL_OF_LADING'
  | 'CROSSING_DOCUMENTS'
  | 'INVOICE'
  | 'MANIFEST'
  | 'PURCHASE_ORDER'
  | 'QUALITY_REPORT'
  | 'OTHER';

export type OrderAttachment = {
  documentType: DocumentType;
  id: string;
  name: string;
  date: string;
};

export type GetOrderResponse = {
  getOrder: {
    dueDate: string | null;
    id: string;
    inventoryItems: InventoryItem[];
    isFixedPrice: boolean | null;
    orderAttachments: OrderAttachment[];
    pickupNumber: string | null;
    receivers: AssetCompany[];
    referenceNumber: string;
    suppliers: AssetCompany[];
  };
};

export type GetOrdersResponse = {
  getOrders: {
    pageInfo: {
      lastPage: number;
      page: number;
      perPage: number;
      totalResults: number;
    };
    orders: Order[];
  };
};

export type GetOrdersVariables = {
  legacyCompanyIds: string[];
  page: number;
  perPage: number;
  search: string;
};
