import React from 'react';
import { Modal } from '@producepay/pp-ui';
import { Formik } from 'formik';
import TabGroup from 'src/components/elements/TabGroup';
import { RepaymentRule } from 'src/routes/Deals/constants';
import { PSRepaymentRule } from 'src/routes/Deals/PS/types';
import { repaymentRuleSchema } from 'src/routes/Deals/PS/validationSchemas';
import { transformRepaymentRuleData } from 'src/routes/Deals/PS/helpers';
import { FixedRepaymentRule, PercentageRepaymentRule, PerUnitRepaymentRule } from './Rules';

interface RepaymentModalProps {
  ruleIndex: number;
  rules: PSRepaymentRule[];
  actions: {
    onSave: (rule: unknown) => void;
    onCancel: () => void;
    onClose: () => void;
  };
}

const getDefaultRule = (rules: PSRepaymentRule[]): RepaymentRule => {
  if (!rules.find(rule => [RepaymentRule.Fixed, RepaymentRule.Percentage].includes(rule.type))) {
    return RepaymentRule.Fixed;
  }
  return RepaymentRule.PerUnit;
};

const defaultValues = {
  type: '',
  amountRate: '',
  amount: '',
  units: [],
  commodity: { name: '', uuid: '' },
  variety: { name: '', uuid: '' },
  size: { name: '', uuid: '' },
  classification: 'all',
};

const RepaymentModal = ({ actions, rules, ruleIndex }: RepaymentModalProps): JSX.Element => {
  const defaultTabId = (rules[ruleIndex] && rules[ruleIndex].type) || getDefaultRule(rules);
  const initialValues = rules[ruleIndex] ? { ...defaultValues, ...rules[ruleIndex] } : defaultValues;

  const handleSubmit = rule => {
    actions.onSave(transformRepaymentRuleData(rule));
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validationSchema={repaymentRuleSchema}
    >
      {formik => (
        <Modal
          style={{ height: '85%', maxWidth: '648px' }}
          className="rounded"
          title={<div className="flex flex-row text-xl font-medium items-center">Add Repayment Rule</div>}
          actions={[
            {
              label: 'Cancel',
              className: 'px-10',
              color: 'default',
              variant: 'text',
              onClick: actions.onCancel,
            },
            {
              label: 'Save',
              className: 'px-10',
              'data-testid': 'repayment-modal-save',
              onClick: formik.submitForm,
            },
          ]}
          onClose={actions.onClose}
        >
          <label className="form-label text-xs font-bold tracking-wide bg-white text-grey-darker" htmlFor="type-group">
            Type
          </label>
          <TabGroup
            id="type-group"
            defaultTabId={defaultTabId}
            tabs={[
              {
                name: 'Fixed',
                id: RepaymentRule.Fixed,
                component: (
                  <FixedRepaymentRule setRuleTypeValue={(type: string) => formik.setFieldValue('type', type)} />
                ),
                disabled:
                  defaultTabId !== RepaymentRule.Fixed &&
                  !!rules.find(rule => [RepaymentRule.Fixed, RepaymentRule.Percentage].includes(rule.type)),
              },
              {
                name: 'Percentage',
                id: RepaymentRule.Percentage,
                component: (
                  <PercentageRepaymentRule setRuleTypeValue={(type: string) => formik.setFieldValue('type', type)} />
                ),
                disabled:
                  defaultTabId !== RepaymentRule.Percentage &&
                  !!rules.find(rule => [RepaymentRule.Fixed, RepaymentRule.Percentage].includes(rule.type)),
              },
              {
                name: 'Per Unit',
                id: RepaymentRule.PerUnit,
                component: (
                  <PerUnitRepaymentRule
                    selectedType={formik.values.classification}
                    setRuleTypeValue={(type: string) => formik.setFieldValue('type', type)}
                  />
                ),
              },
            ]}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default RepaymentModal;
