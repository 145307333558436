import {
  PSGeneralPayload,
  PSGroupedCompanies,
  PSCompany,
  PSGeneralValues,
  PSRepaymentRule,
  PSRepayment,
  PSValues,
  PSMilestonesPayload,
  PSMilestoneValues,
  PSMilestoneValue,
  PSCommoditiesValues,
  PSCommoditiesPayload,
  PSPrepayment,
  PSPrepaymentsPayload,
  PSForecastPayload,
  PSForecastValue,
  PSForecast,
  PSForecastValues,
  PSPrepaymentsValues,
  PSRepaymentValue,
  PSPrepaymentValue,
} from 'src/routes/Deals/PS/types';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { safeFormatISO } from 'src/helpers/dates';
import { PSEntityAction, RepaymentRule } from 'src/routes/Deals/constants';
import parseISO from 'date-fns/parseISO';
import { safeMul } from '../helpers';
import { PSDealResponse } from './Edit/types';

const isForecastEmpty = (forecast: PSForecastValue): boolean => {
  const { week, items, forecastUuid, ...rest } = forecast;
  const flattenedValue = { ...rest, ...Object.fromEntries(Object.keys(items).map(key => [key, items[key].units])) };
  return Object.values(flattenedValue).every(item => isEmpty(item));
};

const isPrepaymentEmpty = p => {
  const { sourceBankAccountUuid, recipientBankAccountUuid, prepaymentUuid, ...prepayment } = p;
  return isEmpty(prepayment);
};
/**
 * Since our form adds defaulted values to make it easier for the user,
 * we should remove those defaults before validation
 */

export const getGeneralValues = (values: PSValues): PSGeneralValues => {
  const { general } = values;
  const distributors = (general.companies?.distributors ?? []).filter(
    (c, index) => !isEmpty(c.identifier) || !isEmpty(c.contactName) || index === 0,
  );
  const producers = (general.companies?.producers ?? []).filter(
    (c, index) => !isEmpty(c.identifier) || !isEmpty(c.contactName) || index === 0,
  );

  return {
    ...general,
    companies: {
      ...general.companies,
      distributors,
      producers,
    },
  };
};

export const getCommoditiesValues = (values: PSValues): PSCommoditiesValues => values.commodities;

export const getForecastValues = (values: PSValues): PSForecastValues =>
  values.forecast.filter((f, i) => !isForecastEmpty(f) || i === 0);

export const getPrepaymentValues = (values: PSValues): PSPrepaymentsValues =>
  values.prepayments.filter((p, i) => !isPrepaymentEmpty(p) || i === 0);

export const getMilestonesValues = (values: PSValues): PSMilestoneValues =>
  values.milestones.filter((m, i) => m.deadline !== null || m.gmvAmount !== null || i === 0);

export const getAllValues = (values: PSValues): PSValues => ({
  ...values,
  general: getGeneralValues(values),
  prepayments: getPrepaymentValues(values),
  milestones: getMilestonesValues(values),
  forecast: getForecastValues(values),
});

const transformCompanyData = (companies: PSGroupedCompanies): { companies: PSCompany[] } => {
  const flattenedCompanies = ['producers', 'distributors']
    .reduce((all, item) => {
      return all.concat(companies[item]);
    }, [])
    .map(c => {
      const { fundingAccounts, ...withoutFundingAccounts } = c;
      return withoutFundingAccounts;
    });
  return { companies: flattenedCompanies };
};

export const transformRepaymentRuleData = (rule: PSRepaymentRule): PSRepaymentRule => {
  const { type, amountRate, amount, units, commodity, variety, size, classification } = rule;
  switch (rule.type) {
    case RepaymentRule.Fixed:
      return { type };
    case RepaymentRule.Percentage:
      return { type, amountRate };
    case RepaymentRule.PerUnit:
      return { type, amount, units, commodity, variety, size, classification };
    default:
      throw new Error('Invalid Repayment Rule');
  }
};

const transformRepaymentData = (repayment: PSRepaymentValue): { repayment: PSRepayment } => ({
  repayment: {
    ...repayment,
    deadline: repayment.deadline ? safeFormatISO(repayment.deadline) : null,
  },
});

export const transformToGeneralPayload = (data: PSGeneralValues): PSGeneralPayload => {
  return {
    ...data,
    ...transformRepaymentData(data.repayment),
    ...transformCompanyData(data.companies),
    effectiveOn: data.effectiveOn ? safeFormatISO(data.effectiveOn) : null,
    endsOn: data.endsOn ? safeFormatISO(data.endsOn) : null,
    forecastedFinancials: mapValues(data.forecastedFinancials, (rate, key) =>
      key !== 'contractedGmv' ? `${Number(rate) / 100}` : rate,
    ),
  };
};

const wasMilestoneUpdated = (initialMilestone: PSMilestoneValue, actualMilestone: PSMilestoneValue) =>
  initialMilestone.deadline !== actualMilestone.deadline || initialMilestone.gmvAmount !== actualMilestone.gmvAmount;

export const transformToMilestonesPayload = (
  dealUuid: string,
  initialMilestones: PSMilestoneValues,
  milestones: PSMilestoneValues,
): PSMilestonesPayload => {
  const payload = {
    dealUuid,
    create: [],
    update: [],
    delete: [],
  };

  milestones.forEach(milestone => {
    const { action, deadline, ...milestoneData } = milestone;
    switch (action) {
      case PSEntityAction.Create:
        return payload.create.push({
          ...milestoneData,
          ...{ deadline: deadline ? safeFormatISO(new Date(deadline)) : null },
        });
      case PSEntityAction.Update:
        // eslint-disable-next-line no-case-declarations
        const initialMilestone = initialMilestones.find(mStone => mStone.milestoneUuid === milestone.milestoneUuid);
        return (
          initialMilestone &&
          wasMilestoneUpdated(initialMilestone, milestone) &&
          payload.update.push({
            ...milestoneData,
            ...{ deadline: deadline ? safeFormatISO(new Date(deadline)) : null },
          })
        );
      case PSEntityAction.Delete:
        return payload.delete.push({ milestoneUuid: milestoneData.milestoneUuid });
      default:
        return undefined;
    }
  });

  return payload;
};

export const transformToCommoditiesPayload = (
  dealUuid: string,
  initialCommodities: PSCommoditiesValues,
  commodities: PSCommoditiesValues,
): PSCommoditiesPayload => {
  const payload = {
    dealUuid,
    create: [],
    update: [],
    delete: [],
  };

  commodities.forEach(commodity => {
    const { action, ...commodityData } = commodity;
    switch (action) {
      case PSEntityAction.Create:
        return payload.create.push(commodityData);
      case PSEntityAction.Update:
        // eslint-disable-next-line no-case-declarations
        const initialCommodity = initialCommodities.find(
          cmd => cmd.forecastCommodityUuid === commodityData.forecastCommodityUuid,
        );
        return initialCommodity && !isEqual(initialCommodity, commodityData) && payload.update.push(commodityData);
      case PSEntityAction.Delete:
        return payload.delete.push({ forecastCommodityUuid: commodityData.forecastCommodityUuid });
      default:
        return undefined;
    }
  });

  return payload;
};

export const transformToValues = (dealConfig: PSDealResponse, _dealUuid: string, status: string): PSValues => {
  const {
    general,
    milestones: { nodes: milestones },
    forecastCommodities: { nodes: commodities },
    financialProducts: { nodes: prepayments },
    forecasts: { nodes: forecast },
  } = dealConfig;
  return {
    general: {
      companies: {
        producers: general.companies.filter(company => company.role === 'producer'),
        distributors: general.companies.filter(company => company.role === 'distributor'),
      },
      dealReference: general.dealReference,
      effectiveOn: general.effectiveOn ? parseISO(general.effectiveOn) : null,
      endsOn: general.endsOn ? parseISO(general.endsOn) : null,
      dealUuid: general.dealUuid,
      productLineId: general.productLineId,
      status: status.toLowerCase(),
      ui: general.ui,
      repayment: {
        ...general.repayment,
        deadline: general.repayment.deadline ? parseISO(general.repayment.deadline) : null,
      },
      forecastedFinancials: {
        aprRate: safeMul(general.forecastedFinancials.aprRate, 100),
        earRate: safeMul(general.forecastedFinancials.earRate, 100),
        ltvRate: safeMul(general.forecastedFinancials.ltvRate, 100),
        contractedGmv: general.forecastedFinancials.contractedGmv,
      },
      applicationFee: general.applicationFee,
    },
    prepayments: prepayments.map(item => ({
      prepaymentUuid: item.prepaymentUuid,
      amount: item.amount,
      effectiveDate: parseISO(item.effectiveDate),
      distributionFee: item.distributionFee,
      dueDate: parseISO(item.dueDate),
      dailyLateFee: item.dailyLateFee,
      sourceBankAccountUuid: item.sourceBankAccount?.identifier,
      recipientBankAccountUuid: item.recipientBankAccount?.identifier,
    })),
    commodities: commodities.map(item => ({
      forecastCommodityUuid: item.forecastCommodityUuid,
      isOrganic: item.isOrganic,
      commodity: {
        name: item.commodityName,
        uuid: item.commodityUuid,
      },
      distributorUuid: item.distributor?.distributorUuid ?? null,
      variety: { name: item.varietyName, uuid: item.varietyUuid },
      packaging: { name: item.packagingName, uuid: item.packagingUuid },
      size: { name: item.sizeName, uuid: item.sizeUuid },
    })),
    forecast: forecast.map(({ forecastsToForecastCommodities, week, ...rest }) => ({
      ...rest,
      week: parseISO(week),
      items: forecastsToForecastCommodities.reduce<PSForecastValue['items']>((acc, item) => {
        acc[item.forecastCommodityUuid] = { uuid: item.forecastCommodityUuid, units: String(item.cases) };
        return acc;
      }, {}),
    })),
    milestones: milestones.map(item => ({ ...item, deadline: parseISO(item.deadline) })),
  };
};

export const transformToPrepaymentsPayload = (
  dealUuid: string,
  initialPrepayments: PSPrepaymentValue[],
  prepayments: PSPrepaymentValue[],
): PSPrepaymentsPayload => {
  const initialMap = new Map(initialPrepayments.map(item => [item.prepaymentUuid, item]));
  const currentMap = new Map(prepayments.map(item => [item.prepaymentUuid, item]));
  function mapToPayload(value: Required<PSPrepaymentValue>): PSPrepayment {
    return {
      ...value,
      effectiveDate: safeFormatISO(value.effectiveDate),
      dueDate: safeFormatISO(value.dueDate),
    };
  }
  return {
    dealUuid,
    create: prepayments.filter(item => !initialMap.has(item.prepaymentUuid)).map(mapToPayload),
    update: prepayments
      .filter(item => initialMap.has(item.prepaymentUuid))
      .filter(item => {
        return !isEqual(item, initialMap.get(item.prepaymentUuid));
      })
      .map(mapToPayload),
    delete: initialPrepayments
      .filter(item => !currentMap.has(item.prepaymentUuid))
      .map(item => ({ prepaymentUuid: item.prepaymentUuid })),
  };
};

export const transformToForecastPayload = (
  dealUuid: string,
  initialForecast: Required<PSForecastValue>[],
  forecast: Required<PSForecastValue>[],
): PSForecastPayload => {
  function transformItems(items: PSForecastValue['items']): PSForecast['items'] {
    return Object.values(items);
  }
  const initialMap = new Map(initialForecast.map(item => [item.forecastUuid, item]));
  const currentMap = new Map(forecast.map(item => [item.forecastUuid, item]));
  const payload: PSForecast[] = forecast
    .filter(item => !initialMap.has(item.forecastUuid) || !isEqual(item, initialMap.get(item.forecastUuid)))
    .map(({ items, week, ...rest }) => ({
      ...rest,
      week: safeFormatISO(week),
      items: transformItems(items),
    }));
  return {
    dealUuid,
    create: payload.filter(item => !initialMap.has(item.forecastUuid)),
    update: payload.filter(item => initialMap.has(item.forecastUuid)),
    delete: initialForecast
      .filter(item => !currentMap.has(item.forecastUuid))
      .map(item => ({ forecastUuid: item.forecastUuid })),
  };
};
