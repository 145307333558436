import React, { FC, useMemo } from 'react';
import { CellProps } from 'react-table';
import cx from 'classnames';

import { Align, AlignCellContent, CellWithAlign, TextAlignClassName } from 'src/components/elements/Table';

import { FundingTransaction } from './types';

export const RecipientAccountCell: FC<
  CellProps<FundingTransaction, FundingTransaction['recipientAccountUuid']> &
    CellWithAlign & {
      fundingAccounts: {
        identifier: string;
        institutionName?: string;
        name: string;
      }[];
    }
> = ({ value, column: { align = Align.Right }, fundingAccounts }) => {
  const textAlign = TextAlignClassName[align];
  const fundingAccount = useMemo(
    () => fundingAccounts.find(item => item.identifier === value),
    [value, fundingAccounts],
  );

  return (
    <AlignCellContent align={align}>
      {fundingAccount ? (
        <div className="flex flex-col">
          <span className={cx(textAlign, 'body2')}>{fundingAccount.institutionName ?? 'N/A'}</span>
          <span className={cx(textAlign, 'text-xs text-gray-500')}>{fundingAccount.name}</span>
        </div>
      ) : null}
    </AlignCellContent>
  );
};
