import React from 'react';
import cx from 'classnames';

/** SimpleErrorMessageWrapper
 * Wrapper for Formik-enabled fields. Similar to the FormikInputWrapper
 * but doesn't require a label to display the error. Created initially
 * for our Deal Forms
 *
 * Note: Error will have an absolute position so as not to disrupt
 * surrounding styles
 */

const SimpleErrorMessageWrapper = ({ className = '', style = null, children, error, cleared = false }) => {
  return (
    <div style={style} className={cx(className, 'w-full')}>
      <div className={cx(!error && 'my-1 pt-1')}>{children}</div>
      <div className={cx('pt-1 text-xs text-red-600 font-normal w-full')}>{error && !cleared && error}</div>
    </div>
  );
};

export default SimpleErrorMessageWrapper;
