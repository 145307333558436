import { VirtualElement } from '@popperjs/core';
import { HTMLAttributes, useCallback } from 'react';
import {
  Hooks,
  CellProps,
  TableInstance,
  MetaBase,
  TableState,
  ActionType,
  actions,
  Cell,
  CellPropGetter,
  TableRowProps,
  Row,
} from 'react-table';
import { createNewRow as createNewRowDefault } from '../helpers/createNewRow';
import { SelectCellMeta } from './useSelectCell';

actions.openMenu = 'openMenu';
actions.closeMenu = 'closeMenu';

export interface RowActionsInstance {
  insertRow(index: number): void;
  deleteRow(index: number): void;
  clearRow(index: number): void;
  closeMenu(): void;
  state: RowActionsState;
}

export interface RowActionsState {
  menuTarget?: { referenceElement: HTMLElement | VirtualElement; columnId: string; rowIndex: number };
}

function useInstance<T extends Record<string, unknown>>(
  instance: TableInstance<T> & {
    onEdit(newData: T[]): void;
    createNewRow?(prevRow?: T, nextRow?: T, currentRow?: T): T;
    toggleAllRowsSelected(value: boolean): void;
  },
): void {
  const { onEdit, data, dispatch, createNewRow = createNewRowDefault, toggleAllRowsSelected } = instance;

  const insertRow = useCallback(
    (index: number) => {
      const newRow = createNewRow(
        index === 0 ? undefined : data[index - 1],
        index === data.length ? undefined : data[index],
      );
      const newData = [...data];
      newData.splice(index, 0, newRow as T);
      onEdit(newData);
    },
    [data, onEdit, createNewRow],
  );

  const deleteRow = useCallback(
    (index: number) => {
      const newData = [...data];
      newData.splice(index, 1);
      onEdit(newData);
    },
    [data, onEdit],
  );

  const clearRow = useCallback(
    (index: number) => {
      const newRow = createNewRow(
        index > 0 ? data[index - 1] : undefined,
        index < data.length - 1 ? data[index + 1] : undefined,
        data[index],
      );
      const newData = [...data];
      newData.splice(index, 1, newRow as T);
      onEdit(newData);
    },
    [data, onEdit, createNewRow],
  );

  const closeMenu = useCallback(() => {
    toggleAllRowsSelected(false);
    dispatch({
      type: actions.closeMenu,
    });
  }, [dispatch, toggleAllRowsSelected]);

  Object.assign(instance, { insertRow, deleteRow, clearRow, closeMenu });
}

function getCellProps<T extends Record<string, unknown>>(
  props: CellProps<T>,
  {
    instance: { dispatch, clearSelection, toggleAllRowsSelected, toggleRowSelected },
    cell,
  }: MetaBase<T> & {
    cell: Cell<T>;
  } & SelectCellMeta & {
      instance: { toggleAllRowsSelected(value: boolean): void; toggleRowSelected(id: string, value: boolean): void };
    },
): (CellProps<T> | HTMLAttributes<HTMLDivElement>)[] {
  return [
    props,
    {
      onContextMenu(e) {
        e.preventDefault();
        toggleAllRowsSelected(false);
        toggleRowSelected(cell.row.id, true);
        clearSelection();
        const x = e.clientX;
        const y = e.clientY;
        dispatch({
          type: actions.openMenu,
          value: {
            referenceElement: {
              getBoundingClientRect() {
                return new DOMRect(x, y);
              },
            },
            columnId: cell.column.id,
            rowIndex: cell.row.index,
          },
        });
      },
    },
  ];
}

function reducer<T extends Record<string, unknown>>(
  state: TableState<T> & RowActionsState,
  action: ActionType,
): TableState<T> & RowActionsState {
  if (action.type === actions.openMenu) {
    return {
      ...state,
      menuTarget: action.value,
    };
  }
  if (action.type === actions.closeMenu) {
    const { menuTarget, ...newState } = state;
    return {
      ...newState,
    };
  }
  return state;
}

function getRowProps<U extends Record<string, unknown>>(
  props: TableRowProps,
  meta: MetaBase<U> & {
    row: Row<U>;
  } & { instance: { state: { selectedRowIds: Record<string, unknown> } } },
): (TableRowProps | HTMLAttributes<HTMLDivElement>)[] {
  return [
    props,
    {
      id: meta.instance.state.selectedRowIds[meta.row.id] ? 'selectedRow' : '',
    },
  ];
}

export function useRowActions<U extends Record<string, unknown>>(hooks: Hooks<U>): void {
  hooks.getCellProps.push(getCellProps as CellPropGetter<U>);
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.getRowProps.push(getRowProps);
}
useRowActions.pluginName = 'useRowActions';
