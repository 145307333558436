import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './styles/index.css';
import './styles/tailwind.css';
import './styles/tailwind-v1-polyfill.css';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

ReactDOM.render(<App />, document.getElementById('root'));
