import { ISFRValues } from 'src/routes/Deals/ISFR/types';
import * as Yup from 'yup';

import { decimalString } from '../helpers';

const validateTotalRate = (value, context) => {
  const maximumRebateRate = Number(value.rate);
  const purchasePriceRate = Number(context.parent.payment.purchasePriceRate);
  const validRate = maximumRebateRate + purchasePriceRate < 100;

  if (!validRate) {
    throw new Yup.ValidationError(
      context.createError({
        path: 'maximumRebate.rate',
        message: 'Maximum Rebate Amount and Purchase Price sum must be less than 100',
      }),
    );
  }

  return true;
};

const validateCompaniesFundingAccounts = (value, context) => {
  const {
    path,
    parent: {
      payment: { receiverRole },
    },
    createError,
  } = context;
  const companies = value[receiverRole] ?? [];
  const errors: Array<Yup.ValidationError> = [];

  companies.forEach((company, companyIndex) => {
    const { fundingAccounts = [] } = company;

    fundingAccounts.forEach((fundingAccount, index) => {
      if (!fundingAccount.accountId) {
        const error = createError({
          path: `${path}.${receiverRole}.${companyIndex}.fundingAccounts.${index}.accountId`,
          message: 'Bank Account is required',
        });

        errors.push(error);
      }
    });
  });

  if (errors.length) {
    throw new Yup.ValidationError(errors);
  }

  return true;
};

const companySchema = Yup.object().shape({
  identifier: Yup.string()
    .when('role', {
      is: role => role === 'seller',
      then: Yup.string().nullable().required('Seller Company is required'),
      otherwise: Yup.string().nullable().required('Receiver Company is required'),
    })
    .nullable()
    .required('Company Required'),
  role: Yup.mixed().oneOf(['seller', 'receiver']),
  contactName: Yup.string().nullable(),
  name: Yup.string().nullable(),
  key: Yup.string().nullable(),
  fundingAccounts: Yup.array().of(
    Yup.object().shape({
      accountId: Yup.string().nullable(),
      value: decimalString('Amount'),
      key: Yup.string().nullable(),
      type: Yup.string().nullable(),
    }),
  ),
});

const activationApplicationFeeSchema = Yup.object().shape({
  amountDue: decimalString('Application Fee').required('Application Fee is required'),
  amountPaid: decimalString('Amount Paid'),
  datePaid: Yup.string().nullable(),
  paymentMethods: Yup.array().of(
    Yup.object().shape({
      amount: decimalString('Amount').required('Amount is required'),
      method: Yup.string().nullable().required('Payment Method is required'),
      key: Yup.string().nullable(),
    }),
  ),
  isWaived: Yup.boolean(),
  companyIdentifier: Yup.string().nullable().required('Company is required'),
  key: Yup.string().nullable(),
});

const applicationFeeSchema = Yup.object().shape({
  minimumAnnualProduceVolumeForRefund: decimalString('Minimum Annual Produce Volume').required(
    'Minimum Annual Produce Volume is required',
  ),
  isRefundable: Yup.boolean(),
  fees: Yup.array().of(activationApplicationFeeSchema).min(1),
});

const activationCompanySchema = Yup.object().shape({
  identifier: Yup.string()
    .when('role', {
      is: role => role === 'seller',
      then: Yup.string().nullable().required('Seller Company is required'),
      otherwise: Yup.string().nullable().required('Receiver Company is required'),
    })
    .nullable()
    .required('Company is required'),
  role: Yup.mixed().oneOf(['seller', 'receiver']),
  contactName: Yup.string().when('role', {
    is: role => role === 'seller',
    then: Yup.string().nullable().required('Seller Contact is required'),
    otherwise: Yup.string().nullable().required('Receiver Contact is required'),
  }),
  name: Yup.string().nullable(),
  key: Yup.string().nullable(),
  fundingAccounts: Yup.array().of(
    Yup.object().shape({
      accountId: Yup.string().nullable(),
      value: decimalString('Amount'),
      key: Yup.string().nullable(),
      type: Yup.string().nullable(),
    }),
  ),
});

const paymentSchema = Yup.object().shape({
  receiverRole: Yup.string().nullable(),
  purchasePriceRate: decimalString('Purchase Price'),
  paymentTerms: decimalString('Payment Terms'),
  receiverExceptions: Yup.array().of(
    Yup.object().shape({
      companyIdentifier: Yup.string().nullable(),
      purchasePriceRate: decimalString('Purchase Price'),
      key: Yup.string().nullable(),
    }),
  ),
  requiredItems: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().nullable(),
      name: Yup.string().nullable(),
      category: Yup.string().nullable(),
    }),
  ),
});

const activationPaymentSchema = Yup.object().shape({
  receiverRole: Yup.string().nullable().required('Payment Recipient is required'),
  purchasePriceRate: decimalString('Purchase Price').required('Purchase Price is required'),
  paymentTerms: decimalString('Payment Terms').required('Payment Terms are required'),
  receiverExceptions: Yup.array().of(
    Yup.object().shape({
      companyIdentifier: Yup.string().required('Receiver is a required field'),
      purchasePriceRate: decimalString('Purchase Price').required('Purchase Price is required'),
      key: Yup.string().nullable(),
    }),
  ),
  requiredItems: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().nullable(),
      name: Yup.string().nullable(),
      category: Yup.string().nullable(),
    }),
  ),
});

export const saveDraftSchema: ISFRValidationSchema = Yup.object().shape({
  applicationFee: Yup.object().shape({
    minimumAnnualProduceVolumeForRefund: decimalString('Minimum Annual Produce Volume'),
    isRefundable: Yup.boolean(),
    fees: Yup.array().of(
      Yup.object().shape({
        amountDue: decimalString('Application Fee'),
        amountPaid: decimalString('Amount Paid'),
        datePaid: Yup.string().nullable(),
        paymentMethods: Yup.array().of(
          Yup.object().shape({
            amount: decimalString('Amount'),
            method: Yup.string().nullable(),
            key: Yup.string().nullable(),
          }),
        ),
        isWaived: Yup.boolean(),
        companyIdentifier: Yup.string().nullable(),
        key: Yup.string().nullable(),
      }),
    ),
  }),
  companies: Yup.object().shape({
    sellers: Yup.array().of(companySchema).min(1, 'Seller Company is required'),
    receivers: Yup.array().of(companySchema).min(1, 'Receiver Company is required'),
  }),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  defaultBankAccounts: Yup.object().shape({
    sourceAccounts: Yup.array(),
  }),
  effectiveOn: Yup.string().nullable(),
  endsOn: Yup.string().nullable(),
  dealUuid: Yup.string().nullable(),
  maximumRebate: Yup.object()
    .shape({
      rate: decimalString('Maximum Rebate Amount'),
      assetPoolAgingDays: decimalString('Asset Pool Aging'),
    })
    .test('validateTotalRate', validateTotalRate),
  payment: paymentSchema,
  productLineId: Yup.number().required(),
  rebates: Yup.array().of(
    Yup.object().shape({
      amount: decimalString('Rebate Amount'),
      key: Yup.string().nullable(),
      transactionVolumeThreshold: decimalString('Transaction Volume Threshold'),
    }),
  ),
  receiverContract: Yup.string().nullable(),
  sellerContract: Yup.string().nullable(),
  status: Yup.string().nullable().required(),
  ui: Yup.object().shape({
    autoRenew: Yup.boolean(),
    receiverExceptionsEnabled: Yup.boolean(),
  }),
});

export const activationSchema: ISFRValidationSchema = Yup.object().shape({
  productLineId: Yup.number().required(),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  dealUuid: Yup.string().nullable(),
  status: Yup.string(),
  effectiveOn: Yup.string().nullable().required('Effective Date is required'),
  endsOn: Yup.string().when('$autoRenew', {
    is: autoRenew => !autoRenew,
    then: Yup.string().nullable().required('End Date is required'),
    otherwise: Yup.string().nullable(),
  }),
  sellerContract: Yup.string().nullable().required('Seller Contract is required'),
  receiverContract: Yup.string().nullable().required('Receiver Contract is required'),
  applicationFee: applicationFeeSchema,
  companies: Yup.object()
    .shape({
      sellers: Yup.array().of(activationCompanySchema).min(1, 'Seller Company is required'),
      receivers: Yup.array().of(activationCompanySchema).min(1, 'Receiver Company is required'),
    })
    .test(validateCompaniesFundingAccounts),
  payment: activationPaymentSchema,
  maximumRebate: Yup.object()
    .shape({
      rate: decimalString('Maximum Rebate Amount').required('Maximum Rebate Amount is required'),
      assetPoolAgingDays: decimalString('Asset Pool Aging').required('Asset Pool Aging is required'),
    })
    .test('validateTotalRate', validateTotalRate),
  defaultBankAccounts: Yup.object().shape({
    sourceAccounts: Yup.array().of(
      Yup.object().shape({
        identifier: Yup.string().when('purpose', {
          is: purpose => purpose === 'payment',
          then: Yup.string().nullable().required('Payment Source Account is required'),
          otherwise: Yup.string().nullable().required('Balance Return Source Account is required'),
        }),
        purpose: Yup.string().nullable().required(),
      }),
    ),
  }),
  rebates: Yup.array().of(
    Yup.object().shape({
      amount: decimalString('Rebate Amount'),
      key: Yup.string().nullable(),
      transactionVolumeThreshold: decimalString('Transaction Volume Threshold'),
    }),
  ),
  ui: Yup.object().shape({
    autoRenew: Yup.boolean(),
    disabled: Yup.boolean(),
    receiverExceptionsEnabled: Yup.boolean(),
  }),
});

export type ISFRValidationSchema = Yup.SchemaOf<ISFRValues>;
