import React, { useEffect, useState } from 'react';
import { Button, Modal, useToast } from '@producepay/pp-ui';
import Trash from 'src/components/icons/Trash';
import { AlertTriangle } from 'react-feather';
import { useCommand } from 'src/helpers/command';
import { DeleteDeal as DeleteDealCommand, DealCommandType } from 'src/helpers/command/types';
import { useHistory } from 'react-router-dom';
import { dealsIndex } from 'src/routes';
import Tooltip from 'src/components/elements/Tooltip';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Deal } from 'src/routes/Deals/types';
import { DealForDelete, DealForDeleteVariables } from 'src/routes/Deals/components/graphql-types/DealForDelete';

export const GET_DEAL_FOR_DELETE_QUERY = gql`
  query DealForDelete($dealUuid: String!) {
    deal(dealUuid: $dealUuid) {
      dealUuid
      dealReference
      productLine {
        id
        name
        shortName
        slug
      }
    }
  }
`;

export function DeleteDeal({ dealUuid }: { dealUuid: string }) {
  const [deal, setDeal] = useState<Deal>();
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { addToastToQueue } = useToast();
  const { send } = useCommand();
  const history = useHistory();

  const { data, loading } = useQuery<DealForDelete, DealForDeleteVariables>(GET_DEAL_FOR_DELETE_QUERY, {
    variables: { dealUuid },
    skip: !dealUuid,
  });

  useEffect(() => {
    if (data?.deal) {
      setDeal(data.deal);
    }
  }, [data, setDeal]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleDelete = async () => {
    try {
      setIsSaving(true);
      await send<DeleteDealCommand>({
        command: DealCommandType.Delete,
        payload: {
          dealUuid: deal.dealUuid,
        },
      });
      addToastToQueue({
        header: 'Success',
        body: 'Draft Deal has been deleted.',
        type: 'success',
      });
      setIsSaving(false);
      handleClose();
      history.push(dealsIndex());
    } catch (e) {
      setIsSaving(false);
      addToastToQueue({
        actions: [{ label: 'Dismiss' }],
        header: 'Error deleting Draft Deal',
        body: e.message,
        type: 'error',
      });
    }
  };

  if (loading || !deal) {
    return null;
  }

  return (
    <>
      <Tooltip tooltipText="Delete Draft">
        <Button
          type="button"
          variant="outlined"
          color="error"
          onClick={handleOpen}
          data-testid="open-delete-draft-deal-modal"
        >
          <Trash size={18} />
        </Button>
      </Tooltip>

      {isOpen && (
        <Modal
          title={
            <div className="flex flex-row items-center p-2">
              <span className="inline-block rounded p-2 mr-4 text-error bg-error bg-opacity-25">
                <AlertTriangle />
              </span>
              <span className="font-bold">Delete Draft?</span>
            </div>
          }
          actions={[
            {
              label: 'Cancel',
              className: 'px-10',
              color: 'default',
              variant: 'outlined',
              onClick: handleClose,
            },
            {
              label: 'Delete Draft',
              className: 'px-10 ml-4 bg-error border-error',
              color: 'warning',
              onClick: handleDelete,
              isLoading: isSaving,
            },
          ]}
          onClose={handleClose}
        >
          <div className="space-y-2">
            <div className="font-bold">You won’t be able to recover the details once it is deleted.</div>
            <div>Are you sure you want to delete this draft?</div>
          </div>

          <div className="space-y-2 mt-6">
            <div data-testid="delete-draft-deal-product-line-name">{deal.productLine.name}</div>
            <div data-testid="delete-draft-deal-reference">{deal.dealReference}</div>
          </div>
        </Modal>
      )}
    </>
  );
}
