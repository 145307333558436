import gql from 'graphql-tag';

export const GET_FUNDING_TRANSACTIONS = gql`
  query FundingTransactions {
    fundingTransactions(limit: 2500, offset: 0) {
      nodes {
        amount
        checkBounceDate
        checkClearDate
        company {
          identifier
          escrow
          name
        }
        effectiveDate
        fundingTransactionUuid
        memo
        recipientAccount {
          identifier
          companyIdentifier
          institutionName
          name
        }
        sourceAccount {
          identifier
          companyIdentifier
          institutionName
          name
        }
        transactionDescription
        transactionNumber
        transactionType
        unreconciledReceivedAmount
      }
    }
  }
`;
