import dateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';

import { safeFormatISO } from 'src/helpers/dates';
import { PNTRCompany, PNTRGroupedCompanies, PNTRValues, PNTRValuesPayload } from 'src/routes/Deals/PNTR/types';
import { DefaultBankAccounts, DestinationAccount, SourceAccount } from 'src/routes/Deals/types';
import { DATEPICKER_FORMAT } from 'src/routes/Deals/constants';
import { safeDiv, safeMul } from 'src/routes/Deals/helpers';

export const removeDefaultEntities = (data: PNTRValues): PNTRValues => data;

const transformCompanyData = (
  companies: PNTRGroupedCompanies,
  sourceAccounts: SourceAccount[],
): {
  companies: PNTRCompany[];
  defaultBankAccounts: DefaultBankAccounts;
} => {
  const receiverCompanies = companies.sellers;
  const destinationAccounts: DestinationAccount[] = flatten(
    receiverCompanies?.map(company => {
      let priority = 0;
      const { fundingAccounts = [], identifier } = company;

      return fundingAccounts?.reduce((acc, { accountId, type, value }) => {
        if (!accountId) {
          return acc;
        }

        priority += 1;

        return [
          ...acc,
          {
            companyIdentifier: identifier,
            fundingAccountIdentifier: accountId,
            type,
            value,
            priority,
          },
        ];
      }, []);
    }),
  );

  return {
    companies: reduce(
      companies,
      (result, value) => {
        return [...result, ...value];
      },
      [],
    ),
    defaultBankAccounts: {
      sourceAccounts,
      destinationAccounts,
    },
  };
};

export const transformToPayload = (data: PNTRValues): PNTRValuesPayload => {
  return {
    ...data,
    effectiveOn: data.effectiveOn ? safeFormatISO(new Date(data.effectiveOn)) : null,
    endsOn: data.endsOn ? safeFormatISO(new Date(data.endsOn)) : null,
    ...transformCompanyData(data.companies, data.defaultBankAccounts.sourceAccounts),
    tradingFees: {
      sellerFeeRate: safeDiv(data.tradingFees.sellerFeeRate, 100),
      buyerFeeRate: safeDiv(data.tradingFees.buyerFeeRate, 100),
    },
  };
};

export const transformToValues = (dealConfig: PNTRValuesPayload, dealUuid: string, status: string): PNTRValues => {
  return {
    ...dealConfig,
    dealUuid,
    status: status.toLowerCase(),
    companies: groupBy(dealConfig.companies, c => `${c.role}s`) as unknown as PNTRGroupedCompanies,
    effectiveOn: dealConfig.effectiveOn
      ? `${dateFnsFormat(parseISO(dealConfig.effectiveOn).getTime(), DATEPICKER_FORMAT)}`
      : null,
    endsOn: dealConfig.endsOn ? `${dateFnsFormat(parseISO(dealConfig.endsOn).getTime(), DATEPICKER_FORMAT)}` : null,
    tradingFees: {
      sellerFeeRate: safeMul(dealConfig.tradingFees.sellerFeeRate, 100),
      buyerFeeRate: safeMul(dealConfig.tradingFees.buyerFeeRate, 100),
    },
  };
};
