export enum MenuItem {
  Imports = 'imports',
  Payments = 'payments',
  Escrow = 'escrow',
}

export interface ReceivedPaymentInfo {
  amount: number;
  unreconciledReceivedAmount: number;
  sourceAccount: {
    institutionName: string;
    name: string;
  };
  recipientAccount: {
    institutionName: string;
    name: string;
  };
  receivedDate: Date;
  transactionDescription: string;
}

interface Order {
  slug: string;
  number: string;
  netValue: number;
  netBalance: number;
}

interface FormOrder extends Order {
  reconciliationAmount: number;
}

export interface ReconcileOption {
  companyName: string;
  companyUuid: string;
  payee?: boolean;
  orders: FormOrder[];
  escrow: { note: string; amount: number };
}

export interface ReconcilePaymentsForm {
  companies: ReconcileOption[];
}

export interface SelectOption {
  label: string;
  value: string;
}
