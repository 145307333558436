import React, { useCallback } from 'react';
import { TableInstance } from 'react-table';

import { Menu } from 'src/components/elements/Menu';
import { MenuItem } from 'src/components/elements/MenuItem';
import { RowActionsInstance, SelectCellInstance } from 'src/components/elements/Table';

export function TableMenu<T extends Record<string, unknown>>({
  state: {
    menuTarget: { rowIndex, referenceElement },
  },
  insertRow,
  deleteRow,
  clearRow,
  closeMenu,
  data,
}: TableInstance<T> & SelectCellInstance & RowActionsInstance) {
  const onClearRow = useCallback(() => {
    clearRow(rowIndex);
    closeMenu();
  }, [clearRow, rowIndex, closeMenu]);

  const onDeleteRow = useCallback(() => {
    deleteRow(rowIndex);
    closeMenu();
  }, [deleteRow, rowIndex, closeMenu]);

  const onInsertRowAbove = useCallback(() => {
    insertRow(rowIndex);
    closeMenu();
  }, [insertRow, rowIndex, closeMenu]);

  const onInsertRowBelow = useCallback(() => {
    insertRow(rowIndex + 1);
    closeMenu();
  }, [insertRow, rowIndex, closeMenu]);

  return (
    <Menu referenceElement={referenceElement} onClose={closeMenu}>
      <MenuItem onClick={onClearRow}>Clear row</MenuItem>
      {(rowIndex === 0 || rowIndex === data.length - 1) && data.length > 1 && (
        <MenuItem onClick={onDeleteRow}>Delete row</MenuItem>
      )}
      {rowIndex === 0 && <MenuItem onClick={onInsertRowAbove}>Insert 1 above</MenuItem>}
      {rowIndex === data.length - 1 && <MenuItem onClick={onInsertRowBelow}>Insert 1 below</MenuItem>}
    </Menu>
  );
}
