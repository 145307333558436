import React from 'react';
import cx from 'classnames';
import { Grid } from '@producepay/pp-ui';

interface SectionItemProps {
  title?: React.ReactNode;
  subtitle?: string;
  controls?: React.ReactNode;
  controlSize?: '2' | '3' | '4' | '6';
  border?: boolean;
}

export default ({ title, subtitle, controls, controlSize = '3', border = true, ...props }: SectionItemProps) => {
  const computedClassName = cx('sm:w-2/3', {
    'pt-2': !subtitle,
  });
  return (
    <div className={`${border ? 'border-b-1' : ''} px-4 mx-4 pb-4`} {...props}>
      <Grid container className="justify-between pt-8 pb-4 m-4">
        <div className={computedClassName}>
          <div className="font-semibold">{title}</div>
          {subtitle && <div className="text-gray-500 body2">{subtitle}</div>}
        </div>
        <div className={`sm:w-1/${controlSize} pl-3 flex justify-end items-center`}>{controls}</div>
      </Grid>
    </div>
  );
};
