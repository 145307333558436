import React from 'react';
import { formatCurrency } from 'src/helpers/currency';
import { safeFormatISO, getUTCDate } from 'src/helpers/dates';
import { ReceivedPaymentInfo } from '../types';

interface ReceivedPaymentProps {
  receivedPayment: ReceivedPaymentInfo;
}

const ReceivedPayment = ({ receivedPayment }: ReceivedPaymentProps) => {
  const { amount, sourceAccount, recipientAccount, receivedDate, transactionDescription } = receivedPayment;
  return (
    <div className="w-full">
      <p className="pb-3 font-semibold text-sm">RECEIVED PAYMENT</p>
      <div className="flex flex-col space-y-10 bg-white rounded py-6 px-10">
        <div className="flex flex-row justify-between">
          <div className="flex-col space-y-2">
            <p data-testid="payment-description" className="text-sm text-gray-500">
              {transactionDescription} • {safeFormatISO(getUTCDate(receivedDate))}
            </p>
            <div className="flex items-center space-x-3">
              <p className="text-xs text-gray-500">TO</p>
              <p data-testid="payment-recipient-description">
                {recipientAccount.institutionName} • {recipientAccount.name}
              </p>
            </div>
          </div>
          <div className="flex-col space-y-2 text-right">
            <div className="flex items-center space-x-3 justify-end">
              <p className="text-xs text-gray-500">PAYEE</p>
              <p data-testid="payment-sender-description" className="font-semibold">
                {sourceAccount.name}
              </p>
            </div>
            <div className="flex items-center space-x-3 justify-end">
              <p className="text-xs text-gray-500">RECEIVED</p>
              <p data-testid="payment-received-amount" className="font-semibold">
                {formatCurrency(amount)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ReceivedPayment);
