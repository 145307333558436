import React from 'react';
import FormikTextField from 'src/components/formik/FormikTextField';
import { ReconcilePaymentsForm } from '../types';
import ReconcileCompanyTransactions from './ReconcileCompanyTransactions';

interface ReconcileOptionsProps {
  reconcileOptions: ReconcilePaymentsForm;
}

const ReconcileOptions = ({ reconcileOptions }: ReconcileOptionsProps) => {
  const payee = reconcileOptions.companies.find(option => option.payee);

  return (
    <div className="w-full pt-14">
      <p className="pb-3 font-semibold text-sm">RECONCILE OPTIONS</p>
      <div className="flex flex-col space-y-6">
        {reconcileOptions.companies
          .filter(option => !option.payee)
          .map((option, optionIndex) => (
            <ReconcileCompanyTransactions key={option.companyName} option={option} optionIndex={optionIndex} />
          ))}
        <div className="bg-white w-full rounded">
          <div className="py-6 px-10 border-b-1 flex gap-4 items-center">
            <p className="text-lg font-semibold">{payee.companyName}</p>
            <p className="text-xs text-gray-500">PAYEE</p>
          </div>
          <div className="py-6 px-10 flex justify-between items-center">
            <p className="text-md font-semibold">Escrow</p>
            <div className="w-40">
              <FormikTextField type="number" name="payee.escrow" iconPrefix={<span>$</span>} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReconcileOptions;
