import React, { useRef, useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { LoadingSpinner, TextField } from '@producepay/pp-ui';

import Downshift from 'downshift';
import { textSearchCompareByWord } from '../../../helpers/common';

import './bi-autocomplete.css';

const DROPDOWN_ITEM_HEIGHT = 33;
const DROPDOWN_ITEM_MAX_SHOW = 5;

interface AutocompleteItem {
  label: string;
  value: string | number;
}

interface CompanyAutocompleteProps {
  labelledBy?: string;
  name?: string;
  id?: string;
  items: AutocompleteItem[];
  onItemSelected: (item: AutocompleteItem) => void;
  onInputChange?: (item: AutocompleteItem) => void;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  initialItem?: AutocompleteItem;
  onBlur?: () => void;
  inputSize?: string;
}

function downshiftStateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
    case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem: {
      return { ...changes, isOpen: false };
    }
    default:
      return changes;
  }
}

function CompanyAutocomplete(props: CompanyAutocompleteProps) {
  const {
    name,
    id,
    items,
    labelledBy,
    onItemSelected,
    placeholder = 'Search',
    loading = false,
    disabled = false,
    onBlur,
    inputSize = 'small',
    ...rest
  } = props;
  const inputEl = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Downshift
      itemToString={item => _.get(item, 'label', '')}
      onChange={item => {
        setSearchTerm(item.label);
        onItemSelected(item);
      }}
      stateReducer={downshiftStateReducer}
      {...rest}
    >
      {({ getInputProps, getItemProps, isOpen, setState }) => {
        const itemsWrapperCN = cx('absolute w-full bg-white border-r border-b border-l shadow overflow-y-scroll z-50', {
          hidden: !isOpen,
        });

        return (
          <div className="relative">
            <div className="relative">
              <TextField
                {...(getInputProps({
                  onChange: e => {
                    setSearchTerm(e.target.value);
                  },
                  onFocus: () => setState({ isOpen: true }),
                  onBlur,
                }) as any)} // eslint-disable-line @typescript-eslint/no-explicit-any
                aria-labelledby={labelledBy}
                id={id}
                name={name}
                ref={inputEl}
                className="border border-gray-400 select"
                placeholder={placeholder}
                value={searchTerm}
                disabled={disabled}
                size={inputSize}
              />
            </div>

            <div
              className={itemsWrapperCN}
              data-testid="company-autocomplete-results"
              style={{
                maxHeight: DROPDOWN_ITEM_HEIGHT * DROPDOWN_ITEM_MAX_SHOW,
              }}
            >
              {loading ? (
                <div className="p-4 flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              ) : (
                items
                  .filter(option => textSearchCompareByWord(searchTerm, option.label))
                  .map((item, index) => {
                    const itemCN = cx('block p-2 text-gray-800 no-underline hover:bg-gray-200 text-sm cursor-pointer', {
                      'border-t': index !== 0,
                    });

                    return (
                      <div
                        key={item.value}
                        className={itemCN}
                        {...getItemProps({
                          item,
                          index,
                        })}
                      >
                        {item.label}
                      </div>
                    );
                  })
              )}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
}

export default React.memo(CompanyAutocomplete);
