import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Card } from '@producepay/pp-ui';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import FormikTextField from 'src/components/formik/FormikTextField';
import { useFormikContext } from 'formik';
import FormLabel from 'src/components/elements/FormLabel';
import { useQuery } from '@apollo/client';
import { GET_COMPANIES_AUTOCOMPLETE } from 'src/graphql/queries/getCompanies';
import FormikAutocomplete from 'src/components/formik/FormikAutocomplete';
import { PNMCompany, PNMValues } from 'src/routes/Deals/PNM/types';
import { CompaniesAutocomplete } from 'src/graphql/queries/graphql-types/CompaniesAutocomplete';

export interface SelectItem {
  label: string;
  value: string;
}

function getDefaultCompany(): PNMCompany {
  return {
    key: uuidv4(),
    contactName: null,
    identifier: null,
    role: 'member',
    name: null,
  };
}

export function Companies() {
  const {
    values: { companies },
    setFieldValue,
  } = useFormikContext<PNMValues>();

  const [companyData, setCompanyData] = useState([]);
  const { data } = useQuery<CompaniesAutocomplete>(GET_COMPANIES_AUTOCOMPLETE);

  useEffect(() => {
    if (data) {
      setCompanyData(data.companies);
    }
  }, [data]);

  useEffect(() => {
    if (companies.length === 0) {
      setFieldValue('companies.0', getDefaultCompany());
    }
  }, [companies, setFieldValue]);

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    // When user completely deletes the text, reset back to empty.
    if (newValue === '') {
      setFieldValue(`companies.0`, getDefaultCompany());
    }
  }

  function handleCompanySelected(company: SelectItem) {
    setFieldValue(`companies.0`, {
      ...companies[0],
      identifier: company.value,
      name: company.label,
    });
  }

  return (
    <Card>
      <SectionHeader title="Companies" subtitle="Entity participating in the Preferred Network" />
      <div className="flex flex-row px-4 py-4">
        <div className="flex-1 p-2">
          <FormLabel label="Company" />
          <FormikAutocomplete
            name="companies.0.identifier"
            key={companies[0]?.key ?? ''} // Hack to clear value on delete
            items={companyData}
            onChange={e => handleOnChange(e)}
            onItemSelected={c => handleCompanySelected(c)}
            initialSearchTerm={companies[0]?.name ?? ''}
          />
        </div>
        <div className="flex-1 p-2">
          <FormLabel label="Contact Name" />
          <FormikTextField name="companies[0].contactName" inputProps={{ className: 'h-10' }} withSimpleErrorStyling />
        </div>
      </div>
    </Card>
  );
}
