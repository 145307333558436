export enum TransactionType {
  Check = 'check',
  Wire = 'wire',
}

export interface FundingTransaction {
  transactionType: TransactionType;
  transactionNumber?: string;
  transactionDescription?: string;
  amount: string;
  effectiveDate: Date;
  checkClearDate?: Date;
  recipientAccountUuid: string;
  sourceCompanyUuid: string;
  memo?: string;
  importedOriginatorCompanyName?: string;
  kept?: boolean;
  discarded?: boolean;
}
