import React from 'react';
import { AlertCircle } from 'react-feather';
import Decimal from 'decimal.js';
import { formatCurrency } from 'src/helpers/currency';
import Tooltip from 'src/components/elements/Tooltip';

import * as styles from './amountAlert.module.css';

interface AmountAlertProps {
  amount: string;
  context: string;
  paymentPurpose: string;
  productType: string;
  suggestedAmount: string;
}

const ALERT_ICON_SIZE = 18;

const formatText = (context, paymentPurpose, productType, suggestedAmount) => {
  const product = productType === 'FactoringRequest' ? 'invoice' : 'shipment';
  const purpose = paymentPurpose.replace(/_/, ' ');
  return (
    <>
      <p>
        The {purpose} amount of this {product} has been updated since this payment was {context}.
      </p>
      <p>The updated amount should be: {formatCurrency(suggestedAmount)}.</p>
    </>
  );
};

export default ({ amount, context, paymentPurpose, productType, suggestedAmount }: AmountAlertProps) => {
  return (
    <>
      {formatCurrency(amount)}{' '}
      {!new Decimal(suggestedAmount).equals(new Decimal(amount)) ? (
        <Tooltip tooltipText={formatText(context, paymentPurpose, productType, suggestedAmount)}>
          <div
            className="relative inline-block mr-1 align-text-bottom"
            style={{ height: ALERT_ICON_SIZE, width: ALERT_ICON_SIZE }}
          >
            <AlertCircle
              className={`text-warning fill-current absolute left-0 top-0 ${styles.default.alertIcon}`}
              stroke="transparent"
              size={ALERT_ICON_SIZE}
              data-testid="amount-alert"
            />
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};
