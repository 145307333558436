import React from 'react';
import PropTypes from 'prop-types';
import { Trash2 as FeatherTrash } from 'react-feather';

const Trash = props => {
  const { size, color, stroke, disabled } = props;

  return (
    <FeatherTrash height={size} stroke={color} strokeWidth={stroke} width={size} opacity={disabled ? '.5' : '1'} />
  );
};

Trash.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stroke: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Trash.defaultProps = {
  color: 'currentColor',
  disabled: false,
  size: 24,
  stroke: 2,
};

export default Trash;
