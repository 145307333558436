import React from 'react';
import { TextField } from '@producepay/pp-ui';
import { Search } from 'react-feather';

export interface EscrowFilters {
  company?: string;
}

interface EscrowFilterBarProps {
  options: EscrowFilters;
  onFilter: (opts: EscrowFilters) => unknown;
}

const EscrowFilterBar = ({ options, onFilter }: EscrowFilterBarProps) => {
  const handleChange = (changedOpts: { [key: string]: unknown }) => onFilter({ ...options, ...changedOpts });

  return (
    <div className="flex bg-white py-1.5 px-2 border-b-1 text-sm pl-6 items-center gap-4">
      <div>
        <TextField
          className="flex-shrink-1"
          placeholder="Search company"
          size="small"
          value={options.company ?? ''}
          iconPrefix={<Search size={16} />}
          onChange={e => handleChange({ company: e.target.value })}
        />
      </div>
    </div>
  );
};

export default EscrowFilterBar;
