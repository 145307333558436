import React, { useEffect, useState } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Card, Grid } from '@producepay/pp-ui';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';

import FormLabel from 'src/components/elements/FormLabel';
import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';
import FormikToggle from 'src/components/formik/FormikToggle';
import FormikAutocomplete, { SelectItem } from 'src/components/formik/FormikAutocomplete';
import Trash from 'src/components/icons/Trash';

import { ReceiverException } from 'src/routes/Deals/types';
import { ISFRCompany, ISFRValues } from 'src/routes/Deals/ISFR/types';
import SectionHeader from '../../components/SectionHeader';
import PaymentsRecipient from '../../components/PaymentsRecipient';
import SectionItem from '../../components/SectionItem';

const RECEIVER_ROLE_ITEMS = [
  { label: 'Seller', value: 'sellers' },
  { label: 'Receiver', value: 'receivers' },
];

const getEmptyExceptionEntity = (): ReceiverException => ({
  companyIdentifier: '',
  purchasePriceRate: '65.00',
  key: uuidv4(),
});

const Payments = () => {
  const {
    values: {
      companies,
      payment,
      ui: { receiverExceptionsEnabled },
    },
    status: { readOnly },
    setFieldValue,
  } = useFormikContext<ISFRValues>();

  const [companyItems, setCompanyItems] = useState<SelectItem[]>([]);

  useEffect(() => {
    if (receiverExceptionsEnabled && payment.receiverExceptions.length === 0) {
      setFieldValue('payment.receiverExceptions', [getEmptyExceptionEntity()]);
    } else if (!receiverExceptionsEnabled && payment.receiverExceptions.length !== 0) {
      setFieldValue('payment.receiverExceptions', []);
    }
  }, [receiverExceptionsEnabled, payment.receiverExceptions, setFieldValue]);

  useEffect(() => {
    const selected = payment.receiverExceptions
      ?.map(ex => ex.companyIdentifier)
      .filter(identifier => identifier !== '');

    // Ignore empty company selections and any existing exceptions.
    const receivers = get(companies, 'receivers', []).filter(
      (c: ISFRCompany) => c.identifier && !selected.includes(c.identifier),
    );

    // TODO handle removing receiver exception if receiver is removed

    setCompanyItems(receivers?.map(c => ({ label: c.name, value: c.identifier })));
  }, [payment.receiverExceptions, companies, setCompanyItems]);

  function handleExceptionSelected(exception: SelectItem, index: number) {
    if (exception) {
      setFieldValue(`payment.receiverExceptions.${index}`, {
        ...payment.receiverExceptions[index],
        companyIdentifier: exception.value,
      });
    } else {
      setFieldValue(`payment.receiverExceptions.${index}`, getEmptyExceptionEntity());
    }
  }

  function getReceiverCompanyName(identifier: string) {
    return companies.receivers.find(v => v.identifier === identifier)?.name || '';
  }

  function canAddExceptions() {
    return !payment.receiverExceptions.find(({ companyIdentifier }) => companyIdentifier === '');
  }

  return (
    <Card className="pb-4">
      <SectionHeader title="Payments" subtitle="Default financial settings for payments" />
      <SectionItem
        title="Purchase Price"
        subtitle="Percentage of properly verified receivable(s)"
        controls={
          <FormikTextField
            name="payment.purchasePriceRate"
            iconSuffix={<span>%</span>}
            inputProps={{ className: 'text-right' }}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title="Payment Terms"
        subtitle="Hours to send payment after proper receivable verification"
        controls={
          <FormikTextField
            name="payment.paymentTerms"
            inputProps={{ className: 'text-right' }}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title="Receiver Exceptions"
        subtitle="Maximum purchase prices for certain receivers"
        border={false}
        controls={
          <Field
            as="checkbox"
            name="ui.receiverExceptionsEnabled"
            data-testid="receiver-exceptions-enabled"
            component={FormikToggle}
          />
        }
      />

      {receiverExceptionsEnabled && (
        <Grid>
          <Grid container className="px-4 pt-2">
            <Grid sm="2/3">
              <FormLabel label="Receiver" />
            </Grid>
            <Grid sm="1/3">
              <FormLabel label="Purchase Price" />
            </Grid>
          </Grid>
          <Grid className="p-4">
            <FieldArray
              name="payment.receiverExceptions"
              render={({ push, remove }) => (
                <>
                  {payment.receiverExceptions.map((exception: ReceiverException, index: number) => {
                    return (
                      <Grid container key={`receiver-exception-${exception.key}`} className="justify-between px-2 pb-4">
                        <Grid sm="2/3">
                          <FormikAutocomplete
                            items={companyItems}
                            onItemSelected={c => handleExceptionSelected(c, index)}
                            initialSearchTerm={getReceiverCompanyName(exception.companyIdentifier)}
                            name={`payment.receiverExceptions.${index}.companyIdentifier`}
                          />
                        </Grid>

                        <Grid sm="1/3" className="flex flex-row">
                          <FormikTextField
                            disabled={!receiverExceptionsEnabled}
                            name={`payment.receiverExceptions.${index}.purchasePriceRate`}
                            iconSuffix={<span>%</span>}
                            inputProps={{ className: 'h-10 text-right' }}
                            withSimpleErrorStyling
                          />
                          {!readOnly && receiverExceptionsEnabled && (
                            <button
                              className="bg-white focus:outline-none py-2 pl-6 pr-2"
                              color="white"
                              type="button"
                              data-testid={`remove-receiver-exception-${index}`}
                              onClick={() => remove(index)}
                            >
                              <Trash size={14} />
                            </button>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                  {!readOnly && receiverExceptionsEnabled && canAddExceptions() && (
                    <div className="py-2 px-2 border-b">
                      <button
                        className="text-primary text-sm font-bold"
                        type="button"
                        onClick={() => push(getEmptyExceptionEntity())}
                        data-testid="add-receiver-exception"
                      >
                        + Add Exception
                      </button>
                    </div>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
      )}

      <div className="px-4 mx-4 mt-2 pb-4">
        <Grid container className="border-1 rounded mt-6">
          <div className="bg-gray-100 p-4 font-bold w-full rounded rounded-b-none flex flex-row justify-between">
            <div>Payment Recipient</div>
            <div className="sm:w-1/4 font-normal">
              <FormikSelect
                name="payment.receiverRole"
                placeholder="Select Recipient Role"
                items={RECEIVER_ROLE_ITEMS}
                withSimpleErrorStyling
              />
            </div>
          </div>
          <Grid className="p-4">
            {payment.receiverRole ? (
              companies[payment.receiverRole].map((c, i) => (
                <PaymentsRecipient receiverRole={payment.receiverRole} key={c.key} company={c} index={i} />
              ))
            ) : (
              <div>Select a Recipient Role</div>
            )}
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default Payments;
