import React, { useEffect, useState } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Decimal } from 'decimal.js';
import _ from 'lodash';
import cx from 'classnames';
import { decimalSum, formatCurrency, toDecimal } from 'src/helpers/currency';
import FormikToggle from 'src/components/formik/FormikToggle';
import { Button, Grid, TextField, Select, TH } from '@producepay/pp-ui';
import AlertCircle from '@producepay/pp-ui/dist/components/icons/AlertCircle';
import ChevronDown from '@producepay/pp-ui/dist/components/icons/ChevronDown';
import ChevronUp from '@producepay/pp-ui/dist/components/icons/ChevronUp';
import Tooltip from 'src/components/elements/Tooltip';

import './table.css';

interface InSeasonTableProps {
  showHeld: boolean;
}

interface Column {
  label: string;
  sortable: boolean;
  value?: string;
  type?: string;
}

interface SortState {
  value: string;
  direction: 'asc' | 'desc';
  type: string;
}

const standardColumns: Column[] = [
  { label: 'Slug', value: 'slug', sortable: true, type: 'string' },
  { label: 'Reference #', value: 'referenceNumber', sortable: true, type: 'string' },
  { label: 'State', value: 'state', sortable: true, type: 'string' },
  { label: 'DPD', value: 'daysPastDue', sortable: true, type: 'number' },
  { label: 'Due Date', value: 'dueDate', sortable: true, type: 'string' },
  { label: 'Late Fees', value: 'lateFees', sortable: true, type: 'number' },
  { label: 'Still Due', value: 'totalDue', sortable: true, type: 'number' },
  { label: 'Minimum Due', value: 'minimumDue', sortable: true, type: 'number' },
  { label: 'Amount', sortable: false },
  { label: 'Balance', sortable: false },
];

const heldColumns: Column[] = [
  { label: 'Slug', value: 'slug', sortable: true, type: 'string' },
  { label: 'Reference #', value: 'referenceNumber', sortable: true, type: 'string' },
  { label: 'State', value: 'state', sortable: true, type: 'string' },
  { label: 'DPD', value: 'daysPastDue', sortable: true, type: 'number' },
  { label: 'Due Date', value: 'dueDate', sortable: true, type: 'string' },
  { label: 'Late Fees', value: 'lateFees', sortable: true, type: 'number' },
  { label: 'Still Due', value: 'totalDue', sortable: true, type: 'number' },
  { label: 'Minimum Due', value: 'minimumDue', sortable: true, type: 'number' },
  { label: 'Amount', sortable: false },
  { label: 'Close', sortable: false },
  { label: 'Balance', sortable: false },
  { label: 'Held', sortable: false },
];

const filterOptions = [
  { label: 'Open', value: 'open' },
  { label: 'Closed', value: 'closed' },
  { label: 'Force Closed', value: 'forceClosed' },
  { label: 'All', value: 'all' },
];

const statuses = {
  open: ['advanced', 'late', 'sold', 'payment_promised'],
  closed: ['settled', 'paid', 'closed'],
  all: ['advanced', 'late', 'sold', 'payment_promised', 'settled', 'paid', 'closed'],
};

function InSeasonTable({ showHeld }: InSeasonTableProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, ...formik } = useFormikContext<any>();
  const defaultSortState: SortState = { value: 'daysPastDue', direction: 'desc', type: 'string' };
  const [sort, setSort] = useState(defaultSortState);
  const [filter, setFilter] = useState('open');
  const [search, setSearch] = useState('');

  const columns = showHeld ? heldColumns : standardColumns;

  const calculateBalance = row => {
    const balance = new Decimal(row.minimumDue).neg().plus(row.amount || '0');
    return balance.greaterThan(0) ? balance : '0.00';
  };

  const balancePositive = row => new Decimal(calculateBalance(row)).greaterThan('0');

  // handle sort/filter side effects
  useEffect(() => {
    const mappedRows =
      sort.type === 'number'
        ? values.products.inSeason.map(row => ({ ...row, [sort.value]: parseFloat(row[sort.value]) }))
        : values.products.inSeason;
    const orderedRows = _.orderBy(mappedRows, [sort.value], [sort.direction]);
    formik.setFieldValue('products.inSeason', orderedRows);
    // missing deps [formik, values.products.inSeason]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  // handle side effects of amount changes
  useEffect(() => {
    values.products.inSeason.forEach((row, index) => {
      if (balancePositive(row) && (row.held === undefined || row.held === null)) {
        formik.setFieldValue(`products.inSeason[${index}].held`, true);
      } else if (row.held === true && !balancePositive(row)) {
        formik.setFieldValue(`products.inSeason[${index}].held`, null);
      }

      if (row.forceClosed && !row.amount) {
        formik.setFieldValue(`products.inSeason[${index}].forceClosed`, null);
      }
    });
    // missing deps [balancePositive, formik]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.products.inSeason]);

  const filterIncludesRow = row => {
    if (filter === 'forceClosed') {
      return row.minimumDueRemaining === 'true' && row.state === 'closed';
    }
    if (filter === 'closed') {
      return row.minimumDueRemaining === 'false' && row.totalDue !== '0.00' && statuses[filter].includes(row.state);
    }
    return statuses[filter].includes(row.state);
  };

  const filterRow = row => {
    if (search) {
      return (
        filterIncludesRow(row) &&
        (row.slug.toLowerCase().includes(search) || row.referenceNumber.toLowerCase().includes(search))
      );
    }
    return filterIncludesRow(row);
  };

  const handleSortClick = (value, type) => {
    const direction = sort.value === value && sort.direction === 'asc' ? 'desc' : 'asc';
    setSort({ value, direction, type });
  };

  const clearAmounts = () => {
    values.products.inSeason.forEach((value, index) => formik.setFieldValue(`products.inSeason[${index}].amount`, ''));
  };

  const belowMinimumDue = row => !!row.amount && new Decimal(row.minimumDue).neg().plus(row.amount).lessThan('0');

  const onAutofillMinimum = () => {
    clearAmounts();
    let totalAvailable = new Decimal(
      values.amount ||
        (values.sourcePayment
          ? values.sourcePayment.unreconciledAmount
          : decimalSum('excessAmount', values.excessPayments)),
    );
    values.products.inSeason.forEach((row, index) => {
      if (filterRow(row) && totalAvailable.greaterThan(row.minimumDue)) {
        formik.setFieldValue(`products.inSeason[${index}].amount`, toDecimal(row.minimumDue));
        totalAvailable = totalAvailable.minus(row.minimumDue);
      } else if (filterRow(row) && totalAvailable.greaterThan(0)) {
        formik.setFieldValue(`products.inSeason[${index}].amount`, totalAvailable.toFixed(2));
        totalAvailable = new Decimal(0);
      }
    });
  };

  const onAutofillTotal = () => {
    clearAmounts();
    let totalAvailable = new Decimal(
      values.amount ||
        (values.sourcePayment
          ? values.sourcePayment.unreconciledAmount
          : decimalSum('excessAmount', values.excessPayments)),
    );
    values.products.inSeason.forEach((row, index) => {
      if (filterRow(row) && totalAvailable.greaterThan(row.totalDue)) {
        formik.setFieldValue(`products.inSeason[${index}].amount`, toDecimal(row.totalDue));
        totalAvailable = totalAvailable.minus(row.totalDue);
      } else if (filterRow(row) && totalAvailable.greaterThan(0)) {
        formik.setFieldValue(`products.inSeason[${index}].amount`, totalAvailable.toFixed(2));
        totalAvailable = new Decimal(0);
      }
    });
  };

  return (
    <FieldArray name="products.inSeason">
      {({ form }) => (
        <div>
          <Grid container>
            <Grid sm="1/6" className="flex items-center">
              <Select items={filterOptions} size="small" onChange={item => setFilter(item.value)} />
            </Grid>
            <Grid sm="1/2" className="flex items-center">
              <TextField
                className="border"
                size="small"
                placeholder="Search by slug or invoice/reference #"
                onChange={e => setSearch(e.target.value.toLowerCase())}
                value={search}
              />
            </Grid>
            <Grid sm="1/3">
              <div className="py-2 flex flex-row-reverse">
                <div className="px-2 text-sm">
                  <Button label="Clear" type="button" variant="outlined" onClick={clearAmounts} />
                </div>
                <div className="px-2 text-sm">
                  <Button label="Autofill Min Due" type="button" onClick={onAutofillMinimum} />
                </div>
                <div className="px-2 text-sm">
                  <Button label="Autofill Total Due" type="button" onClick={onAutofillTotal} />
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="table-secondary table-p-sm table-alternating-rows">
            <table className="w-full">
              <thead>
                <tr>
                  {columns.map(th => (
                    <TH align="left" key={th.label} size="xxs-xs" weight="bold">
                      {th.label}{' '}
                      {th.sortable &&
                        (th.value === sort.value && sort.direction === 'asc' ? (
                          <ChevronUp
                            className="inline-block"
                            size={11}
                            onClick={() => handleSortClick(th.value, th.type)}
                          />
                        ) : (
                          <ChevronDown
                            className="inline-block"
                            size={11}
                            onClick={() => handleSortClick(th.value, th.type)}
                          />
                        ))}
                    </TH>
                  ))}
                </tr>
              </thead>
              <tbody>
                {form.values.products.inSeason.map(
                  (row, index) =>
                    filterRow(row) && (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={index}>
                        <td className="text-sm">
                          <a href={row.detailsUrl} rel="noopener noreferrer" target="_blank" className="text-primary">
                            {row.slug}
                          </a>
                        </td>
                        <td className="text-sm max-w-xs truncate">{row.referenceNumber}</td>
                        <td className="text-sm">{row.state}</td>
                        <td className="text-sm">{row.daysPastDue}</td>
                        <td className="text-sm">{row.dueDate}</td>
                        <td className="text-sm">{formatCurrency(row.lateFees)}</td>
                        <td className="text-sm">{formatCurrency(row.totalDue)}</td>
                        <td className="text-sm">{formatCurrency(row.minimumDue)}</td>
                        <Field name={`products.inSeason[${index}].amount`}>
                          {({ field }) => (
                            <td className="whitespace-nowrap">
                              $
                              <input
                                type="number"
                                step="0.01"
                                data-testid="in-season-amount"
                                onWheel={e => (e.target as HTMLElement).blur()}
                                className={cx(
                                  'w-32 mx-2 textfield border outline-none textfield-sm text-sm',
                                  belowMinimumDue(row)
                                    ? 'border-warning focus:border-warning-400'
                                    : 'focus:border-blue-400',
                                )}
                                {...field}
                              />
                              {belowMinimumDue(row) && (
                                <Tooltip tooltipText="Amount entered is below minimum due">
                                  <AlertCircle size={14} color="#FFAB48" className="inline" />
                                </Tooltip>
                              )}
                            </td>
                          )}
                        </Field>
                        {showHeld && (
                          <td>
                            {belowMinimumDue(row) && (
                              <Field
                                as="checkbox"
                                name={`products.inSeason[${index}].forceClosed`}
                                data-testid="in-season-force-closed-toggle"
                                component={FormikToggle}
                              />
                            )}
                          </td>
                        )}
                        <td className="text-sm">{row.totalDue && formatCurrency(calculateBalance(row))}</td>
                        {showHeld && (
                          <td>
                            {balancePositive(row) && (
                              <Field
                                as="checkbox"
                                data-testid="in-season-held-toggle"
                                name={`products.inSeason[${index}].held`}
                                component={FormikToggle}
                              />
                            )}
                          </td>
                        )}
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </FieldArray>
  );
}

export default React.memo(InSeasonTable);
