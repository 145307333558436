import React from 'react';
import { Card } from '@producepay/pp-ui';

import FormikTextField from 'src/components/formik/FormikTextField';

import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';

const PSForecastedFinancials = () => (
  <Card>
    <SectionHeader title="Forecasted Financials" subtitle="Transaction rates for reporting" />
    <SectionItem
      title="Contracted GMV"
      controls={
        <FormikTextField
          name="general.forecastedFinancials.contractedGmv"
          iconPrefix={<span>$</span>}
          inputProps={{ className: 'text-right' }}
          withSimpleErrorStyling
        />
      }
    />
    <SectionItem
      title="LTV"
      controls={
        <FormikTextField
          name="general.forecastedFinancials.ltvRate"
          iconSuffix={<span>%</span>}
          inputProps={{ className: 'text-right' }}
          withSimpleErrorStyling
        />
      }
    />
    <SectionItem
      title="APR"
      controls={
        <FormikTextField
          name="general.forecastedFinancials.aprRate"
          iconSuffix={<span>%</span>}
          inputProps={{ className: 'text-right' }}
          withSimpleErrorStyling
        />
      }
    />
    <SectionItem
      title="EAR"
      controls={
        <FormikTextField
          name="general.forecastedFinancials.earRate"
          iconSuffix={<span>%</span>}
          inputProps={{ className: 'text-right' }}
          withSimpleErrorStyling
        />
      }
    />
  </Card>
);

export default PSForecastedFinancials;
