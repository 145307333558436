import React, { FC, MouseEvent } from 'react';
import { List } from '@producepay/pp-ui';

import { Popper, PopperProps } from '../Popper';

export interface MenuProps extends Pick<PopperProps, 'referenceElement'> {
  width?: number;
  onClose?(e: MouseEvent<HTMLDivElement>): void;
}

export const Menu: FC<MenuProps> = ({ referenceElement, children, width = 200, onClose }) => {
  return (
    <Popper referenceElement={referenceElement} onClickOutside={onClose}>
      <div data-testid="menu" style={{ width }}>
        <List className="shadow cursor-pointer">{children}</List>
      </div>
    </Popper>
  );
};
