import React from 'react';
import { Formik, useFormikContext } from 'formik';

import { SectionedContent, Section } from 'src/components/elements/SectionedContent/SectionedContent';
import { DocumentRequirements } from 'src/routes/Deals/ISFR/Form/DocumentRequirements';
import { FormikDealStatus, OnDealSave } from 'src/routes/Deals/types';
import { ISFRValues, ISFRValuesPayload } from 'src/routes/Deals/ISFR/types';
import { FormAction } from '../../constants';
import DealReference from '../../components/DealReference';
import FormHeader from '../../components/FormHeader';
import { activationSchema, saveDraftSchema } from '../validationSchemas';
import { removeDefaultEntities, transformToPayload } from '../helpers';
import ApplicationFee from './ApplicationFee';
import Companies from './Companies';
import Payments from './Payments';
import RebateSchedule from './RebateSchedule';
import SourceAccounts from './SourceAccounts';
import MaximumRebateAmount from './MaximumRebateAmount';

interface ISFRFormProps {
  action: FormAction;
  onDealSave: OnDealSave;
}

interface FormikISFRFormProps extends ISFRFormProps {
  initialValues: ISFRValues;
  initialStatus: FormikDealStatus;
}

const sections: Section[] = [
  { name: 'Companies', id: 'companies', component: <Companies /> },
  { name: 'Source Accounts', id: 'source-accounts', component: <SourceAccounts /> },
  { name: 'Payments', id: 'payments', component: <Payments /> },
  { name: 'Maximum Rebate Amount', id: 'maximum-rebate-amount', component: <MaximumRebateAmount /> },
  { name: 'Application Fee', id: 'application-fee', component: <ApplicationFee /> },
  {
    name: 'Document Requirements',
    id: 'document-requirements',
    component: <DocumentRequirements />,
  },
  { name: 'Volume Rebate Schedule', id: 'rebate-schedule', component: <RebateSchedule /> },
];

const ISFRForm = ({ action, onDealSave }: ISFRFormProps) => {
  const {
    values: {
      status,
      dealUuid,
      ui: { autoRenew },
    },
  } = useFormikContext<ISFRValues>();

  return (
    <form onSubmit={e => e.preventDefault()}>
      <FormHeader<ISFRValues, ISFRValuesPayload>
        action={action}
        status={status}
        dealUuid={dealUuid}
        onDealSave={onDealSave}
        productLine="In-Season Factoring"
        activationSchema={activationSchema}
        saveDraftSchema={saveDraftSchema}
        transformToPayload={transformToPayload}
        removeDefaultEntities={removeDefaultEntities}
      />
      <DealReference autoRenew={autoRenew} renewable />
      <div className="px-6">
        <SectionedContent sections={sections} stickyOffset={50 /* offset height of the top bar */} />
      </div>
    </form>
  );
};

export default ({ action, initialStatus, initialValues, onDealSave }: FormikISFRFormProps) => {
  return (
    <Formik
      enableReinitialize
      initialStatus={initialStatus}
      initialValues={initialValues}
      onSubmit={null}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <ISFRForm action={action} onDealSave={onDealSave} />
    </Formik>
  );
};
