import React from 'react';
import { Card } from '@producepay/pp-ui';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';
import FormikSelect from 'src/components/formik/FormikSelect';
import { useFormikContext } from 'formik';
import PaymentsRecipient from 'src/routes/Deals/components/PaymentsRecipient';
import { PNTRDocumentAcceptance, PNTRValues } from 'src/routes/Deals/PNTR/types';

export const DOCUMENT_ACCEPTANCE_ITEMS: Array<{
  label: string;
  value: PNTRDocumentAcceptance;
}> = [
  { label: 'Distributor or ProducePay', value: PNTRDocumentAcceptance.DistributorOrProducePay },
  { label: 'Distributor', value: PNTRDocumentAcceptance.Distributor },
  { label: 'Not Required', value: PNTRDocumentAcceptance.NotRequired },
];

export function BalanceReturns() {
  const {
    values: { companies },
  } = useFormikContext<PNTRValues>();
  return (
    <Card>
      <SectionHeader title="Balance Returns" subtitle="Default financial settings for returning customer balances" />
      <SectionItem
        title="Document Acceptance"
        subtitle="Party responsible for accepting the provided document(s) in order to access customer balance"
        controls={<FormikSelect name="documentAcceptance" items={DOCUMENT_ACCEPTANCE_ITEMS} withSimpleErrorStyling />}
      />
      <div className="px-4 py-6">
        <div className="flex flex-wrap border-1 rounded">
          <div className="bg-gray-100 p-4 flex flex-row justify-between w-full">
            <div className="font-bold">Payment Recipient</div>
          </div>
          <div className="p-2 px-4 w-full">
            {companies.sellers.map((company, index) => (
              <PaymentsRecipient key={company.key} company={company} receiverRole="sellers" index={index} />
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}
