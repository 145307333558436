import React from 'react';
import { Card } from '@producepay/pp-ui';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';
import FormikTextField from 'src/components/formik/FormikTextField';

export function PreferredNetworkFees() {
  return (
    <Card>
      <div className="w-full">
        <SectionHeader title="Preferred Network Fees" subtitle="Default financial settings for fees per shipment" />
        <SectionItem
          title="Seller Fee"
          subtitle="Percentage of actual shipment value"
          controls={
            <FormikTextField
              name="tradingFees.sellerFeeRate"
              iconSuffix={<span>%</span>}
              inputProps={{ className: 'text-right' }}
              withSimpleErrorStyling
            />
          }
        />
        <SectionItem
          title="Buyer Fee"
          subtitle="Percentage of actual shipment value"
          controls={
            <FormikTextField
              name="tradingFees.buyerFeeRate"
              iconSuffix={<span>%</span>}
              inputProps={{ className: 'text-right' }}
              withSimpleErrorStyling
            />
          }
        />
      </div>
    </Card>
  );
}
