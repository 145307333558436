import React, { FC, useCallback, useState } from 'react';
import { FormLabel, Select } from '@producepay/pp-ui';

import { TransactionType } from '../../types';
import { CheckForm } from './CheckForm';
import { WireForm } from './WireForm';

const transactionTypeItems: Option[] = [
  { label: 'Check', value: TransactionType.Check },
  { label: 'Wire', value: TransactionType.Wire },
];

interface Option {
  label: string;
  value: TransactionType;
}

export const ManualPayment: FC = () => {
  const [transactionType, setTransactionType] = useState(transactionTypeItems[0]);
  const onChange = useCallback((item: Option) => {
    setTransactionType(item);
  }, []);

  return (
    <div>
      <FormLabel label="Payment Type" className="pb-4">
        <Select size="small" selectedItem={transactionType} items={transactionTypeItems} onChange={onChange} />
      </FormLabel>
      {transactionType.value === TransactionType.Check ? <CheckForm /> : <WireForm />}
    </div>
  );
};
