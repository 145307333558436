import React, { useState } from 'react';
import { ButtonGroup } from '@producepay/pp-ui';

import AllocateBalances from 'src/routes/Company/CompanyBalances/AllocateBalances';
import ReleaseBalances from 'src/routes/Company/CompanyBalances/ReleaseBalances';
import { FundAction } from 'src/routes/Company/CompanyBalances/types';

const actionLabel = {
  [FundAction.Allocate]: 'Allocate',
  [FundAction.Release]: 'Release',
};

interface CompanyBalancesDestinationsProps {
  companyIdentifier: string;
}

export function CompanyBalancesDestinations({ companyIdentifier }: CompanyBalancesDestinationsProps) {
  const [selectedAction, setSelectedAction] = useState(FundAction.Allocate);

  const handleActionChange = newAction => {
    setSelectedAction(newAction);
  };

  return (
    <>
      <ButtonGroup
        className="flex "
        type="button"
        options={[
          {
            label: actionLabel[FundAction.Allocate],
            value: FundAction.Allocate,
            type: 'button',
            className: 'flex-grow ',
          },
          {
            label: actionLabel[FundAction.Release],
            value: FundAction.Release,
            type: 'button',
            className: 'flex-grow',
          },
        ]}
        selectedValue={selectedAction}
        onChange={handleActionChange}
      />
      {selectedAction === FundAction.Allocate && <AllocateBalances companyIdentifier={companyIdentifier} />}
      {selectedAction === FundAction.Release && <ReleaseBalances />}
    </>
  );
}
