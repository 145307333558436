import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { map } from 'lodash';
import useSkus from 'src/hooks/use-skus';

import FormikMultiSelect from 'src/components/formik/FormikMultiSelect';
import FormikRadioGroup from 'src/components/formik/FormikRadioGroup';
import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';
import { CommodityTypes, RepaymentRule } from 'src/routes/Deals/constants';

import { PSRepaymentRule } from '../../../types';

interface RepaymentRuleProps {
  setRuleTypeValue: (value: string) => void;
}

export const FixedRepaymentRule = ({ setRuleTypeValue }: RepaymentRuleProps): JSX.Element => {
  useEffect(() => {
    setRuleTypeValue(RepaymentRule.Fixed);
  }, [setRuleTypeValue]);

  return <p className="w-full text-sm">Define the weekly fixed repayment terms in the forecast.</p>;
};

export const PercentageRepaymentRule = ({ setRuleTypeValue }: RepaymentRuleProps): JSX.Element => {
  useEffect(() => {
    setRuleTypeValue(RepaymentRule.Percentage);
  }, [setRuleTypeValue]);

  return (
    <div className="flex gap-3 items-center">
      <div className="w-4/5">
        <FormikTextField
          name="amountRate"
          label="Percentage"
          iconSuffix={<span>%</span>}
          inputProps={{ className: 'text-right' }}
          withSimpleErrorStyling
        />
      </div>
      <p className="w-full text-sm">of actual shipment value before deductions</p>
    </div>
  );
};

const mapSkuOptionsToItems = options => {
  return map(options, opt => ({ label: opt.name, value: opt.id }));
};

interface PerUnitRepaymentRuleProps extends RepaymentRuleProps {
  selectedType: string;
}

export const PerUnitRepaymentRule = ({ setRuleTypeValue, selectedType }: PerUnitRepaymentRuleProps): JSX.Element => {
  useEffect(() => {
    setRuleTypeValue(RepaymentRule.PerUnit);
  }, [setRuleTypeValue, selectedType]);

  const { values, setValues, setFieldError } = useFormikContext<PSRepaymentRule>();

  const selectedCommodityId = values.commodity?.uuid;
  const selectedVarietyId = values.variety?.uuid;
  const selectedSizeId = values.size?.uuid;

  const { commodities, varieties, packages, sizes } = useSkus({
    commodityId: selectedCommodityId,
    varietyId: selectedVarietyId,
    sizeId: selectedSizeId,
  });

  const onSelectSkuOption = newValues => {
    setValues({ ...values, ...newValues });
  };

  const classificationOptions = map(CommodityTypes, option => {
    return {
      ...option,
      selected: option.value === values?.classification,
    };
  });

  const selectedUnits = values.units.map(unitObj => ({
    label: unitObj.name,
    value: unitObj.uuid,
  }));

  return (
    <div>
      <FormikSelect
        isSearchable
        data-testid="commodity-select"
        name="commodity.uuid"
        className="pb-4"
        label="Commodity"
        placeholder="Select a commodity"
        items={mapSkuOptionsToItems(commodities)}
        onChange={item => {
          onSelectSkuOption({
            commodity: { name: item.label, uuid: item.value },
            variety: { name: null, uuid: null },
            packaging: { name: null, uuid: null },
            size: { name: null, uuid: null },
          });
          setFieldError('commodity', '');
        }}
        shouldShowDefault={false}
      />

      <FormikSelect
        isSearchable
        data-testid="variety-select"
        name="variety.uuid"
        className="pb-4"
        label="Variety (optional)"
        placeholder="All varieties"
        items={mapSkuOptionsToItems(varieties)}
        onChange={item => {
          onSelectSkuOption({ variety: { name: item.label, uuid: item.value } });
        }}
        disabled={!selectedCommodityId}
      />

      <FormikSelect
        isSearchable
        data-testid="size-select"
        name="size.uuid"
        className="pb-4"
        label="Size (optional)"
        placeholder="All sizes"
        items={mapSkuOptionsToItems(sizes)}
        onChange={item => {
          onSelectSkuOption({ size: { name: item.label, uuid: item.value } });
        }}
        disabled={!selectedCommodityId}
      />

      <FormikRadioGroup
        name="classification"
        label="Type"
        data-testid="classification-select"
        className="pb-4"
        options={classificationOptions}
      />

      <div className="flex items-start gap-4">
        <FormikTextField iconPrefix={<span>$</span>} className="w-full" name="amount" label="Amount Per Unit" />

        <p className="text-sm mt-6">per</p>

        <FormikMultiSelect
          isSearchable
          data-testid="packaging-select"
          inputProps={{ className: 'mt-4' }}
          multipleItemLabel="types of packaging"
          name="units"
          placeholder="Select packaging"
          size="small"
          items={mapSkuOptionsToItems(packages)}
          onChange={selectedItems => {
            onSelectSkuOption({
              units: selectedItems.map(item => ({
                name: item.label,
                uuid: item.value,
              })),
            });
          }}
          selectedItem={selectedUnits.length === 1 ? selectedUnits[0] : null}
          selectedItems={selectedUnits}
          disabled={!selectedCommodityId}
        />
      </div>
    </div>
  );
};
