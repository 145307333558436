import * as Yup from 'yup';

import { PNMSDocumentAcceptanceRole, PNMSRole, PNMSValues } from 'src/routes/Deals/PNMS/types';
import { decimalString } from '../helpers';

const validateCompaniesFundingAccounts = (value, context) => {
  const {
    path,
    parent: {
      payment: { receiverRole },
    },
    createError,
  } = context;
  const companies = value[receiverRole] ?? [];
  const errors: Array<Yup.ValidationError> = [];

  companies.forEach((company, companyIndex) => {
    const { fundingAccounts = [] } = company;

    fundingAccounts.forEach((fundingAccount, index) => {
      if (!fundingAccount.accountId) {
        const error = createError({
          path: `${path}.${receiverRole}.${companyIndex}.fundingAccounts.${index}.accountId`,
          message: 'Bank Account is required',
        });

        errors.push(error);
      }
    });
  });

  if (errors.length) {
    throw new Yup.ValidationError(errors);
  }

  return true;
};

const companySchema = Yup.object().shape({
  identifier: Yup.string()
    .when('role', {
      is: role => role === 'distributor',
      then: Yup.string().nullable().required('Distributor Company is required'),
      otherwise: Yup.string().nullable().required('Producer Company is required'),
    })
    .nullable()
    .required('Company Required'),
  role: Yup.mixed().oneOf(['producer', 'distributor']),
  contactName: Yup.string().nullable(),
  name: Yup.string().nullable(),
  key: Yup.string().nullable(),
  fundingAccounts: Yup.array().of(
    Yup.object().shape({
      accountId: Yup.string().nullable(),
      value: decimalString('Amount'),
      key: Yup.string().nullable(),
      type: Yup.string().nullable(),
    }),
  ),
});

export const saveDraftSchema: PNMSValidationSchema = Yup.object().shape({
  productLineId: Yup.number().required(),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  dealUuid: Yup.string().nullable(),
  status: Yup.string(),
  effectiveOn: Yup.string().nullable(),
  endsOn: Yup.string().nullable(),
  companies: Yup.object().shape({
    distributor: Yup.array().of(companySchema).min(1, 'Distributor Company is required'),
    producer: Yup.array().of(companySchema).min(1, 'Producer Company is required'),
  }),
  defaultBankAccounts: Yup.object().shape({
    sourceAccounts: Yup.array(),
  }),
  payment: Yup.object().shape({
    receiverRole: Yup.mixed<PNMSRole>().nullable().required('Payment Recipient is required'),
    requiredItems: Yup.array(),
    documentAcceptanceRole: Yup.mixed<PNMSDocumentAcceptanceRole>(),
    paymentRate: Yup.string().nullable(),
  }),
  distributionFee: Yup.object().shape({
    rate: Yup.string().nullable(),
  }),
  lateFee: Yup.object().shape({
    makeGoodDeliveryDays: Yup.string().nullable(),
    dailyLateFeeRate: Yup.string().nullable(),
  }),
  ui: Yup.object().shape({
    autoRenew: Yup.boolean(),
  }),
});

const activationCompanySchema = Yup.object().shape({
  identifier: Yup.string()
    .when('role', {
      is: role => role === 'distributor',
      then: Yup.string().nullable().required('Distributor Company is required'),
      otherwise: Yup.string().nullable().required('Producer Company is required'),
    })
    .nullable()
    .required('Company is required'),
  role: Yup.mixed().oneOf(['producer', 'distributor']),
  contactName: Yup.string().when('role', {
    is: role => role === 'distributor',
    then: Yup.string().nullable().required('Distributor Contact is required'),
    otherwise: Yup.string().nullable().required('Producer Contact is required'),
  }),
  name: Yup.string().nullable(),
  key: Yup.string().nullable(),
  fundingAccounts: Yup.array().of(
    Yup.object().shape({
      accountId: Yup.string().nullable(),
      value: decimalString('Amount'),
      key: Yup.string().nullable(),
      type: Yup.string().nullable(),
    }),
  ),
});

export const activationSchema: PNMSValidationSchema = Yup.object().shape({
  productLineId: Yup.number().required(),
  dealReference: Yup.string().nullable().required('Deal ID is required'),
  dealUuid: Yup.string().nullable(),
  status: Yup.string(),
  effectiveOn: Yup.string().nullable().required('Effective Date is required'),
  endsOn: Yup.string().when('$autoRenew', {
    is: autoRenew => !autoRenew,
    then: Yup.string().nullable().required('End Date is required'),
    otherwise: Yup.string().nullable(),
  }),
  companies: Yup.object()
    .shape({
      distributor: Yup.array().of(activationCompanySchema).min(1, 'Distributor Company is required'),
      producer: Yup.array().of(activationCompanySchema).min(1, 'Producer Company is required'),
    })
    .test(validateCompaniesFundingAccounts),
  defaultBankAccounts: Yup.object().shape({
    sourceAccounts: Yup.array().of(
      Yup.object().shape({
        identifier: Yup.string().when('purpose', {
          is: purpose => purpose === 'payment',
          then: Yup.string().nullable().required('Payment Source Account is required'),
          otherwise: Yup.string().nullable().required('Balance Return Source Account is required'),
        }),
        purpose: Yup.string().nullable().required(),
      }),
    ),
  }),
  payment: Yup.object().shape({
    receiverRole: Yup.mixed<PNMSRole>().required('Payment Recipient is required'),
    requiredItems: Yup.array(),
    documentAcceptanceRole: Yup.mixed<PNMSDocumentAcceptanceRole>().required('Document Acceptance Role is required'),
    paymentRate: decimalString('Payment Ratio').required('Payment Ratio is required'),
  }),
  distributionFee: Yup.object().shape({
    rate: Yup.string().nullable().required('Distribution Fee Ratio is required'),
  }),
  lateFee: Yup.object().shape({
    makeGoodDeliveryDays: Yup.string().nullable().required('Make Good Delivery Days is required'),
    dailyLateFeeRate: Yup.string().nullable().required('Daily Late Fee Calculation is required'),
  }),
  ui: Yup.object().shape({
    autoRenew: Yup.boolean(),
  }),
});

export type PNMSValidationSchema = Yup.SchemaOf<PNMSValues>;
