import React from 'react';
import _ from 'lodash';

interface FormLabelProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  label: string;
  error?: string;
  for?: string;
}

function FormLabel(props: FormLabelProps): JSX.Element {
  const { className = '', id, for: htmlFor, label, error = null, children } = props;

  return (
    <div className={className}>
      <div>
        <label className="text-gray-500 caption uppercase" htmlFor={htmlFor} id={id}>
          {label}
        </label>
      </div>

      {children}

      {error ? (
        <div className="pt-1 body2 text-red-600">
          {_.startCase(label)}
          {error}
        </div>
      ) : null}
    </div>
  );
}

export default React.memo(FormLabel);
