import React, { memo } from 'react';
import { CellProps } from 'react-table';
import cx from 'classnames';
import { Align, AlignCellContent, TextAlignClassName } from './AlignCellContent';

export const DisplayCell = memo(
  ({
    value,
    column: { formatter, align = Align.Right },
  }: CellProps<Record<string, unknown>> & { column: { formatter?(value: unknown): string; align?: Align } }) => {
    const textAlign = TextAlignClassName[align];
    return (
      <AlignCellContent align={align}>
        <div>
          <span className={cx('body2', textAlign)}>{formatter?.(value) ?? value}</span>
        </div>
      </AlignCellContent>
    );
  },
  (prev, next) => prev.value === next.value,
);
