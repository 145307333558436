import React, { useEffect, useMemo } from 'react';
import { Field, Formik, useField, useFormikContext } from 'formik';
import { nextFriday, differenceInWeeks, addWeeks } from 'date-fns';

import * as Yup from 'yup';
import { last } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import FormikDatePicker from 'src/components/formik/FormikDatePicker';
import FormikTextField from 'src/components/formik/FormikTextField';
import { PSForecastValues, PSValues } from '../../types';
import { getEndDate, getStartDate } from './helpers';

interface DateRange {
  from: Date;
  to: Date;
}

function useDateRange() {
  const {
    values: {
      general: { effectiveOn },
      forecast,
      commodities,
    },
  } = useFormikContext<PSValues>();
  const startDate = getStartDate(forecast, effectiveOn);
  const endDate = getEndDate(forecast, startDate);
  const [, , { setValue }] = useField<PSForecastValues>('forecast');
  const dateRange = useMemo(
    () => ({
      from: startDate,
      to: endDate,
    }),
    [startDate, endDate],
  );

  return {
    dateRange,
    onChange(newDateRange: DateRange) {
      // TODO Kirill: try to get rid of this, or maybe remove first row creation in ForecastView
      if (forecast.length === 0) {
        return;
      }
      const newForecast = [];
      if (newDateRange.from < forecast[0].week) {
        let fromDate = newDateRange.from;
        while (fromDate < forecast[0].week && fromDate <= newDateRange.to) {
          newForecast.push({
            forecastUuid: uuidv4(),
            week: fromDate,
            // TODO Kirill: get rid of code duplication with items creation
            items: Object.fromEntries(
              commodities.map(item => [item.forecastCommodityUuid, { uuid: item.forecastCommodityUuid, units: null }]),
            ),
          });
          fromDate = nextFriday(fromDate);
        }
      }
      if (forecast[0].week <= newDateRange.to) {
        let fromDate = newDateRange.from <= forecast[0].week ? forecast[0].week : newDateRange.from;
        let index = forecast.findIndex(item => item.week.getTime() === fromDate.getTime());
        if (index !== -1) {
          while (fromDate <= last(forecast).week && fromDate <= newDateRange.to) {
            newForecast.push(forecast[index]);
            index += 1;
            fromDate = nextFriday(fromDate);
          }
        }
      }
      if (last(forecast).week < newDateRange.to) {
        let fromDate = newDateRange.from <= last(forecast).week ? nextFriday(last(forecast).week) : newDateRange.from;
        while (fromDate <= newDateRange.to) {
          newForecast.push({
            forecastUuid: uuidv4(),
            week: fromDate,
            items: Object.fromEntries(
              commodities.map(item => [item.forecastCommodityUuid, { uuid: item.forecastCommodityUuid, units: null }]),
            ),
          });
          fromDate = nextFriday(fromDate);
        }
      }
      setValue(newForecast);
    },
  };
}

// TODO Kirill: validate that both dates are Fridays
const validationSchema = Yup.object({
  from: Yup.date(),
  to: Yup.date().min(Yup.ref('from'), 'Must be later than start date'),
  numberOfWeeks: Yup.number()
    .typeError('Must be a number')
    .integer()
    .moreThan(0, 'Must be more than 0')
    .required('Required'),
});

const FridayPicker = props => {
  return (
    <FormikDatePicker
      {...props}
      dayPickerProps={{
        modifiers: {
          disabled: { daysOfWeek: [0, 1, 2, 3, 4, 6] },
        },
      }}
      errorClearable={false}
    />
  );
};

const DateRangeForm = () => {
  const {
    submitForm,
    values: { from, to },
  } = useFormikContext<DateRange>();
  useEffect(() => {
    submitForm();
  }, [from, to, submitForm]);
  return (
    <div className="flex justify-end items-baseline">
      <span className="body2 font-bold pr-4">Forecasted Shipping Dates</span>
      <div className="w-1/5 pr-4">
        <Field
          name="from"
          component={FridayPicker}
          inputProps={{ name: 'from' }}
          placeholderDate="MM/DD/YYYY"
          withSimpleErrorStyling
        />
      </div>
      <span className="body2 pr-4">to</span>
      <div className="w-1/5 pr-4">
        <Field
          name="to"
          component={FridayPicker}
          inputProps={{ name: 'to' }}
          placeholderDate="MM/DD/YYYY"
          withSimpleErrorStyling
        />
      </div>
      <div className="w-10">
        <FormikTextField
          name="numberOfWeeks"
          type="number"
          inputProps={{ onBlur: submitForm, className: 'text-center' }}
          withSimpleErrorStyling
          errorClearable={false}
        />
      </div>
      <span className="body2 pl-4">weeks</span>
    </div>
  );
};

export const Actions = () => {
  const { dateRange, onChange } = useDateRange();
  const initialValues = useMemo(
    () => ({ ...dateRange, numberOfWeeks: differenceInWeeks(dateRange.to, dateRange.from) + 1 }),
    [dateRange],
  );
  // TODO Kirill: rewrite Actions without Formik?
  // Just don't have time right now to extract common DatePicker logic from FormikDatePicker
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={({ from, to, numberOfWeeks }) => {
        if (dateRange.from.getTime() !== from.getTime() || dateRange.to.getTime() !== to.getTime()) {
          onChange({ from, to });
        } else {
          onChange({ from, to: addWeeks(from, numberOfWeeks - 1) });
        }
      }}
    >
      <DateRangeForm />
    </Formik>
  );
};
