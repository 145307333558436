import React from 'react';
import { Formik, useFormikContext } from 'formik';

import { SectionedContent, Section } from 'src/components/elements/SectionedContent/SectionedContent';
import { SourceAccounts } from 'src/routes/Deals/PNTR/Form/SourceAccounts';
import { Companies } from 'src/routes/Deals/PNTR/Form/Companies';
import { BalanceReturns } from 'src/routes/Deals/PNTR/Form/BalanceReturns';
import { DocumentRequirements } from 'src/routes/Deals/PNTR/Form/DocumentRequirements';
import { PNTRValues, PNTRValuesPayload } from 'src/routes/Deals/PNTR/types';
import { FormikDealStatus, OnDealSave } from 'src/routes/Deals/types';
import { RepaymentTerms } from 'src/routes/Deals/PNTR/Form/RepaymentTerms';
import { FormAction } from 'src/routes/Deals/constants';
import { removeDefaultEntities, transformToPayload } from 'src/routes/Deals/PNTR/helpers';
import { activationSchema, saveDraftSchema } from 'src/routes/Deals/PNTR/validationSchemas';
import FormHeader from 'src/routes/Deals/components/FormHeader';
import DealReference from 'src/routes/Deals/components/DealReference';
import { PreferredNetworkFees } from 'src/routes/Deals/PNTR/Form/PreferredNetworkFees';
import { TradeAssurance } from 'src/routes/Deals/PNTR/Form/TradeAssurance';

interface PNTRFormProps {
  action: FormAction;
  onDealSave: OnDealSave;
}

interface FormikPNTRFormProps extends PNTRFormProps {
  initialValues: PNTRValues;
  initialStatus: FormikDealStatus;
}

const sections: Section[] = [
  { name: 'Companies', id: 'companies', component: <Companies /> },
  { name: 'Source Accounts', id: 'source-accounts', component: <SourceAccounts /> },
  {
    name: 'Balance Returns',
    id: 'balance-returns',
    component: <BalanceReturns />,
  },
  { name: 'Preferred Network Fees', id: 'preferred-network-fees', component: <PreferredNetworkFees /> },
  { name: 'Repayment Terms & Alerts', id: 'repayment-terms', component: <RepaymentTerms /> },
  { name: 'Trade Assurance', id: 'trade-assurance', component: <TradeAssurance /> },
  { name: 'Document Requirements', id: 'document-requirements', component: <DocumentRequirements /> },
];

const PNTRForm = ({ action, onDealSave }: PNTRFormProps) => {
  const {
    values: {
      status,
      dealUuid,
      ui: { autoRenew },
    },
  } = useFormikContext<PNTRValues>();

  return (
    <form onSubmit={e => e.preventDefault()}>
      <FormHeader<PNTRValues, PNTRValuesPayload>
        action={action}
        status={status}
        dealUuid={dealUuid}
        onDealSave={onDealSave}
        productLine="Preferred Network Trading"
        activationSchema={activationSchema}
        saveDraftSchema={saveDraftSchema}
        transformToPayload={transformToPayload}
        removeDefaultEntities={removeDefaultEntities}
      />
      <DealReference autoRenew={autoRenew} renewable />
      <div className="px-6">
        <SectionedContent sections={sections} stickyOffset={50 /* offset height of the top bar */} />
      </div>
    </form>
  );
};

export default ({ action, initialValues, initialStatus, onDealSave }: FormikPNTRFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      initialStatus={initialStatus}
      onSubmit={null}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      <PNTRForm action={action} onDealSave={onDealSave} />
    </Formik>
  );
};
