import React from 'react';
import { Card } from '@producepay/pp-ui';

import { DocList } from 'src/routes/Deals/components/DocList';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';

const DocumentRequirements = () => {
  return (
    <Card className="pb-6">
      <SectionHeader title="Document Requirements" subtitle="Default documents needed to enable payment" />
      <DocList
        showError
        className="pt-8"
        items={[
          {
            name: 'Notice of Intent',
            formLocation: 'general.repayment.requiredItems.0.type',
          },
        ]}
      />
    </Card>
  );
};

export default DocumentRequirements;
