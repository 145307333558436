import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import * as routes from 'src/routes';
import { PNMSCreate } from 'src/routes/Deals/PNMS/Create';
import ISPPCreate from './ISPP/Create';
import ISFRCreate from './ISFR/Create';
import PNMCreate from './PNM/Create';
import PNTRCreate from './PNTR/Create';
import PSCreate from './PS/Create';
import EditRouter from './Edit';
import { DealsList } from '../DealsList';

function Deals(): JSX.Element {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={routes.dealsISPPCreate()}>
        <ISPPCreate />
      </Route>
      <Route path={routes.dealsISFRCreate()}>
        <ISFRCreate />
      </Route>
      <Route path={routes.dealsPNMCreate()}>
        <PNMCreate />
      </Route>
      <Route path={routes.dealsPNTRCreate()}>
        <PNTRCreate />
      </Route>
      <Route path={routes.dealsPNMSCreate()}>
        <PNMSCreate />
      </Route>
      <Route path={routes.dealsPSCreate()}>
        <PSCreate />
      </Route>
      <Route path={routes.dealsEdit(':dealUuid')}>
        <EditRouter />
      </Route>
      <Route path={match.path}>
        <DealsList />
      </Route>
    </Switch>
  );
}

export default Deals;
