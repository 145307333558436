import React, { useCallback, useMemo, useState } from 'react';
import { Button } from '@producepay/pp-ui';
import { format, subDays } from 'date-fns';
import { useQuery } from '@apollo/client';
import { times } from 'lodash';
import { useFilters, useGlobalFilter, usePagination, useSortBy } from 'react-table';

import { currencyCellFormatter, HeaderCell, ReactTableContext, useReactTable } from 'src/components/elements/Table';
import GET_FUNDING_ACCOUNTS from 'src/graphql/queries/getFundingAccounts';
import { CompaniesAutocomplete } from 'src/graphql/queries/graphql-types/CompaniesAutocomplete';
import { GET_COMPANIES_AUTOCOMPLETE } from 'src/graphql/queries/getCompanies';
import { PRODUCE_PAY_COMPANY_IDENTIFIER } from 'src/helpers/products';

import Header from '../Header';
import PaymentsFilterBar from '../FilterBar/PaymentsFilterBar';
import { AddPaymentModal } from './AddPaymentModal';
import { PayeeCompanyCell } from './PayeeCompanyCell';
import { PaymentTypeCell } from './PaymentTypeCell';
import { RecipientAccountCell } from './RecipientAccountCell';
import { ActionCell } from './ActionCell';
import { ReconcilePaymentsTable } from './ReconcilePaymentsTable';
import { insideDateRange, oneOf, globalFilter } from './helpers';
import { ColumnAccessors } from './constants';

const paymentTypes = [
  {
    label: 'ACH',
    value: 'ach',
  },
  {
    label: 'Wire',
    value: 'wire',
  },
  {
    label: 'Check',
    value: 'check',
  },
];

const AddPaymentButton = ({ onClick = undefined }) => (
  <Button onClick={onClick} type="button" className="w-40 ml-2">
    Add Payment
  </Button>
);

// Mock data
const initialData = times(100, () => [
  {
    receivedDate: subDays(new Date(), 2),
    recipientAccountUuid: '93654786-af86-48f3-a3a9-77d00165560a',
    importedOriginatorCompanyName: 'Abc Co',
    sourceCompanyUuid: 'companies_659',
    transactionType: 'wire',
    transactionNumber: 'DWR-1234',
    transactionDescription: 'Details: TRANSFER FROM DD 80006065959/',
    amount: '40100.10',
    kept: true,
    discarded: false,
  },
  {
    receivedDate: new Date(),
    recipientAccountUuid: '9cbec432-5cab-4cce-be9b-32f20127bc65',
    importedOriginatorCompanyName: 'Xyz Co',
    sourceCompanyUuid: null,
    transactionType: 'check',
    transactionNumber: 'DWR-00373514',
    transactionDescription: 'Details: TRANSFER FROM DD 80006065959/',
    amount: '38900.55',
    kept: false,
    discarded: false,
  },
  {
    receivedDate: new Date(),
    recipientAccountUuid: '9cbec432-5cab-4cce-be9b-32f20127bc65',
    importedOriginatorCompanyName: 'Xyz Co',
    sourceCompanyUuid: null,
    transactionType: 'check',
    transactionNumber: 'DWR-00373514',
    transactionDescription: 'Details: TRANSFER FROM DD 80006065959/',
    amount: '38900.55',
    kept: false,
    discarded: true,
  },
]).flat();

const Imports = () => {
  const { data: { fundingAccounts } = {} } = useQuery<{
    fundingAccounts: {
      identifier: string;
      institutionName?: string;
      name: string;
    }[];
  }>(GET_FUNDING_ACCOUNTS, {
    variables: {
      companyIdentifier: PRODUCE_PAY_COMPANY_IDENTIFIER,
    },
  });
  const { data: { companies } = {} } = useQuery<CompaniesAutocomplete>(GET_COMPANIES_AUTOCOMPLETE);
  const isLoading = !fundingAccounts || !companies;
  const fundingAccountOptions = (fundingAccounts ?? []).map(item => ({ label: item.name, value: item.identifier }));

  const [data, setData] = useState(initialData);

  const onEdit = useCallback(
    newData => {
      setData(newData);
    },
    [setData],
  );

  const columns = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return [
      {
        Header: props => <HeaderCell {...props} name="Received On" className="text-gray-500 uppercase" sortable />,
        accessor: ColumnAccessors.ReceivedDate,
        formatter: date => format(date, 'MM-dd-yyyy'),
        align: 'center',
        width: 150,
        canResize: false,
        filter: insideDateRange,
        sortType: 'datetime',
      },
      {
        Header: props => <HeaderCell {...props} name="Recipient Acct" className="text-gray-500 uppercase" />,
        Cell: props => <RecipientAccountCell {...props} fundingAccounts={fundingAccounts} />,
        accessor: ColumnAccessors.RecipientAccountUuid,
        align: 'left',
        width: 150,
        canResize: false,
        filter: oneOf,
      },
      {
        Header: props => <HeaderCell {...props} name="Payee Company" className="text-gray-500 uppercase" />,
        Cell: props => <PayeeCompanyCell {...props} companies={companies} onEdit={onEdit} />,
        accessor: ColumnAccessors.SourceCompanyUuid,
        align: 'left',
        filter: oneOf,
      },
      {
        Header: props => <HeaderCell {...props} name="Payment Type" className="text-gray-500 uppercase" />,
        Cell: PaymentTypeCell,
        accessor: ColumnAccessors.TransactionType,
        align: 'left',
        width: 150,
        canResize: false,
        filter: oneOf,
      },
      {
        Header: props => <HeaderCell {...props} name="Available Amount" className="text-gray-500 uppercase" />,
        accessor: ColumnAccessors.Amount,
        formatter: currencyCellFormatter,
        width: 150,
        canResize: false,
      },
      {
        Header: props => <HeaderCell {...props} name="" />,
        Cell: props => <ActionCell {...props} onEdit={onEdit} />,
        id: 'action',
      },
    ];
  }, [isLoading, fundingAccounts, companies, onEdit]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const tableInstance = useReactTable({
    data,
    columns,
    customOptions: { autoResetPage: false, autoResetFilters: false, autoResetGlobalFilter: false, globalFilter },
    pluginHooks: [useFilters, useGlobalFilter, useSortBy, usePagination],
    initialState: { sortBy: [{ id: ColumnAccessors.ReceivedDate, desc: true }] },
  });

  return (
    <div>
      <Header actionComponents={<AddPaymentButton onClick={openModal} />} />
      <ReactTableContext.Provider value={tableInstance}>
        <PaymentsFilterBar
          payeeCompanies={companies ?? []}
          fundingAccounts={fundingAccountOptions}
          paymentTypes={paymentTypes}
        />
        <ReconcilePaymentsTable isLoading={isLoading} />
      </ReactTableContext.Provider>
      {isModalOpen && <AddPaymentModal actions={{ onSave: closeModal, onClose: closeModal, onCancel: closeModal }} />}
    </div>
  );
};

export default Imports;
