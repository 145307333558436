import React, { useEffect, useState, useRef } from 'react';
import { Checkbox, Select, Button, Grid, InlineDateRangePicker, TextField } from '@producepay/pp-ui';
import { useQuery } from '@apollo/client';
import SearchIcon from '@producepay/pp-ui/dist/components/icons/Search';
import { formatISO, subDays, subBusinessDays, startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { cleanNumericString } from 'src/helpers/currency';
import GET_COMPANIES from 'src/graphql/queries/getCompanies';
import Paginator from '../../molecules/Paginator';

export interface FundActivitiesQuery {
  companyIds: Array<number>;
  startDate: Date;
  endDate: Date;
  dateRangeFor: string;
  searchText: string;
  entryTypes: Array<string>;
  perPage: number;
  page: number;
  showReverted: boolean;
}

interface FundActivitiesQueryBarProps {
  pageSize?: number;
  onChange(FundActivitiesQuery): void;
  totalRecords: number;
}

const DATE_RANGE_FOR_OPTIONS = [
  { label: 'Created on', value: 'createdAt' },
  { label: 'Effective on', value: 'effectiveDate' },
];

const PRESET_OPTIONS = [
  {
    label: 'Today',
    start: new Date(),
    end: new Date(),
  },
  {
    label: 'Last Biz Day',
    start: subBusinessDays(new Date(), 1),
    end: subBusinessDays(new Date(), 1),
  },
  {
    label: 'Last 7 Days',
    start: subDays(new Date(), 7),
    end: new Date(),
  },
  {
    label: 'Last 30 Days',
    start: subDays(new Date(), 30),
    end: new Date(),
  },
  {
    label: 'This Month',
    start: startOfMonth(new Date()),
    end: new Date(),
  },
  {
    label: 'Last Month',
    start: startOfMonth(subMonths(new Date(), 1)),
    end: endOfMonth(subMonths(new Date(), 1)),
  },
];

const ENTRY_TYPES = [
  { label: 'Sent Payment', value: 'Sent Payment' },
  { label: 'Receive Payment', value: 'Received Payment' },
  { label: 'Transfer', value: 'Transfer' },
];

const QueryBar = ({ pageSize = 30, onChange, totalRecords }: FundActivitiesQueryBarProps) => {
  const [companyIdentifiers, setCompanyIdentifiers] = useState([]);
  const [dateRangeFor, setDateRangeFor] = useState(DATE_RANGE_FOR_OPTIONS[0]);
  const [endDate, setEndDate] = useState(new Date());
  const [perPage, setPerPage] = useState(pageSize);
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [entryTypes, setEntryTypes] = useState(ENTRY_TYPES);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [showReverted, setShowReverted] = useState(false);
  const lastQuery = useRef(null);
  useEffect(() => {
    let newQuery = {
      companyIdentifiers: companyIdentifiers.map(selectOption => selectOption.value),
      startDate: formatISO(startDate, { representation: 'date' }),
      endDate: formatISO(endDate, { representation: 'date' }),
      dateRangeFor: dateRangeFor.value,
      searchText,
      entryTypes: entryTypes.map(selectOption => selectOption.value),
      perPage,
      page,
      showReverted,
    };
    if (lastQuery.current && page === lastQuery.current.page) {
      newQuery = { ...newQuery, page: 1 };
    }
    lastQuery.current = newQuery;
    onChange(newQuery);
    // missing deps: [onChange]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyIdentifiers, startDate, endDate, dateRangeFor, searchText, entryTypes, perPage, page, showReverted]);
  const resetFilters = () => {
    setCompanyIdentifiers([]);
    setSearchText('');
    setShowReverted(false);
    setPage(1);
  };
  // company filter related code
  const { data } = useQuery(GET_COMPANIES);
  // reset filters
  const companies = data ? data.companies.map(company => ({ label: company.name, value: company.identifier })) : [];
  return (
    <form>
      <Grid container>
        <Grid sm="1/6">
          <InlineDateRangePicker
            className="mb-4"
            size="small"
            startDate={startDate}
            endDate={endDate}
            presetRanges={PRESET_OPTIONS}
            selectOptions={DATE_RANGE_FOR_OPTIONS}
            selectedItem={dateRangeFor}
            onChangeSelectedItem={setDateRangeFor}
            onChange={(newStartDate, newEndDate) => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
          />
        </Grid>
        <Grid sm="1/3">
          <Select
            className="text-left whitespace-nowrap overflow-hidden"
            isMultiple
            isSearchable
            items={companies}
            selectedItems={companyIdentifiers}
            onChange={setCompanyIdentifiers}
            multipleItemLabel="companies"
            placeholder="Company"
          />
        </Grid>
        <Grid sm="1/6">
          <Select
            className="text-left"
            isMultiple
            items={ENTRY_TYPES}
            selectedItems={entryTypes}
            onChange={newEntryTypes => {
              setEntryTypes(newEntryTypes);
            }}
            multipleItemLabel="entry types"
            placeholder="Entry Types"
          />
        </Grid>
        <Grid className="flex flex-row items-center" sm="1/6">
          <Checkbox
            checked={showReverted}
            value={showReverted}
            className="inline-block mr-2"
            onClick={() => {
              setShowReverted(!showReverted);
            }}
          />
          Show reverted entries
        </Grid>
        <Grid className="flex justify-end" sm="1/6">
          <Button
            className="px-8 mr-2"
            label="Clear"
            color="secondary"
            variant="solid"
            type="reset"
            disabled={!(searchText || companies.length)}
            onClick={resetFilters}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid sm="1/3">
          <TextField
            placeholder="Search by slug, inv/ref/chk # or exact amount"
            iconSuffix={<SearchIcon size="16" color="#ffffff" />}
            color="primary"
            value={searchText}
            onChange={e => {
              setSearchText(cleanNumericString(e.target.value));
            }}
          />
        </Grid>
        <Grid sm="2/3" className="flex flex-row-reverse">
          <Paginator
            setPage={setPage}
            page={page}
            totalCount={totalRecords}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default QueryBar;
