import React from 'react';
import { Field, FieldArray, FormikErrors } from 'formik';
import { Card } from '@producepay/pp-ui';
import { PlusCircle } from 'react-feather';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';
import FormikDatePicker from 'src/components/formik/FormikDatePicker';
import BorderedButton from 'src/components/elements/BorderedButton';
import { PSRepaymentValue, PSRepaymentRule, PSValues } from 'src/routes/Deals/PS/types';
import RepaymentModal from './Modal';

import RepaymentRulesTable from './RulesTable';

interface ModalState {
  show: boolean;
  index: number;
}

interface ModalAction {
  type: 'create' | 'edit' | 'cancel' | 'close' | 'save';
  callback?: (...args) => void;
  index?: number;
}

const modalReducer = (state: ModalState, action: ModalAction) => {
  switch (action.type) {
    case 'create':
      return { show: true, index: action.index };
    case 'edit':
      return { show: true, index: action.index };
    case 'save':
      action.callback();
      return { show: false, index: action.index + 1 };
    case 'cancel':
      return { show: false, index: action.index };
    case 'close':
      return { show: false, index: action.index };
    default:
      throw new Error('Invalid action type');
  }
};

interface RepaymentProps {
  formData: {
    repayment: PSRepaymentValue;
  };
  errors: FormikErrors<PSValues>;
}

const Repayment = ({ formData, errors }: RepaymentProps): JSX.Element => {
  const [modalState, dispatchModal] = React.useReducer(modalReducer, { show: false, index: 0 });
  const { repayment } = formData;

  return (
    <Card>
      <SectionHeader title="Repayment" subtitle="Default financial settings for recuperation" />
      <SectionItem
        title="Repayment Deadline"
        controls={
          <Field
            name="general.repayment.deadline"
            component={FormikDatePicker}
            inputProps={{ name: 'general.repayment.deadline' }}
            placeholderDate="MM-DD-YYYY"
            withSimpleErrorStyling
          />
        }
      />
      <FieldArray
        name="general.repayment.rules"
        render={({ remove }) => (
          <>
            <SectionItem
              title="Repayment Rules"
              border={false}
              subtitle="Fixed, percentage, and per unit rules for collecting funds for repayment"
              controls={
                <BorderedButton
                  data-testid="add-repayment-rule-button"
                  error={
                    errors.general?.repayment && !repayment.rules.length && (errors.general?.repayment.rules as string)
                  }
                  onClick={() => dispatchModal({ type: 'create', index: repayment.rules.length })}
                  icon={<PlusCircle />}
                >
                  Add Repayment Rule
                </BorderedButton>
              }
            />
            {modalState.show ? (
              <RepaymentModal
                rules={repayment.rules}
                ruleIndex={modalState.index}
                actions={{
                  onSave: rule =>
                    dispatchModal({
                      type: 'save',
                      callback: () => {
                        const rules = [...repayment.rules];
                        rules[modalState.index] = rule as PSRepaymentRule;
                        repayment.rules = rules;
                      },
                      index: repayment.rules.length,
                    }),
                  onClose: () => dispatchModal({ type: 'close' }),
                  onCancel: () => dispatchModal({ type: 'cancel' }),
                }}
              />
            ) : null}
            {repayment.rules.length > 0 && (
              <div className="px-6 pb-6">
                <RepaymentRulesTable
                  actions={{
                    onDelete: remove,
                    onEdit: (ruleIndex: number) => dispatchModal({ type: 'edit', index: ruleIndex }),
                  }}
                  rules={repayment.rules}
                />
              </div>
            )}
          </>
        )}
      />
    </Card>
  );
};

export default Repayment;
