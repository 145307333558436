import React from 'react';
import { dateTimeStringToDate } from 'src/helpers/dates';
import { formatCurrency } from 'src/helpers/currency';
import { Datum, Row } from 'src/components/molecules/ElasticTable';
import FundActivityDetails from '../FundActivityDetails';
import RevertButton, { REVERTABLE_FUND_ACTIVITY_TYPES } from './RevertButton';

interface Details {
  allocations: FundActivityAllocation[];
  checkReleaseDate: string;
  paymentMethod: string;
}

interface FundActivityRowProps {
  createdOn: string;
  defaultOpen: boolean;
  effectiveOn: string;
  identifier: string;
  legacyPaymentUrl: string;
  companyName: string;
  entryType: string;
  amount: string;
  checkNumber: string;
  details: Details;
  reverted: boolean;
  refetch: () => void;
}

function FundActivityRow(props: FundActivityRowProps): JSX.Element {
  const dotColor = {
    'Received Payment': '#5dd16a',
    Transfer: '#2988E0',
    'Sent Payment': '#FF4040',
  };

  const dotStyle = {
    backgroundColor: dotColor[props.entryType],
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    boxShadow: `0 0 0px 4px ${dotColor[props.entryType]}33`,
  };

  return (
    <Row
      className={props.reverted ? 'opacity-50' : ''}
      defaultOpen={props.defaultOpen}
      detail={() => (
        <div>
          <FundActivityDetails entryType={props.entryType} details={props.details} identifier={props.identifier} />
        </div>
      )}
    >
      <Datum name="created-on">{dateTimeStringToDate(props.createdOn)}</Datum>
      <Datum name="effective-on">{props.effectiveOn}</Datum>
      <Datum name="company">
        <span className="text-base font-bold">{props.companyName}</span>
      </Datum>
      <Datum name="link">
        {props.entryType === 'Received Payment' && (
          <a href={props.legacyPaymentUrl} className="text-primary" rel="noopener noreferrer" target="_blank">
            view payment
          </a>
        )}
      </Datum>
      <Datum className="relative whitespace-nowrap flex flex-row items-center" name="entry-type">
        <div className="inline-block bottom-0 pr-3 align-baseline">
          <div style={dotStyle} />
        </div>
        <div className="inline-block whitespace-normal">
          {props.entryType}
          {props.checkNumber && <div className="font-light text-xs">Check: {props.checkNumber}</div>}
        </div>
      </Datum>
      <Datum className="text-right font-bold" name="amount">
        {formatCurrency(props.amount)}
      </Datum>
      <Datum className="text-right" name={['balance', 'hold']}>
        {REVERTABLE_FUND_ACTIVITY_TYPES.some(activityType => activityType === props.entryType) &&
          (props.reverted ? (
            'Reverted'
          ) : (
            <RevertButton identifier={props.identifier} entryType={props.entryType} onSuccess={props.refetch} />
          ))}
      </Datum>
    </Row>
  );
}

export default FundActivityRow;
