import React from 'react';
import { Card } from '@producepay/pp-ui';

import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import RoleCompanies from 'src/routes/Deals/components/RoleCompanies';

const PSFormCompanies = () => (
  <Card>
    <SectionHeader title="Companies" subtitle="Entities trading through the deal" />
    <RoleCompanies companyRole="Producer" formRole="producers" formLocation="general.companies" />
    <RoleCompanies companyRole="Distributor" formRole="distributors" formLocation="general.companies" />
  </Card>
);

export default PSFormCompanies;
