import React, { useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { ButtonGroup } from '@producepay/pp-ui';
import { v4 as uuidv4 } from 'uuid';
import some from 'lodash/some';

import Trash from 'src/components/icons/Trash';

import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';
import SimpleErrorMessageWrapper from 'src/components/formik/SimpleErrorMessageWrapper';
import { BaseFormCompany, SharedDealFormValues } from 'src/routes/Deals/types';
import { FormCompany } from 'src/routes/Deals/components/types';
import {
  GetRecipientFundingAccounts,
  GetRecipientFundingAccountsVariables,
} from 'src/routes/Deals/components/graphql-types/GetRecipientFundingAccounts';

export const GET_RECIPIENT_FUNDING_ACCOUNTS = gql`
  query GetRecipientFundingAccounts($companyIdentifier: String) {
    fundingAccounts(companyIdentifier: $companyIdentifier, status: "active") {
      value: identifier
      label: name
    }
  }
`;

interface PaymentsRecipientProps {
  company: BaseFormCompany;
  receiverRole: string;
  index: number;
}

const PaymentsRecipient = ({ company, receiverRole, index }: PaymentsRecipientProps) => {
  const {
    values: { companies },
    status: { readOnly },
    errors,
    setFieldValue,
  } = useFormikContext<SharedDealFormValues>();

  const { data: companyFundingAccountData, ...rest } = useQuery<
    GetRecipientFundingAccounts,
    GetRecipientFundingAccountsVariables
  >(GET_RECIPIENT_FUNDING_ACCOUNTS, {
    variables: {
      companyIdentifier: company.identifier,
    },
  });

  const [companyFundingAccounts, setCompanyFundingAccounts] = useState([]);

  useEffect(() => {
    if (companyFundingAccountData) {
      setCompanyFundingAccounts(companyFundingAccountData.fundingAccounts);
    }
  }, [companyFundingAccountData, rest]);

  function handleValueTypeChange(type: string, c: BaseFormCompany) {
    companies[receiverRole].forEach((rc: FormCompany, i: number) => {
      if (rc.identifier === c.identifier) {
        const accounts = rc.fundingAccounts || [
          {
            type: '',
          },
        ];
        accounts.forEach((_, j) => {
          setFieldValue(`companies.${receiverRole}.${i}.fundingAccounts.${j}.type`, type);
        });
      }
    });
  }

  const currentValueType = companies[receiverRole][index].fundingAccounts
    ? companies[receiverRole][index].fundingAccounts[0].type
    : 'percentage';

  if (!some(companies[receiverRole], 'identifier')) {
    return <div>Please add a company to the {receiverRole} section above.</div>;
  }

  if (!company.identifier) {
    return null;
  }

  return (
    <>
      <div data-testid={`receiver-${index}`} className="py-4 border-b-1 last:border-b-0">
        <div className="flex flex-row justify-between">
          <div>
            If the {company.role} is <span className="font-bold">{company.name}</span>
          </div>
          {currentValueType === 'fixed_amount' && !readOnly && (
            <div className="italic">Leave one empty as the remaining payment</div>
          )}
        </div>
        {companyFundingAccounts.length > 0 ? (
          <>
            <FieldArray
              name={`companies.${receiverRole}.${index}.fundingAccounts`}
              render={({ push, remove }) => (
                <>
                  <div className="w-full">
                    {companies[receiverRole][index].fundingAccounts &&
                      companies[receiverRole][index].fundingAccounts.map((d, j) => (
                        <div key={d.key} className="flex flex-column py-2">
                          <div className="flex-grow pr-2">
                            <FormikSelect
                              name={`companies.${receiverRole}[${index}].fundingAccounts[${j}].accountId`}
                              items={companyFundingAccounts}
                              placeholder="Select a Bank Account"
                              withSimpleErrorStyling
                            />
                          </div>
                          {companies[receiverRole][index].fundingAccounts.length > 1 && (
                            <div className="sm:w-1/4">
                              <FormikTextField
                                name={`companies.${receiverRole}[${index}].fundingAccounts[${j}].value`}
                                iconPrefix={currentValueType === 'fixed_amount' ? <span>$</span> : null}
                                iconSuffix={currentValueType === 'percentage' ? <span>%</span> : null}
                                inputProps={currentValueType === 'percentage' ? { className: 'text-right' } : null}
                                withSimpleErrorStyling
                              />
                            </div>
                          )}
                          {companies[receiverRole][index].fundingAccounts.length > 1 && j === 0 && (
                            <div className="ml-2 pr-2">
                              <ButtonGroup
                                type="button"
                                options={[
                                  {
                                    label: '%',
                                    value: 'percentage',
                                    type: 'button',
                                    disabled: readOnly,
                                  },
                                  {
                                    label: '$',
                                    value: 'fixed_amount',
                                    type: 'button',
                                    disabled: readOnly,
                                  },
                                ]}
                                selectedValue={currentValueType}
                                onChange={v => handleValueTypeChange(v, company)}
                              />
                            </div>
                          )}
                          {j !== 0 && !readOnly && (
                            <div className="mx-2 flex justify-end pr-2">
                              <button
                                className="bg-white focus:outline-none py-2 pl-5 pr-2 ml-10 text-align-right"
                                color="white"
                                type="button"
                                data-testid={`remove-company-${index}-account-${j}`}
                                onClick={() => remove(j)}
                                disabled={readOnly}
                              >
                                <Trash disabled={readOnly} size={14} />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>

                  {!readOnly && (
                    <div>
                      <button
                        type="button"
                        data-testid="add-account"
                        className="text-primary body2 font-semibold"
                        onClick={() =>
                          push({
                            type: currentValueType,
                            key: uuidv4(),
                            value: '',
                          })
                        }
                        disabled={readOnly}
                      >
                        + Add Bank Account
                      </button>
                    </div>
                  )}
                </>
              )}
            />
          </>
        ) : (
          <SimpleErrorMessageWrapper
            error={errors?.companies?.[receiverRole]?.[index]?.fundingAccounts?.[0]?.accountId}
          >
            <div className="bg-gray-200 p-4 rounded mt-3">
              This company has no active bank accounts. Please save your progress and add the bank account(s) to the{' '}
              <Link target="_blank" to={`/companies/${company.identifier}/profile/fundingAccounts/active`}>
                <span className="text-primary font-bold">company’s profile</span>{' '}
              </Link>
              before completing this section.
            </div>
          </SimpleErrorMessageWrapper>
        )}
      </div>
    </>
  );
};

export default PaymentsRecipient;
