import React from 'react';
import { Card } from '@producepay/pp-ui';

import FormikSelect from 'src/components/formik/FormikSelect';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';

import { FARMERS_WIRE_IDENTIFIER, INSEASON_FUND_IDENTIFIER } from '../../constants';

const SOURCE_ACCOUNT_ITEMS = [
  { label: 'Farmers Wire', value: FARMERS_WIRE_IDENTIFIER },
  { label: 'PP Balance Sheet', value: INSEASON_FUND_IDENTIFIER },
];

export function SourceAccounts() {
  return (
    <Card>
      <div className="w-full">
        <SectionHeader title="Source Accounts" subtitle="Default bank accounts to send funds from" />
        <SectionItem
          title="Balance Returns"
          subtitle="Account for sending customer balances"
          controls={
            <FormikSelect
              name="defaultBankAccounts.sourceAccounts[0].identifier"
              items={SOURCE_ACCOUNT_ITEMS}
              withSimpleErrorStyling
            />
          }
        />
      </div>
    </Card>
  );
}
