import React from 'react';
import cx from 'classnames';

import SimpleErrorMessageWrapper from 'src/components/formik/SimpleErrorMessageWrapper';

interface BorderedButtonProps extends React.HTMLProps<HTMLButtonElement> {
  icon?: JSX.Element;
  error?: string;
  iconProps?: {
    style?;
    className?;
  };
}

const BorderedButton = ({
  icon,
  iconProps,
  type,
  children,
  className,
  error,
  ...props
}: BorderedButtonProps): JSX.Element => {
  const button = (
    <button
      type="button"
      {...props}
      className={cx(
        className,
        'flex gap-2 justify-center h-8 items-center py-4 px-2 border border-black w-auto text-sm rounded',
        error && 'border-red-600',
      )}
    >
      {icon &&
        React.cloneElement(icon, {
          className: cx('w-4', iconProps && iconProps.className),
          style: iconProps && iconProps.style,
        })}
      {children}
    </button>
  );

  if (error) {
    return (
      <SimpleErrorMessageWrapper style={{ width: 'auto' }} error={error} cleared={false}>
        {button}
      </SimpleErrorMessageWrapper>
    );
  }

  return button;
};

export default BorderedButton;
