import React from 'react';
import { Card } from '@producepay/pp-ui';
import { Field, useFormikContext } from 'formik';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';
import SectionItem from 'src/routes/Deals/components/SectionItem';
import FormikToggle from 'src/components/formik/FormikToggle';
import { AlertTriangle } from 'react-feather';
import { PNTRValues } from 'src/routes/Deals/PNTR/types';

function BuyerVerificationWarning() {
  return (
    <div
      className="border-l-6 border-warning bg-warning bg-opacity-10 p-3 rounded mt-6 mx-6 body2 text-gray-600"
      data-testid="buyer-verification-warning"
    >
      <span data-testid="alert-triangle" className="inline-block align-middle mb-1 mx-1">
        <AlertTriangle fill="#FFA843" stroke="white" strokeWidth="2px" strokeLinecap="round" size={20} />
      </span>
      <span className="font-semibold pr-2"> Select a buyer company. </span>
      Trade assurance is available to relationships with gold verified buyers.
    </div>
  );
}

export function TradeAssurance() {
  const {
    values: {
      companies: { buyers },
    },
  } = useFormikContext<PNTRValues>();

  // Sometimes buyers array is populated with an empty object so just checking buyers.length does not work
  const buyerExists = buyers.some(buyer => buyer.identifier);

  return (
    <Card>
      <div className="w-full">
        <SectionHeader
          title="Trade Assurance"
          subtitle="Guarantee repayment to the Seller on all trades within the Preferred Network relationship"
        />
        {!buyerExists && <BuyerVerificationWarning />}
        <SectionItem
          title="On-time Payment Guarantee"
          subtitle="Enable coverage of the Seller’s outstanding shipment balance within the repayment terms"
          controls={
            <Field
              as="checkbox"
              name="repayment.tradeAssurance"
              disabled={!buyerExists}
              component={FormikToggle}
              data-testid="repayment-trade-assurance"
            />
          }
        />
      </div>
    </Card>
  );
}
