export type OnDealSave = (dealUuid: string) => void;
export type TransformToPayload<T, R> = (data: T) => R;
export type RemoveDefaultEntities<T> = (data: T) => T;

export interface FormUI {
  autoRenew: boolean;
}

export interface SourceAccount {
  identifier: string;
  purpose: string;
}

export interface DestinationAccount {
  companyIdentifier: string;
  fundingAccountIdentifier: string;
  value: string;
  type: string;
  priority: number;
}

export interface LateFee {
  repaymentDays: string;
  collectionDays: string;
  feePayerRole: string;
}

export interface ReceiverException {
  companyIdentifier: string;
  purchasePriceRate: string;
  key?: string;
}

export interface RequiredItem {
  name: string;
  type: string;
  category: string;
}

export interface ApplicationFeeFormValues {
  minimumAnnualProduceVolumeForRefund: string;
  isRefundable: boolean;
  fees: ApplicationFee[];
}

export interface ApplicationFee {
  amountDue: string;
  amountPaid: string;
  datePaid: string;
  paymentMethods: PaymentMethod[];
  companyIdentifier: string;
  isWaived: boolean;
  key: string;
}

export interface PaymentMethod {
  amount: string;
  method: string;
  key: string;
}

export interface Rebate {
  transactionVolumeThreshold: string;
  amount: string;
  key: string;
}

export interface BaseFormCompany {
  identifier: string;
  contactName: string;
  name: string;
  key: string;
  role: string;
}

export interface FormFundingAccount {
  accountId: string;
  value: string;
  key: string;
  type: string;
}

export interface DefaultBankAccounts {
  sourceAccounts: SourceAccount[];
  destinationAccounts: DestinationAccount[];
}

export interface SharedDealFormValues {
  companies?: Record<string, BaseFormCompany[]> | BaseFormCompany[];
  dealReference: string;
  effectiveOn: string;
  endsOn?: string;
  dealUuid: string;
  productLineId: number;
  status: string;
  ui?: unknown;
}

export interface Deal {
  dealUuid: string;
  dealReference: string;
  productLine: {
    id: number;
    name: string;
    shortName: string;
    slug: string;
  };
}

export interface BaseFormikStatus {
  readOnly: boolean;
}

export type FormikDealStatus = BaseFormikStatus;

export enum MenuItem {
  General = 'general',
  Prepayments = 'prepayments',
  Commodities = 'commodities',
  Forecast = 'forecast',
  Milestones = 'milestones',
}
