import React, { useMemo } from 'react';
import { Button } from '@producepay/pp-ui';
import { format } from 'date-fns';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { currencyCellFormatter, HeaderCell, ReactTableContext, useReactTable } from 'src/components/elements/Table';

import * as routes from 'src/routes';
import { GET_FUNDING_TRANSACTIONS } from 'src/graphql/queries/getFundingTransactions';
import { useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import Header from '../Header';
import PaymentsFilterBar from '../FilterBar/PaymentsFilterBar';
import { PaymentTypeCell } from '../Imports/PaymentTypeCell';
import { ReconcilePaymentsTable } from '../Imports/ReconcilePaymentsTable';
import { ColumnAccessors } from './constants';
import { globalFilter, insideDateRange, oneOf } from './helpers';

const paymentTypes = [
  {
    label: 'ACH',
    value: 'ach',
  },
  {
    label: 'Wire',
    value: 'wire',
  },
  {
    label: 'Check',
    value: 'check',
  },
];

const Payments = () => {
  const history = useHistory();
  const { data } = useQuery<{ fundingTransactions: { nodes: FundingTransaction[] } }>(GET_FUNDING_TRANSACTIONS);
  const fundingAccountOptions = (data?.fundingTransactions.nodes ?? []).map(item => ({
    label: item.recipientAccount.name,
    value: item.recipientAccount.identifier,
  }));
  const companies = (data?.fundingTransactions.nodes ?? []).map(item => ({
    label: item.sourceAccount.name,
    value: item.sourceAccount.identifier,
  }));
  const isLoading = !(data && companies);

  const columns = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return [
      {
        Header: props => <HeaderCell {...props} name="Received On" className="text-gray-500 uppercase" />,
        accessor: ColumnAccessors.ReceivedDate,
        formatter: date => format(date, 'MM-dd-yyyy'),
        align: 'center',
        width: 150,
        canResize: false,
        filter: insideDateRange,
      },
      {
        Header: props => <HeaderCell {...props} name="Recipient Acct" className="text-gray-500 uppercase" />,
        accessor: ColumnAccessors.RecipientAccount,
        align: 'left',
        width: 150,
        canResize: false,
        filter: oneOf,
      },
      {
        Header: props => <HeaderCell {...props} name="Payee Company" className="text-gray-500 uppercase" />,
        accessor: ColumnAccessors.SourceAccount,
        align: 'left',
        filter: oneOf,
      },
      {
        Header: props => <HeaderCell {...props} name="Payment Type" className="text-gray-500 uppercase" />,
        Cell: PaymentTypeCell,
        accessor: ColumnAccessors.TransactionType,
        align: 'left',
        width: 150,
        canResize: false,
        filter: oneOf,
      },
      {
        Header: props => <HeaderCell {...props} name="Available Amount" className="text-gray-500 uppercase" />,
        accessor: ColumnAccessors.Amount,
        formatter: currencyCellFormatter,
        width: 150,
        canResize: false,
      },
      {
        Header: props => <HeaderCell {...props} />,
        Cell: props => (
          <div className="w-full text-right pr-3 py-4">
            <Button
              label="Reconcile"
              variant="outlined"
              color="default"
              onClick={() => history.push(routes.reconcilePaymentPaymentsEdit(props.value))}
            />
          </div>
        ),
        id: 'action',
        accessor: ColumnAccessors.TransactionIdentifier,
      },
    ];
  }, [history, isLoading]);

  const tableInstance = useReactTable({
    data: data?.fundingTransactions.nodes ?? [],
    columns,
    customOptions: { autoResetPage: false, autoResetFilters: false, autoResetGlobalFilter: false, globalFilter },
    pluginHooks: [useFilters, useGlobalFilter, useSortBy, usePagination],
    initialState: { sortBy: [{ id: ColumnAccessors.ReceivedDate, desc: true }] },
  });

  return (
    <div>
      <Header />
      <ReactTableContext.Provider value={tableInstance}>
        <PaymentsFilterBar
          payeeCompanies={companies}
          fundingAccounts={fundingAccountOptions}
          paymentTypes={paymentTypes}
          isPayment
        />
        <ReconcilePaymentsTable isLoading={isLoading} />
      </ReactTableContext.Provider>
    </div>
  );
};

export default Payments;
