import React from 'react';
import { Card } from '@producepay/pp-ui';

import FormikTextField from 'src/components/formik/FormikTextField';

import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';

export function RepaymentTerms() {
  return (
    <Card>
      <SectionHeader title="Repayment Terms & Alerts" subtitle="Default financial settings for repayment terms" />
      <SectionItem
        title="Repayment Terms"
        subtitle="Days since shipment acceptance before late fees begin to incur"
        controls={
          <FormikTextField
            name="repayment.repaymentDays"
            inputProps={{ className: 'text-right' }}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title="Consignment Collection Alert"
        subtitle="Days since shipment acceptance before collections team reaches out"
        controls={
          <FormikTextField
            name="repayment.consignmentCollectionDays"
            inputProps={{ className: 'text-right' }}
            withSimpleErrorStyling
          />
        }
      />
      <SectionItem
        title="Fixed Price Collection Alert"
        subtitle="Days since shipment acceptance before collections team reaches out"
        controls={
          <FormikTextField
            name="repayment.fixedPriceCollectionDays"
            inputProps={{ className: 'text-right' }}
            withSimpleErrorStyling
          />
        }
      />
    </Card>
  );
}
