import React, { useState } from 'react';
import { Select, TextField, InlineDateRangePicker } from '@producepay/pp-ui';
import { Search } from 'react-feather';
import { startOfDay, subDays } from 'date-fns';
import {
  UseFiltersInstanceProps,
  UseFiltersState,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
  useAsyncDebounce,
} from 'react-table';

import { useReactTableInstance } from 'src/components/elements/Table';
import { SelectOption } from 'src/routes/ReconcilePayments/types';
import { FundingTransaction } from '../Imports/types';
import { ColumnAccessors as ImportsColumnAccessors } from '../Imports/constants';
import { ColumnAccessors as PaymentsColumnAccessors } from '../Payments/constants';

const PRESET_OPTIONS = [
  {
    label: 'Today',
    start: startOfDay(new Date()),
    end: startOfDay(new Date()),
  },
  {
    label: 'Yesterday',
    start: subDays(startOfDay(new Date()), 1),
    end: subDays(startOfDay(new Date()), 1),
  },
];

export interface PaymentsFilters {
  payment?: string;
  recipientAccounts?: SelectOption[];
  payeeCompanies?: SelectOption[];
  paymentTypes?: SelectOption[];
  startDate?: Date;
  endDate?: Date;
}

interface PaymentsFilterBarProps {
  payeeCompanies: SelectOption[];
  fundingAccounts: SelectOption[];
  paymentTypes: SelectOption[];
  isPayment?: boolean;
}

const PaymentsFilterBar = ({ payeeCompanies, fundingAccounts, paymentTypes, isPayment }: PaymentsFilterBarProps) => {
  const instance = useReactTableInstance<
    never,
    UseFiltersInstanceProps<FundingTransaction> & {
      state: UseFiltersState<FundingTransaction> & UseGlobalFiltersState<FundingTransaction>;
    } & UseGlobalFiltersInstanceProps<FundingTransaction>
  >();

  const receivedDateFilter = instance.state.filters.find(
    item => item.id === (isPayment ? PaymentsColumnAccessors.ReceivedDate : ImportsColumnAccessors.ReceivedDate),
  );
  const recipientAccountFilter = instance.state.filters.find(
    item =>
      item.id === (isPayment ? PaymentsColumnAccessors.RecipientAccount : ImportsColumnAccessors.RecipientAccountUuid),
  );
  const sourceCompanyFilter = instance.state.filters.find(
    item => item.id === (isPayment ? PaymentsColumnAccessors.SourceAccount : ImportsColumnAccessors.SourceCompanyUuid),
  );
  const transactionTypeFilter = instance.state.filters.find(
    item => item.id === (isPayment ? PaymentsColumnAccessors.TransactionType : ImportsColumnAccessors.TransactionType),
  );

  const [globalFilter, setGlobalFilter] = useState('');
  const onGlobalFilterChange = useAsyncDebounce(value => {
    instance.setGlobalFilter(value);
  }, 200);

  return (
    <div className="flex bg-white py-1 px-2 border-b-1 text-sm pl-6 items-center gap-4">
      <div className="w-52">
        <TextField
          placeholder="Search payment"
          size="small"
          value={globalFilter}
          iconPrefix={<Search size={16} />}
          onChange={e => {
            setGlobalFilter(e.target.value);
            onGlobalFilterChange(e.target.value);
          }}
        />
      </div>
      <div className="w-52">
        <InlineDateRangePicker
          size="small"
          startDate={startOfDay(receivedDateFilter?.value.startDate ?? Date.now())}
          endDate={startOfDay(receivedDateFilter?.value.endDate ?? Date.now())}
          onChange={(startDate, endDate) =>
            instance.setFilter(isPayment ? PaymentsColumnAccessors.ReceivedDate : ImportsColumnAccessors.ReceivedDate, {
              startDate,
              endDate,
            })
          }
          presetRanges={PRESET_OPTIONS}
        />
      </div>
      <div className="w-52">
        <Select
          isMultiple
          multipleItemLabel="accounts"
          placeholder="Recipient account"
          size="small"
          items={fundingAccounts}
          selectedItems={recipientAccountFilter?.value ?? []}
          onChange={accounts => {
            instance.setFilter(
              isPayment ? PaymentsColumnAccessors.RecipientAccount : ImportsColumnAccessors.RecipientAccountUuid,
              accounts,
            );
          }}
        />
      </div>
      <div className="w-52">
        <Select
          isSearchable
          isMultiple
          multipleItemLabel="companies"
          placeholder="Payee company"
          size="small"
          items={payeeCompanies}
          selectedItems={sourceCompanyFilter?.value ?? []}
          onChange={companies =>
            instance.setFilter(
              isPayment ? PaymentsColumnAccessors.SourceAccount : ImportsColumnAccessors.SourceCompanyUuid,
              companies,
            )
          }
        />
      </div>
      <div className="w-52">
        <Select
          isMultiple
          multipleItemLabel="types"
          placeholder="Payment type"
          size="small"
          items={paymentTypes}
          selectedItems={transactionTypeFilter?.value ?? []}
          onChange={types =>
            instance.setFilter(
              isPayment ? PaymentsColumnAccessors.TransactionType : ImportsColumnAccessors.TransactionType,
              types,
            )
          }
        />
      </div>
    </div>
  );
};

export default PaymentsFilterBar;
