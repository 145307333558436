import React from 'react';
import { Grid } from '@producepay/pp-ui';
import cx from 'classnames';
import FormikSelect from 'src/components/formik/FormikSelect';

interface DocListProps {
  items: { name: string; formLocation: string }[];
  className?: string;
  showError?: boolean;
}

export const DocList = ({ items, className, showError = false }: DocListProps) => {
  return (
    <div className={cx('px-4 mx-4 border-b-1 pb-8 last:border-b-0 last:pb-2', className)}>
      <Grid container className="border-1 rounded px-4">
        {items.map(item => (
          <div className="flex justify-between py-2 w-full border-b-1 last:border-b-0" key={item.formLocation}>
            <div className="w-1/2 flex flex-col body2 justify-center">{item.name}</div>
            <div className="sm:w-1/4 pl-3">
              <FormikSelect
                withSimpleErrorStyling={showError}
                name={item.formLocation}
                data-testid={item.name}
                items={[
                  {
                    label: 'Preferred',
                    value: 'preferred',
                  },
                  {
                    label: 'Disabled',
                    value: 'disabled',
                  },
                  {
                    label: 'Required',
                    value: 'required',
                  },
                ]}
              />
            </div>
          </div>
        ))}
      </Grid>
    </div>
  );
};
