import React from 'react';
import { Grid } from '@producepay/pp-ui';
import { decimalSum } from 'src/helpers/currency';
import ReallocationSource from './ReallocationSource';
import ReallocationDestination from './ReallocationDestination';

interface ReallocationDetailRowProps {
  allocations: FundActivityAllocation[];
}

function ReallocationDetailRow(props: ReallocationDetailRowProps) {
  const { allocations } = props;

  const allocationsSourceTotal = decimalSum<FundActivityAllocation>('sourceAmount', allocations).abs().toNumber();

  return (
    <>
      <Grid container className="even:bg-table-highlight" spacing={0}>
        <Grid md="1/2" className="overflow-hidden">
          <ReallocationSource details={allocations[0]} total={allocationsSourceTotal} />
        </Grid>
        <Grid md="1/2" className="overflow-hidden">
          <ReallocationDestination allocations={allocations} />
        </Grid>
      </Grid>
    </>
  );
}

export default ReallocationDetailRow;
