import React, { useMemo } from 'react';
import { HeaderProps } from 'react-table';
import cx from 'classnames';
import { identity } from 'lodash';
import { ChevronUp, ChevronDown } from 'react-feather';

import { Align, TextAlignClassName } from './AlignCellContent';

// eslint-disable-next-line @typescript-eslint/ban-types
export function HeaderCell<T extends {}>({
  name,
  formula,
  data,
  column: {
    align = Align.Right,
    verticalAlign = 'top',
    id,
    formatter = identity,
    isSorted,
    isSortedDesc,
    toggleSortBy,
  },
  subtitle,
  className,
  sortable,
}: HeaderProps<T> & {
  name: string;
  formula?(props: HeaderProps<T>['data'], columnId: string): unknown;
  subtitle?: string;
  className?: string;
  sortable?: boolean;
} & {
  column: {
    align?: Align;
    verticalAlign?: string;
    formatter?: (value: unknown) => string;
    isSorted?: boolean;
    isSortedDesc?: boolean;
    toggleSortBy?(descending: boolean): void;
  };
}) {
  const formulaValue = useMemo(() => {
    return formula?.(data, id);
  }, [formula, data, id]);
  const alignItems = align === 'right' ? 'items-end' : align === 'center' ? 'items-center' : 'items-start';
  const justifyContent =
    verticalAlign === 'center' ? 'justify-center' : verticalAlign === 'bottom' ? 'justify-end' : 'justify-start';
  const textAlign = TextAlignClassName[align];
  return (
    <div
      role="presentation"
      className={cx('flex flex-col h-full', alignItems, justifyContent, sortable && 'cursor-pointer')}
      onClick={() => {
        if (!sortable) {
          return;
        }
        toggleSortBy(!isSortedDesc);
      }}
    >
      <div className="flex items-center">
        <div className={cx('font-semibold text-xs', textAlign, className)}>{name}</div>
        {isSorted &&
          (isSortedDesc ? (
            <ChevronDown size={16} className={cx('ml-1', className)} />
          ) : (
            <ChevronUp size={16} className={cx('ml-1', className)} />
          ))}
      </div>
      {formulaValue !== undefined && (
        <span className={cx('text-xs pt-1 text-gray-400', textAlign)}>{formatter(formulaValue)}</span>
      )}
      {subtitle && <span className={cx('text-xs pt-1 text-gray-400', textAlign)}>{subtitle}</span>}
    </div>
  );
}
