import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { LoadingSpinner, CardHeader, Badge } from '@producepay/pp-ui';
import cx from 'classnames';
import { useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';
import { Decimal } from 'decimal.js';

import * as routes from 'src/routes';
import MenuBar from 'src/components/elements/MenuBar';
import { MenuBarItem } from 'src/components/elements/MenuBar/MenuBar';
import { CompanyDashboard } from 'src/routes/Company/CompanyDashboard';
import { CompanyFinancing } from 'src/routes/Company/CompanyFinancing';
import { CompanyBalances } from 'src/routes/Company/CompanyBalances';
import CompanyProfile from 'src/routes/Companies/CompanyProfile';
import { GetCompany, GetCompanyVariables } from 'src/routes/Company/graphql-types/GetCompany';
import { GetPositiveAssets, GetPositiveAssetsVariables } from 'src/routes/Company/graphql-types/GetPositiveAssets';
import { CompanyOrders } from 'src/routes/Company/CompanyOrders';
import { formatCurrency } from 'src/helpers/currency';
import {
  GetFinancialProductsEligibleForBalanceTransferFromPreSeasonDeals,
  GetFinancialProductsEligibleForBalanceTransferFromPreSeasonDealsVariables,
} from 'src/routes/Company/graphql-types/GetFinancialProductsEligibleForBalanceTransferFromPreSeasonDeals';
import { Source, SourceOrder, SourcePreSeasonCollection, SourceType } from 'src/routes/Company/CompanyBalances/types';
import { getSourcesSum } from 'src/routes/Company/CompanyBalances/helpers';

const GET_COMPANY = gql`
  query GetCompany($companyIdentifier: String!) {
    company(identifier: $companyIdentifier) {
      identifier
      name
      isSuspended
      escrow
    }
  }
`;

const GET_POSITIVE_ASSETS = gql`
  query GetPositiveAssets($companyUuid: String!) {
    positiveAssets(companyUuid: $companyUuid) {
      assetUuid
      balance
      slug
      referenceNumber
    }
  }
`;

const GET_FINANCIAL_PRODUCTS_ELIGIBLE_FOR_BALANCE_TRANSFERS = gql`
  query GetFinancialProductsEligibleForBalanceTransferFromPreSeasonDeals($companyUuid: String!) {
    financialProductsEligibleForBalanceTransferFromPreSeasonDeals(companyUuid: $companyUuid) {
      nodes {
        identifier
        reference
        paid
        repaid
        lateFees
        otherFees
        dueDate
        deal {
          dealUuid
          dealReference
        }
      }
      totalCount
    }
  }
`;

function StatusBadge({ active }: { active: boolean }) {
  const status = active ? 'active' : 'inactive';

  const activeClassNames = 'bg-primary text-white';
  const inactiveClassNames = 'bg-gray-300 text-smoke';

  return (
    <Badge
      className={cx('caption text-semibold ml-4', {
        [activeClassNames]: active,
        [inactiveClassNames]: !active,
      })}
    >
      {status.toUpperCase()}
    </Badge>
  );
}

function DashboardItem({ name, value = null }: { name: string; value?: string }): JSX.Element {
  return (
    <>
      <span className="font-bold">{name}</span>
      {value && <span className="pl-2">{value}</span>}
    </>
  );
}

export function Company() {
  const { companyIdentifier, activeItem: menuItem } = useParams();

  const { loading: loadingCompany, data: companyData } = useQuery<GetCompany, GetCompanyVariables>(GET_COMPANY, {
    variables: { companyIdentifier },
  });

  const { loading: loadingAssets, data: positiveAssetsData } = useQuery<GetPositiveAssets, GetPositiveAssetsVariables>(
    GET_POSITIVE_ASSETS,
    {
      variables: { companyUuid: companyIdentifier },
    },
  );
  const { loading: loadingFinancialProductsData, data: financialProductsData } = useQuery<
    GetFinancialProductsEligibleForBalanceTransferFromPreSeasonDeals,
    GetFinancialProductsEligibleForBalanceTransferFromPreSeasonDealsVariables
  >(GET_FINANCIAL_PRODUCTS_ELIGIBLE_FOR_BALANCE_TRANSFERS, {
    variables: { companyUuid: companyIdentifier },
  });

  if (loadingCompany || loadingAssets || loadingFinancialProductsData) {
    return (
      <div className="py-32">
        <LoadingSpinner centered />
      </div>
    );
  }

  const { company } = companyData ?? {};

  if (!company) {
    return null;
  }

  const active = !company.isSuspended;

  const { positiveAssets } = positiveAssetsData ?? { positiveAssets: [] };
  const { financialProductsEligibleForBalanceTransferFromPreSeasonDeals } = financialProductsData ?? {};
  const { nodes: financialProducts } = financialProductsEligibleForBalanceTransferFromPreSeasonDeals ?? {};

  const orderSources: SourceOrder[] = positiveAssets.map(asset => ({
    type: SourceType.Order,
    uuid: asset.assetUuid,
    balance: asset.balance,
    reference: asset.referenceNumber,
    slug: asset.slug,
  }));

  const preSeasonCollectionSources: SourcePreSeasonCollection[] = (financialProducts ?? []).map(financialProduct => ({
    type: SourceType.PreSeasonCollections,
    uuid: financialProduct.identifier,
    paid: financialProduct.paid,
    balance: financialProduct.repaid,
    fees: Decimal.add(financialProduct.lateFees, financialProduct.otherFees),
    reference: financialProduct.reference,
    dueDate: parseISO(financialProduct.dueDate),
    dealReference: financialProduct.deal.dealReference,
    dealUuid: financialProduct.deal.dealUuid,
  }));

  const sources: Source[] = [
    {
      type: SourceType.Escrow,
      uuid: company.identifier,
      balance: company.escrow,
    },
    ...orderSources,
    ...preSeasonCollectionSources,
  ];
  const availableBalance = getSourcesSum(sources);

  const menuItems: MenuBarItem[] = [
    {
      label: <DashboardItem name="Dashboard" />,
      active: menuItem === 'dashboard',
      url: routes.company.dashboard(companyIdentifier),
    },
    {
      label: <DashboardItem name="Orders" />,
      active: menuItem === 'orders',
      url: routes.company.orders.index(companyIdentifier),
    },
    {
      label: <DashboardItem name="Available Financing" value={formatCurrency(8700)} />,
      active: menuItem === 'available-financing',
      url: routes.company.availableFinancing(companyIdentifier),
    },
    {
      label: <DashboardItem name="Available Balance" value={formatCurrency(availableBalance)} />,
      active: menuItem === 'available-balance',
      url: routes.company.availableBalance(companyIdentifier),
    },
    {
      label: <DashboardItem name="Profile" />,
      active: menuItem === 'profile',
      url: routes.company.profile(companyIdentifier),
    },
  ];

  return (
    <>
      <CardHeader
        className="bg-white"
        titleClassName="h1"
        title={company.name}
        headerComponents={<StatusBadge active={active} />}
      />
      <MenuBar className="border-b-1" items={menuItems} />
      <Switch>
        <Route path={routes.company.dashboard(':companyIdentifier')} render={() => <CompanyDashboard />} />
        <Route path={routes.company.orders.index(':companyIdentifier')} render={() => <CompanyOrders />} />
        <Route path={routes.company.availableFinancing(':companyIdentifier')} render={() => <CompanyFinancing />} />
        <Route
          path={routes.company.availableBalance(':companyIdentifier')}
          render={() => <CompanyBalances companyIdentifier={companyIdentifier} sources={sources} />}
        />
        <Route
          path={routes.company.profile(':companyIdentifier')}
          render={() => <CompanyProfile companyIdentifier={companyIdentifier} />}
        />
      </Switch>
    </>
  );
}
