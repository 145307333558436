import React, { FC, useContext, useEffect } from 'react';
import { Field, FormikContext, useFormik } from 'formik';
import { FormLabel } from '@producepay/pp-ui';
import * as Yup from 'yup';

import FormikTextField from 'src/components/formik/FormikTextField';
import FormikDatePicker from 'src/components/formik/FormikDatePicker';
import { useQuery } from '@apollo/client';
import FormikAutocomplete from 'src/components/formik/FormikAutocomplete';
import { GET_COMPANIES_AUTOCOMPLETE } from 'src/graphql/queries/getCompanies';
import { GET_PRODUCE_PAY_FUNDING_ACCOUNTS } from 'src/graphql/queries/getProducePayFundingAccounts';
import { CompaniesAutocomplete } from 'src/graphql/queries/graphql-types/CompaniesAutocomplete';
import FormikSelect, { SelectItem } from 'src/components/formik/FormikSelect';
import { decimalString } from 'src/routes/Deals/helpers';

import { AddPaymentContext } from '../AddPaymentContext';
import { TransactionType } from '../../types';

interface FormValues {
  transactionNumber?: string;
  amount?: string;
  effectiveDate?: Date;
  checkClearDate?: Date;
  recipientAccountUuid?: string;
  sourceCompanyUuid?: SelectItem<string>;
  memo?: string;
}

const validationSchema = Yup.object().shape({
  transactionNumber: Yup.number().required('Check # is required'),
  amount: decimalString('Amount').required('Amount is required'),
  effectiveDate: Yup.date().required('Received on is required'),
  checkClearDate: Yup.date().required('Scheduled Release on is required'),
  recipientAccountUuid: Yup.string().nullable().required('Recipient Account is required'),
  sourceCompanyUuid: Yup.object().nullable().required('Payee is required'),
  memo: Yup.string().nullable(),
});

export const CheckForm: FC = () => {
  const { data: { fundingAccounts } = { fundingAccounts: [] } } = useQuery<{ fundingAccounts: SelectItem[] }>(
    GET_PRODUCE_PAY_FUNDING_ACCOUNTS,
  );
  const { data: { companies } = { companies: [] } } = useQuery<CompaniesAutocomplete>(GET_COMPANIES_AUTOCOMPLETE);
  const { setSubmitForm, onSubmit } = useContext(AddPaymentContext);
  const formik = useFormik<FormValues>({
    initialValues: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: values => {
      const { sourceCompanyUuid, ...rest } = values as Required<FormValues>;
      onSubmit([
        {
          ...rest,
          sourceCompanyUuid: sourceCompanyUuid.value,
          transactionType: TransactionType.Check,
        },
      ]);
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });
  const { submitForm, setTouched } = formik;

  useEffect(() => {
    setSubmitForm(() => {
      submitForm();
      setTouched({});
    });
  }, [submitForm, setSubmitForm, setTouched]);

  return (
    <FormikContext.Provider value={formik}>
      <>
        <div className="flex items-start gap-4 pb-4">
          <FormLabel label="Check #" className="flex-grow">
            <FormikTextField
              name="transactionNumber"
              placeholder="Enter numbers only"
              type="number"
              withSimpleErrorStyling
            />
          </FormLabel>
          <FormLabel label="Amount" className="flex-grow">
            <FormikTextField
              iconPrefix={<span>$</span>}
              name="amount"
              className="flex-grow"
              type="number"
              withSimpleErrorStyling
            />
          </FormLabel>
        </div>
        <FormLabel label="Received on" className="pb-4">
          <Field
            name="effectiveDate"
            component={FormikDatePicker}
            inputProps={{ name: 'effectiveDate' }}
            placeholderDate="MM/DD/YYYY"
            withSimpleErrorStyling
          />
        </FormLabel>
        <FormLabel label="Scheduled Release on" className="pb-4">
          <Field
            name="checkClearDate"
            component={FormikDatePicker}
            inputProps={{ name: 'checkClearDate' }}
            placeholderDate="MM/DD/YYYY"
            withSimpleErrorStyling
          />
        </FormLabel>
        <FormLabel label="Recipient Account" className="pb-4">
          <FormikSelect
            name="recipientAccountUuid"
            placeholder="Select bank account"
            items={fundingAccounts}
            withSimpleErrorStyling
          />
        </FormLabel>
        <FormLabel label="Payee" className="pb-4">
          <FormikAutocomplete name="sourceCompanyUuid" items={companies} placeholder="Select company" size="small" />
        </FormLabel>
        <FormikTextField name="memo" label="Memo (optional)" placeholder="Type memo here if written on check" />
      </>
    </FormikContext.Provider>
  );
};
