import React, { useEffect, useState } from 'react';
import { ApolloClient, ApolloLink, from, InMemoryCache, HttpLink } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { setContext } from '@apollo/client/link/context';
import trim from 'lodash/trim';
import { camelizeKeys } from 'humps';

import { FULFILLMENT_API_CLIENT_NAME } from 'src/graphql/backends';
import { useAuth } from './auth/AuthProvider';

const freshLink = new HttpLink({
  uri: trim(`${process.env.REACT_APP_BACKEND_URL}/query`),
  headers: {},
});

const fulfillmentLink = new HttpLink({
  uri: trim(process.env.REACT_APP_FULFILLMENT_GRAPHQL_URL),
  headers: {},
});

const camelizeLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    // camelize all keys returned except ones with leading __ for apollo usage
    return camelizeKeys(data, (key, convert) => (/^__/.test(key) ? key : convert(key)));
  });
});

const apolloCache = new InMemoryCache();

const AuthorizedApolloProvider = ({ children }) => {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const [apolloClient, setApolloClient] = useState();
  const authLink = setContext(async (_request, { headers }) => {
    const token = await getTokenSilently();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const backendLink = ApolloLink.split(
    operation => operation.getContext().clientName === FULFILLMENT_API_CLIENT_NAME,
    fulfillmentLink,
    freshLink,
  );

  // backendLink must be last as it is a terminating link
  const links = from([authLink, camelizeLink, backendLink]);

  useEffect(() => {
    setApolloClient(
      new ApolloClient({
        link: links,
        cache: apolloCache,
        connectToDevTools: true,
      }),
    );
    // missing deps: [links]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return apolloClient ? <ApolloProvider client={apolloClient}>{children}</ApolloProvider> : null;
};

export default AuthorizedApolloProvider;
