import React, { useState } from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LoadingSpinner, TextField } from '@producepay/pp-ui';
import { Search } from 'react-feather';

import Paginator from 'src/components/molecules/Paginator';
import { FULFILLMENT_API_CLIENT_NAME } from 'src/graphql/backends';
import GET_ORDERS from 'src/graphql/queries/getOrders';
import { SEARCH_DEBOUNCE_MS, useDebounce } from 'src/helpers/common';
import * as routes from 'src/routes';

import Table from './Table';
import OrderOverview from '../OrderOverview';
import { GetOrdersResponse, GetOrdersVariables } from './types';

const OrdersIndex = () => {
  const history = useHistory();
  const { companyIdentifier } = useParams();

  const legacyCompanyId: string = companyIdentifier.replace(/\D/g, '');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState('');

  const searchDebounced = useDebounce(search, SEARCH_DEBOUNCE_MS);

  const { data, loading } = useQuery<GetOrdersResponse, GetOrdersVariables>(GET_ORDERS, {
    context: { clientName: FULFILLMENT_API_CLIENT_NAME },
    variables: {
      legacyCompanyIds: [legacyCompanyId],
      page: page - 1,
      perPage,
      search: searchDebounced,
    },
  });

  const handleSearch = (searchInput: string) => {
    setSearch(searchInput);
    setPage(1);
  };

  const Pagination = () => (
    <div className="flex justify-end p-6 pb-4">
      <Paginator
        page={page}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        totalCount={data?.getOrders?.pageInfo?.totalResults ?? 0}
      />
    </div>
  );

  return (
    <>
      <Route path={routes.company.orders.overview(':companyIdentifier', ':orderId')}>
        <OrderOverview onClose={() => history.push(routes.company.orders.index(companyIdentifier))} />
      </Route>
      <div className="bg-white border-t border-b px-6 py-4">
        <div className="w-1/4">
          <TextField
            placeholder="Search order"
            iconPrefix={<Search size={16} />}
            value={search}
            onChange={e => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <Pagination />
      <div className="px-6">{loading ? <LoadingSpinner /> : <Table data={data?.getOrders?.orders ?? []} />}</div>
      <Pagination />
    </>
  );
};

export default OrdersIndex;
