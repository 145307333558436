import React from 'react';
import { useHistory } from 'react-router-dom';

import { dealsEdit } from 'src/routes';
import { FormikDealStatus } from 'src/routes/Deals/types';
import { PSValues } from 'src/routes/Deals/PS/types';
import { v4 as uuid } from 'uuid';
import { Document, DocumentCategory, FormAction, PSEntityAction } from 'src/routes/Deals/constants';
import FormikPSForm from '../Form';

export const initialValues: PSValues = {
  general: {
    effectiveOn: null,
    productLineId: 3,
    status: 'draft',
    dealUuid: null,
    dealReference: '',
    ui: {
      disabled: false,
    },
    companies: {
      distributors: [],
      producers: [],
    },
    repayment: {
      deadline: null,
      rules: [],
      requiredItems: [{ name: Document.NOTICE_OF_INTENT, type: 'required', category: DocumentCategory.OTHER }],
    },
    forecastedFinancials: {
      contractedGmv: null,
      ltvRate: null,
      aprRate: null,
      earRate: null,
    },
    applicationFee: {
      isRefundable: false,
      minimumAnnualProduceVolumeForRefund: '1000000.00',
      fees: [],
    },
  },
  prepayments: [],
  commodities: [],
  forecast: [],
  milestones: [{ milestoneUuid: uuid(), deadline: null, gmvAmount: null, action: PSEntityAction.Create }],
};

const initialStatus: FormikDealStatus = {
  readOnly: false,
};

export default () => {
  const history = useHistory();
  const handleDealSave = dealUuid => history.push(dealsEdit(dealUuid));

  return (
    <FormikPSForm
      initialValues={initialValues}
      initialStatus={initialStatus}
      action={FormAction.Create}
      onDealSave={handleDealSave}
    />
  );
};
