import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import * as routes from 'src/routes';
import Escrow from './Escrow';
import Imports from './Imports';
import Payments from './Payments';
import ReconcilePaymentDetails from './ReconcileDetails';

function Deals(): JSX.Element {
  return (
    <>
      <Switch>
        <Route exact path={routes.reconcilePaymentsImports()}>
          <Imports />
        </Route>
        <Route exact path={routes.reconcilePaymentsPayments()}>
          <Payments />
        </Route>
        <Route exact path={routes.reconcilePaymentPaymentsEdit(':paymentUuid')}>
          <ReconcilePaymentDetails />
        </Route>
        <Route exact path={routes.reconcilePaymentsEscrow()}>
          <Escrow />
        </Route>
        <Route>
          <Redirect to={routes.reconcilePaymentsImports()} />
        </Route>
      </Switch>
    </>
  );
}

export default Deals;
