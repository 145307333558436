import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, Grid } from '@producepay/pp-ui';

import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';
import FormikToggle from 'src/components/formik/FormikToggle';
import { ISPPValues } from 'src/routes/Deals/ISPP/types';
import SectionHeader from '../../components/SectionHeader';
import SectionItem from '../../components/SectionItem';
import PaymentsRecipient from '../../components/PaymentsRecipient';

const UI_PAYMENT_TYPE_ITEMS = [
  { label: 'Standard', value: 'standard' },
  { label: 'Per Unit', value: 'perUnit' },
];

const SHIPMENT_ACCEPTANCE_ITEMS = [
  { label: 'By Distributor', value: 'distributor' },
  { label: 'By Distributor or ProducePay', value: 'distributorOrProducePay' },
  { label: 'Acceptance is not required', value: 'none' },
];

const RECEIVER_ROLE_ITEMS = [
  { label: 'Producer', value: 'producers' },
  { label: 'Distributor', value: 'distributors' },
];

export default () => {
  const [selectRoleDisabled, setSelectRoleDisabled] = useState(false);
  const {
    values: {
      companies,
      payment,
      ui: {
        dealType,
        payment: { type: uiPaymentType, secondPaymentEnabled },
      },
    },
    setFieldValue,
  } = useFormikContext<ISPPValues>();

  useEffect(() => {
    if (dealType === 'distributor') {
      setFieldValue('payment.receiverRole', 'distributors');
      setSelectRoleDisabled(true);
    } else {
      setSelectRoleDisabled(false);
    }
  }, [setFieldValue, dealType]);

  useEffect(() => {
    if (!secondPaymentEnabled) {
      setFieldValue('payment.secondaryRate', null);
    }
  }, [setFieldValue, secondPaymentEnabled]);

  useEffect(() => {
    if (uiPaymentType === 'standard') {
      if (!payment.initialRate) {
        setFieldValue('payment.initialRate', '50.00');
      }
      if (!payment.secondaryRate) {
        setFieldValue('payment.secondaryRate', '80.00');
      }

      setFieldValue('payment.unitRate', null);
    }
    // payment.initialRate & payment.secondaryRate
    // cause default to be reset after deleting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, uiPaymentType]);

  return (
    <Card className="pb-4">
      <SectionHeader
        title="Payments"
        subtitle="Default financial settings for payments"
        controls={<FormikSelect name="ui.payment.type" items={UI_PAYMENT_TYPE_ITEMS} />}
      />
      {uiPaymentType === 'standard' && (
        <>
          <SectionItem
            title="Initial Payment Ratio"
            subtitle="Percentage of initial shipment value"
            controls={
              <FormikTextField
                name="payment.initialRate"
                iconSuffix={<span>%</span>}
                inputProps={{ className: 'text-right' }}
                withSimpleErrorStyling
              />
            }
          />
          <SectionItem
            title="Second Payment Ratio"
            subtitle="Max payments allowed upon approval"
            controls={
              <div className="flex flex-row">
                <div className="pr-2">
                  <FormikTextField
                    name="payment.secondaryRate"
                    disabled={!secondPaymentEnabled}
                    iconSuffix={<span>%</span>}
                    inputProps={{ className: 'text-right' }}
                    withSimpleErrorStyling
                  />
                </div>
                <div className="flex flex-row items-center space-x-3">
                  <Field
                    as="checkbox"
                    name="ui.payment.secondPaymentEnabled"
                    data-testid="ui-payment-second-payment-enabled"
                    component={FormikToggle}
                  />
                </div>
              </div>
            }
          />
        </>
      )}
      {uiPaymentType === 'perUnit' && (
        <SectionItem
          title="Payment Per Unit"
          subtitle="Fixed amount per accepted unit"
          controls={<FormikTextField name="payment.unitRate" iconPrefix={<span>$</span>} withSimpleErrorStyling />}
        />
      )}
      <SectionItem
        title="Shipment Acceptance"
        subtitle="Specified party must accept the shipment in order to access payment"
        controls={
          <FormikSelect name="payment.shipmentAcceptance" items={SHIPMENT_ACCEPTANCE_ITEMS} withSimpleErrorStyling />
        }
        border={false}
      />
      <div className="px-4 mx-4 mt-2 pb-4">
        <Grid container className="border-1 rounded mt-6">
          <div className="bg-gray-100 p-4 font-bold w-full rounded rounded-b-none flex flex-row justify-between">
            <div>Payment Recipient</div>
            <div className="sm:w-1/4 font-normal">
              <FormikSelect
                name="payment.receiverRole"
                placeholder="Select Recipient Role"
                items={RECEIVER_ROLE_ITEMS}
                disabled={selectRoleDisabled}
                withSimpleErrorStyling
              />
            </div>
          </div>
          <Grid className="p-4">
            {payment.receiverRole ? (
              companies[payment.receiverRole].map((c, i) => (
                <PaymentsRecipient key={c.key} company={c} receiverRole={payment.receiverRole} index={i} />
              ))
            ) : (
              <div>Select a Recipient Role</div>
            )}
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};
