import React, { FC, useCallback, useState } from 'react';
import { Modal } from '@producepay/pp-ui';

import TabGroup from 'src/components/elements/TabGroup';
import { ManualPayment } from './ManualPayment';
import { FileUploadPayment } from './FileUploadPayment';
import { FundingTransaction } from '../types';
import { AddPaymentContext } from './AddPaymentContext';

interface AddPaymentModalProps {
  actions: {
    onSave(payments: FundingTransaction[]): void;
    onCancel(): void;
    onClose(): void;
  };
}

enum Type {
  Manual,
  FileUpload,
}

export const AddPaymentModal: FC<AddPaymentModalProps> = ({ actions: { onSave, onCancel, onClose } }) => {
  const [submitForm, setSubmitForm] = useState<() => void>();
  const save = useCallback(async () => {
    // eslint-disable-next-line no-unused-expressions
    submitForm?.();
  }, [submitForm]);

  const setSubmitFormCb = useCallback(
    (cb: () => void) => {
      setSubmitForm(() => cb);
    },
    [setSubmitForm],
  );

  return (
    <AddPaymentContext.Provider value={{ setSubmitForm: setSubmitFormCb, onSubmit: onSave }}>
      <Modal
        className="rounded"
        title={<div className="flex flex-row text-xl font-medium items-center">Add Payment</div>}
        actions={[
          {
            label: 'Cancel',
            className: 'px-10',
            color: 'default',
            variant: 'text',
            onClick: onCancel,
          },
          {
            label: 'Save Payment',
            className: 'px-10',
            'data-testid': 'repayment-modal-save',
            onClick: e => {
              e.stopPropagation();
              save();
            },
          },
        ]}
        onClose={onClose}
      >
        <TabGroup
          id="type-group"
          tabs={[
            {
              name: 'Manual',
              id: Type.Manual,
              component: <ManualPayment />,
            },
            {
              name: 'File Upload',
              id: Type.FileUpload,
              component: <FileUploadPayment />,
            },
          ]}
        />
      </Modal>
    </AddPaymentContext.Provider>
  );
};
