import React from 'react';

import { OrderStatus } from '../types';

const backgroundColors = {
  [OrderStatus.ACCEPTED]: '#D2EAF5',
  [OrderStatus.BOOKED]: '#33CE9B33',
  [OrderStatus.CREATED]: '#FFFAC2',
  [OrderStatus.DISPUTED]: '#FCDADA',
  [OrderStatus.PAID]: '#27CF7E',
  [OrderStatus.REJECTED]: '#666A71',
  [OrderStatus.VOID]: '#DCDCDE',
};

const fontColors = {
  [OrderStatus.ACCEPTED]: '#0B7EB0',
  [OrderStatus.BOOKED]: '#1D936C',
  [OrderStatus.CREATED]: '#3D3800',
  [OrderStatus.DISPUTED]: '#EF6461',
  [OrderStatus.PAID]: '#FFFFFF',
  [OrderStatus.REJECTED]: '#F8F9FB',
  [OrderStatus.VOID]: '#656566',
};

type StatusCellProps = {
  status: OrderStatus;
};

const StatusCell = ({ status }: StatusCellProps) => {
  return (
    <span
      className="block rounded-sm py-1 px-4 text-center uppercase"
      style={{ backgroundColor: backgroundColors[status], color: fontColors[status] }}
    >
      {status}
    </span>
  );
};

export default StatusCell;
