import gql from 'graphql-tag';

const GET_ORDER = gql`
  query GetOrder($id: String!) {
    getOrder(id: $id) {
      id
      dueDate
      inventoryItems {
        commodity {
          name
        }
        grade {
          name
        }
        hasGrowerLabel
        pricePerUnit
        quantity
        size {
          name
        }
        variety {
          name
        }
      }
      isFixedPrice
      orderAttachments {
        documentType
        id
        name
        date
      }
      pickupNumber
      referenceNumber
      receivers {
        name
        role
      }
      suppliers {
        name
        role
      }
    }
  }
`;

export default GET_ORDER;
