import React from 'react';
import { FormikDealStatus, OnDealSave } from 'src/routes/Deals/types';
import { ISFRValuesPayload } from 'src/routes/Deals/ISFR/types';
import { DealQueryResponse } from 'src/routes/Deals/Edit/types';
import FormikISFRForm from '../Form';
import { initialValues } from '../Create';
import { transformToValues } from '../helpers';
import { FormAction, FormStatus } from '../../constants';

type ISFREditProps = {
  dealResponse: DealQueryResponse;
  handleDealSave: OnDealSave;
};

export default ({ dealResponse, handleDealSave }: ISFREditProps) => {
  const { dealConfig, dealUuid, status } = dealResponse;
  const deal = dealConfig ? transformToValues(dealConfig as ISFRValuesPayload, dealUuid, status) : initialValues;
  const readOnly = status === FormStatus.Active;

  const initialStatus: FormikDealStatus = {
    readOnly,
  };

  return (
    <FormikISFRForm
      initialValues={deal}
      initialStatus={initialStatus}
      action={FormAction.Edit}
      onDealSave={handleDealSave}
    />
  );
};
