import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { LoadingSpinner } from '@producepay/pp-ui';
import { FormAction, FormStatus } from '../../constants';
import { DealQueryResponse } from '../../Edit/types';
import { FormikDealStatus, OnDealSave } from '../../types';
import FormikPSForm from '../Form';
import { transformToValues } from '../helpers';
import { PSGeneralPayload, PSValues } from '../types';
import { PSDealRelationshipsResponse } from './types';

type PSEditProps = {
  dealResponse: DealQueryResponse<PSGeneralPayload>;
  handleDealSave: OnDealSave;
};

export const GET_DEAL_RELATIONSHIPS = gql`
  query GetDealRelationships(
    $milestonesFilter: MilestonesFilters
    $forecastCommoditiesFilter: ForecastCommoditiesFilters
    $financialProductsFilter: FinancialProductsFilters
    $forecastsFilters: ForecastsFilters
    $limit: Int!
    $offset: Int!
  ) {
    milestones(filters: $milestonesFilter, limit: $limit, offset: $offset) {
      nodes {
        milestoneUuid
        gmvAmount
        deadline
      }
    }
    forecastCommodities(filters: $forecastCommoditiesFilter, limit: $limit, offset: $offset) {
      nodes {
        commodityUuid
        commodityName
        distributor {
          distributorUuid: identifier
        }
        forecastCommodityUuid
        isOrganic
        packagingName
        packagingUuid
        sizeName
        sizeUuid
        varietyName
        varietyUuid
      }
    }
    financialProducts(filters: $financialProductsFilter, limit: $limit, offset: $offset) {
      nodes {
        dailyLateFee
        dueDate
        effectiveDate
        prepaymentUuid: identifier
        distributionFee: otherFees
        recipientBankAccount {
          identifier
        }
        amount: requestedAmount
        sourceBankAccount {
          identifier
        }
      }
    }
    forecasts(filters: $forecastsFilters, limit: $limit, offset: $offset) {
      nodes {
        forecastUuid
        repayment
        totalGmv
        week
        forecastsToForecastCommodities {
          cases
          forecastCommodityUuid
        }
      }
    }
  }
`;

type FormState = {
  initialValues: PSValues;
  initialStatus: FormikDealStatus;
};

export default ({ dealResponse, handleDealSave }: PSEditProps) => {
  const { dealConfig, dealUuid, status } = dealResponse;
  const [formState, setFormState] = React.useState<FormState>();

  const { data, refetch, error } = useQuery<PSDealRelationshipsResponse>(GET_DEAL_RELATIONSHIPS, {
    variables: {
      milestonesFilter: { dealUuids: dealUuid },
      forecastCommoditiesFilter: { dealUuids: dealUuid },
      financialProductsFilter: { dealUuids: dealUuid },
      forecastsFilters: { dealUuids: dealUuid },
      limit: 100,
      offset: 0,
    },
  });

  React.useEffect(() => {
    if (!error && data) {
      const initialValues = transformToValues(
        {
          general: { ...dealConfig, dealUuid },
          ...data,
        },
        dealUuid,
        status,
      );
      const initialStatus = {
        readOnly: status !== FormStatus.Draft,
      };
      setFormState({ initialValues, initialStatus });
    }
  }, [dealConfig, status, dealUuid, data, error]);

  const onDealSave = (uuid: string) => {
    handleDealSave(uuid);
    setTimeout(refetch, 1000);
  };

  return formState ? (
    <FormikPSForm {...formState} action={FormAction.Edit} onDealSave={onDealSave} />
  ) : (
    <div className="mt-10">
      <LoadingSpinner centered />
    </div>
  );
};
