import React, { useState } from 'react';
import { useCommand } from 'src/helpers/command';
import { Command } from 'src/helpers/command/types';
import { Button, useToast } from '@producepay/pp-ui';

interface CommandButtonProps<T> extends PPUI.ButtonProps {
  className?: string;
  children: React.ReactNode;
  command: T;
  errorTemplate?: (err: Error) => { body: string; header: string };
  onSuccess?: () => void;
  successTemplate?: () => { body: string; header: string };
}

const CommandButton = <T extends Command>({
  children,
  className = '',
  command,
  errorTemplate = err => ({
    body: `An error occurred while processing this request: ${err.message}.`,
    header: 'Error',
  }),
  onSuccess,
  successTemplate = () => ({ body: 'The request completed successfully.', header: 'Success' }),
  ...props
}: CommandButtonProps<T>) => {
  const { send } = useCommand();
  const { addToastToQueue } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async () => {
    try {
      setIsLoading(true);
      await send<T>(command);
      setIsLoading(false);
      const toastProps = successTemplate();
      onSuccess && onSuccess();
      addToastToQueue({
        type: 'success',
        ...toastProps,
      });
    } catch (e) {
      setIsLoading(false);
      const toastProps = errorTemplate(e);
      addToastToQueue({
        actions: [{ label: 'Dismiss' }],
        type: 'error',
        ...toastProps,
      });
    }
  };
  return (
    <Button className={`${className}`} isLoading={isLoading} onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export default CommandButton;
