import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useFormikContext, yupToFormErrors, FormikErrors } from 'formik';
import { Button, useToast } from '@producepay/pp-ui';
import { isEqual } from 'lodash';

import { useCommand } from 'src/helpers/command';
import { Deal, DealCommandType } from 'src/helpers/command/types';
import { ValidationSchema } from 'src/routes/Deals/components/types';

import { OnDealSave, RemoveDefaultEntities, SharedDealFormValues, TransformToPayload } from 'src/routes/Deals/types';
import { FormAction } from '../constants';
import { DeleteDeal } from './DeleteDeal';
import { usePSDealNavigation } from '../PS/components/PSDealNavigation/context';

interface DealResponse extends AxiosResponse {
  dealUuid: string;
}

interface FormControlsProps<V, P> {
  action: FormAction;
  status: string;
  dealUuid: string;
  onDealSave: OnDealSave;
  activationSchema: ValidationSchema;
  saveDraftSchema: ValidationSchema;
  transformToPayload: TransformToPayload<V, P>;
  removeDefaultEntities: RemoveDefaultEntities<V>;
  inputProps?: {
    onSaveDraftSuccessToastHeader?: string;
    onSaveDraftSuccessToastBody?: string;
    onSaveDraftFailedToastHeader?: string;
    onSaveDraftFailedToastBody?: string;
    saveDraftButtonText?: string;
    formatErrors?: (errors: FormikErrors<unknown>) => FormikErrors<unknown>;
    getAllValues?: RemoveDefaultEntities<V>;
    getGeneralValues?: RemoveDefaultEntities<V>;
    transformToGeneralPayload?: TransformToPayload<V, P>;
  };
  commandType?: DealCommandType;
}

type YupErrors = {
  fieldErrors: FormikErrors<unknown>;
  structureErrors: string[];
};

const getYupErrors = (errors): YupErrors => {
  const formErrors = {
    fieldErrors: yupToFormErrors(errors),
    structureErrors: [],
  };
  const yupErrors = errors.inner as { message: string; value: unknown; type: string }[];
  if (yupErrors.length > 0) {
    const arrayLengthError = yupErrors.find(yupError => Array.isArray(yupError.value) && yupError.type === 'min');
    arrayLengthError && formErrors.structureErrors.push(arrayLengthError.message);
  }
  return formErrors;
};

const FormControls = <Values extends SharedDealFormValues, Payload>({
  action,
  activationSchema,
  status,
  dealUuid,
  onDealSave,
  saveDraftSchema,
  transformToPayload,
  removeDefaultEntities,
  inputProps = {},
  commandType,
}: FormControlsProps<Values, Payload>) => {
  const { send } = useCommand();
  const { addToastToQueue } = useToast();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, initialValues, isSubmitting, setErrors, setTouched } = useFormikContext<any>();

  const context = values.ui ?? {};

  const sendDealCommand = async (command: DealCommandType, payload) => {
    try {
      const response = await send<Deal<Payload>, DealResponse>({
        command,
        payload,
      });
      addToastToQueue({
        header: inputProps.onSaveDraftSuccessToastHeader || 'Success',
        body: inputProps.onSaveDraftSuccessToastBody || `Deal Saved.`,
        type: 'success',
      });
      onDealSave(response.data.dealUuid);
    } catch (e) {
      addToastToQueue({
        actions: [{ label: 'Dismiss' }],
        header: inputProps.onSaveDraftFailedToastHeader || `Error saving Deal`,
        body: e.message,
        type: 'error',
      });
    }
  };

  const handleSaveDraftClick = (): void => {
    const trimmedForm = removeDefaultEntities(values);
    const command = commandType ?? (action === FormAction.Create ? DealCommandType.Create : DealCommandType.Update);
    saveDraftSchema
      .validate(trimmedForm, { abortEarly: false, context })
      .then(() => {
        setErrors({});
        setTouched({});
        const payload = transformToPayload(trimmedForm);
        sendDealCommand(command, payload);
      })
      .catch(err => {
        const { fieldErrors, structureErrors } = getYupErrors(err);
        setErrors(inputProps.formatErrors ? inputProps.formatErrors(fieldErrors) : fieldErrors);
        setTouched({});
        addToastToQueue({
          header: inputProps.onSaveDraftFailedToastHeader || `Error saving Deal`,
          body: structureErrors.length ? structureErrors[0] : 'Please provide values for all required fields.',
          type: 'error',
        });
      });
  };

  const handleActivateClick = (): void => {
    const trimmedForm = inputProps.getAllValues ? inputProps.getAllValues(values) : removeDefaultEntities(values);
    const command =
      action === FormAction.Create ? DealCommandType.CreateAndActivate : DealCommandType.UpdateAndActivate;

    activationSchema
      .validate(trimmedForm, { abortEarly: false, context })
      .then(() => {
        setErrors({});
        setTouched({});
        const data = inputProps.getGeneralValues ? inputProps.getGeneralValues(trimmedForm) : trimmedForm;
        const payload = inputProps.transformToGeneralPayload
          ? inputProps.transformToGeneralPayload(data)
          : transformToPayload(data);
        sendDealCommand(command, payload);
      })
      .catch(err => {
        const { fieldErrors } = getYupErrors(err);
        setErrors(fieldErrors);
        setTouched({});
        addToastToQueue({
          header: 'Error Activating Deal',
          body: 'Please provide values for all required fields.',
          type: 'error',
        });
      });
  };

  // This really ties the PS Deals to this component. We should refactor this
  // as soon as we get the chance.
  const { setSaveCallback } = usePSDealNavigation();
  useEffect(() => {
    setSaveCallback(() => {
      switch (commandType) {
        case DealCommandType.UpdatePSForecastCommodities: {
          return !isEqual(values.commodities, initialValues.commodities) && handleSaveDraftClick();
        }
        case DealCommandType.UpdatePSForecast: {
          values.forecast = values.forecast.filter(item => Object.keys(item.items).length > 1);
          return !isEqual(values.forecast, initialValues.forecast) && handleSaveDraftClick();
        }
        case DealCommandType.UpdatePSMilestones: {
          return !isEqual(values.milestones, initialValues.milestones) && handleSaveDraftClick();
        }
        case DealCommandType.UpdatePSTranches: {
          return !isEqual(values.prepayments, initialValues.prepayments) && handleSaveDraftClick();
        }
        default:
          return !isEqual(values.general, initialValues.general) && handleSaveDraftClick();
      }
    });
  });

  return (
    <>
      {status === 'draft' && dealUuid && (
        <div className="w-1/4 flex justify-end">
          <DeleteDeal dealUuid={dealUuid} />
          <div className="w-px border my-1 mx-3" />
        </div>
      )}
      <div className="w-1/3 flex">
        {status === 'draft' && (
          <>
            <Button
              type="button"
              className="w-1/2"
              onClick={handleSaveDraftClick}
              variant="outlined"
              isLoading={isSubmitting}
            >
              {inputProps.saveDraftButtonText || 'Save Draft'}
            </Button>
            <Button type="button" className="w-1/2 ml-2" onClick={handleActivateClick} isLoading={isSubmitting}>
              Activate
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default FormControls;
