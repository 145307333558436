import React from 'react';
import { get } from 'lodash';
import { FormLabel } from '@producepay/pp-ui';

/** FormikInputWrapper
 * Wrapper for Formik-enabled fields. Wires up labels and
 * error printing to the Formik context when a `label` is set,
 * otherwise just renders its children.
 */

const FormikInputWrapper = ({ className = '', children, label, name, form: { touched, errors, submitCount } }) =>
  label ? (
    <FormLabel
      className={className}
      id={`form-label-${name}`}
      label={label}
      error={submitCount > 0 || touched[name] ? get(errors, name) : null}
    >
      {children}
    </FormLabel>
  ) : (
    <>{children} </>
  );

export default FormikInputWrapper;
