import { isFriday, nextFriday, startOfDay } from 'date-fns';
import { last } from 'lodash';

import { PSForecastValues } from '../../types';

export function getStartDate(forecasts: PSForecastValues, effectiveOn?: Date): Date {
  if (forecasts.length > 0) {
    return forecasts[0].week;
  }
  const startFromDate = effectiveOn ?? startOfDay(new Date());
  return isFriday(startFromDate) ? startFromDate : nextFriday(startFromDate);
}

export function getEndDate(forecasts: PSForecastValues, startFrom: Date): Date {
  if (forecasts.length > 0) {
    return last(forecasts).week;
  }
  return startFrom;
}
