export const FARMERS_WIRE_IDENTIFIER = '9cbec432-5cab-4cce-be9b-32f20127bc65';
export const INSEASON_FUND_IDENTIFIER = 'c864fddf-f20b-436c-b76a-b082136e2c3e';

export const DATEPICKER_FORMAT = 'MM/dd/yyyy';

export enum FormAction {
  Create = 'Create',
  Edit = 'Edit',
}

export enum FormStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
}

export const CommodityTypes = {
  ALL_TYPES: { label: 'All types', value: 'all' },
  CONVENTIONAL: { label: 'Conventional', value: 'conventional' },
  ORGANIC: { label: 'Organic', value: 'organic' },
};

export enum RepaymentRule {
  Fixed = 'fixed',
  Percentage = 'percentage',
  PerUnit = 'per_unit',
}

export enum Document {
  SIGNED_BILL_OF_LADING = 'Signed Bill of Lading',
  MANIFEST = 'Manifest',
  PACA_INVOICE = 'PACA Invoice',
  OUTSIDE_INVOICE = 'Outside Invoice',
  PURCHASE_ORDER = 'Purchase Order',
  NOTICE_OF_INTENT = 'Notice of Intent',
  BILL_OF_SALE = 'Bill of Sale',
  CROSSING_DOCUMENTS = 'Crossing Documents',
}

export enum DocumentCategory {
  PROOF_OF_DELIVERY = 'proof-of-delivery',
  PROOF_OF_LOAD_VALUE = 'proof-of-load-value',
  OTHER = 'other',
  PROOF_OF_INTENT_TO_SELL_OR_PURCHASE = 'proof-of-intent-to-sell-or-purchase',
}

export enum PSEntityAction {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}
