import React from 'react';
import { Badge } from '@producepay/pp-ui';

import { ValidationSchema } from 'src/routes/Deals/components/types';
import {
  MenuItem,
  OnDealSave,
  RemoveDefaultEntities,
  SharedDealFormValues,
  TransformToPayload,
} from 'src/routes/Deals/types';
import { FormikErrors } from 'formik';
import { DealCommandType } from 'src/helpers/command/types';
import { FormAction } from '../constants';
import FormControls from './FormControls';

interface FormHeaderProps<V, P> {
  action: FormAction;
  status: string;
  dealUuid?: string;
  onDealSave: OnDealSave;
  productLine: string;
  saveDraftSchema: ValidationSchema;
  activationSchema: ValidationSchema;
  transformToPayload: TransformToPayload<V, P>;
  removeDefaultEntities: RemoveDefaultEntities<V>;
  selectedTab?: MenuItem;
  inputProps?: {
    onSaveDraftSuccessToastHeader?: string;
    onSaveDraftSuccessToastBody?: string;
    onSaveDraftFailedToastHeader?: string;
    onSaveDraftFailedToastBody?: string;
    saveDraftButtonText?: string;
    formatErrors?: (errors: FormikErrors<unknown>) => FormikErrors<unknown>;
    getAllValues?: RemoveDefaultEntities<V>;
    getGeneralValues?: RemoveDefaultEntities<V>;
    transformToGeneralPayload?: TransformToPayload<V, P>;
  };
  commandType?: DealCommandType;
}

const FormHeader = <Values extends SharedDealFormValues, Payload>({
  action,
  status,
  dealUuid,
  onDealSave,
  productLine,
  saveDraftSchema,
  activationSchema,
  transformToPayload,
  removeDefaultEntities,
  inputProps,
  commandType,
}: FormHeaderProps<Values, Payload>) => {
  const badgeColors = status === 'active' ? 'bg-primary text-white' : 'bg-gray-300 text-smoke';
  return (
    <div className="sticky top-0 z-20">
      <div className="bg-white flex flex-row items-center justify-between py-2 px-8 border-b-1">
        <div className="flex flex-row items-center sm:w-1/2">
          <div className="h2 font-semibold capitalize">{action} Deal</div>
          <div className="w-px border my-1 mx-3 h-6" />
          <span className="h2">{productLine}</span>
          <Badge className={`caption text-semibold ${badgeColors} ml-2`}>{status.toUpperCase()}</Badge>
        </div>
        <FormControls<Values, Payload>
          action={action}
          dealUuid={dealUuid}
          status={status}
          onDealSave={onDealSave}
          saveDraftSchema={saveDraftSchema}
          activationSchema={activationSchema}
          transformToPayload={transformToPayload}
          removeDefaultEntities={removeDefaultEntities}
          inputProps={inputProps}
          commandType={commandType}
        />
      </div>
    </div>
  );
};

export default FormHeader;
