import React, { useEffect, useState } from 'react';
import { useFormikContext, FieldArray, useField } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/client';
import { Grid } from '@producepay/pp-ui';
import lowerCase from 'lodash/lowerCase';
import reject from 'lodash/reject';
import flatMap from 'lodash/flatMap';

import { GET_COMPANIES_AUTOCOMPLETE } from 'src/graphql/queries/getCompanies';
import Trash from 'src/components/icons/Trash';
import FormikSelect from 'src/components/formik/FormikSelect';
import FormikTextField from 'src/components/formik/FormikTextField';
import FormLabel from 'src/components/elements/FormLabel';
import FormikAutocomplete, { SelectItem } from 'src/components/formik/FormikAutocomplete';
import { FormCompany } from 'src/routes/Deals/components/types';
import { CompaniesAutocomplete } from 'src/graphql/queries/graphql-types/CompaniesAutocomplete';
import { BaseFormCompany, SharedDealFormValues } from '../types';

interface RoleCompaniesProps {
  companyRole: 'Seller' | 'Receiver' | 'Producer' | 'Distributor' | 'Buyer';
  contractItems?: SelectItem[];
  formLocation?: string;
  formRole: string;
}

interface Company {
  key: string;
  contactName: string;
  identifier: string;
  role: string;
  name: string;
}

const getDefaultCompany = (companyRole: string): Company => {
  return {
    key: uuidv4(),
    contactName: null,
    identifier: null,
    role: lowerCase(companyRole),
    name: null,
  };
};

const RoleCompanies = ({ contractItems, companyRole, formRole, formLocation = 'companies' }: RoleCompaniesProps) => {
  const {
    status: { readOnly },
  } = useFormikContext<SharedDealFormValues>();
  const [{ value: companies }, , { setValue }] = useField<BaseFormCompany>(formLocation);
  const [companyData, setCompanyData] = useState([]);
  const { data } = useQuery<CompaniesAutocomplete>(GET_COMPANIES_AUTOCOMPLETE);

  useEffect(() => {
    if (data) {
      const mapped = flatMap(companies).map(c => c.identifier);
      const filtered = reject(data.companies, dc => mapped.includes(dc.value));
      setCompanyData(filtered);
    }
  }, [companies, data]);

  useEffect(() => {
    if (companies?.[formRole]?.length === 0) {
      setValue({
        ...companies,
        [formRole]: [getDefaultCompany(companyRole)],
      });
    }
  }, [companies, formRole, setValue, companyRole]);

  const insertNewCompany = (newCompany: Company, index: number): void => {
    const newFormRoleCompanies = [...companies[formRole]];
    newFormRoleCompanies[index] = newCompany;
    setValue({
      ...companies,
      [formRole]: newFormRoleCompanies,
    });
  };

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const newValue = e.target.value;
    // When user completely deletes the text, reset back to empty.
    if (newValue === '') {
      insertNewCompany(getDefaultCompany(companyRole), index);
    }
  }

  function handleCompanySelected(company: SelectItem, index: number) {
    if (company) {
      insertNewCompany(
        {
          ...companies[formRole][index],
          identifier: company.value,
          name: company.label,
          fundingAccounts: [
            {
              type: 'percentage',
              key: uuidv4(),
            },
          ],
        },
        index,
      );
    } else {
      insertNewCompany(getDefaultCompany(companyRole), index);
    }
  }

  const handleCompanyRemoved = (index: number) => {
    const companiesValues = companies?.[formRole];
    if (companiesValues) {
      const newCompaniesValues = [
        ...companiesValues.slice(0, index),
        ...companiesValues.slice(index + 1, companiesValues.length),
      ];
      setValue({
        ...companies,
        [formRole]: newCompaniesValues,
      });
    }
  };

  return (
    <div className="border-b-1 last:border-b-0 mx-6 pb-2">
      {contractItems && (
        <div className="flex flex-wrap justify-between pt-6">
          <div className="sm:w-1/2">
            <div className="font-semibold">{companyRole} Contract</div>
            <div className="body2 text-gray-500">Agreed upon and signed by the {companyRole}</div>
          </div>
          <div className="sm:w-1/3 pl-3 flex self-center">
            <FormikSelect
              name={`${lowerCase(companyRole)}Contract`}
              placeholder="Select Contract"
              items={contractItems}
              withSimpleErrorStyling
            />
          </div>
        </div>
      )}
      <div className="border-1 rounded mt-6 mb-4">
        <div className="bg-gray-100 p-4 font-bold w-full rounded rounded-b-none">{companyRole}</div>
        <Grid container className="px-4 pt-2">
          <Grid sm="2/3">
            <FormLabel label="Company" />
          </Grid>
          <Grid sm="1/3">
            <FormLabel label="Contact Name" />
          </Grid>
        </Grid>
        <Grid className="p-4">
          <FieldArray
            name={`${formLocation}.${formRole}`}
            render={({ push }) => (
              <>
                {companies[formRole].map((company: FormCompany, index: number) => {
                  return (
                    <Grid container key={`companies-${companyRole}-${company.key}`} className="justify-between px-2">
                      <Grid sm="2/3">
                        <FormikAutocomplete
                          name={`${formLocation}.${formRole}.${index}.identifier`}
                          items={companyData}
                          onChange={e => handleOnChange(e, index)}
                          onItemSelected={c => handleCompanySelected(c, index)}
                          initialSearchTerm={companies[formRole][index].name}
                        />
                      </Grid>
                      <Grid sm="1/3" className="flex flex-row">
                        <FormikTextField
                          name={`${formLocation}.${formRole}.${index}.contactName`}
                          label=""
                          inputProps={{
                            className: 'h-10',
                          }}
                          withSimpleErrorStyling
                        />
                        {!readOnly && (
                          <button
                            className="bg-white focus:outline-none py-2 pl-6 pr-2"
                            color="white"
                            type="button"
                            data-testid={`remove-companies-${formRole}-${index}`}
                            onClick={() => handleCompanyRemoved(index)}
                          >
                            <Trash size={14} />
                          </button>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                {!readOnly && (
                  <div className="pt-3 pb-1 px-2">
                    <button
                      className="text-primary body2 font-semibold"
                      type="button"
                      onClick={() => push(getDefaultCompany(companyRole))}
                      data-testid={`add-companies-${formRole}`}
                    >
                      + Add Company
                    </button>
                  </div>
                )}
              </>
            )}
          />
        </Grid>
      </div>
    </div>
  );
};

export default RoleCompanies;
