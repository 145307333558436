import { LoadingSpinner, Modal } from '@producepay/pp-ui';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { FULFILLMENT_API_CLIENT_NAME } from 'src/graphql/backends';
import GET_ORDER from 'src/graphql/queries/getOrder';
import DocumentsCard from './DocumentsCard';
import { GetOrderResponse } from '../OrdersIndex/types';
import OrderDetailsCard from './OrderDetailsCard';
import FinancingCard from './FinancingCard';
import OrderOverviewCard from './OrderOverviewCard';

export type OrderOverviewProps = {
  onClose: () => void;
};

const OrderOverview = ({ onClose }: OrderOverviewProps) => {
  const { orderId } = useParams();

  const { data, error, loading } = useQuery<GetOrderResponse>(GET_ORDER, {
    context: { clientName: FULFILLMENT_API_CLIENT_NAME },
    variables: { id: orderId },
  });

  const Overview = () => {
    const { dueDate, isFixedPrice, pickupNumber, receivers, referenceNumber, suppliers } = data.getOrder;
    const receiverName = receivers.length > 0 ? receivers[0].name : '';
    const supplierName = suppliers.length > 0 ? suppliers[0].name : '';

    return (
      <>
        <OrderOverviewCard
          dueDate={dueDate}
          isFixedPrice={isFixedPrice}
          pickupNumber={pickupNumber}
          receiverName={receiverName}
          referenceNumber={referenceNumber}
          supplierName={supplierName}
        />
        <OrderDetailsCard inventoryItems={data.getOrder.inventoryItems} />
        <DocumentsCard documents={data.getOrder.orderAttachments} />

        {process.env.REACT_APP_IS_FINANCING_CARD_ENABLED === 'true' && (
          <FinancingCard inventoryItems={data.getOrder.inventoryItems} />
        )}
      </>
    );
  };

  return (
    <Modal className="md:max-w-2xl lg:max-w-5xl bg-turnip-100" onClose={onClose}>
      {loading ? <LoadingSpinner /> : error ? <span>{error.message}</span> : <Overview />}
    </Modal>
  );
};

export default OrderOverview;
