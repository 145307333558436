import _ from 'lodash';

export function orderByDateStr(list, key, dir = 'desc') {
  if (_.isEmpty(list)) return [];

  return list.sort((a, b) => {
    return dir === 'desc' ? new Date(b[key]) - new Date(a[key]) : new Date(a[key]) - new Date(b[key]);
  });
}

export function takeNth(arr, nth) {
  if (!Array.isArray(arr)) return arr;

  return arr.filter((val, idx) => idx % nth === 0);
}

export function notEq(obj) {
  return _.negate(_.matches(obj));
}

export const isPresent = _.negate(_.isEmpty);

export function snakeCaseKeys(obj) {
  return _.mapKeys(obj, (_value, key) => _.snakeCase(key));
}

/* camelToSnake
 * A lot like the lodash _.snakeCase but it ONLY
 * changes strings that are in strict 4lphaNumericCamelCase.
 * This one will preserve "kabob-case", "snake_case", and
 * "!?wtf$case_^is&this".
 */
export function camelToSnake(camel) {
  const snake = [];
  for (let i = 0; i < camel.length; i += 1) {
    if (i !== 0 && camel.charCodeAt(i) > 47 && camel.charCodeAt(i) < 91) {
      // char is uppercase or number
      snake.push('_');
      snake.push(camel[i].toLowerCase());
    } else {
      snake.push(camel[i].toLowerCase());
    }
  }
  return snake.join('');
}

/** camelToSnakeKeysDeep
 *  json-like objects with nested props. Historically this has been done
 *  by manually calling `snakeCaseKeys` on nested objects, but this breaks
 *  We have a scenario (commands) where we really do need to snake_case_ify
 *  our ability to assert strict typing on our commands (see `src/helpers/command/types.ts`).
 *
 *  Solution: make a comprehensive snakeCaseKeysDeep that can be called
 *  by the command helper after it has verified that the command payload
 *  is what was expected. That way we keep our (snakeCased) commands strongly
 *  typed until the last possible moment.
 */

export function camelToSnakeKeysDeep(obj, options = { maxDepth: 10 }) {
  const { maxDepth } = options;

  const nextOptions = {
    ...options,
    maxDepth: maxDepth - 1,
  };

  // This is pretty much the best we can do in JS to find out if something is
  // really a key/value store.
  const isDictionary = value => _.isObjectLike(value) && !_.isArray(value) && !_.isError(value) && !_.isRegExp(value);

  const camelizeContents = contents => {
    if (maxDepth === 0) {
      throw new Error('Object is too deep. Try setting a higher value for `maxDepth`.');
    }
    switch (true) {
      case isDictionary(contents):
        return camelToSnakeKeysDeep(contents, nextOptions);
      case _.isArray(contents):
        return contents.map(item => (isDictionary(item) ? camelToSnakeKeysDeep(item, nextOptions) : item));
      default:
        return contents;
    }
  };
  return Object.entries(obj).reduce(
    (memo, [k, v]) => ({
      ...memo,
      [camelToSnake(k)]: camelizeContents(v),
    }),
    {},
  );
}

export const omitDeep = (collection, excludeKeys) => {
  function omitFn(value) {
    if (value && typeof value === 'object') {
      excludeKeys.forEach(key => {
        delete value[key]; // eslint-disable-line no-param-reassign
      });
    }
  }
  return _.cloneDeepWith(collection, omitFn);
};
