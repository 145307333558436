import { Column } from 'react-table';
import React from 'react';

import AuthenticatedDownloadLink from './AuthenticatedDownloadLink';
import Card from './Card';
import { OrderAttachment } from '../OrdersIndex/types';
import OrderOverviewTable from './Table';

const documentTypesMapping = {
  BILL_OF_LADING: 'Signed Bill of Lading',
  CROSSING_DOCUMENTS: 'Crossing Documents',
  INVOICE: 'PACA Invoice',
  MANIFEST: 'Manifest',
  PURCHASE_ORDER: 'Purchase Order',
  QUALITY_REPORT: 'Quality Report',
  OTHER: 'Other',
};

type DocumentsCardProps = {
  documents: OrderAttachment[];
};

const DocumentsCard = ({ documents }: DocumentsCardProps) => {
  const columns = React.useMemo<Column<OrderAttachment>[]>(
    () => [
      {
        Header: 'DATE',
        Cell: ({ value }) => (<div>{value || '--'}</div>),
      },
      {
        Header: 'TYPE',
        accessor: ({ documentType }) => documentTypesMapping[documentType],
        className: 'text-left',
      },
      {
        Header: 'ATTACHMENT',
        accessor: 'name',
        Cell: ({ row, value }) => {
          const fileName = row.original.name;
          const orderAttachmentId = row.original.id;
          const url = `${process.env.REACT_APP_FULFILLMENT_REST_URL}/order_attachments/${orderAttachmentId}/download`;
          return (
            <AuthenticatedDownloadLink fileName={fileName} url={url}>
              {value}
            </AuthenticatedDownloadLink>
          );
        },
      },
    ],
    [],
  );

  return (
    <Card>
      <h2 className="mb-7 font-semibold text-2xl text-turnip-900">Documents</h2>
      <OrderOverviewTable columns={columns} data={documents} />
    </Card>
  );
};

export default DocumentsCard;
