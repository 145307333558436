import { Decimal } from 'decimal.js';
import React from 'react';
import { useFormikContext } from 'formik';

import { formatCurrency } from 'src/helpers/currency';
import { isDecimal } from 'src/routes/Company/CompanyBalances/helpers';
import { AllocateBalanceFormValues } from 'src/routes/Company/CompanyBalances/types';

export function AllocationHeader({ selectedSourceSum, totalStillDue }) {
  const { values } = useFormikContext<AllocateBalanceFormValues>();

  const selectedDestinationSum = Decimal.sum(
    0,
    ...(Object.values(values)
      .flatMap(f => Object.values(f))
      .filter(isDecimal) as string[]),
  );

  const remainingSum = Decimal.sub(selectedSourceSum, selectedDestinationSum);
  const negativeRemaining = remainingSum.lt(0);

  return (
    <div>
      <div className="rounded border-1 p-4 my-4 flex justify-between">
        <div className="flex items-center">
          <div className="text-5xl font-bold" data-testid="destinations-selected-balance">
            {formatCurrency(selectedSourceSum)}
          </div>
          <div className="text-gray-500 mx-2">selected</div>
        </div>
        <div className={`${negativeRemaining ? 'bg-error bg-opacity-25' : 'bg-gray-200'} rounded p-2`}>
          <span className="font-semibold" data-testid="selected-source-remaining">
            {formatCurrency(remainingSum)}
          </span>{' '}
          remaining
        </div>
      </div>
      <div>
        <div className="text-md font-semibold">Allocate to Still Due Balances</div>
        <span className="text-gray-500">
          <span data-testid="total-unallocated">
            {formatCurrency(Decimal.sub(totalStillDue, selectedDestinationSum))}
          </span>{' '}
          can receive the selected funds
        </span>
      </div>
    </div>
  );
}
