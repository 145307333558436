import React from 'react';

import { FieldArray, useFormikContext } from 'formik';
import { Decimal } from 'decimal.js';
import { Grid } from '@producepay/pp-ui';
import Card from '../../elements/Card';
import FormRow from './FormRow';

import { formatCurrency } from '../../../helpers/currency';

function Unreconciled(): JSX.Element {
  const { values } = useFormikContext<ReceivePaymentFormValues>();

  const totalAllocated = values.unreconciled
    .reduce((acc, row) => new Decimal(row.amount || '0.00').plus(acc), new Decimal('0.00'))
    .toString();

  return (
    <FieldArray name="unreconciled">
      {({ form }) => (
        <div className="px-5 sm:px-8 sm:py-3" data-testid="received-payment-unreconciled">
          <Card className="px-5 sm:py-8">
            <Grid container>
              <Grid className="md:border-b">
                <div className="float-left font-bold text-lg pb-4">Unreconciled</div>
                <div className="float-right text-sm">
                  <span className="font-bold">{formatCurrency(totalAllocated)}</span>
                  <span> categorized as Unreconciled</span>
                </div>
              </Grid>
            </Grid>
            {form.values.unreconciled.map((row, index) => (
              <FormRow key={`unreconciled-${row}-${row.referenceNumber}`} index={index} form={form} />
            ))}
            {/*
              Temporarily disable adding additional unreconciled invoices
              TODO: Re-enable as part of https://app.clubhouse.io/producepay/story/8097

            <Grid container>
              <Grid sm="1/6" />
              <Grid>
                <button
                  type="button"
                  className="add-new-product"
                  onClick={() => push({ amount: '', referenceNumber: '', note: '' })}
                >
                  + Add Another Unreconciled Invoice
                </button>
              </Grid>
            </Grid>
            */}
          </Card>
        </div>
      )}
    </FieldArray>
  );
}

export default React.memo(Unreconciled);
