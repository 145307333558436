import React from 'react';
import { useHistory } from 'react-router-dom';

import { dealsEdit } from 'src/routes';
import { FormikDealStatus } from 'src/routes/Deals/types';
import { PNMValues } from 'src/routes/Deals/PNM/types';
import { FormAction } from '../../constants';
import FormikPNMForm from '../Form';

export const initialValues: PNMValues = {
  companies: [],
  dealReference: null,
  effectiveOn: null,
  endsOn: null,
  dealUuid: null,
  membershipFee: {
    amountDue: '1000.00',
    amountPaid: null,
    datePaid: null,
    methodPaid: null,
  },
  productLineId: 4,
  ratingTier: null,
  status: 'draft',
};

const initialStatus: FormikDealStatus = {
  readOnly: false,
};

export default () => {
  const history = useHistory();
  const handleDealSave = dealUuid => history.push(dealsEdit(dealUuid));

  return (
    <FormikPNMForm
      initialValues={initialValues}
      initialStatus={initialStatus}
      action={FormAction.Create}
      onDealSave={handleDealSave}
    />
  );
};
