import React from 'react';
import { useLocation } from 'react-router-dom';

import { startCase } from 'lodash';
import MenuBar from 'src/components/elements/MenuBar';
import { MENU_ORDER } from '../constants';

interface HeaderProps {
  actionComponents?: JSX.Element[] | JSX.Element;
}

function Header({ actionComponents }: HeaderProps): JSX.Element {
  const location = useLocation();

  const menuBarItems = MENU_ORDER.map(value => ({
    value,
    label: startCase(value),
    url: value,
    active: location.pathname.includes(`/${value}`),
  }));

  return (
    <div className="grid">
      <div className="bg-white border-b-1">
        <div className="px-8 py-2 h-16 flex justify-between items-center">
          <div className="h2 font-semibold capitalize">Reconcile Payments</div>
          {actionComponents && <div>{actionComponents}</div>}
        </div>
        <div className="max-w-sm">
          <MenuBar items={menuBarItems} />
        </div>
      </div>
    </div>
  );
}

export default Header;
