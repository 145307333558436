import React from 'react';
import { Card } from '@producepay/pp-ui';

import RoleCompanies from 'src/routes/Deals/components/RoleCompanies';
import SectionHeader from 'src/routes/Deals/components/SectionHeader';

export function Companies() {
  return (
    <Card>
      <SectionHeader title="Companies" subtitle="Entities trading through the deal" />
      <RoleCompanies companyRole="Seller" formRole="sellers" />
      <RoleCompanies companyRole="Buyer" formRole="buyers" />
    </Card>
  );
}
