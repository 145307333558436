import React, { useState } from 'react';
import { Checkbox } from '@producepay/pp-ui';

import DataTable from 'src/components/molecules/DataTable';
import { safeFormatISO } from 'src/helpers/dates';
import { formatCurrency } from 'src/helpers/currency';
import { useCompanyBalances } from 'src/routes/Company/CompanyBalances/CompanyBalancesContext';
import {
  Source,
  SourceEscrow,
  SourceOrder,
  SourcePreSeasonCollection,
  SourceType,
} from 'src/routes/Company/CompanyBalances/types';
import {
  groupPreSeasonCollections,
  PreSeasonCollection as PreSeasonCollectionType,
} from 'src/routes/Company/CompanyBalances/groupPreSeasonCollections';
import { getSourcesSum } from 'src/routes/Company/CompanyBalances/helpers';

function filterSources<T extends Source>(sources: Source[], type: SourceType): T[] {
  return sources.filter(source => source.type === type) as T[];
}

function rejectSources(sources: Source[], type: SourceType) {
  return sources.filter(source => source.type !== type);
}

function Title({ children }: { children: React.ReactNode }): JSX.Element {
  return <div className="flex justify-between font-semibold text-lg pb-3">{children}</div>;
}

function Escrow({ source }: { source?: SourceEscrow }) {
  const [checked, setChecked] = useState(false);

  const { selectedSources, setSelectedSources } = useCompanyBalances();

  const onSelected = () => {
    if (checked) {
      const sources = rejectSources(selectedSources, SourceType.Escrow);
      setSelectedSources([...sources]);
    } else {
      setSelectedSources([...selectedSources, source]);
    }
    setChecked(!checked);
  };

  if (!source) return null;

  return (
    <div className="bg-white py-4 px-5 my-3 flex justify-between font-semibold rounded">
      <div className="flex items-center" data-testid="source-escrow">
        <Checkbox onClick={onSelected} checked={checked} value="escrow" />
        <div className="mx-2">Escrow</div>
      </div>
      <div data-testid="source-escrow-balance">{formatCurrency(source.balance)}</div>
    </div>
  );
}

function Orders({ sources }: { sources: SourceOrder[] }) {
  const { selectedSources, setSelectedSources } = useCompanyBalances();
  const sourceOrdersBalance = getSourcesSum(sources);

  const onSelected = (_, selectedSourceOrders) => {
    const otherSources = rejectSources(selectedSources, SourceType.Order);
    setSelectedSources([...otherSources, ...selectedSourceOrders]);
  };

  return (
    <div className="bg-white rounded" data-testid="source-orders">
      <div className="flex justify-between font-semibold p-3 border-b-1">
        <div className="flex items-center">
          <div className="mx-2">Orders</div>
        </div>
        <div className="mx-2" data-testid="source-orders-balance">
          {formatCurrency(sourceOrdersBalance)}
        </div>
      </div>
      <div className="py-4 px-5 ">
        <DataTable<SourceOrder>
          className="table-auto"
          selectable
          trClass="odd:bg-gray-100"
          onSelect={onSelected}
          columns={[
            {
              displayName: 'Slug',
              name: 'slug',
              formatter: (v: string) => <span className="text-primary font-bold">{v}</span>,
            },
            {
              displayName: 'Order #',
              name: 'reference',
            },
            {
              displayName: 'Available',
              name: 'balance',
              formatter: (v: string) => formatCurrency(v),
            },
          ]}
          keyName="slug"
          rows={sources}
        />
      </div>
    </div>
  );
}

function PreSeasonCollection({ collection }: { collection: PreSeasonCollectionType }) {
  const { selectedSources, setSelectedSources } = useCompanyBalances();

  const onSelected = (_, selectedPreSeasonCollectionSources) => {
    const otherSources = rejectSources(selectedSources, SourceType.PreSeasonCollections);
    const otherPreSeasonCollectionSources = selectedSources.filter(
      selectedSource =>
        selectedSource.type === SourceType.PreSeasonCollections && selectedSource.dealUuid !== collection.dealUuid,
    );
    setSelectedSources([...otherSources, ...otherPreSeasonCollectionSources, ...selectedPreSeasonCollectionSources]);
  };
  return (
    <div data-testid={`source-pre-season-collections-${collection.dealUuid}`}>
      <div className="bg-gray-100 p-3 border-t-1 border-b-1 font-semibold">{collection.dealReference}</div>
      <div className="bg-white rounded py-4 px-5">
        <DataTable<SourcePreSeasonCollection>
          className="table-auto"
          selectable
          trClass="odd:bg-gray-100"
          onSelect={onSelected}
          columns={[
            {
              displayName: 'Prepayment #',
              name: 'reference',
            },
            {
              displayName: 'Prepayment',
              name: 'paid',
              formatter: (v: string) => formatCurrency(v),
            },
            {
              displayName: 'Fees',
              name: 'fees',
              formatter: (v: string) => formatCurrency(v),
            },
            {
              displayName: 'Due On',
              name: 'dueDate',
              formatter: (v: string) => safeFormatISO(v),
            },
            {
              displayName: 'Available',
              name: 'balance',
              formatter: (v: string) => formatCurrency(v),
            },
          ]}
          keyName="uuid"
          rows={collection.sources}
        />
      </div>
    </div>
  );
}

function PreSeasonCollectionsList({ sources }: { sources: SourcePreSeasonCollection[] }) {
  const sourcePreSeasonCollectionsBalance = getSourcesSum(sources);
  const preSeasonCollections = groupPreSeasonCollections(sources);

  return (
    <>
      <div className="bg-white rounded">
        <div className="flex justify-between font-semibold p-3 border-b-1">
          <div className="flex items-center">
            <div className="mx-2">Pre-Season Collections</div>
          </div>
          <div className="mx-2" data-testid="source-pre-season-collections-balance">
            {formatCurrency(sourcePreSeasonCollectionsBalance)}
          </div>
        </div>
      </div>

      {preSeasonCollections.map(collection => (
        <PreSeasonCollection key={collection.dealUuid} collection={collection} />
      ))}
    </>
  );
}

export function CompanyBalancesSources({ sources }: { sources: Source[] }) {
  const escrowSources = filterSources<SourceEscrow>(sources, SourceType.Escrow) ?? [];
  const escrowSource = escrowSources[0] ?? null;

  const orderSources = filterSources<SourceOrder>(sources, SourceType.Order);
  const preSeasonCollectionSources = filterSources<SourcePreSeasonCollection>(sources, SourceType.PreSeasonCollections);

  const availableBalance = getSourcesSum(sources);

  const collectionSources = [...preSeasonCollectionSources];
  const collectionsAvailableBalance = getSourcesSum(collectionSources);

  return (
    <>
      <div className="container">
        <Title>
          <div>Balance</div>
          <div data-testid="source-available-balance">{formatCurrency(availableBalance)}</div>
        </Title>
        <Escrow source={escrowSource} />
        <Orders sources={orderSources} />
      </div>

      <div className="container mt-4">
        <Title>
          <div>Collections</div>
          <div data-testid="source-collections-available-balance-r">{formatCurrency(collectionsAvailableBalance)}</div>
        </Title>
        <PreSeasonCollectionsList sources={preSeasonCollectionSources} />
      </div>
    </>
  );
}
