import React from 'react';
import { Card } from '@producepay/pp-ui';

import RoleCompanies from '../../components/RoleCompanies';
import SectionHeader from '../../components/SectionHeader';

const ISFRFormCompanies = () => {
  const sellerContracts = [
    { label: 'Non-recourse Purchase Agreement', value: 'NRPA' },
    { label: 'Recourse Purchase Agreement', value: 'RPA' },
  ];

  const receiverContracts = [{ label: 'AR Agreement Letter', value: 'ARRL' }];

  return (
    <Card>
      <SectionHeader title="Companies" subtitle="Entities trading through the deal" />
      <RoleCompanies companyRole="Seller" formRole="sellers" contractItems={sellerContracts} />
      <RoleCompanies companyRole="Receiver" formRole="receivers" contractItems={receiverContracts} />
    </Card>
  );
};

export default ISFRFormCompanies;
