import React from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { dealsEdit } from 'src/routes';
import { FormikDealStatus } from 'src/routes/Deals/types';
import { ISFRValues } from 'src/routes/Deals/ISFR/types';
import {
  FormAction,
  FARMERS_WIRE_IDENTIFIER,
  INSEASON_FUND_IDENTIFIER,
  DocumentCategory,
  Document,
} from '../../constants';
import FormikISFRForm from '../Form';

export const initialValues: ISFRValues = {
  applicationFee: {
    isRefundable: false,
    minimumAnnualProduceVolumeForRefund: '1000000.00',
    fees: [],
  },
  companies: {
    sellers: [],
    receivers: [],
  },
  dealReference: null,
  defaultBankAccounts: {
    sourceAccounts: [
      { identifier: INSEASON_FUND_IDENTIFIER, purpose: 'payment' },
      { identifier: FARMERS_WIRE_IDENTIFIER, purpose: 'balance_return' },
    ],
  },
  effectiveOn: null,
  endsOn: null,
  dealUuid: null,
  maximumRebate: {
    rate: '17.5',
    assetPoolAgingDays: '30',
  },
  payment: {
    receiverRole: null,
    purchasePriceRate: '80',
    paymentTerms: '24',
    receiverExceptions: [],
    requiredItems: [
      { name: Document.SIGNED_BILL_OF_LADING, type: 'preferred', category: DocumentCategory.PROOF_OF_DELIVERY },
      { name: Document.PACA_INVOICE, type: 'preferred', category: DocumentCategory.PROOF_OF_LOAD_VALUE },
      { name: Document.NOTICE_OF_INTENT, type: 'preferred', category: DocumentCategory.OTHER },
      { name: Document.BILL_OF_SALE, type: 'preferred', category: DocumentCategory.OTHER },
    ],
  },
  productLineId: 2,
  rebates: [
    { transactionVolumeThreshold: '', amount: '', key: uuidv4() },
    { transactionVolumeThreshold: '', amount: '', key: uuidv4() },
    { transactionVolumeThreshold: '', amount: '', key: uuidv4() },
  ],
  receiverContract: 'ARRL',
  sellerContract: null,
  status: 'draft',
  ui: {
    autoRenew: true,
    receiverExceptionsEnabled: false,
  },
};

const initialStatus: FormikDealStatus = {
  readOnly: false,
};

export default () => {
  const history = useHistory();
  const handleDealSave = dealUuid => history.push(dealsEdit(dealUuid));

  return (
    <FormikISFRForm
      initialStatus={initialStatus}
      initialValues={initialValues}
      action={FormAction.Create}
      onDealSave={handleDealSave}
    />
  );
};
