import React from 'react';
import { Formik, Form, FormikBag } from 'formik';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { add, formatISO } from 'date-fns';
import EnterPaymentDetails from 'src/components/molecules/PaymentDetails';
import { CardHeader, LoadingSpinner, Snackbar } from '@producepay/pp-ui';
import { toDecimal } from 'src/helpers/currency';
import api from 'src/helpers/axios';
import { snakeCaseKeys } from 'src/helpers/lodash';
import GET_COMPANIES from 'src/graphql/queries/getCompanies';
import { useAuth } from 'src/contexts/auth/AuthProvider';
import AllocatePayment from 'src/components/molecules/AllocatePaymentForm';

const initialValues = {
  receivedDate: new Date(),
  companyId: '',
  type: '',
  amount: '',
  checkNumber: '',
  checkReleaseDate: add(new Date(), { days: 7 }),
  products: {
    inSeason: [],
    preSeason: [],
  },
  excessPayment: { recipientId: '', amount: '', note: '' },
  unreconciled: [{ amount: '', referenceNumber: '', note: '' }],
};

const mapProducts = (products: Products) => {
  const mergedProducts = products.inSeason.concat(products.preSeason);
  return mergedProducts.map(
    product =>
      product.amount &&
      snakeCaseKeys({
        productIdentifier: product.identifier,
        type: product.category,
        amount: toDecimal(product.amount),
        held: product.held,
        forceClosed: product.forceClosed,
      }),
  );
};

const mapUnreconciled = (unreconciledItems: UnreconciledItem[]) =>
  unreconciledItems.map(
    item =>
      item.amount &&
      snakeCaseKeys({
        type: 'unreconciled',
        amount: toDecimal(item.amount),
        note: item.note,
        referenceNumber: item.referenceNumber,
      }),
  );

const mapExcessPayment = (excessPayment: ExcessPayment) => [
  excessPayment.amount &&
    snakeCaseKeys({
      type: 'excess payment',
      recipientId: excessPayment.recipientId,
      amount: toDecimal(excessPayment.amount),
      note: excessPayment.note,
    }),
];

function ReceivePaymentsForm(): JSX.Element {
  const { loading, data } = useQuery(GET_COMPANIES);
  const { getTokenSilently } = useAuth();
  const history = useHistory();
  const companies = data ? data.companies.map(company => ({ label: company.name, value: company.legacyId })) : [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (values: ReceivePaymentFormValues, formik: FormikBag<ReceivePaymentFormValues, any>) => {
    const allocations = _.compact(
      mapProducts(values.products).concat(mapUnreconciled(values.unreconciled), mapExcessPayment(values.excessPayment)),
    );

    const payload = snakeCaseKeys({
      command: 'ReceivePayment',
      type: values.type,
      receivedDate: formatISO(values.receivedDate, { representation: 'date' }),
      companyId: values.companyId,
      amount: toDecimal(values.amount),
      checkNumber: values.checkNumber,
      checkReleaseDate: values.type === 'check' ? formatISO(values.checkReleaseDate, { representation: 'date' }) : '',
      allocations,
    });

    try {
      const token = await getTokenSilently();
      const response = await api.post('/command', payload, { headers: { Authorization: `Bearer ${token}` } });
      history.push(response.data.redirect_url);
    } catch (error) {
      const errorMessage = error.response ? error.response.data.error.message : error.message;
      formik.setErrors({ companyId: `Error: ${errorMessage}` });
      formik.setSubmitting(false);
    }
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {props => (
          <Form>
            <CardHeader className="bg-white" titleClassName="font-extrabold text-2xl" title="Receive Payments" />
            {loading ? (
              <div className="text-center py-32">
                <LoadingSpinner className="h-3" />
              </div>
            ) : (
              <>
                <EnterPaymentDetails companies={companies} />
                <AllocatePayment companies={companies} />
                {props.errors && (
                  <Snackbar
                    error
                    onClose={() => props.setErrors({})}
                    isOpen={Boolean(props.errors.companyId)}
                    label={props.errors.companyId}
                  />
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default React.memo(ReceivePaymentsForm);
