import {
  BaseFormCompany,
  DefaultBankAccounts,
  FormFundingAccount,
  FormUI,
  RequiredItem,
  SharedDealFormValues,
  SourceAccount,
} from 'src/routes/Deals/types';

export interface PNMSValues extends SharedDealFormValues {
  companies: PNMSGroupedCompanies;
  defaultBankAccounts: {
    sourceAccounts: SourceAccount[];
  };
  distributionFee: PNMSDistributionFee;
  lateFee: PNMSLateFee;
  payment: PNMSPayment;
  ui: FormUI;
}

export interface PNMSValuesPayload extends Omit<PNMSValues, 'companies'> {
  companies: PNMSCompany[];
  defaultBankAccounts: DefaultBankAccounts;
  ui: FormUI;
}

export interface PNMSCompany extends BaseFormCompany {
  role: 'producer' | 'distributor';
  fundingAccounts: FormFundingAccount[];
}

export enum PNMSRole {
  Producer = 'producer',
  Distributor = 'distributor',
}

export type PNMSGroupedCompanies = Record<PNMSRole, PNMSCompany[]>;

interface PNMSDistributionFee {
  rate: string;
}

interface PNMSLateFee {
  dailyLateFeeRate: string;
  makeGoodDeliveryDays: string;
}

export enum PNMSDocumentAcceptanceRole {
  DistributorOrProducePay = 'distributorOrProducePay',
  Distributor = 'distributor',
}

interface PNMSPayment {
  documentAcceptanceRole: PNMSDocumentAcceptanceRole;
  receiverRole: PNMSRole;
  paymentRate: string;
  requiredItems: RequiredItem[];
}
