import { format, formatISO, isValid } from 'date-fns';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { CellProps } from 'react-table';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import cx from 'classnames';

import CalendarIcon from '@producepay/pp-ui/dist/components/icons/CalendarIcon';

import { Popper } from '../Popper';
import { EditCell, EditorProps } from './EditCell';
import { SelectCellInstance } from './plugins/useSelectCell';
import { Align, TextAlignClassName } from './AlignCellContent';

const parseToIso = (value: string): string => {
  const dateValue = new Date(value);
  if (!isValid(dateValue.getTime())) {
    return value;
  }
  return formatISO(dateValue);
};

const DATEPICKER_FORMAT = 'MM/dd/yyyy';

function formatDate(date: string | Date): string {
  try {
    return format(date instanceof Date ? date : new Date(date), DATEPICKER_FORMAT);
  } catch (e) {
    return '###ERROR';
  }
}

export const Calendar: FC = () => (
  <div className="h-6 w-6 rounded-full flex flex-shrink-0 items-center justify-center bg-gray-100">
    <CalendarIcon size={14} />
  </div>
);

export function DateViewer({
  value,
  column: { align = Align.Right },
}: CellProps<Record<string, unknown>> & {
  column: { align?: Align };
}) {
  const labelClassName = cx(TextAlignClassName[align], 'body2 pr-2');
  const label = value ? formatDate(value) : 'Select a Date';
  return (
    <div className="flex items-center">
      <span className={labelClassName}>{label}</span>
      <Calendar />
    </div>
  );
}

export function DateEditor({
  inputProps: { onBlur, ...inputProps },
  finishEdit,
  value,
  selectCellBelow,
}: EditorProps & CellProps<Record<string, unknown>> & SelectCellInstance) {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>();
  const Overlay = useMemo(
    () =>
      ({ classNames, month, ...props }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const ref = useRef<HTMLDivElement>();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const onOverlayBlur = useCallback((e: React.FocusEvent<Element>): void => {
          if (ref.current && !ref.current.contains(e.relatedTarget as Node)) {
            finishEdit();
          }
        }, []);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const onClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
          e.nativeEvent.stopImmediatePropagation();
        }, []);

        return (
          <Popper placement="bottom-start" referenceElement={referenceElement}>
            <div // eslint-disable-line jsx-a11y/click-events-have-key-events
              role="presentation"
              ref={ref}
              {...props}
              className="bg-white shadow 2"
              onBlur={onOverlayBlur}
              // TODO Kirill: figure out why onClick is required to stop event in the app
              // but not required in Storybook
              onClick={onClick}
            >
              {props.children}
            </div>
          </Popper>
        );
      },
    [referenceElement, finishEdit],
  );

  const dateValue = new Date(value);
  const isInvalidDate = !isValid(dateValue);

  const onDayPickerHide = useCallback(() => {
    finishEdit();
  }, [finishEdit]);
  const onDayClick = useCallback(
    day => {
      finishEdit({ setNewValue: true, newValue: day });
      selectCellBelow();
    },
    [finishEdit, selectCellBelow],
  );

  return (
    <div ref={setReferenceElement} className="flex items-center">
      <DayPickerInput
        overlayComponent={Overlay}
        keepFocus={false}
        dayPickerProps={{
          onDayClick,
          selectedDays: isInvalidDate ? new Date() : dateValue,
          month: isInvalidDate ? new Date() : dateValue,
        }}
        onDayPickerHide={onDayPickerHide}
        value={isInvalidDate ? value : formatDate(value)}
        inputProps={{
          ...inputProps,
          className: cx('pr-2 block', inputProps.className),
        }}
        format={DATEPICKER_FORMAT}
        formatDate={formatDate}
        placeholder="MM/DD/YYYY"
      />
      <Calendar />
    </div>
  );
}

export const DateCell = (props: CellProps<Record<string, unknown>> & { rootFieldName: string }) => (
  <EditCell preserveDisplayCellInput={false} {...props} Viewer={DateViewer} Editor={DateEditor} parser={parseToIso} />
);
