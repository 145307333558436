import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { FieldArray, useFormikContext } from 'formik';
import { TH } from '@producepay/pp-ui';
import ChevronDown from '@producepay/pp-ui/dist/components/icons/ChevronDown';
import ChevronUp from '@producepay/pp-ui/dist/components/icons/ChevronUp';
import Tooltip from '../../../elements/Tooltip';
import { formatCurrency } from '../../../../helpers/currency';

import './table.css';

interface SortState {
  value: string;
  direction: 'asc' | 'desc';
  type: string;
}

function UnreconciledPaymentIndexTable(): JSX.Element {
  const { values, ...formik } = useFormikContext<UnreconciledPaymentValues>();
  const defaultSortState: SortState = { value: 'paymentDate', direction: 'asc', type: 'string' };
  const [sort, setSort] = useState(defaultSortState);

  useEffect(() => {
    const mapRows = () =>
      sort.type === 'number'
        ? values.unreconciledPayments.map(row => ({ ...row, [sort.value]: parseFloat(row[sort.value]) }))
        : values.unreconciledPayments;
    const orderedRows = _.orderBy(mapRows(), [sort.value], [sort.direction]);
    formik.setFieldValue('unreconciledPayments', orderedRows);
    // missing deps: [formik, values.unreconciledPayments]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const handleSortClick = (value, type) => {
    const direction = sort.value === value && sort.direction === 'asc' ? 'desc' : 'asc';
    setSort({ value, direction, type });
  };

  const columns = [
    { label: 'Check #', value: 'checkNumber', sortable: true, type: 'string' },
    { label: 'From/Sender', value: 'senderName', sortable: true, type: 'string' },
    { label: 'Invoice/Reference #', value: 'referenceNumber', sortable: true, type: 'string' },
    { label: 'Received Amount', value: 'paymentAmount', sortable: true, type: 'number' },
    { label: 'Unreconciled Amount', value: 'unreconciledAmount', sortable: true, type: 'number' },
    { label: 'Received On', value: 'paymentDate', sortable: true, type: 'string' },
    { label: 'Notes', value: 'unreconciledNotes', sortable: true, type: 'string' },
  ];

  const setSourcePayment = payment => {
    formik.setFieldValue('sourcePayment', payment);
  };

  const renderNotes = notes =>
    notes && notes.length > 25 ? (
      <Tooltip placement="bottomRight" tooltipText={notes}>
        <span>{`${notes.slice(0, 24)}...`}</span>
      </Tooltip>
    ) : (
      notes
    );

  return (
    <FieldArray name="unreconciledPayments">
      {({ form }) => (
        <div className="pt-3 table-secondary table-p-sm table-alternating-rows">
          <table className="w-full">
            <thead>
              <tr>
                {columns.map(th => (
                  <TH align="left" size="xxs-xs" weight="bold">
                    {th.label}{' '}
                    {th.sortable &&
                      (th.value === sort.value && sort.direction === 'asc' ? (
                        <ChevronUp
                          className="inline-block"
                          size={11}
                          onClick={() => handleSortClick(th.value, th.type)}
                        />
                      ) : (
                        <ChevronDown
                          className="inline-block"
                          size={11}
                          onClick={() => handleSortClick(th.value, th.type)}
                        />
                      ))}
                  </TH>
                ))}
              </tr>
            </thead>
            <tbody>
              {form.values.unreconciledPayments.map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  <td className="text-sm">{row.checkNumber}</td>
                  <td className="text-sm">{row.senderName}</td>
                  <td className="text-sm">{row.referenceNumber}</td>
                  <td className="text-sm">{formatCurrency(row.paymentAmount)}</td>
                  <td className="text-sm">{formatCurrency(row.unreconciledAmount)}</td>
                  <td className="text-sm">{row.paymentDate}</td>
                  <td className="text-sm max-w-xs truncate">{renderNotes(row.unreconciledNotes)}</td>
                  <td className="text-sm">
                    <button onClick={() => setSourcePayment(row)} className="text-primary" type="button">
                      Allocate
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </FieldArray>
  );
}

export default React.memo(UnreconciledPaymentIndexTable);
