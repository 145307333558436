import React, { useState } from 'react';

import { CompanyBalancesSources } from 'src/routes/Company/CompanyBalances/CompanyBalancesSources';
import { CompanyBalancesDestinations } from 'src/routes/Company/CompanyBalances/CompanyBalancesDestinations';
import { CompanyBalancesContext } from 'src/routes/Company/CompanyBalances/CompanyBalancesContext';
import { Source } from 'src/routes/Company/CompanyBalances/types';

type Props = {
  companyIdentifier: string;
  sources: Source[];
};

export function CompanyBalances({ companyIdentifier, sources }: Props) {
  const [selectedSources, setSelectedSources] = useState([]);

  return (
    <CompanyBalancesContext.Provider value={{ selectedSources, setSelectedSources }}>
      <div className="flex justify-between">
        <div className="p-6 w-1/2">
          <CompanyBalancesSources sources={sources} />
        </div>
        <div className="p-6 bg-white w-1/2">
          <CompanyBalancesDestinations companyIdentifier={companyIdentifier} />
        </div>
      </div>
    </CompanyBalancesContext.Provider>
  );
}
