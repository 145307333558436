import { SourcePreSeasonCollection } from 'src/routes/Company/CompanyBalances/types';

export type PreSeasonCollection = { dealReference: string; dealUuid: string; sources: SourcePreSeasonCollection[] };

export function groupPreSeasonCollections(sources: SourcePreSeasonCollection[]): PreSeasonCollection[] {
  return sources.reduce<PreSeasonCollection[]>((acc, source) => {
    const foundIndex = acc.findIndex(
      ({ dealReference, dealUuid }) => dealReference === source.dealReference && dealUuid === source.dealUuid,
    );

    if (foundIndex === -1) {
      return [
        ...acc,
        {
          dealReference: source.dealReference,
          dealUuid: source.dealUuid,
          sources: [source],
        },
      ];
    }

    const found = acc[foundIndex];
    const a = acc.slice(0, foundIndex);
    const b = acc.slice(foundIndex + 1);
    return [
      ...a,
      ...b,
      {
        ...found,
        sources: [...found.sources, source],
      },
    ];
  }, []);
}
