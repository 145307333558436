import { FormikProps, FormikErrors } from 'formik';

import GeneralView from 'src/routes/Deals/PS/Form/General';
import MilestonesView from 'src/routes/Deals/PS/Form/Milestones';
import CommoditiesView from 'src/routes/Deals/PS/Form/Commodities';

import { PSForecastValue, PSValues } from 'src/routes/Deals/PS/types';
import { ValidationSchema } from 'src/routes/Deals/components/types';
import { RemoveDefaultEntities, TransformToPayload, MenuItem } from 'src/routes/Deals/types';
import { DealCommandType } from 'src/helpers/command/types';
import { FormAction } from 'src/routes/Deals/constants';

import {
  generalValidationSchema,
  prepaymentsValidationSchema,
  commoditiesValidationSchema,
  forecastValidationSchema,
  milestonesValidationSchema,
} from '../../validationSchemas';

import {
  transformToGeneralPayload,
  transformToMilestonesPayload,
  transformToCommoditiesPayload,
  getAllValues,
  getForecastValues,
  getGeneralValues,
  getMilestonesValues,
  getPrepaymentValues,
  getCommoditiesValues,
  transformToPrepaymentsPayload,
  transformToForecastPayload,
} from '../../helpers';
import { PrepaymentsView } from '../../Form/Prepayments';
import { ForecastView } from '../../Form/Forecast';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface PSConfigObj {
  SelectedView: React.ReactType;
  validationSchema: ValidationSchema;
  transformPayload: TransformToPayload<any, any>;
  removeDefaultEntities: RemoveDefaultEntities<any>;
  saveDraftSuccessToastBody: string;
  saveDraftFailedToastHeader: string;
  saveDraftButtonText: string;
  formatErrors: (errors: FormikErrors<unknown>) => FormikErrors<unknown>;
  getAllValues: RemoveDefaultEntities<any>;
  getGeneralValues: RemoveDefaultEntities<any>;
  transformToGeneralPayload: TransformToPayload<any, any>;
  commandType?: DealCommandType;
  onDealSaveCallback?(): void;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
type PSConfigMap = {
  [key in MenuItem]: PSConfigObj;
};
export const getConfigMap = (formikContext: FormikProps<PSValues>, formAction: FormAction): PSConfigMap => {
  const {
    values: {
      general: { dealUuid },
      commodities,
      milestones,
    },
    setFieldValue,
    initialValues: {
      milestones: initialMilestones,
      commodities: initialCommodities,
      prepayments: initialPrepayments,
      forecast: initialForecast,
    },
  } = formikContext;

  return {
    [MenuItem.General]: {
      SelectedView: GeneralView,
      validationSchema: generalValidationSchema,
      transformPayload: transformToGeneralPayload,
      removeDefaultEntities: getGeneralValues,
      saveDraftSuccessToastBody: 'Draft Saved.',
      saveDraftFailedToastHeader: 'Error saving Draft',
      saveDraftButtonText: 'Save Draft',
      getAllValues,
      getGeneralValues,
      transformToGeneralPayload,
      formatErrors: errors => ({ general: errors }),
      commandType: formAction === FormAction.Create ? DealCommandType.Create : DealCommandType.Update,
    },
    [MenuItem.Prepayments]: {
      SelectedView: PrepaymentsView,
      validationSchema: prepaymentsValidationSchema,
      transformPayload: values => transformToPrepaymentsPayload(dealUuid, initialPrepayments, values),
      removeDefaultEntities: getPrepaymentValues,
      saveDraftSuccessToastBody: 'Prepayments Saved.',
      saveDraftFailedToastHeader: 'Error saving Prepayments',
      saveDraftButtonText: 'Save Prepayments',
      getAllValues,
      getGeneralValues,
      transformToGeneralPayload,
      formatErrors: errors => ({ prepayments: errors }),
      commandType: DealCommandType.UpdatePSTranches,
    },
    [MenuItem.Commodities]: {
      SelectedView: CommoditiesView,
      validationSchema: commoditiesValidationSchema,
      transformPayload: commoditiesPayload =>
        transformToCommoditiesPayload(dealUuid, initialCommodities, commoditiesPayload),
      removeDefaultEntities: getCommoditiesValues,
      saveDraftSuccessToastBody: 'Commodities Saved.',
      saveDraftFailedToastHeader: 'Error saving Commodities',
      saveDraftButtonText: 'Save Commodities',
      getAllValues,
      getGeneralValues,
      transformToGeneralPayload,
      formatErrors: errors => ({ commodities: errors }),
      commandType: DealCommandType.UpdatePSForecastCommodities,
      onDealSaveCallback: () => {
        setFieldValue(
          'commodities',
          commodities
            .filter(commodity => commodity.action !== 'delete')
            .map(commodity => {
              const { action, ...commodityData } = commodity;
              return commodityData;
            }),
        );
      },
    },
    [MenuItem.Forecast]: {
      SelectedView: ForecastView,
      validationSchema: forecastValidationSchema,
      transformPayload: values =>
        transformToForecastPayload(dealUuid, initialForecast as Required<PSForecastValue>[], values),
      removeDefaultEntities: getForecastValues,
      saveDraftSuccessToastBody: 'Forecast Saved.',
      saveDraftFailedToastHeader: 'Error saving Forecast',
      saveDraftButtonText: 'Save Forecast',
      getAllValues,
      getGeneralValues,
      transformToGeneralPayload,
      formatErrors: errors => ({ forecast: errors }),
      commandType: DealCommandType.UpdatePSForecast,
    },
    [MenuItem.Milestones]: {
      SelectedView: MilestonesView,
      validationSchema: milestonesValidationSchema,
      transformPayload: milestonesPayload =>
        transformToMilestonesPayload(dealUuid, initialMilestones, milestonesPayload),
      removeDefaultEntities: getMilestonesValues,
      saveDraftSuccessToastBody: 'Milestones Saved.',
      saveDraftFailedToastHeader: 'Error saving Milestones',
      saveDraftButtonText: 'Save Milestones',
      getAllValues,
      getGeneralValues,
      transformToGeneralPayload,
      formatErrors: errors => ({ milestones: errors }),
      commandType: DealCommandType.UpdatePSMilestones,
      onDealSaveCallback: () => {
        setFieldValue(
          'milestones',
          milestones
            .filter(milestone => milestone.action !== 'delete')
            .map(milestone => {
              const { action, ...milestoneData } = milestone;
              return milestoneData;
            }),
        );
      },
    },
  };
};
