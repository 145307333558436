import gql from 'graphql-tag';

const GET_FUND_ACTIVITIES = gql`
  query FundActivities(
    $companyIdentifiers: [String]
    $startDate: String
    $endDate: String
    $dateRangeFor: String
    $searchText: String
    $entryTypes: [String]
    $perPage: Int
    $page: Int
    $showReverted: Boolean
  ) {
    fundActivities(
      companyIdentifiers: $companyIdentifiers
      startDate: $startDate
      endDate: $endDate
      dateRangeFor: $dateRangeFor
      searchText: $searchText
      entryTypes: $entryTypes
      perPage: $perPage
      page: $page
      showReverted: $showReverted
    ) {
      totalCount
      results {
        identifier
        createdOn: createdAt
        effectiveOn: effectiveDate
        companyId
        companyName
        entryType: activityType
        amount
        checkNumber
        legacyPaymentUrl
        reverted
        details {
          paymentMethod
          checkReleaseDate: checkClearDate
          allocations {
            allocationIdentifier
            destinationAllocationType
            destinationType
            destinationCompanyId
            destinationCompanyName
            destinationProductIdentifier
            destinationSlug
            destinationAmount
            destinationNotes
            destinationHeldAmount
            destinationReferenceNumber
            destinationCustomerBalance
            destinationMinimumStillDue
            destinationDetailsUrl
            sourceAllocationType
            sourceType
            sourceCompanyId
            sourceCompanyName
            sourceProductIdentifier
            sourceSlug
            sourceAmount
            sourceNotes
            sourceFundsCheckNumber
            sourceFundsIdentifier
            sourceLegacyPaymentUrl
            sourceReferenceNumber
            sourceCustomerBalance
            sourceMinimumStillDue
            sourceDetailsUrl
          }
        }
      }
    }
  }
`;

export default GET_FUND_ACTIVITIES;
