export interface Command {
  command: string;
}

export interface SetDefaultPrepaymentSourceFundingAccountForCompany {
  command: 'SetDefaultPrepaymentSourceFundingAccountForCompany';
  companyIdentifier: string;
  fundingAccountIdentifier: string;
}

export interface CreateFundingAccountCommand extends Command {
  command: 'CreateFundingAccount';
  companyIdentifier: string;
  institutionName: string;
  name: string;
  country: string;
  status: string;
  wireFee: string | null;
}

export interface UpdateFundingAccountCommand extends Command {
  command: 'UpdateFundingAccount';
  identifier: string;
  institutionName: string;
  name: string;
  country: string;
  status: string;
  wireFee: string | null;
}

export interface RevertReceivedPayment extends Command {
  command: 'RevertReceivedPayment';
  eventIdentifier: string;
}

export interface RevertTransferAllocated extends Command {
  command: 'RevertTransferAllocated';
  eventIdentifier: string;
}

export interface PreparedOutboundPaymentItem {
  destinationAccountIdentifier: string;
  sourceAccountIdentifier: string;
  amount: string;
  productIdentifier: string;
  purpose: string;
}

export interface PrepareItemsForOutboundPayment extends Command {
  command: 'PrepareItemsForOutboundPayment';
  payload: {
    outboundPaymentItems: PreparedOutboundPaymentItem[];
  };
}

export interface ApproveOutboundPayment extends Command {
  command: 'ApproveOutboundPayment';
  payload: {
    outboundPaymentItemIdentifiers: string[];
    sourceAccountIdentifier: string;
    destinationAccountIdentifier: string;
    notes: string;
  };
}

export interface UpdateOutboundPaymentNote extends Command {
  command: 'UpdateOutboundPaymentNote';
  payload: {
    outboundPaymentItemIdentifiers: string[];
    notes: string;
  };
}

export interface UnprepareItemsForOutboundPayment extends Command {
  command: 'UnprepareItemsForOutboundPayment';
  payload: {
    outboundPaymentItemIdentifiers: string[];
  };
}

export interface UnapproveOutboundPayment extends Command {
  command: 'UnapproveOutboundPayment';
  payload: {
    sourceAccountIdentifier: string;
    destinationAccountIdentifier: string;
    outboundPaymentItemIdentifiers: string[];
  };
}

export interface CancelApprovedOutboundPayment extends Command {
  command: 'CancelApprovedOutboundPayment';
  payload: {
    sourceAccountIdentifier: string;
    destinationAccountIdentifier: string;
    outboundPaymentItemIdentifiers: string[];
  };
}

export interface ReconcileOutboundPayment extends Command {
  command: 'ReconcileOutboundPayment';
  payload: {
    outboundPaymentItemIdentifiers: string[];
    sourceAccountIdentifier: string;
    destinationAccountIdentifier: string;
    notes: string;
    wireFee: string;
  };
}

export enum DealCommandType {
  Create = 'CreateDeal',
  Update = 'UpdateDeal',
  CreateAndActivate = 'CreateAndActivateDeal',
  UpdateAndActivate = 'UpdateAndActivateDeal',
  Delete = 'DeleteDeal',
  UpdatePSMilestones = 'UpdatePreseasonDealMilestones',
  UpdatePSForecastCommodities = 'UpdatePreseasonDealForecastCommodities',
  UpdatePSForecast = 'UpdatePreseasonDealForecasts',
  UpdatePSTranches = 'UpdatePreseasonDealTranches',
}

export interface Deal<P> extends Command {
  command: DealCommandType;
  payload: P;
}

export interface DeleteDeal extends Command {
  command: DealCommandType.Delete;
  payload: {
    dealUuid: string;
  };
}
