import React from 'react';
import { Decimal } from 'decimal.js';
import { flattenDeep, get, groupBy, isEmpty } from 'lodash';
import { Formik, Form } from 'formik';
import { Info as InfoIcon } from 'react-feather';
import { isBefore, parseISO } from 'date-fns';
import { LoadingSpinner } from '@producepay/pp-ui';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import gql from 'graphql-tag';

import { formatCurrency } from 'src/helpers/currency';
import FormikTextField from 'src/components/formik/FormikTextField';
import Tooltip from 'src/components/elements/Tooltip';

import { DestinationType, useCompanyBalances } from 'src/routes/Company/CompanyBalances/CompanyBalancesContext';
import { getSourcesSum, isDecimal } from 'src/routes/Company/CompanyBalances/helpers';
import { AllocationHeader } from 'src/routes/Company/CompanyBalances/AllocationHeader';
import { AllocationSection } from 'src/routes/Company/CompanyBalances/AllocationSection';
import { GetUnpaidFees, GetUnpaidFeesVariables } from 'src/routes/Company/CompanyBalances/graphql-types/GetUnpaidFees';
import {
  GetFinancialProductsWithBalance,
  GetFinancialProductsWithBalanceVariables,
} from 'src/routes/Company/CompanyBalances/graphql-types/GetFinancialProductsWithBalance';
import {
  ApplicationFee,
  FinancialProduct,
  MembershipFee,
  PartnershipFee,
  SectionNames,
  TestWire,
  TradingFee,
} from 'src/routes/Company/CompanyBalances/types';

export const GET_UNPAID_FEES_QUERY = gql`
  query GetUnpaidFees($companyUuid: String!) {
    unpaidFees(companyUuid: $companyUuid) {
      applicationFees {
        balance: amount
        toUuid: dealUuid
        dealSlug
        dueOn
        productLineId
      }
      membershipFees {
        balance: amount
        toUuid: dealUuid
        dealSlug
        dueOn
        productLineId
      }
      testWires {
        balance: amount
      }
      tradingFees {
        toUuid: assetUuid
        assetSlug
        balance: amount
        dueOn
      }
    }
  }
`;

export const GET_FINANCIAL_PRODUCTS_WITH_BALANCE_QUERY = gql`
  query GetFinancialProductsWithBalance($companyUuid: String!) {
    financialProductsWithBalance(companyUuid: $companyUuid) {
      totalCount
      nodes {
        toUuid: identifier
        balance
        reference
        dueDate
        deal {
          dealUuid
          dealReference
          productLine {
            slug
            id
          }
        }
      }
    }
  }
`;

interface AllocateBalancesProps {
  companyIdentifier: string;
}

export default function AllocateBalances({ companyIdentifier }: AllocateBalancesProps) {
  const { loading: loadingFees, data: feeData } = useQuery<GetUnpaidFees, GetUnpaidFeesVariables>(
    GET_UNPAID_FEES_QUERY,
    {
      variables: { companyUuid: companyIdentifier },
    },
  );

  const { loading: loadingFinancialProducts, data: financialProductData } = useQuery<
    GetFinancialProductsWithBalance,
    GetFinancialProductsWithBalanceVariables
  >(GET_FINANCIAL_PRODUCTS_WITH_BALANCE_QUERY, {
    variables: { companyUuid: companyIdentifier },
  });

  const { selectedSources } = useCompanyBalances();

  if (loadingFees || loadingFinancialProducts) {
    return <LoadingSpinner />;
  }

  // interact with queries after this

  const {
    financialProductsWithBalance: { nodes: financialProducts },
  } = financialProductData;

  const { unpaidFees } = feeData;

  const PRODUCT_LINE_GROUPING = {
    ispp: SectionNames.InSeason,
    isfr: SectionNames.InSeason,
    pnms: SectionNames.MidSeason,
    ps: SectionNames.PreSeason,
  };

  // TODO: Refactor when implementing financing allocations
  const groupedSources = {
    inSeason: [],
    preSeason: [],
    midSeason: [],
    membershipFees: [],
    applicationFees: [],
    tradingFees: [],
    ...unpaidFees,
    testWires: [
      new Decimal(unpaidFees?.testWires?.balance).gt(0) && {
        balance: unpaidFees?.testWires?.balance,
        type: DestinationType.Company,
        toUuid: companyIdentifier,
        toType: DestinationType.Company,
      },
    ],
    ...groupBy(financialProducts, v => PRODUCT_LINE_GROUPING[v?.deal?.productLine?.slug]),
  };

  function validateDecimal(value: string, balance: Decimal) {
    if (!isEmpty(value)) {
      if (isDecimal(value)) {
        const newAllocation = new Decimal(value);
        if (newAllocation.gt(balance)) {
          return 'Allocation too large';
        }
      } else {
        return 'Invalid input';
      }
    }
    return null;
  }

  const absCurrency = (value: string): string => formatCurrency(Decimal.abs(value ?? 0));

  const selectedSourceSum = getSourcesSum(selectedSources);

  const totalStillDue = Decimal.sum(
    0,
    ...flattenDeep(Object.values(groupedSources))
      .map(v => get(v, 'balance', 0))
      .map(v => Decimal.abs(v ?? 0)),
  );
  const stillDueHeader = (
    <span className="inline-flex">
      Still Due
      <Tooltip tooltipText="Principle and Fees" placement="top">
        <InfoIcon size={14} className="mx-1" />
      </Tooltip>
    </span>
  );

  return (
    <Formik initialValues={{}} onSubmit={null}>
      <Form>
        <AllocationHeader selectedSourceSum={selectedSourceSum} totalStillDue={totalStillDue} />
        <AllocationSection<FinancialProduct>
          title="In-Season"
          name={SectionNames.InSeason}
          allocationItems={groupedSources.inSeason}
          tableColumns={[
            {
              displayName: 'Slug',
              name: 'deal.dealReference',
              formatter: (v: string) => <span className="text-primary font-bold">{v}</span>,
            },
            {
              displayName: 'Due On',
              name: 'dueDate',
              formatter: (v: string) => {
                return <span className={cx({ 'text-error': isBefore(parseISO(v), Date.now()) })}>{v}</span>;
              },
            },
            {
              displayName: stillDueHeader,
              name: 'balance',
              formatter: absCurrency,
            },
            {
              displayName: 'Allocate',
              name: 'allocate',
              formatter: (_value, row) => (
                <FormikTextField
                  name={`inSeason.[${row.toUuid}]`}
                  iconPrefix={<span>$</span>}
                  validate={v => validateDecimal(v, Decimal.abs(row.balance ?? 0))}
                  withSimpleErrorStyling
                  errorClearable={false}
                />
              ),
            },
          ]}
        />
        <AllocationSection<FinancialProduct>
          title="Pre-Season"
          name={SectionNames.PreSeason}
          groupByKey="deal.dealReference"
          allocationItems={groupedSources.preSeason}
          tableColumns={[
            {
              displayName: 'Prepayment #',
              name: 'reference',
            },
            {
              displayName: 'Due On',
              name: 'dueDate',
            },
            {
              displayName: stillDueHeader,
              name: 'balance',
              formatter: (v: string) => formatCurrency(Decimal.abs(v ?? 0)),
            },
            {
              displayName: 'Allocate',
              name: 'allocate',
              formatter: (_value, row) => (
                <FormikTextField
                  name={`preSeason.[${row.toUuid}]`}
                  iconPrefix={<span>$</span>}
                  validate={v => validateDecimal(v, Decimal.abs(row.balance))}
                  withSimpleErrorStyling
                  errorClearable={false}
                />
              ),
            },
          ]}
        />
        <AllocationSection<FinancialProduct>
          title="Mid-Season"
          name={SectionNames.MidSeason}
          allocationItems={groupedSources.midSeason}
          tableColumns={[
            {
              displayName: 'Slug',
              name: 'deal.dealReference',
              formatter: (v: string) => <span className="text-primary font-bold">{v}</span>,
            },
            {
              displayName: 'Due On',
              name: 'dueDate',
            },
            {
              displayName: 'Still Due',
              name: 'balance',
              formatter: (v: string) => formatCurrency(Decimal.abs(v ?? 0)),
            },
            {
              displayName: 'Allocate',
              name: 'allocate',
              formatter: (_value, row) => (
                <FormikTextField
                  name={`midSeason.[${row.toUuid}]`}
                  iconPrefix={<span>$</span>}
                  validate={v => validateDecimal(v, Decimal.abs(row.balance))}
                  withSimpleErrorStyling
                  errorClearable={false}
                />
              ),
            },
          ]}
        />
        <AllocationSection<PartnershipFee>
          title="Partnerships"
          name={SectionNames.Partnerships}
          allocationItems={[]}
          tableColumns={[
            {
              displayName: 'Invoice',
              name: 'invoice',
              formatter: (v: string) => <span className="text-primary font-bold">{v}</span>,
            },
            {
              displayName: 'Due On',
              name: 'dueOn',
            },
            {
              displayName: 'Still Due',
              name: 'amountDue',
              formatter: (v: string) => formatCurrency(v),
            },
            {
              displayName: 'Allocate',
              name: 'allocate',
              formatter: (_value, row) => (
                <FormikTextField
                  name={`partnerships.[${row.toUuid}]`}
                  iconPrefix={<span>$</span>}
                  validate={v => validateDecimal(v, Decimal.abs(row.balance))}
                  withSimpleErrorStyling
                  errorClearable={false}
                />
              ),
            },
          ]}
        />
        <AllocationSection<ApplicationFee>
          title="In-Season App Fee"
          name={SectionNames.ApplicationFees}
          allocationItems={groupedSources.applicationFees}
          tableColumns={[
            {
              displayName: 'Deal',
              name: 'dealSlug',
              formatter: (v: string) => <span className="text-primary font-bold">{v}</span>,
            },
            {
              displayName: 'Still Due',
              name: 'balance',
              formatter: (v: string) => formatCurrency(Decimal.abs(v ?? 0)),
            },
            {
              displayName: 'Allocate',
              name: 'allocate',
              formatter: (_value, row) => (
                <FormikTextField
                  name={`applicationFees.[${row.toUuid}]`}
                  iconPrefix={<span>$</span>}
                  validate={v => validateDecimal(v, Decimal.abs(row.balance))}
                  withSimpleErrorStyling
                  errorClearable={false}
                />
              ),
            },
          ]}
        />
        <AllocationSection<MembershipFee>
          title="PN Membership"
          name={SectionNames.MembershipFees}
          allocationItems={groupedSources.membershipFees}
          tableColumns={[
            {
              displayName: 'Deal',
              name: 'dealSlug',
              formatter: (v: string) => <span className="text-primary font-bold">{v}</span>,
            },
            {
              displayName: 'Still Due',
              name: 'balance',
              formatter: (v: string) => formatCurrency(v),
            },
            {
              displayName: 'Allocate',
              name: 'allocate',
              formatter: (_value, row) => (
                <FormikTextField
                  name={`membershipFees.[${row.toUuid}]`}
                  iconPrefix={<span>$</span>}
                  validate={v => validateDecimal(v, Decimal.abs(row.balance))}
                  withSimpleErrorStyling
                  errorClearable={false}
                />
              ),
            },
          ]}
        />
        <AllocationSection<TradingFee>
          title="PN Trading Fee"
          name={SectionNames.TradingFees}
          allocationItems={groupedSources.tradingFees}
          tableColumns={[
            {
              displayName: 'Slug',
              name: 'assetSlug',
              formatter: (v: string) => <span className="text-primary font-bold">{v}</span>,
            },
            {
              displayName: 'Due On',
              name: 'dueOn',
              formatter: (v: string) => {
                return <span className={cx({ 'text-error': isBefore(parseISO(v), Date.now()) })}>{v}</span>;
              },
            },
            {
              displayName: 'Still Due',
              name: 'balance',
              formatter: (v: string) => formatCurrency(v),
            },
            {
              displayName: 'Allocate',
              name: 'allocate',
              formatter: (_value, row) => (
                <FormikTextField
                  name={`tradingFees.[${row.toUuid}]`}
                  iconPrefix={<span>$</span>}
                  validate={v => validateDecimal(v, Decimal.abs(row.balance))}
                  withSimpleErrorStyling
                  errorClearable={false}
                />
              ),
            },
          ]}
        />

        <AllocationSection<TestWire>
          title="Test Wire"
          name={SectionNames.TestWires}
          allocationItems={groupedSources.testWires}
          tableColumns={[
            {
              displayName: 'Still Due',
              name: 'balance',
              formatter: (v: string) => formatCurrency(v),
            },
            {
              displayName: 'Allocate',
              name: 'allocate',
              formatter: (_value, row) => (
                <FormikTextField
                  name={`testWires.[${companyIdentifier}]`}
                  iconPrefix={<span>$</span>}
                  validate={v => validateDecimal(v, Decimal.abs(row.balance))}
                  withSimpleErrorStyling
                  errorClearable={false}
                />
              ),
            },
          ]}
        />
      </Form>
    </Formik>
  );
}
