import React, { useRef, FC, useEffect, createContext, useContext } from 'react';
import { TableInstance, HeaderGroup, Cell, UsePaginationInstanceProps } from 'react-table';
import cx from 'classnames';

import styles from './table.module.css';
import { RowActionsState } from './plugins/useRowActions';

export interface ReactTableProps<T extends Record<string, unknown>> {
  outerBorders?: boolean;
  verticalBorders?: boolean;
  headerBorders?: boolean;
  Menu?: FC<TableInstance<T>>;
}

export const ReactTableContext = createContext<TableInstance<Record<string, unknown>>>(null);

export function useReactTableInstance<
  T extends Record<string, unknown>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  TOptions extends {} = {},
>(): TableInstance<T> & {
  clearSelection?(): void;
} & { state: RowActionsState } & TOptions {
  return useContext(ReactTableContext) as TableInstance<T> & {
    clearSelection?(): void;
  } & { state: RowActionsState } & TOptions;
}

export function ReactTable<T extends Record<string, unknown>>({
  outerBorders = true,
  verticalBorders = true,
  headerBorders = true,
  Menu,
}: ReactTableProps<T>) {
  const instance = useReactTableInstance<T, UsePaginationInstanceProps<Record<string, unknown>>>();

  const { getTableProps, headerGroups, rows, prepareRow, clearSelection, state, page } = instance;

  const tableRef = useRef<HTMLDivElement>();

  useEffect(() => {
    function onClick(e) {
      if (tableRef.current && !tableRef.current.contains(e.target)) {
        // eslint-disable-next-line no-unused-expressions
        clearSelection?.();
      }
    }
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [clearSelection]);

  return (
    <>
      <div
        role="table"
        {...getTableProps()}
        className={cx(
          styles.table,
          !outerBorders && styles.noOuterBorders,
          !verticalBorders && styles.noVerticalBorders,
        )}
        ref={tableRef}
      >
        <div role="rowgroup">
          {headerGroups.map(headerGroup => (
            <div role="row" {...headerGroup.getHeaderGroupProps()} className={styles.tr}>
              {headerGroup.headers.map((column: HeaderGroup<T> & { className?: string }) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                <div
                  role="columnheader"
                  tabIndex={0}
                  {...column.getHeaderProps()}
                  className={cx('p-3', styles.th, column.className)}
                >
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div role="rowgroup" className={cx(!headerBorders && styles.noHeaderBorder)}>
          {(page ?? rows).map(row => {
            prepareRow(row);
            return (
              <div role="row" {...row.getRowProps()} className={styles.tr}>
                {row.cells.map((cell: Cell<T> & { column: { className?: string } }) => (
                  <div role="cell" {...cell.getCellProps()} className={cx(styles.td, 'p-0.5', cell.column.className)}>
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      {state.menuTarget && Menu && <Menu {...instance} />}
    </>
  );
}
