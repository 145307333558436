import React, { useState } from 'react';
import { keyBy, mapValues, reject, sortBy, isEmpty, findIndex } from 'lodash';

import { Card } from '@producepay/pp-ui';
import { PlusCircle } from 'react-feather';
import { FieldArray, useFormikContext, useField } from 'formik';

import BorderedButton from 'src/components/elements/BorderedButton';
import { PSValues } from '../../types';
import PSFooter from '../../components/PSDealNavigation/PSFooter';
import PSCommodityModal from './PSCommodityModal';
import PSCommodityTableRow from './PSCommodityTableRow';

const CommoditiesView = () => {
  const { values, errors } = useFormikContext<PSValues>();
  const [, { value: commodityValues }, { setTouched }] = useField('commodities');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const distributorMap = keyBy(values.general.companies?.distributors, 'identifier');
  const distributorNameMap = mapValues(distributorMap, 'name');

  const filteredCommodityValues = reject(commodityValues, { action: 'delete' });
  const sortedCommodityValues = sortBy(filteredCommodityValues, [
    'commodity.name',
    'variety.name',
    'size.name',
    'packaging.name',
  ]);

  return (
    <FieldArray
      name="commodities"
      render={arrayHelpers => (
        <>
          <div className="p-6">
            <Card className="p-6">
              <div className="flex justify-between items-center">
                <div className="h3">Commodities</div>

                <BorderedButton
                  data-testid="add-commodity-button"
                  icon={<PlusCircle />}
                  error={errors.commodities && !commodityValues.length && 'Commodity is required'}
                  onClick={() => setIsModalOpen(true)}
                >
                  Add Commodity
                </BorderedButton>
              </div>

              <div className="pt-4">
                <table className="financeTable">
                  <thead>
                    <tr>
                      <th>Commodity</th>
                      <th>Variety</th>
                      <th>Size</th>
                      <th>Packaging</th>
                      <th>Type</th>
                      <th>Distributor</th>
                      <th style={{ width: 40 }} aria-label="icons" />
                    </tr>
                  </thead>

                  <tbody>
                    {!isEmpty(sortedCommodityValues) ? (
                      sortedCommodityValues.map(commodityValueObj => {
                        const { forecastCommodityUuid } = commodityValueObj;
                        // Find the non filtered index for proper remove and replace calls
                        const nonFilteredIndex = findIndex(commodityValues, { forecastCommodityUuid });

                        const isNewlyCreatedCommodity = commodityValueObj.action === 'create';

                        return (
                          <PSCommodityTableRow
                            key={forecastCommodityUuid}
                            commodity={commodityValueObj}
                            distributorNameMap={distributorNameMap}
                            onUpdate={newCommodityValues => {
                              const action = isNewlyCreatedCommodity ? 'create' : 'update';
                              arrayHelpers.replace(nonFilteredIndex, { ...newCommodityValues, action });
                            }}
                            onDelete={() => {
                              if (isNewlyCreatedCommodity) {
                                arrayHelpers.remove(nonFilteredIndex);
                              } else {
                                arrayHelpers.replace(nonFilteredIndex, { ...commodityValueObj, action: 'delete' });
                              }
                            }}
                          />
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <button
                            className="font-semibold block m-auto focus:outline-none"
                            type="button"
                            onClick={() => setIsModalOpen(true)}
                          >
                            + Add Commodity
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Card>

            <PSFooter className="p-6" />
          </div>

          {isModalOpen && (
            <PSCommodityModal
              title="Add Commodity"
              onClose={() => setIsModalOpen(false)}
              onSave={newCommodityValues => {
                arrayHelpers.push({ ...newCommodityValues, action: 'create' });
                setTouched(true);
              }}
            />
          )}
        </>
      )}
    />
  );
};

export default CommoditiesView;
