import React, { useCallback } from 'react';
import { TableInstance } from 'react-table';

import { SelectCellInstance } from './plugins/useSelectCell';
import { Menu } from '../Menu';
import { RowActionsInstance } from './plugins/useRowActions';
import { MenuItem } from '../MenuItem';

export function TableMenu<T extends Record<string, unknown>>({
  state: {
    menuTarget: { rowIndex, referenceElement },
  },
  insertRow,
  deleteRow,
  clearRow,
  closeMenu,
}: TableInstance<T> & SelectCellInstance & RowActionsInstance) {
  const onClearRow = useCallback(() => {
    clearRow(rowIndex);
    closeMenu();
  }, [clearRow, rowIndex, closeMenu]);

  const onDeleteRow = useCallback(() => {
    deleteRow(rowIndex);
    closeMenu();
  }, [deleteRow, rowIndex, closeMenu]);

  const onInsertRowAbove = useCallback(() => {
    insertRow(rowIndex);
    closeMenu();
  }, [insertRow, rowIndex, closeMenu]);

  const onInsertRowBelow = useCallback(() => {
    insertRow(rowIndex + 1);
    closeMenu();
  }, [insertRow, rowIndex, closeMenu]);

  return (
    <Menu referenceElement={referenceElement} onClose={closeMenu}>
      <MenuItem onClick={onClearRow}>Clear row</MenuItem>
      <MenuItem onClick={onDeleteRow}>Delete row</MenuItem>
      <MenuItem onClick={onInsertRowAbove}>Insert 1 above</MenuItem>
      <MenuItem onClick={onInsertRowBelow}>Insert 1 below</MenuItem>
    </Menu>
  );
}
