import React from 'react';
import { formatCurrency } from 'src/helpers/currency';

interface ReconcileFooterProps {
  amountToReconcile: number;
  onSave: () => void;
  onCancel: () => void;
}

const ReconcileFooter = ({ amountToReconcile, onSave, onCancel }: ReconcileFooterProps) => {
  return (
    <div className="bg-white px-40 py-4 mt-12 text-md font-semibold flex items-center justify-center">
      <div className="flex space-x-4 items-center">
        <p>Remaining amount to be reconciled</p>
        <p className="bg-tangerine bg-opacity-20 p-2 rounded">{formatCurrency(amountToReconcile)}</p>
      </div>
      <div className="flex items-center absolute right-40">
        <button type="button" className="ml-2 rounded-full py-2 px-4" onClick={onCancel}>
          Cancel
        </button>
        <button
          data-testid="save-reconciliation"
          type="button"
          className="ml-2 rounded-full bg-primary text-white py-2 px-4"
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default React.memo(ReconcileFooter);
