import React from 'react';

import SvgBankIcon from 'src/components/icons/Bank';

interface SectionHeaderProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  controls?: React.ReactNode;
  showIcon?: boolean;
}

const SectionHeader = ({ title, subtitle, controls, showIcon = true }: SectionHeaderProps) => {
  return (
    <div className="flex flex-row items-center justify-between border-b-1 p-6">
      <div className="sm:w-2/3 flex flex-row">
        {showIcon && (
          <span className="inline-block rounded p-2.5 mr-2 h-12 w-12 text-primary bg-primary-lighter bg-opacity-25">
            <SvgBankIcon size="28" />
          </span>
        )}
        <div>
          <div className="h3">{title}</div>
          <div className="body2 text-gray-500">{subtitle}</div>
        </div>
      </div>
      <div className="sm:w-1/3 pl-3">{controls}</div>
    </div>
  );
};

export default SectionHeader;
