import { Formik } from 'formik';
import React from 'react';
import { useToast } from '@producepay/pp-ui';
import { sumBy } from 'lodash';

import { ReceivedPaymentInfo, ReconcilePaymentsForm, SelectOption } from '../types';
import ReconcileBalance from './ReconcileBalance';
import ReconcileOptions from './ReconcileOptions';
import ReconcileFooter from './ReconcileFooter';
import ReceivedPayment from './ReceivedPayment';
import AddCompany from './AddCompany';

export interface ReconcilePaymentDetailsProps {
  receivedPayment: ReceivedPaymentInfo;
  initialValues: ReconcilePaymentsForm;
}

const calculateReconciliationAmounts = (formValues: ReconcilePaymentsForm, reconciledAmount: number) => {
  const toReconcile = formValues.companies
    .map(transaction => transaction.escrow.amount || 0 + sumBy(transaction.orders, 'reconciliationAmount'))
    .reduce((a, b) => a + b);

  const reconciled = toReconcile + reconciledAmount;

  return { reconciled, toReconcile };
};

const ReconcileDetails = ({ receivedPayment, initialValues }: ReconcilePaymentDetailsProps) => {
  const { addToastToQueue } = useToast();

  return (
    <Formik validateOnBlur={false} validateOnChange={false} initialValues={initialValues} onSubmit={() => undefined}>
      {({ values, handleSubmit, setValues }) => {
        const amounts = calculateReconciliationAmounts(
          values,
          receivedPayment.amount - receivedPayment.unreconciledReceivedAmount,
        );

        const handleSave = () => {
          if (amounts.reconciled > receivedPayment.amount) {
            addToastToQueue({
              actions: [{ label: 'Dismiss' }],
              header: 'Error reconciling payment',
              body: `The amount to reconcile can't be greater than the amount received on the payment.`,
              type: 'error',
            });
          } else {
            handleSubmit();
          }
        };

        const handleCompanyAdded = (company: SelectOption) => {
          values.companies.push({
            companyName: company.label,
            companyUuid: company.value,
            orders: [],
            escrow: { note: '', amount: 0 },
          });
          setValues(values);
        };

        return (
          <>
            <div className="flex flex-col items-center px-40 py-5 space-y-6">
              <ReconcileBalance receivedAmount={receivedPayment.amount} reconciledAmount={amounts.reconciled} />
              <ReceivedPayment receivedPayment={receivedPayment} />
              <ReconcileOptions reconcileOptions={values} />
              <AddCompany onCompanyAdded={handleCompanyAdded} />
            </div>
            <ReconcileFooter onSave={handleSave} onCancel={() => undefined} amountToReconcile={amounts.toReconcile} />
          </>
        );
      }}
    </Formik>
  );
};

export default ReconcileDetails;
