import React from 'react';
import { UseReactTableOptions, useReactTable } from './useReactTable';
import { ReactTableContext, ReactTable } from './ReactTable';

export function StandaloneReactTable<T extends Record<string, unknown>>({
  data,
  columns,
  options,
  customOptions,
  pluginHooks,
  ...rest
}: UseReactTableOptions<T>) {
  const instance = useReactTable({ data, columns, options, customOptions, pluginHooks });

  return (
    <ReactTableContext.Provider value={instance}>
      <ReactTable {...rest} />
    </ReactTableContext.Provider>
  );
}
